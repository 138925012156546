import { Box, LinearProgress, Skeleton } from '@mui/material'
import React from 'react'
import GetStartedDialog from './GetStartedDialog'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import { GetStartedStepType } from '__generated__/graphql'
import CloseIcon from '@mui/icons-material/Close'
import DismissGetStartedDialog from './DismissGetStartedDialog'

const GET_STARTED_STEPS_QUERY = gql(/* GraphQL */ `
  query getStartedSteps_GetStartedStatus($companieId: String!) {
    getStartedSteps(companieId: $companieId) {
      name
      type
      done
    }
  }
`)

const COUNTED_STEP_TYPES = [GetStartedStepType.Required, GetStartedStepType.Recommended]

const GetStartedStatus = () => {
  const { userRoleCompanie } = useAppContextWithCompany()

  const [dismissOpen, setDismissOpen] = React.useState(false)
  const { data, refetch } = useQuery(GET_STARTED_STEPS_QUERY, { variables: { companieId: userRoleCompanie.companie.id } })

  const steps = data?.getStartedSteps

  const { totalSteps, stepsCompleted } = React.useMemo(() => {
    let totalSteps = 0,
      stepsCompleted = 0
    if (!steps) return { totalSteps, stepsCompleted }

    for (const step of steps) {
      if (COUNTED_STEP_TYPES.includes(step.type)) {
        totalSteps++
        if (step.done) stepsCompleted++
      }
    }
    return { totalSteps, stepsCompleted }
  }, [steps])

  const showDismiss = React.useMemo(() => {
    if (!steps) return false
    for (const step of steps) {
      if (step.type === GetStartedStepType.Required && !step.done) return false
    }
    return true
  }, [steps])

  return (
    <Box padding="10px 20px">
      {!steps && <Skeleton variant="rectangular" sx={{ height: '120px', borderRadius: '8px' }} />}

      {steps && (
        <Box
          className="bg-dark-blue tac"
          sx={{
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          {showDismiss && (
            <Box sx={{ mt: '-8px', mr: '-8px', display: 'flex' }}>
              <CloseIcon onClick={() => setDismissOpen(true)} className="text-white fs-14 cursor" sx={{ ml: 'auto' }} />
              <DismissGetStartedDialog
                userRoleCompanieId={userRoleCompanie.id}
                open={dismissOpen}
                closeDialog={() => setDismissOpen(false)}
              />
            </Box>
          )}

          <Box className="fs-12 ff-roobert text-white">Get started with NachoNacho</Box>

          <LinearProgress
            variant="determinate"
            value={(stepsCompleted / totalSteps) * 100}
            sx={{
              bgcolor: 'white',
              height: '8px',
              borderRadius: '8px',
              my: '12px',
              '.MuiLinearProgress-bar': { bgcolor: 'var(--blue)' },
            }}
          />

          <Box className="fs-12 ff-roobert text-white fw-700" mb="12px">
            {stepsCompleted}/{totalSteps}
          </Box>

          <GetStartedDialog getStartedSteps={steps} refetch={refetch} />
        </Box>
      )}
    </Box>
  )
}

export default GetStartedStatus
