import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { User } from '__generated__/graphql'

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUserDateOfBirth_UpdateUserDob($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserDateOfBirth(data: $data, where: $where) {
      id
      dobYearTemp
      dobMonthTemp
      dobDayTemp
    }
  }
`)

type Props = {
  updateTextButton: string
  disabled: boolean
  user: Pick<User, 'id' | 'dobYearTemp' | 'dobMonthTemp' | 'dobDayTemp'>
  onUpdate: () => void
}

const UpdateUserDob = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [updateUserDob] = useMutation(UPDATE_USER_MUTATION)

  const updateUserDobF = async () => {
    setLoading(true)
    let updatedUser
    try {
      updatedUser = await updateUserDob({
        variables: {
          where: { id: props.user.id },
          data: {
            dobYearTemp: props.user.dobYearTemp,
            dobMonthTemp: props.user.dobMonthTemp,
            dobDayTemp: props.user.dobDayTemp,
          },
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
    setLoading(false)
    if (updatedUser) {
      props.onUpdate()
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="updateUserDob"
        id="updateUserDob"
        icon=""
        color="primary"
        disabled={props.disabled}
        variant="outlined"
        size="medium"
        buttonText={props.updateTextButton}
        buttonLoadingText="Loading..."
        onClick={updateUserDobF}
        loading={loading}
      />{' '}
      {errorMessage && <div className="secondary">{errorMessage}</div>}
    </>
  )
}

export default UpdateUserDob
