import React from 'react'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import CompanyToCateogoriesManagementChild from './CompanyToMetaTagsManagementChild'
import { Box, Chip, Paper } from '@mui/material'
import themeNN from 'components/themeNN'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import { TypeMetaTag } from '__generated__/graphql'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import Error from 'components/nav/error/Error'

const QUERY = gql(/* GraphQL */ `
  query metaTagsOfObjects_CompanyToMetaTagsManagement(
    $type: TypeMetaTag!
    $companieId: String
    $userRoleCompanieId: String
    $productId: String
  ) {
    metaTagsOfObjects(type: $type, companieId: $companieId, userRoleCompanieId: $userRoleCompanieId, productId: $productId) {
      id
      name
      type
    }
  }
`)

type Props = {
  companieId?: string
  userRoleCompanieId?: string
  productId?: string
  title: string
  type: TypeMetaTag
  multiple: boolean
  onUpdate?: () => void
}

const CompanyToMetaTagsManagement = (props: Props) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const { data, loading, error, refetch } = useQuery(QUERY, {
    variables: {
      userRoleCompanieId: props.userRoleCompanieId,
      companieId: props.companieId,
      productId: props.productId,
      type: props.type,
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.metaTagsOfObjects) return <NotFound />

  return (
    <>
      <div className="paperOut">
        <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
          {isEdit ? (
            <>
              <h4>{props.title}</h4>
              <CompanyToCateogoriesManagementChild
                multiple={props.multiple}
                metaTags={data.metaTagsOfObjects}
                companieId={props.companieId}
                userRoleCompanieId={props.userRoleCompanieId}
                productId={props.productId}
                type={props.type}
                onUpdate={() => {
                  refetch()
                  setIsEdit(false)
                  props.onUpdate && props.onUpdate()
                }}
                onCancel={() => {
                  setIsEdit(false)
                }}
              />
            </>
          ) : (
            <div>
              <EditHeaderSection
                canEdit={true}
                title={props.title}
                onClick={() => setIsEdit(true)}
                isEdit={isEdit}
                id="id-CompanyToMetaTagsManagement"
              />
              <Box display="flex" gap={1} flexWrap="wrap">
                {data.metaTagsOfObjects.map((title) => (
                  <Chip key={title.id} label={title.name} />
                ))}
              </Box>
            </div>
          )}
        </Paper>
      </div>
    </>
  )
}

export default CompanyToMetaTagsManagement
