import React from 'react'
import { Paper } from '@mui/material'
import { useQuery } from '@apollo/client'
import NotFound from 'components/nav/error/NotFound'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import { gql } from '__generated__'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import themeNN from 'components/themeNN'
import UserWhereHearAboutUsForm from './UserWhereHearAboutUsForm'
import utils from 'components/utils'

const USER_QUERY = gql(/* GraphQL */ `
  query user_UserWhereHearAboutUs($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      whereDidyouHearAboutUs
      whereDidYouHearAboutUsSource
    }
  }
`)

type Props = {
  userId: string
  defaultIsEdit: boolean
}

const UserWhereHearAboutUs: React.FC<Props> = (props: Props) => {
  const { userId } = props

  const [isEdit, setIsEdit] = React.useState(props.defaultIsEdit)

  const { loading, error, data } = useQuery(USER_QUERY, { variables: { where: { id: userId } } })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />
  const title = 'Where did you hear about us?'
  return (
    <div className="paperOut">
      <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
        {isEdit ? (
          <div>
            <h4>{title}</h4>
            <UserWhereHearAboutUsForm
              showCancelButton={true}
              updateTextButton="Save"
              cancelTextButton="Cancel"
              user={data.user}
              onUpdate={() => setIsEdit(false)}
              onCancel={() => setIsEdit(false)}
            />
          </div>
        ) : (
          <>
            <EditHeaderSection
              canEdit={true}
              id="id-UserProfileDob"
              title={title}
              onClick={() => setIsEdit(true)}
              isEdit={isEdit}
            />
            <br />
            <div>
              {data.user.whereDidYouHearAboutUsSource && (
                <div>{utils.getFriendlyWhereDidYouHearAboutUsSource(data.user.whereDidYouHearAboutUsSource)}</div>
              )}
            </div>
            {data.user.whereDidyouHearAboutUs}
          </>
        )}
      </Paper>
    </div>
  )
}

export default UserWhereHearAboutUs
