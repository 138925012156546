import React from 'react'
import { FormControl, FormLabel } from '@mui/material'
import { Product } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'
import UpdateProductLight from 'components/product/single/edit/UpdateProductLight'
import EditField from 'components/product/single/edit/wysiwyg/EditField'
import theme from 'components/themeNN'

type Props = {
  product: Pick<Product, 'id' | 'shortDescription' | 'productDescription'>
  onUpdate: () => void
}

const MavenDescriptionForm = (props: Props) => {
  const MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH = 120
  const [product, setProduct] = React.useState(props.product)
  const [errorShortDescription, setErrorShortDescription] = React.useState('')
  const validateName = (value: string) => {
    if (value.length > MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH) {
      setErrorShortDescription(`Short description can't be longer than ${MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH} characters`)
      return false
    }
    if (value.length === 0) {
      setErrorShortDescription('Short description can not be empty')
      return false
    }
    setErrorShortDescription('')
    return true
  }
  return (
    <>
      <div className="titleStepKYC">Maven profile</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div id="shortDescription">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="shortDescription"
              label={`Short description (${product.shortDescription.length}/${MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH})`}
              multiline
              maxRows={4}
              onChange={(e) => {
                validateName(e.target.value)
                setProduct({
                  ...product,
                  shortDescription: e.target.value,
                })
              }}
              error={!!errorShortDescription}
              helperText={errorShortDescription}
              type="text"
              value={product.shortDescription}
            />
          </FormControl>
        </div>
        <div id="productDescription">
          <FormLabel htmlFor="productDescription" sx={theme.formLabel}>
            Full description
          </FormLabel>
          <EditField
            options={['inline', 'blockType', 'list']}
            text={product.productDescription}
            onChange={(productDescription) => {
              setProduct({
                ...product,
                productDescription,
              })
            }}
          />
        </div>

        <div style={{ marginTop: '20px' }}>
          <UpdateProductLight
            buttonText="Continue"
            hideCancel
            fullWidth
            onCancel={() => {}}
            product={{
              id: props.product.id,
              shortDescription: product.shortDescription,
              productDescription: product.productDescription,
            }}
            disabled={!product.shortDescription || !!errorShortDescription}
            onSaved={() => {
              props.onUpdate && props.onUpdate()
            }}
          />
        </div>
      </div>
    </>
  )
}
export default MavenDescriptionForm
