import React from 'react'
import utils from '../../../utils'
import { useMutation, useApolloClient } from '@apollo/client'
import { ButtonProps } from '@mui/material'
import ButtonLoadingAfterClick from '../../../nav/ButtonLoadingAfterClick'
import { useNavigate } from 'react-router-dom'
import useShowErrors from 'hooks/useShowErrors'
import { Companie, Invoice } from '__generated__/graphql'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation payInvoice_PayInvoiceButton($where: InvoiceWhereUniqueInput!) {
    payInvoice(where: $where) {
      id
    }
  }
`)

type Props = {
  invoice: Pick<Invoice, 'id' | 'buyerFinalPrice'> & {
    companie: Pick<Companie, 'id'>
  }
  size?: ButtonProps['size']
  color?: ButtonProps['color']
  variant?: ButtonProps['variant']
}

const PayInvoiceButton = (props: Props) => {
  const showErrors = useShowErrors()
  const navigate = useNavigate()
  const client = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const [payInvoice] = useMutation(MUTATION)
  const payInvoiceF = async () => {
    setLoading(true)

    try {
      const newInvoice = await payInvoice({
        variables: {
          where: {
            id: props.invoice.id,
          },
        },
      })

      if (newInvoice) {
        await client.resetStore()
        navigate('/paymentSource/' + props.invoice.companie.id, { replace: true })
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)

      return
    }
  }
  return (
    <>
      <ButtonLoadingAfterClick
        actionName="payInvoice"
        id="idButton"
        disabled={false}
        icon=""
        color={props.color}
        variant={props.variant ? props.variant : 'contained'}
        size={props.size ? props.size : 'medium'}
        buttonText={`Pay now: ${utils.priceFormated(-props.invoice.buyerFinalPrice, 'usd')}`}
        buttonLoadingText="Setting up..."
        onClick={payInvoiceF}
        loading={loading}
      />

      <div id="idMessage" className="secondary">
        {message}
      </div>
    </>
  )
}

export default PayInvoiceButton
