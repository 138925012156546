import React from 'react'
import Icon from '@mui/material/Icon'
import { useAppContext } from '../../AppContext'

type Props = {
  message: string
}

const Error = (props: Props) => {
  const context = useAppContext()

  React.useEffect(() => {
    if (props.message === 'NOT_AUTH') {
      console.log(props.message)
      console.log('logout-76')
      context.logout(['app'])
      return
    }
    if (props.message === 'jwt expired') {
      console.log('logout-1')
      console.log(props.message)
      context.logout(['app'])
      return
    }
    if (props.message === 'NO_DEVICE') {
      console.log('logout-78')
      console.log(props.message)
      context.logout(['app'])
      return
    }
    if (props.message === 'DEVICE_NOT_VERIFIED') {
      console.log('logout-74')
      console.log(props.message)
      context.logout(['app'])
      return
    }
  }, [context, props.message])

  return (
    <>
      {context.me.role === 'ADMIN' && (
        <div className="tac">
          <Icon className="textSize20">error_outline</Icon>
          <h2>Error!</h2>
          <h3>Oops! Something went wrong. Please try again or contact us.</h3>
        </div>
      )}
    </>
  )
}

export default Error
