import React from 'react'
import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { User } from '__generated__/graphql'

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUserCreationIssuedCard_UserNameFormCreationIssuedCard($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserCreationIssuedCard(data: $data, where: $where) {
      id
      lastName
      firstName
    }
  }
`)

type Props = {
  user: Pick<User, 'id' | 'firstName' | 'lastName' | 'dobMonth' | 'dobYear' | 'dobDay'>
  disabled: boolean
  onUpdate: () => void
}

const UserNameFormCreationIssuedCard = (props: Props) => {
  const showErrors = useShowErrors()
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)
  const [errorMessage, setErrorMessage] = React.useState('')

  const updateUserF = async () => {
    try {
      setErrorMessage('')
      const userData = await updateUser({
        variables: {
          where: { id: props.user.id },
          data: {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            dobMonth: props.user.dobMonth,
            dobYear: props.user.dobYear,
            dobDay: props.user.dobDay,
          },
        },
      })

      console.log('userData')
      console.log(userData)
      if (userData) {
        console.log('onUpdate')
        props.onUpdate()
      }
    } catch (e) {
      showErrors(e, setErrorMessage)
    }
  }

  return (
    <>
      <Button disabled={props.disabled} variant="outlined" color="primary" onClick={updateUserF}>
        Next
      </Button>{' '}
      <div className="secondary">{errorMessage}</div>
    </>
  )
}

export default UserNameFormCreationIssuedCard
