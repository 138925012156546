import React from 'react'
import ButtonLoadingAfterClick from '../nav/ButtonLoadingAfterClick'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation createInvitationOwnership_CreateInvitationOwnership($companieId: String!, $email: String!) {
    createInvitationOwnership(companieId: $companieId, email: $email) {
      id
    }
  }
`)

type Props = {
  email: string
  disabled: boolean
  companieId: string
  onCreate?: (userId: string) => void
}

const CreateInvitationOwnership = (props: Props) => {
  const showErrors = useShowErrors()

  const [createInvitation] = useMutation(MUTATION)

  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const createInvitationF = async () => {
    setLoading(true)

    let invitation
    try {
      setMessage('')
      invitation = await createInvitation({
        variables: {
          email: props.email,
          companieId: props.companieId,
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
    setLoading(false)
    if (invitation) {
      if (props.onCreate) {
        props.onCreate(invitation.data.createInvitationOwnership.id)
      }
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createInvitation"
        id="idButton"
        icon=""
        color="primary"
        variant="contained"
        disabled={props.disabled}
        sx={{ width: '100%', marginRight: '10px' }}
        size="medium"
        buttonText="Invite via email"
        buttonLoadingText="Setting up..."
        onClick={createInvitationF}
        loading={loading}
      />
      <p id="idMessage" className="secondary">
        {message}
      </p>
    </>
  )
}

export default CreateInvitationOwnership
