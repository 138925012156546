import React from 'react'
import { Paper } from '@mui/material'
import { useAppContextWithCompany } from 'components/AppContext'
import KnowYourCustomerDialog from 'components/onboarding/KnowYourCustomerDialog'
import utils from 'components/utils'
import { gql } from '__generated__'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import { useQuery } from '@apollo/client'

const KYC_QUERY = gql(/* GraphQL */ `
  query getMavenKYCStatus_MavenVerificationWarning($companieId: String!) {
    getMavenKYCStatus(companieId: $companieId) {
      personalDetails
      personalLocation
      mavenType
      companyDetails
      mavenAddress
      basicInformation
      serviceCategories
      socialMedia
      productDescription
      valueProposition
      pricing
      targetCustomers
      submitForm
    }
  }
`)

const MavenVerificationWarning = () => {
  const context = useAppContextWithCompany()
  const isVerified = utils.getVerifiedState(context)
  const isSubmitted = utils.getSubmittedState(context)
  const { data, loading, error } = useQuery(KYC_QUERY, {
    variables: { companieId: context.userRoleCompanie.companie.id },
  })
  if (loading) return <></>
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (!data) return <NotFound />
  if (isVerified) return null
  let continueForm = false
  for (const key in data.getMavenKYCStatus) {
    if (['personalDetails', 'personalLocation', 'valueProposition'].includes(key)) continue
    if (data.getMavenKYCStatus[key] === true) continueForm = true
  }
  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn" style={{ backgroundColor: '#1445FE' }}>
          <div style={{ color: 'white', fontFamily: 'Roobert' }}>
            {isSubmitted ? (
              <>
                <b style={{ fontSize: 18 }}>Pending profile verification</b>
                <p style={{ fontSize: 14 }}>
                  We have received your completed information, and are reviewing it. Once you’re verified, your Maven profile will
                  be made public and will become discoverable by hundreds of thousands of businesses.
                </p>
              </>
            ) : (
              <>
                <b style={{ fontSize: 18 }}>
                  {continueForm ? 'Let’s finish your Maven profile setup!' : 'Let’s set up your Maven profile!'}
                </b>
                <p style={{ fontSize: 14 }}>
                  Tell us about you, your company and the services you provide, so we can match you with your ideal customers.
                </p>
                <KnowYourCustomerDialog
                  title={continueForm ? 'Continue Profile Setup' : 'Get started'}
                  size="small"
                  variant="outlined"
                />
              </>
            )}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default MavenVerificationWarning
