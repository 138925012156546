import React from 'react'
import NotFound from '../../../nav/error/NotFound'
import Loading from '../../../nav/error/Loading'
import Error from '../../../nav/error/Error'
import utils from '../../../utils'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'

type Props = {
  userId: string
}

const USER_QUERY = gql(/* GraphQL */ `
  query user_UserName($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      email
    }
  }
`)

const UserName = (props: Props) => {
  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: { where: { id: props.userId } },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  const user = data.user

  return <>{utils.getNameOrEmail(user, 'both')}</>
}

export default UserName
