import React from 'react'
import CreateIssuedCard from './CreateIssuedCard'
import AddressesQuery from '../../../addresse/list/AddressesQuery'
import { useNavigate } from 'react-router-dom'
import MappingAuthorizedAmountUnit from '../authorization/MappingAuthorizedAmountUnit'
import utils from '../../../utils'
import { Button } from '@mui/material'
import { Companie, IssuedCardTypeCreation, TypeIssuedCard } from '__generated__/graphql'
import UserName from 'components/nav/layout/titlePage/UserName'

type IssuedCardType = React.ComponentProps<typeof CreateIssuedCard>['issuedCard']

type Props = {
  companie: Pick<Companie, 'id' | 'name' | 'isPersonal'>
  userId: string
  issuedCard: IssuedCardType
  onCancel: () => void
}

const CreatePhyisicalIssuedCardSummary = (props: Props) => {
  const navigate = useNavigate()
  return (
    <div style={{ width: '350px', margin: '32px auto 0' }}>
      <h2 style={{ fontWeight: 'bold', fontFamily: 'Roobert' }}>Review your order</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Name of the card:</div> <div>{props.issuedCard.name}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Cardholder:</div>{' '}
        <div>
          <UserName userId={props.userId} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>One-time cost:</div>
        <div>$15</div>
      </div>

      <div>
        <AddressesQuery
          title="Shipping Address"
          variables={{
            where: {
              companie: { id: props.companie.id },
              user: { id: props.userId },
              type: 'SHIPPING',
            },
          }}
        />
      </div>
      <div>
        <h3>Spending Limit (you can change this at any time) </h3>
        {props.issuedCard.authorizedAmountUnit === 'NONE' ? (
          <div>No limit</div>
        ) : (
          <div>
            {utils.priceFormated(Number(props.issuedCard.authorizedAmount), 'usd')}{' '}
            <MappingAuthorizedAmountUnit authorizedAmountUnit={props.issuedCard.authorizedAmountUnit} />
          </div>
        )}
      </div>

      <div style={{ height: '15px' }} />
      <div style={{ marginLeft: '-17px' }}>
        <Button onClick={props.onCancel}>Back</Button>{' '}
        <CreateIssuedCard
          typeCreation={IssuedCardTypeCreation.CreateCardForm}
          disabled={false}
          productId=""
          buttonText="Ship it"
          type={TypeIssuedCard.Physical}
          issuedCard={props.issuedCard}
          userId={props.userId}
          companieId={props.companie.id}
          onCreate={(issuedCard) => navigate(`/issuedCard/${issuedCard.id}?isNewCard=true`, { replace: true })}
        />
      </div>
    </div>
  )
}

export default CreatePhyisicalIssuedCardSummary
