import React from 'react'
import { useAppContext } from 'components/AppContext'
import UserRoleCompanieMenuListX from 'components/userRoleCompanie/list/UserRoleCompanieMenuListX'
import { UserRoleCompanie } from '__generated__/graphql'

type Props = {
  onClose: () => void
  userRoleCompanies: Array<
    Pick<UserRoleCompanie, 'id'> & {
      companie: Pick<UserRoleCompanie['companie'], 'id' | 'name' | 'typeCompanie'>
    }
  >
}

const MenuListLeft = (props: Props) => {
  const context = useAppContext()

  return (
    <div id="menu-companie">
      {context.me && <UserRoleCompanieMenuListX userRoleCompanies={props.userRoleCompanies} onClose={props.onClose} />}
    </div>
  )
}

export default MenuListLeft
