import React from 'react'
import Error from '../../../nav/error/Error'
import Loading from '../../../nav/error/Loading'
import { useQuery } from '@apollo/client'
import NotFound from '../../../nav/error/NotFound'
import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material'
import { useAppContextWithCompany } from 'components/AppContext'
import ImageTemplate from 'components/nav/ImageTemplate'
import CreateInvitation from 'components/invitation/CreateInvitation'
import { ReactComponent as NewUserIcon } from 'assets/icon/newUser.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { gql } from '__generated__'
import { CompanieRole, UserRoleCompanie, UserRoleCompanieOrderByInput } from '__generated__/graphql'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompaniesTeamConnection_UserTeamSelect(
    $orderBy: UserRoleCompanieOrderByInput
    $where: UserRoleCompanieWhereInput
    $skip: Int
    $first: Int
  ) {
    userRoleCompaniesTeamConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      edges {
        node {
          id
          companieRole
          user {
            id
            firstName
            nameFile
            lastName
            email
          }
          companie {
            id
            name
          }
        }
      }
    }
  }
`)

type Props = {
  label: string
  hideAnalyst?: boolean
  onChange: (userRoleCompanie: UserRoleCompanie) => void
}

const UserTeamSelect = (props: Props) => {
  const context = useAppContextWithCompany()
  const location = useLocation()
  const navigate = useNavigate()
  const parsed = queryString.parse(location.search)
  const userId: string = parsed.selectUserId ? (parsed.selectUserId as string) : ''
  const [open, setOpen] = React.useState(false)
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      orderBy: UserRoleCompanieOrderByInput.FirstNameAsc,
      where: {
        companieId: context.userRoleCompanie.companie.id,
        companieRole: props.hideAnalyst ? { in: [CompanieRole.Admin, CompanieRole.Owner, CompanieRole.Purchaser] } : undefined,
      },
    },
    fetchPolicy: 'no-cache',
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.userRoleCompaniesTeamConnection) return <NotFound />

  const { edges } = data.userRoleCompaniesTeamConnection

  const onCreate = async (newUserId) => {
    await refetch()
    parsed.selectUserId = newUserId
    navigate('?' + queryString.stringify(parsed))
  }

  return (
    <>
      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="userRoleCompanie">
          <b className="black2">{props.label}</b>
        </InputLabel>
        <TextField
          id="userRoleCompanie"
          size="small"
          select
          className="width100per spaceForm"
          placeholder=""
          SelectProps={{
            renderValue: (selected) => {
              const userRoleCompanieNode = edges.find((userRoleCompanie) => userRoleCompanie.node.user.id === selected)
              if (!userRoleCompanieNode) return <></>
              return (
                <span>
                  {userRoleCompanieNode.node.user.firstName
                    ? `${userRoleCompanieNode.node.user.firstName} ${userRoleCompanieNode.node.user.lastName}`
                    : userRoleCompanieNode.node.user.email}
                </span>
              )
            },
          }}
          onChange={(e) => {
            if (e.target.value === 'addMember') {
              setOpen(true)
              return
            }
            const selectedId = e.target.value
            parsed.selectUserId = selectedId
            navigate('?' + queryString.stringify(parsed))
            const userRoleCompanieNode = edges.find((userRoleCompanie) => userRoleCompanie.node.user.id === selectedId)
            if (userRoleCompanieNode) {
              props.onChange(userRoleCompanieNode.node as UserRoleCompanie)
            }
          }}
          type="text"
          value={userId}
        >
          <MenuItem value="" />
          {edges &&
            edges.map((userRoleCompanie) => (
              <MenuItem key={userRoleCompanie.node.user.id} value={userRoleCompanie.node.user.id}>
                <div style={{ display: 'flex' }}>
                  <ImageTemplate format="avatar" nameFile={userRoleCompanie.node.user.nameFile} />
                  <div
                    style={{
                      fontSize: 14,
                      paddingLeft: 10,
                      fontWeight: userRoleCompanie.node.user.id === context.me.id ? 'bold' : 'unset',
                    }}
                  >
                    <div>
                      {userRoleCompanie.node.user.firstName} {userRoleCompanie.node.user.lastName}
                    </div>
                    <div style={{ color: '#858580' }}>{userRoleCompanie.node.user.email}</div>
                  </div>
                </div>
              </MenuItem>
            ))}
          <MenuItem key="addMember" value="addMember" onClick={() => setOpen(true)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NewUserIcon style={{ width: 40, height: 40 }} />{' '}
              <div style={{ paddingLeft: 10, color: '#1445FE', fontWeight: 700, fontSize: 14 }}>+ Add a team member</div>
            </div>
          </MenuItem>
        </TextField>
      </FormControl>
      <CreateInvitation
        open={open}
        hideAnalyst={props.hideAnalyst}
        setOpen={setOpen}
        companieId={context.userRoleCompanie.companie.id}
        onCreate={onCreate}
      />
    </>
  )
}

export default UserTeamSelect
