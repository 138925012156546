import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { Addresse } from '__generated__/graphql'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation updateAddresse_UpdateAddresse($data: AddresseUpdateInput!, $where: AddresseWhereUniqueInput!) {
    updateAddresse(data: $data, where: $where) {
      id
      name
      type
      address1
      address2
      city
      zip
      state
      country
    }
  }
`)

type Props = {
  addresse: Pick<Addresse, 'id' | 'type' | 'name' | 'address1' | 'address2' | 'city' | 'zip' | 'state' | 'country'>
  disabled: boolean
  textButton: string
  textButtonCancel: string
  onUpdate: () => void
  onCancel: () => void
  hideCancel?: boolean
}

const UpdateAddresse = (props: Props) => {
  const showErrors = useShowErrors()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [updateAddresseMutation] = useMutation(MUTATION)
  const updateAddresse = async () => {
    setLoading(true)
    let newAddresse
    try {
      newAddresse = await updateAddresseMutation({
        variables: {
          where: {
            id: props.addresse.id,
          },
          data: {
            type: props.addresse.type,
            name: props.addresse.name,
            address1: props.addresse.address1,
            address2: props.addresse.address2,
            city: props.addresse.city,
            zip: props.addresse.zip,
            state: props.addresse.state,
            country: props.addresse.country,
          },
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
    if (newAddresse) {
      setLoading(false)
      props.onUpdate()
    }
  }

  return (
    <>
      {!props.hideCancel && <Button onClick={props.onCancel}>{props.textButtonCancel}</Button>}{' '}
      <ButtonLoadingAfterClick
        actionName="updateAddress"
        id="idButtonUpdateAddress"
        disabled={props.disabled}
        icon=""
        size="medium"
        sx={{ width: props.hideCancel ? '100%' : 'unset' }}
        color="primary"
        variant={props.hideCancel ? 'contained' : 'outlined'}
        buttonText={props.textButton}
        buttonLoadingText="Setting up..."
        onClick={updateAddresse}
        loading={loading}
      />
      <div id="idMessage" className="secondary">
        {message}
      </div>
    </>
  )
}

export default UpdateAddresse
