import { Paper, Grid } from '@mui/material'
import LogOutCross from 'components/nav/LogoutCross'
import React from 'react'
import { Link } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import UserDobForm from './UserDobForm'
import { UserContext } from 'components/Context.type'
import LogoNN from 'assets/logo/nn/nachonacho-full-blue-horizontal.svg'

type Props = {
  user: Pick<UserContext, 'id' | 'dobYear' | 'dobMonth' | 'dobDay'>
}

const UserDobFormOffSite = (props: Props) => {
  const client = useApolloClient()
  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <Grid container>
            <Grid item xs={12} className="tar">
              <LogOutCross />
            </Grid>
          </Grid>

          <div className="tac margin6">
            <Link to="/">
              <img alt="logo" className="logoNachoNacho" src={LogoNN} />
            </Link>
          </div>
          <div className="tac responsiveMargin2">
            <h3>Date of Birth</h3>
            <div className="tal">
              <p>
                Please add your date of birth. NachoNacho has a regulatory requirement to conduct ongoing monitoring and due
                diligence of users on our platform.
              </p>
            </div>

            <UserDobForm
              onCancel={() => {}}
              showCancel={false}
              user={props.user}
              onUpdate={async () => await client.resetStore()}
              buttonSave="Save"
              buttonCancel="Cancel"
            />
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default UserDobFormOffSite
