import React from 'react'
import { File } from '__generated__/graphql'
import UploadFilePrivate, { FileContainer } from '../UploadFilePrivate'

type Props = {
  onChange: (files: File[]) => void
  files: File[]
  errorMessageFileNotPermited: string
  acceptedImageTypes: string[]
  fileSizeMax: number
  textDragNDrop: string
}

const FileForm = (props: Props) => {
  return (
    <div>
      <UploadFilePrivate
        maxFiles={5}
        textDragNDrop={props.textDragNDrop}
        fileSizeMax={props.fileSizeMax}
        errorMessageFileNotPermited={props.errorMessageFileNotPermited}
        acceptedImageTypes={props.acceptedImageTypes}
        privateFileType="PRIVATE_FILE"
        onSelectFileContainer={(fileContainers: FileContainer[]) => {
          props.onChange(
            fileContainers.map((file, index) => {
              return {
                ...props.files[index],
                nameFile: file.nameFile,
                type: file.type,
                shortNameFile: file.shortNameFile,
              }
            }),
          )
        }}
      />
    </div>
  )
}

export default FileForm
