import { Role, User, VerificationStatus as VerificationStatusGen, SignupType as SignupTypeGen } from '__generated__/graphql'

export const userInit: Pick<
  User,
  | 'id'
  | 'role'
  | 'firstName'
  | 'lastName'
  | 'nameFile'
  | 'email'
  | 'signupType'
  | 'isEmailValidated'
  | 'isPhoneValidated'
  | 'whereDidyouHearAboutUs'
  | 'requestWhereDidyouHearAboutUs'
  | 'requestEmailValidatedOffSite'
  | 'verificationStatus'
  | 'dobYear'
  | 'dobMonth'
  | 'dobDay'
  | 'requestBirthdayOffSite'
  | 'isPhoneValidationRequired'
  | 'showGettingStarted'
  | 'enabled2FAPhone'
  | 'enabled2FAEmail'
  | 'enabled2FATotp'
  | 'showInviteContactsFeature'
> = {
  id: '',
  verificationStatus: VerificationStatusGen.Required,
  whereDidyouHearAboutUs: '',
  requestWhereDidyouHearAboutUs: false,
  requestBirthdayOffSite: false,
  requestEmailValidatedOffSite: true,
  firstName: '',
  enabled2FAPhone: false,
  showGettingStarted: true,
  enabled2FAEmail: false,
  enabled2FATotp: false,
  isPhoneValidationRequired: true,
  role: Role.Customer,
  email: '',
  isPhoneValidated: false,
  lastName: '',
  nameFile: '',
  isEmailValidated: false,
  signupType: SignupTypeGen.Userform,
  showInviteContactsFeature: false,
  dobDay: null,
  dobMonth: null,
  dobYear: null,
}
