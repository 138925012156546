import React from 'react'
import '../Style.css'
import { Icon, Button, useMediaQuery, Theme, Box } from '@mui/material'

type Props = {
  shwowActionButton: boolean
  actionText: string
  message: string
  onClick: () => void
}

const WarningAction = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <Box sx={{ color: 'white', fontFamily: 'Roobert' }}>
      <Box sx={{ fontSize: 14, marginBottom: '15px', alignItems: 'center', display: 'flex' }}>
        <Icon className="textSize12 mr-5">warning</Icon>
        <div>{props.message}</div>
      </Box>
      {props.shwowActionButton && (
        <Button size="small" variant="outlined" onClick={props.onClick}>
          {props.actionText}
        </Button>
      )}
      {isMobile && <div style={{ height: '10px' }} />}
    </Box>
  )
}

export default WarningAction
