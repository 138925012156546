import React from 'react'
import ButtonLoadingAfterClick from '../nav/ButtonLoadingAfterClick'
import { useMutation, useApolloClient } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation createInvitationBuyer_CreateInvitationButton($companieId: String!, $email: String!, $companieRole: String!) {
    createInvitationBuyer(companieId: $companieId, email: $email, companieRole: $companieRole) {
      id
    }
  }
`)

type Props = {
  email: string
  companieId: string
  onCreate?: (userId: string) => void
  companieRole: string
  buttonText?: string
}

const CreateInvitationButton = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()

  const [createInvitation] = useMutation(MUTATION)

  const [isEmailValidated, setIsEmailValidated] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const check = () => {
    return props.companieRole && props.email && isEmailValidated
  }

  const createInvitationF = async () => {
    if (!check()) return
    setLoading(true)

    try {
      setMessage('')
      const invitation = await createInvitation({
        variables: {
          email: props.email,
          companieRole: props.companieRole,
          companieId: props.companieId,
        },
      })
      if (invitation?.data?.createInvitationBuyer) {
        setIsEmailValidated(true)
        await client.resetStore()
        if (props.onCreate) {
          props.onCreate(invitation.data.createInvitationBuyer.id)
        }
      }
    } catch (e) {
      setLoading(false)
      console.log(e)

      showErrors(e, setMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createInvitation"
        id="idButton"
        icon=""
        color="primary"
        variant={props.buttonText ? 'text' : 'outlined'}
        disabled={!check()}
        size="medium"
        buttonText={props.buttonText ? props.buttonText : 'Invite'}
        buttonLoadingText="Setting up..."
        onClick={createInvitationF}
        loading={loading}
      />
      <div id="idMessage" className="secondary">
        {message}
      </div>
    </>
  )
}

export default CreateInvitationButton
