import React from 'react'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import { Button, Paper, Theme, useMediaQuery } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { URL_HOME } from 'config/config'
import MavenVerificationRequestSubmitted from 'components/onboarding/MavenVerificationRequestSubmitted'
import OnboardingConfirmationFormMaven from '../seller/OnboardingConfirmationFormMaven'
import AdditionalDetailsFormMaven from 'components/onboarding/AdditionalDetailsFormMaven'
import { MavenKYCStep, useMavenKYCContext } from 'components/onboarding/kyc/maven/MavenKYCContext'
import MavenKYCStepsProgress from 'components/onboarding/kyc/maven/MavenKYCStepsProgress'
import MavenCompanieAddresssForm from 'components/onboarding/kyc/maven/MavenCompanyAddressForm'
import MavenBasicInformationForm from 'components/onboarding/kyc/maven/MavenBasicInformationForm'
import MavenSocialMediaForm from 'components/onboarding/kyc/maven/MavenSocialMediaForm'
import MavenDescriptionForm from 'components/onboarding/kyc/maven/MavenDescriptionForm'
import OnboardingMavenProductDetailsForm from './OnboardingMavenProductDetailsForm'
import MavenServiceCategoriesForm from 'components/onboarding/kyc/maven/MavenServiceCategoriesForm'
import MavenValueProvidedForm from 'components/onboarding/kyc/maven/MavenValueProvidedForm'
import MavenTargetCustomersForm from 'components/onboarding/kyc/maven/MavenTargetCustomersForm'
import MavenPersonalLocationForm from 'components/onboarding/kyc/maven/MavenPersonalLocationForm'
import MavenCompanieForm from 'components/onboarding/kyc/maven/MavenCompanieForm'
import MavenPricingKYCForm from 'components/onboarding/kyc/maven/MavenPricingKYCForm'
import MavenPaymentForm from 'components/onboarding/kyc/maven/payment/MavenPaymentForm'

const USER_QUERY = gql(/* GraphQL */ `
  query user_MavenKYCForm($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      linkedInLink
    }
  }
`)
const QUERY = gql(/* GraphQL */ `
  query companie_MavenKYCForm($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      registeredBusinessName
      typeCompanie
      requestCardVerification
      addStripeBank
      incomingPaymentFeeACHCard
      hideDebitCard
      incomingPaymentFeeDebitCard
      hideDebitCredit
      incomingPaymentFeeCreditCard
      website
      linkedin
      mcc
      companyPhone
      companyPhoneCode
      emailCompany
      ownedProducts {
        id
        name
        entityType
        sellerLink
        startedOperatingInWhichYear
        numberOfEmployees
        workLocation
        linkedInLink
        twitterLink
        facebookLink
        youtubeLink
        instagramLink
        shortDescription
        productDescription
        atWhatStageIsMostHelpful
        nameFile
        averageMonthyRateRangeHigh
        averageMonthyRateRangeLow
        averageHourlyRate
        typicalProjectPriceRangeHigh
        typicalProjectPriceRangeLow
        isIcpAllIndustries
        isIcpAllLocations
      }
    }
  }
`)
type Props = {
  handleClose: () => void
}

const MavenKYCForm = (props: Props) => {
  const context = useAppContextWithCompany()
  const mavenKYCContext = useMavenKYCContext()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const companyQuery = useQuery(QUERY, {
    variables: { where: { id: context.userRoleCompanie.companie.id } },
  })

  const userQuery = useQuery(USER_QUERY, {
    variables: { where: { id: context.me.id } },
  })
  if (userQuery.error)
    return <Error message={userQuery.error.graphQLErrors.length > 0 ? userQuery.error.graphQLErrors[0].message : ''} />
  if (companyQuery.error)
    return <Error message={companyQuery.error.graphQLErrors.length > 0 ? companyQuery.error.graphQLErrors[0].message : ''} />
  if (userQuery.loading || companyQuery.loading) return null
  if (!userQuery.data?.user || !companyQuery.data?.companie) return <NotFound />

  const user = userQuery.data.user
  const companie = companyQuery.data.companie
  const product = companie.ownedProducts[0]

  const setStep = (step: MavenKYCStep, force?: boolean) => {
    companyQuery.refetch()
    userQuery.refetch()
    mavenKYCContext.reloadKYCState()
    mavenKYCContext.setCurrentStep(step, force)
  }
  const nextStep = () => {
    companyQuery.refetch()
    userQuery.refetch()
    mavenKYCContext.reloadKYCState()
    mavenKYCContext.nextStep()
  }
  const KYCPages = (
    <>
      {mavenKYCContext.currentStep() === 'personalDetails' && (
        <AdditionalDetailsFormMaven user={user} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'personalLocation' && (
        <MavenPersonalLocationForm
          userId={context.me.id}
          onCreate={() => nextStep()}
          onUpdate={() => nextStep()}
          onCancel={() => mavenKYCContext.previousStep()}
        />
      )}

      {mavenKYCContext.currentStep() === 'mavenType' && (
        <OnboardingMavenProductDetailsForm
          product={product}
          onUpdate={(isFreelancer) => {
            isFreelancer ? setStep('mavenAddress', true) : nextStep()
          }}
        />
      )}
      {mavenKYCContext.currentStep() === 'companyDetails' && (
        <MavenCompanieForm onUpdate={() => nextStep()} onCancel={() => mavenKYCContext.previousStep()} companie={companie} />
      )}
      {mavenKYCContext.currentStep() === 'mavenAddress' && (
        <MavenCompanieAddresssForm
          userId={context.me.id}
          productId={product.id}
          onCreate={() => nextStep()}
          onUpdate={() => nextStep()}
          onCancel={() => mavenKYCContext.previousStep()}
        />
      )}

      {mavenKYCContext.currentStep() === 'basicInformation' && (
        <MavenBasicInformationForm product={product} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'serviceCategories' && (
        <MavenServiceCategoriesForm product={product} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'socialMedia' && <MavenSocialMediaForm product={product} onUpdate={() => nextStep()} />}
      {mavenKYCContext.currentStep() === 'productDescription' && (
        <MavenDescriptionForm product={product} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'valueProposition' && (
        <MavenValueProvidedForm product={product} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'pricing' && <MavenPricingKYCForm product={product} onUpdate={() => nextStep()} />}
      {mavenKYCContext.currentStep() === 'targetCustomers' && (
        <MavenTargetCustomersForm product={product} onUpdate={() => nextStep()} />
      )}
      {mavenKYCContext.currentStep() === 'payment' && <MavenPaymentForm companie={companie} onUpdate={() => nextStep()} />}

      {mavenKYCContext.currentStep() === 'submitForm' && <OnboardingConfirmationFormMaven onUpdate={() => nextStep()} />}
      {mavenKYCContext.currentStep() === 'requestSent' && <MavenVerificationRequestSubmitted product={product} />}
    </>
  )
  return (
    <div
      style={{
        width: isMobile ? '100%' : '1100px',
        maxWidth: '100vw',
        height: '100vh',
        background: '#FAF8F7',
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#2475FF',
          height: '100px',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          padding: isMobile ? '0 15px' : '0 35px',
          alignItems: 'center',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <span style={{ fontSize: '21px' }}>Maven profile</span>
        <Button
          onClick={props.handleClose}
          variant="outlined"
          sx={{ fontFamily: 'Roobert', fontSize: 16, borderRadius: '20px', lineHeight: 1 }}
        >
          <LogoutIcon style={{ height: '18px', marginRight: '5px' }} />
          {mavenKYCContext.filledSteps['submitForm'] ? 'Close' : 'Continue later'}
        </Button>
      </div>
      <div style={{ width: '100%', overflow: 'scroll', height: '100vh' }}>
        <div
          style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', padding: '38px', paddingTop: '140px' }}
        >
          {isMobile ? (
            <div>{KYCPages}</div>
          ) : (
            <Paper variant="outlined" className="paperIn" style={{ background: 'white', paddingTop: '60px', flex: 1 }}>
              {KYCPages}
            </Paper>
          )}
          <div>
            {!isMobile && (
              <>
                <MavenKYCStepsProgress entityType={product.entityType} />
                <Button
                  variant="outlined"
                  sx={{ fontFamily: 'Roobert', borderRadius: '20px', margin: '50px 0 0 50px' }}
                  target="_blank"
                  href={`${URL_HOME}/seller-meeting`}
                >
                  Request a video call
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MavenKYCForm
