import { useQuery } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import React from 'react'
import { gql } from '__generated__'
import { KYCContext, KYCContextType, KYCStep } from './KYCContext'

const KYC_QUERY = gql(/* GraphQL */ `
  query getKYCStatus_KYCContextProvider($companieId: String!) {
    getKYCStatus(companieId: $companieId) {
      personalAddress
      personalDetails
      personalPhoneNumber
      companyType
      companyAddress
      companyDetails
      beneficiaryDetails
      leadershipDocuments
      leadershipIdNumber
      submitForm
    }
  }
`)

type Props = {
  children: React.ReactNode
}

const stepsMap = {
  0: 'personalPhoneNumber',
  1: 'personalAddress',
  2: 'personalDetails',
  3: 'companyType',
  4: 'companyAddress',
  5: 'companyDetails',
  6: 'beneficiaryDetails',
  7: 'leadershipDocuments',
  8: 'leadershipIdNumber',
  9: 'submitForm',
  10: 'requestSent',
}

const KYCContextProvider = (props: Props) => {
  const [step, setStep] = React.useState(0)
  const [firstLoad, setFirstLoad] = React.useState(true)

  const appContext = useAppContextWithCompany()
  const { data, loading, error, refetch } = useQuery(KYC_QUERY, {
    variables: { companieId: appContext.userRoleCompanie.companie.id },
  })
  if (loading) return <></>
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (!data) return <NotFound />

  const filledSteps = data.getKYCStatus
  if (firstLoad) {
    setFirstLoad(false)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    setStep(firstUnfilled)
  }
  const nextStep = () => {
    const lastStep = Object.keys(stepsMap).length - 1
    if (step < lastStep) {
      setStep(step + 1)
    }
  }
  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }
  const setCurrentStep = (kycStep: KYCStep, force?: boolean) => {
    const lastStep = Object.keys(stepsMap).length - 1
    if (step === lastStep) {
      return
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index <= firstUnfilled || force) {
      setStep(index)
    }
  }
  const getStepStatus = (kycStep: KYCStep) => {
    if (!filledSteps[kycStep]) {
      return false
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index < firstUnfilled) {
      return true
    }
    return false
  }
  const context: KYCContextType = {
    filledSteps,
    currentStep: () => stepsMap[step],
    setCurrentStep,
    nextStep,
    previousStep,
    getStepStatus,
    reloadKYCState: refetch,
  }

  return <KYCContext.Provider value={context}>{props.children}</KYCContext.Provider>
}

export default KYCContextProvider
