import { Addresse, TypeAddresse } from '__generated__/graphql'

export const addressInit: Pick<
  Addresse,
  'id' | 'zip' | 'type' | 'name' | 'address1' | 'address2' | 'country' | 'city' | 'state'
> = {
  id: '',
  zip: '',
  type: TypeAddresse.Billing,
  name: '',
  address1: '',
  address2: '',
  country: 'US',
  city: '',
  state: '',
}
