import React from 'react'
import AddBankMicroDeposit from './AddBankMicroDeposit'
import { STRIPE } from 'config/config'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useAppContextWithCompany } from '../../AppContext'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import { useQuery } from '@apollo/client'
import AddCardAddressesCheck from './AddCardAddressesCheck'
import CompanieName from '../../companie/single/CompanieName'
import { Companie } from '__generated__/graphql'
import { gql } from '__generated__'

const USER_QUERY = gql(/* GraphQL */ `
  query user_AddSourcePageStripe($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
    }
  }
`)

type Props = {
  companie: Pick<Companie, 'id' | 'requestCardVerification'>
  paymentMethod: string
  onCancel: () => void
  onSuccess: () => void
}

const AddSourcePageStripe = (props: Props) => {
  const context = useAppContextWithCompany()
  const testMode = context.testMode
  const stripePromise = loadStripe(testMode ? STRIPE.STRIPE_KEY_PK_TEST : STRIPE.STRIPE_KEY_PK_LIVE)

  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: { where: { id: context.me.id } },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  const user = data.user

  return (
    <div className="paperOut">
      <div className="paperOut">
        <h1 className="titlePage">
          Add Payment Source for <CompanieName companieId={props.companie.id} />
        </h1>

        <div>
          {props.paymentMethod === 'bank' && (
            <Elements stripe={stripePromise}>
              <AddBankMicroDeposit companieId={props.companie.id} onCancel={props.onCancel} onSuccess={props.onSuccess} />
            </Elements>
          )}
          {props.paymentMethod === 'debitCard' && (
            <Elements stripe={stripePromise}>
              <AddCardAddressesCheck
                companie={props.companie}
                onSuccess={props.onSuccess}
                onCancel={props.onCancel}
                user={user}
              />
            </Elements>
          )}
          {props.paymentMethod === 'creditCard' && (
            <Elements stripe={stripePromise}>
              <AddCardAddressesCheck
                companie={props.companie}
                onSuccess={props.onSuccess}
                onCancel={props.onCancel}
                user={user}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddSourcePageStripe
