import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useAppContextWithCompany } from 'components/AppContext'

type Props = {
  id?: string
  title: string
  size?: ButtonProps['size']
  color?: ButtonProps['color']
  sx?: ButtonProps['sx']
  variant?: ButtonProps['variant']
  style?: React.CSSProperties
}
const KnowYourCustomerDialog = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const parsed = queryString.parse(location.search)
  const context = useAppContextWithCompany()
  const openVerificationDialog = () => {
    parsed.showDialog = 'userVerification'
    navigate('?' + queryString.stringify(parsed))
  }
  if (['PURCHASER', 'ANALYST'].includes(context.userRoleCompanie.companieRole)) return null

  return (
    <Button
      sx={props.sx}
      id={props.id}
      style={props.style}
      size={props.size}
      variant={props.variant}
      color={props.color}
      onClick={openVerificationDialog}
    >
      <b>{props.title}</b>
    </Button>
  )
}
export default KnowYourCustomerDialog
