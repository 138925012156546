import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import React from 'react'
import { gql } from '__generated__'
import { Companie, Product } from '__generated__/graphql'

import OnboardingSellerProductForm from './OnboardingSellerProductForm'

const QUERY = gql(/* GraphQL */ `
  query product_OnboardingSellerProduct($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      id
      name
      shortDescription
      sellerLink
      twitterLink
      instagramLink
      linkedInLink
      facebookLink
      youtubeLink
      shortDescription
      productDescription
      keyBenefits
      features
      pricing
      nameFile
      productEditRequests {
        id
        updatedAt
        productFieldName
        productFieldValue
      }
    }
  }
`)

type Props = {
  onUpdate: () => void
  companie: Pick<Companie, 'id'> & {
    ownedProducts: Array<Pick<Product, 'id'>>
  }
}

const OnboardingSellerProduct = (props: Props) => {
  const productId = props.companie.ownedProducts[0].id
  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: { id: productId } },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />

  if (!data?.product) return <NotFound />
  const product = data.product
  return <OnboardingSellerProductForm product={product} onUpdate={props.onUpdate} />
}

export default OnboardingSellerProduct
