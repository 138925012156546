export const FRESHCHAT_WIDGET_ID = '9d7113bc-f43e-4a3a-b806-28f359c768dc'
export const FRESHCHAT_SRC = 'https://nachonacho.freshchat.com/js/widget.js'
export const FRESHCHAT_TOKEN = '38763c71-4163-4185-894d-32edcf49b610'
export const FRESHCHAT_HOST = 'https://nachonacho.freshchat.com'

export const AUTH_DEVICE = 'auth-device'
export const REFERRER = 'referrer'
export const USER_ROLE_COMPANIE = 'userRoleCompanie'
export const USER_ROLE_COMPANIE_ADMIN_WHILE_SPOOFING = 'userRoleCompanieAdminWhileSpoofing'
export const TEST_MODE = 'test-mode'
export const RECAPTCHA_TOKEN = 'recaptchatoken'

export const SPOOFED_USER_ID = 'spoofed-user-id'
export const SENTRY_DSN = 'https://381e2dae90f54d6a9589053c6eee91df@o1413067.ingest.us.sentry.io/6753267'
const GOOGLE_CLIENT_ID_PROD = '439167645385-j4h6ibor0vv23jga5iadlfg41el36k1d.apps.googleusercontent.com'
const GOOGLE_CLIENT_ID_DEV = '549045750270-paqg8g8drnabhoo8n5u7p98m4d3oguot.apps.googleusercontent.com'

type Env = 'local' | 'dev' | 'production' | 'localProd'

type Config<T> = {
  [key in Env]: T
}

const google: Config<{ clientId: string }> = {
  local: { clientId: GOOGLE_CLIENT_ID_DEV },
  dev: { clientId: GOOGLE_CLIENT_ID_DEV },
  production: { clientId: GOOGLE_CLIENT_ID_PROD },
  localProd: { clientId: GOOGLE_CLIENT_ID_DEV },
}

const googleTagManager: Config<string> = {
  local: 'GTM-MD7WQ6D',
  dev: 'GTM-MD7WQ6D',
  production: 'GTM-T8Q8PLN',
  localProd: 'GTM-MD7WQ6D',
}

const hotjar: Config<{ hjid: number; hjsv: number }> = {
  local: { hjid: 0, hjsv: 0 },
  dev: { hjid: 2916921, hjsv: 6 },
  production: { hjid: 2859416, hjsv: 6 },
  localProd: { hjid: 2859416, hjsv: 6 },
}

const redirectUrl: Config<string> = {
  local: 'https://web.nachonacho.co',
  dev: 'https://web.nachonacho.co',
  production: 'https://web.nachonacho.com',
  localProd: 'https://web.nachonacho.com',
}
const embedUrl: Config<string> = {
  local: 'http://localhost:3003',
  dev: 'https://embed.nachonacho.co',
  production: 'https://embed.nachonacho.com',
  localProd: 'https://embed.nachonacho.com',
}

const linkedin: Config<{ redirect_uri: string; client_id: string }> = {
  local: {
    redirect_uri: 'http://localhost:3000/linkedin',
    client_id: '86b4uppnjgf3gy',
  },
  dev: {
    redirect_uri: 'https://app.nachonacho.co/linkedin',
    client_id: '86b4uppnjgf3gy',
  },
  production: {
    redirect_uri: 'https://app.nachonacho.com/linkedin',
    client_id: '86b4uppnjgf3gy',
  },
  localProd: {
    redirect_uri: 'http://localhost:3000/linkedin',
    client_id: '86b4uppnjgf3gy',
  },
}

const cookieOptions: Config<{ domain: string; path: string; maxAge: number }> = {
  local: {
    domain: 'localhost',
    path: '/',
    maxAge: 172800,
  },
  dev: {
    domain: '.nachonacho.co',
    path: '/',
    maxAge: 172800,
  },
  production: {
    domain: '.nachonacho.com',
    path: '/',
    maxAge: 172800,
  },
  localProd: {
    domain: '.nachonacho.com',
    path: '/',
    maxAge: 172800,
  },
}

const stripe: Config<{ STRIPE_KEY_PK_TEST: string; STRIPE_KEY_PK_LIVE: string }> = {
  local: {
    STRIPE_KEY_PK_TEST: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
    STRIPE_KEY_PK_LIVE: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
  },
  dev: {
    STRIPE_KEY_PK_TEST: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
    STRIPE_KEY_PK_LIVE: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
  },
  production: {
    STRIPE_KEY_PK_TEST: 'pk_test_tM0LP7u1kZGXfItnLF2AX4Hr',
    STRIPE_KEY_PK_LIVE: 'pk_live_dvs371P8HQXmVeGHhA87AL3q',
  },
  localProd: {
    STRIPE_KEY_PK_TEST: 'pk_test_tM0LP7u1kZGXfItnLF2AX4Hr',
    STRIPE_KEY_PK_LIVE: 'pk_live_dvs371P8HQXmVeGHhA87AL3q',
  },
}

const stripeIssuing: Config<{ STRIPE_KEY_PK_TEST: string; STRIPE_KEY_PK_LIVE: string }> = {
  local: {
    STRIPE_KEY_PK_TEST: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
    STRIPE_KEY_PK_LIVE: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
  },
  dev: {
    STRIPE_KEY_PK_TEST: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
    STRIPE_KEY_PK_LIVE: 'pk_test_Felr1a2BJ0OaGwLe6XgjKR9T00oxym8OL3',
  },
  production: {
    STRIPE_KEY_PK_TEST: '',
    STRIPE_KEY_PK_LIVE: 'pk_live_Nc7rhAfML5ckHux8h1y5kneq',
  },
  localProd: {
    STRIPE_KEY_PK_TEST: '',
    STRIPE_KEY_PK_LIVE: '',
  },
}

const plaid: Config<{ WEBHOOK: string }> = {
  local: {
    // PUBLIC_KEY_PLAID: 'a096e037c2e6a4d13c9a5f10fb2356',
    WEBHOOK: 'https://endpoint.nachonacho.com/plaid',
  },
  dev: {
    // PUBLIC_KEY_PLAID: 'c718c36f0db040aad68d8f4b865fe1',
    WEBHOOK: 'https://endpoint.nachonacho.co/plaid',
  },
  production: {
    // PUBLIC_KEY_PLAID: 'a096e037c2e6a4d13c9a5f10fb2356',
    WEBHOOK: 'https://endpoint.nachonacho.com/plaid',
  },
  localProd: {
    // PUBLIC_KEY_PLAID: 'a096e037c2e6a4d13c9a5f10fb2356',
    WEBHOOK: 'https://endpoint.nachonacho.com/plaid',
  },
}

const fullStory: Config<{ ORG_ID: string }> = {
  local: { ORG_ID: '' },
  dev: { ORG_ID: '' },
  production: { ORG_ID: 'R32XW' },
  localProd: { ORG_ID: '' },
}

const slack = {
  local: { client_id: '349219186546.2081633562758' },
  dev: { client_id: '349219186546.1367304552357' },
  production: { client_id: '349219186546.936765661634' },
}

const url_server_media: Config<string> = {
  local: 'https://files.nachonacho.co',
  dev: 'https://files.nachonacho.co',
  production: 'https://files.nachonacho.com',
  localProd: 'https://files.nachonacho.com',
}

const url_server_endpoint: Config<string> = {
  local: 'http://localhost:9000',
  dev: 'https://endpoint.nachonacho.co',
  production: 'https://endpoint.nachonacho.com',
  localProd: 'http://localhost:9000',
}
const url_server_public_shared_lambda: Config<string> = {
  local: 'https://public.nachonacho.co',
  dev: 'https://public.nachonacho.co',
  production: 'https://public.nachonacho.com',
  localProd: 'https://public.nachonacho.co',
}

const url_server_graphql: Config<string> = {
  local: 'http://localhost:4000',
  dev: 'https://api.nachonacho.co',
  production: 'https://api.nachonacho.com',
  localProd: 'http://localhost:4000',
}

const url_frontEnd: Config<string> = {
  local: 'http://localhost:3000',
  dev: 'https://app.nachonacho.co',
  production: 'https://app.nachonacho.com',
  localProd: 'http://localhost:3000',
}

const url_home: Config<string> = {
  local: 'http://localhost:2200',
  dev: 'https://nachonacho.co',
  production: 'https://nachonacho.com',
  localProd: 'http://localhost:3000',
}
const url_frontEnd_admin: Config<string> = {
  local: 'http://localhost:3033',
  dev: 'https://admin.nachonacho.co',
  production: 'https://admin.nachonacho.com',
  localProd: 'http://localhost:3033',
}
const recaptchaKey: Config<string> = {
  local: '6LdPp3gjAAAAAOtgBKV6fDxCDZcwQWWcN0NUZ89N',
  dev: '6LdPp3gjAAAAAOtgBKV6fDxCDZcwQWWcN0NUZ89N',
  production: '6LdYxkobAAAAAP07Nn-3zkPD9JA6w7Yyf12Iftg6',
  localProd: '6LdYxkobAAAAAP07Nn-3zkPD9JA6w7Yyf12Iftg6',
}

const authFrontendUrl: Config<string> = {
  local: 'http://localhost:3002',
  dev: 'https://connect.nachonacho.co',
  production: 'https://connect.nachonacho.com',
  localProd: 'http://localhost:3002',
}
const socketUrl: Config<string> = {
  local: 'wss://chat.nachonacho.co',
  dev: 'wss://chat.nachonacho.co',
  production: 'wss://chat.nachonacho.com',
  localProd: 'wss://chat.nachonacho.co',
}

export const env = (process.env.REACT_APP_ENV || 'local') as Env

export const SOCKET_URL = socketUrl[env]

export const GOOGLE = google[env]
export const ENV = env
export const GOOGLE_TAG_MANAGER = googleTagManager[env]
export const HOTJAR = hotjar[env]
export const REDIRECT_URL = redirectUrl[env]
export const LINKEDIN = linkedin[env]
export const COOKIE_OPTIONS = cookieOptions[env]
export const STRIPE = stripe[env]
export const STRIPE_ISSUING = stripeIssuing[env]
export const PLAID = plaid[env]
export const FULLSTORY = fullStory[env]
export const SLACK = slack[env]
export const URL_SERVER_GRAPHQL = url_server_graphql[env]
export const URL_FRONTEND = url_frontEnd[env]
export const URL_SERVER_MEDIA = url_server_media[env]
export const URL_HOME = url_home[env]
export const URL_FRONTEND_ADMIN = url_frontEnd_admin[env]
export const URL_SERVER_ENDPOINT = url_server_endpoint[env]
export const URL_SERVER_PUBLIC_SHARED_LAMBDA = url_server_public_shared_lambda[env]
export const RECAPTCHA_KEY = recaptchaKey[env]
export const AUTH_FRONTEND_URL = authFrontendUrl[env]
export const EMBED_URL = embedUrl[env]

export const URL_MARKETPLACE = 'https://nachonacho.com/marketplace/'
export const EXTENSION_ID = 'koeoaeaogagaommgnlinhmhckiibbndd'
export const nachoNachoProductId = 'cjubrwtfr3cqq0748gtvve5sb'
