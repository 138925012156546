import React from 'react'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import NumberCardFormat from './NumberCardFormat'
import utils from '../../../utils'
import { CardholderStripe, Companie, IssuedCard, IssuedCardStripe, Maybe } from '__generated__/graphql'
import BigNachoCardDesign from '../../../../assets/nachoCard/bigNachoCardDesign.svg'
import BigNachoCardDesignPhysical from '../../../../assets/nachoCard/bigNachoCardDesignPhysical.svg'
// import { ReactComponent as CashbackSticker } from '../../../../assets/icon/cashback_sticker.svg'
import ProductImage from 'components/product/single/ProductImage'
import UserName from 'components/nav/layout/titlePage/UserName'
import ProductImagePublic from 'components/product/single/ProductImagePublic'

type Props = {
  issuedCard: Pick<IssuedCard, 'type' | 'name' | 'statusBox' | 'last4'> & {
    issuedCardStripe?: Maybe<
      Pick<IssuedCardStripe, 'id' | 'number' | 'exp_month' | 'exp_year' | 'cvc'> & {
        cardholder: Pick<CardholderStripe, 'name'>
      }
    >
    companie: Pick<Companie, 'name'>
  }
  productId?: string
  userId?: string
  showCopyToClipboard: boolean
  // showCashbackSticker?: boolean
  showPublicProduct?: boolean
}

const IssuedCardDesign = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const boldText = { fontSize: 13, fontWeight: 600 }
  const notBoldText = { fontSize: 10, fontWeight: 400, color: '#D3D3D3' }
  const bgImage = props.issuedCard.type === 'physical' ? BigNachoCardDesignPhysical : BigNachoCardDesign
  const issuedCardStripe = props.issuedCard.issuedCardStripe
  const expiry = new Date()
  expiry.setFullYear(expiry.getFullYear() + 3)
  const expiryMonth = expiry.getMonth() + 1
  const expiryYear = expiry.getFullYear()
  return (
    <div style={{ width: isMobile ? 320 : 440 }}>
      <div
        style={{
          color: 'white',
          marginLeft: 10,
          height: isMobile ? 200 : 270,
          backgroundSize: isMobile ? 310 : 420,
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          style={{
            paddingTop: isMobile ? 14 : 20,
            paddingLeft: isMobile ? 20 : 28,
          }}
        >
          {/* {props.showCashbackSticker && (
            <div style={{ width: isMobile ? '250px' : '350px', position: 'relative' }}>
              <div style={{ position: 'absolute', top: isMobile ? '-5px' : 0, left: '50%', transform: 'translateX(-50%)' }}>
                <CashbackSticker style={{ width: isMobile ? '70px' : 'inherit' }} />
              </div>
            </div>
          )} */}
          <Grid container>
            <Grid item>
              <div style={{ height: 20 }}>
                <span style={{ fontSize: 12 }}>{props.issuedCard.name}</span>
              </div>
              <div style={{ height: 40 }}>
                {props.productId && !props.showPublicProduct && (
                  <ProductImage style={{ height: '100%' }} boxStyle={{ height: 40 }} productId={props.productId} />
                )}
                {props.productId && props.showPublicProduct && (
                  <ProductImagePublic style={{ height: '100%' }} boxStyle={{ height: 40 }} productId={props.productId} />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className="fs-block data-hj-suppress" data-private data-hj-suppress>
              <div
                style={{
                  marginTop: isMobile ? 14 : 35,
                  fontFamily: 'Credit-Card-Font',
                  fontSize: isMobile ? 17 : 20.9,
                  paddingLeft: isMobile ? 0 : 0,
                }}
              >
                {issuedCardStripe ? (
                  <>
                    {issuedCardStripe?.number ? (
                      <NumberCardFormat issuedCard={props.issuedCard} showCopyToClipboard={props.showCopyToClipboard} />
                    ) : (
                      <span>
                        {props.issuedCard.statusBox === 'pendingActivation' && props.issuedCard.type === 'physical'
                          ? '---- ---- ---- ----'
                          : `---- ---- ---- ${props.issuedCard.last4}`}
                      </span>
                    )}
                  </>
                ) : (
                  <span>4859 5--- ---- ----</span>
                )}
              </div>
            </Grid>
          </Grid>
          <div style={{ marginLeft: isMobile ? 0 : 0 }}>
            <div style={{ marginTop: isMobile ? 20 : 40 }}>
              <Grid container>
                <Grid item>
                  <span style={notBoldText}>Expiry</span>{' '}
                  <span style={boldText}>
                    {issuedCardStripe ? (
                      <>
                        {isNaN(Number(issuedCardStripe?.exp_month))
                          ? issuedCardStripe?.exp_month ?? '--'
                          : utils.twoDigits(Number(issuedCardStripe?.exp_month))}
                        /{issuedCardStripe?.exp_year ?? '--'}
                      </>
                    ) : (
                      <>
                        {isNaN(Number(expiryMonth)) ? '--' : utils.twoDigits(Number(expiryMonth))}/{expiryYear}
                      </>
                    )}
                  </span>
                </Grid>
                <Grid item style={{ marginLeft: isMobile ? 130 : 234 }}>
                  <span style={notBoldText}>CVC</span>{' '}
                  <span style={boldText}>{issuedCardStripe?.cvc ? <>{issuedCardStripe.cvc}</> : <>---</>}</span>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: isMobile ? 5 : 10 }}>
              <span style={boldText}>
                {issuedCardStripe ? issuedCardStripe.cardholder.name : props.userId ? <UserName userId={props.userId} /> : ''}
              </span>
            </div>
            <div style={{ marginTop: isMobile ? 5 : 10 }}>
              <span style={boldText}>{props.issuedCard.companie.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IssuedCardDesign
