import React from 'react'
import { BankAddress } from '../BankAddress.type'
import { MenuItem } from '@mui/material'
import SelectFieldNN from 'components/ui/SelectFieldNN'

type Props = {
  id: string
  country: string
  label: string
  onUpdate: (country: string) => void
  bankCountries: BankAddress[]
}

const SelectCountryForm = (props: Props) => {
  const { id, country, label, onUpdate, bankCountries } = props

  return (
    <SelectFieldNN id={id} label={label} value={country} onChange={(e) => onUpdate(e.target.value)}>
      {bankCountries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          {country.label}
        </MenuItem>
      ))}
    </SelectFieldNN>
  )
}

export default SelectCountryForm
