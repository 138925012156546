import { Box, Button } from '@mui/material'
import OffsiteTemplate from 'components/user/single/profile/sectionDetails/OffsiteTemplate'
import { URL_HOME } from 'config/config'
import React from 'react'

const PreDemoSubmited: React.FC = () => {
  return (
    <OffsiteTemplate>
      <Box textAlign="left">
        <p>Thank you for contacting NachoNacho! We can&apos;t wait to start working with you.</p>

        <p>Let&apos;s book a quick video call so we can show you how NachoNacho works.</p>
      </Box>

      <Box my="20px" textAlign="left">
        <Button variant="contained" color="primary" href={`${URL_HOME}/demo`}>
          Book a demo
        </Button>
      </Box>

      <Box textAlign="left">
        <p>
          In the meantime, feel free to{' '}
          <a href={`${URL_HOME}/marketplace`} className="link">
            browse our marketplace
          </a>{' '}
          and pick out products you like.
        </p>
      </Box>
    </OffsiteTemplate>
  )
}

export default PreDemoSubmited
