import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Companie, Product } from '__generated__/graphql'

type Props = {
  companie: Pick<Companie, 'id' | 'isProductEnabledForSeller'> & {
    ownedProducts: Array<Pick<Product, 'id'>>
  }
}
const SellerVerificationRequestSubmitted = (props: Props) => {
  const productId = props.companie.ownedProducts[0].id
  const isMarketplaceProduct = props.companie.isProductEnabledForSeller
  return (
    <>
      <div className="titleStepKYC">Next steps</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        {isMarketplaceProduct ? (
          <>
            <p>
              We have received your Seller application and will review it asap. Once your application is approved, your product
              will be discoverable by hundreds of thousands of SaaS buyers.
            </p>
            <span className="tal">
              <b className="tal black2">What{`'`}s next?</b>
              <p>
                Did you know that NachoNacho offers a comprehensive subscription management, discovery and discounts platform?
                This platform is available to all companies, including Sellers. You can start using this right away by clicking on
                the
                {`'`}Create a Buyer Account{`'`}. In addition to all the benefits the Buyer Account offers, if you do at least one
                transaction as a Buyer:
              </p>

              <p>1) We will prioritize your Seller application over other Seller applications</p>
              <p>2) You may qualify for special promotions NachoNacho would do for your product across our multiple channels</p>
            </span>
            <div style={{ maxWidth: '350px', margin: '0 auto' }}>
              <Link className="link" to="/company/createCompany">
                <Button id="createBuyerCompany" size="medium" sx={{ width: '100%' }} color="primary" variant="contained">
                  Create a Buyer Account
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <p>Now you need to create a Perk Offer that will be available to various ecosystems.</p>
            <div style={{ maxWidth: '350px', margin: '0 auto' }}>
              <Link className="link" to={`/seller/perks/${productId}`}>
                <Button id="createBuyerCompany" size="medium" sx={{ width: '100%' }} color="primary" variant="contained">
                  Go to My Perk Offer
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default SellerVerificationRequestSubmitted
