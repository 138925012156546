import React from 'react'
import { Box, Button, Chip, FormHelperText, Paper } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import { gql } from '__generated__'
import AddPositionProduct from '../AddPositionProduct'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import { CategorieProduct, PositionProduct, Visibility } from '__generated__/graphql'
import themeNN from 'components/themeNN'

const QUERY = gql(/* GraphQL */ `
  query positionProductsConnection_PositionProductsAutocomplete(
    $where: PositionProductWhereInput
    $orderBy: PositionProductOrderByInput
    $first: Int
    $skip: Int
  ) {
    positionProductsConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      nodes {
        id
        categorieProduct {
          id
          name
        }
      }
    }
  }
`)

const MUTATION = gql(/* GraphQL */ `
  mutation connectProductToCategories_PositionProductsAutocomplete(
    $productId: String!
    $categorieProductIds: [String!]!
    $visibility: Visibility!
  ) {
    connectProductToCategories(productId: $productId, categorieProductIds: $categorieProductIds, visibility: $visibility) {
      id
    }
  }
`)

type Props = {
  title: string
  productId: string
  visibility: Visibility
  noMargin?: boolean
  subtext?: string
  sublink?: string
  onUpdate?: (boolean: boolean) => void
}

const PositionProductsAutocomplete = (props: Props) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const [positionProductsSelected, setPositionProductsSelected] = React.useState<
    Array<
      Pick<PositionProduct, 'id'> & {
        categorieProduct: Pick<CategorieProduct, 'id' | 'name'>
      }
    >
  >([])

  const [connectProductToCategories] = useMutation(MUTATION)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      where: {
        categorieProduct: {
          visibility: { in: [props.visibility] },
        },
        product: {
          id: { equals: props.productId },
        },
      },
    },
  })

  const setPositionProductsF = async () => {
    try {
      const categorieProductIds = positionProductsSelected.map((item) => item.categorieProduct.id)

      const response = await connectProductToCategories({
        variables: {
          productId: props.productId,
          categorieProductIds,
          visibility: props.visibility,
        },
      })

      if (response) {
        refetch()
        setIsEdit(false)
        props.onUpdate && props.onUpdate(positionProductsSelected.length > 0)
      }
    } catch (e) {
      console.log(e)
    }
    setIsEdit(false)
  }

  React.useEffect(() => {
    if (data?.positionProductsConnection) {
      setPositionProductsSelected(data.positionProductsConnection.nodes)
    }
  }, [data])

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.positionProductsConnection) return <NotFound />

  return (
    <div>
      <div className={props.noMargin ? '' : 'paperOut'}>
        <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
          {!isEdit ? (
            <>
              <EditHeaderSection
                canEdit={true}
                title={props.title}
                onClick={() => setIsEdit(true)}
                isEdit={isEdit}
                id="id-PositionProductsAutocomplete"
              />
              <Box display="flex" gap={1} flexWrap="wrap">
                {data.positionProductsConnection.nodes.map((positionProductNode) => (
                  <Chip key={positionProductNode.id} label={positionProductNode.categorieProduct.name} />
                ))}
              </Box>
            </>
          ) : (
            <>
              <h4>{props.title}</h4>
              <AddPositionProduct
                visibility={props.visibility}
                positionProductsSelected={positionProductsSelected}
                onUpdate={(values) => setPositionProductsSelected(values)}
              />
              {props.subtext && (
                <FormHelperText style={{ cursor: 'default', width: 'fit-content' }} className="primary">
                  <a href={props.sublink} target="_blank" rel="noreferrer" className="primary">
                    {props.subtext}
                  </a>
                </FormHelperText>
              )}
              <div style={{ height: 10 }} />
              <Button onClick={() => setIsEdit(false)}>Cancel</Button>{' '}
              <Button variant="contained" onClick={setPositionProductsF}>
                Save
              </Button>
            </>
          )}
        </Paper>
      </div>
    </div>
  )
}

export default PositionProductsAutocomplete
