import React from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import NotFound from '../../../nav/error/NotFound'
import Error from '../../../nav/error/Error'
import Loading from '../../../nav/error/Loading'
import { Paper, Grid } from '@mui/material'
import LogOutCross from 'components/nav/LogoutCross'
import LogoNN from 'assets/logo/nn/nachonacho-full-blue-horizontal.svg'
import { gql } from '__generated__'
import Check2FAPageChild from './Check2FAPageChild'

const USER_QUERY = gql(/* GraphQL */ `
  query User_Check2FAPage($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      isPhoneValidated
      enabled2FAPhone
      isEmailValidated
      enabled2FAEmail
      isTwoFactorTotpVerified
      enabled2FATotp
    }
  }
`)

type Props = {
  userId: string
}

const Check2FAPage = (props: Props) => {
  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: {
      where: {
        id: props.userId,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <div className="tac margin6">
          <Link to="/">
            <img alt="logo" className="logoNachoNacho" src={LogoNN} />
          </Link>
        </div>

        <Paper variant="borderless" className="paperIn">
          <Grid container>
            <Grid item xs={6} className="tal">
              <h3>2-Factor Authentication</h3>
              <p>This extra step shows it’s really you trying to sign in.</p>
            </Grid>
            <Grid item xs={6} className="tar">
              <LogOutCross />
            </Grid>
          </Grid>
          <div style={{ height: 10 }} />
          <Check2FAPageChild user={data.user} />
        </Paper>
      </div>
    </div>
  )
}

export default Check2FAPage
