import React from 'react'
import FileForm from './FileForm'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { Dialog, Button } from '@mui/material'
import IconFilePrivate from '../IconFilePrivate'
import { File, TypeFile } from '__generated__/graphql'
import { gql } from '__generated__'
import TextFieldNN from 'components/ui/TextFieldNN'

const CREATE_FILE_MUTATION = gql(/* GraphQL */ `
  mutation createFile_CreateFile($data: FileCreateInput!) {
    createFile(data: $data) {
      id
    }
  }
`)

type Props = {
  typeFile: TypeFile
  onCancel: () => void
  onCreate: () => void
  subscriptionId?: string
  invoiceId?: string | null
  perkId?: string
  contractId?: string
  userId?: string
  sourceId?: string
  companieId?: string | null
  disputeIssuingId?: string
  errorMessageFileNotPermited: string
  textDragNDrop: string
  acceptedImageTypes: string[]
  fileSizeMax: number
}

const CreateFile = (props: Props) => {
  const [files, setFiles] = React.useState<Array<File>>([])
  const [loading, setLoading] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [createFile] = useMutation(CREATE_FILE_MUTATION)

  const createFileF = async () => {
    setLoading(true)

    try {
      await Promise.all(
        files.map(async (file) => {
          await createFile({
            variables: {
              data: {
                nameFile: file.nameFile,
                type: file.type,
                description: file.description ? file.description : '',
                typeFile: props.typeFile,
                companie: props.companieId ? { connect: { id: props.companieId } } : undefined,
                subscription: props.subscriptionId ? { connect: { id: props.subscriptionId } } : undefined,
                user: props.userId ? { connect: { id: props.userId } } : undefined,
                source: props.sourceId ? { connect: { id: props.sourceId } } : undefined,
                contract: props.contractId ? { connect: { id: props.contractId } } : undefined,
                invoice: props.invoiceId ? { connect: { id: props.invoiceId } } : undefined,
                perk: props.perkId ? { connect: { id: props.perkId } } : undefined,
                disputeIssuing: props.disputeIssuingId ? { connect: { id: props.disputeIssuingId } } : undefined,
              },
            },
          })
        }),
      )
      setLoading(false)
      props.onCreate()
    } catch (e) {
      setLoading(false)
      return
    }
  }

  const updateFiles = (changedFiles: Array<File>) => {
    setFiles(changedFiles)
  }
  return (
    <>
      <FileForm
        fileSizeMax={props.fileSizeMax}
        textDragNDrop={props.textDragNDrop}
        errorMessageFileNotPermited={props.errorMessageFileNotPermited}
        acceptedImageTypes={props.acceptedImageTypes}
        files={files}
        onChange={(files) => {
          updateFiles(files)
          if (files.length > 0) {
            setShowModal(true)
          }
        }}
      />
      <div style={{ height: '10px' }} />
      <Dialog open={showModal} maxWidth="md" fullWidth onClose={() => setShowModal(false)}>
        <div style={{ padding: 20 }}>
          {files.map((file) => (
            <div key={file.shortNameFile} style={{ marginTop: '20px' }}>
              <div>
                <IconFilePrivate key={file.shortNameFile} type={file.type ? file.type : ''} />
              </div>
              <div>{file.shortNameFile}</div>
              <div style={{ height: 20 }} />
              <TextFieldNN
                className="width100per"
                key={file.shortNameFile}
                label="File description (optional)"
                id="description"
                type="text"
                value={file.description}
                onChange={(e) => {
                  if (e.target.value) {
                    file.description = e.target.value
                    setFiles(files)
                  }
                }}
              />
            </div>
          ))}
          <div style={{ marginTop: 10 }}>
            <Button onClick={() => props.onCancel()}>Cancel</Button>{' '}
            <ButtonLoadingAfterClick
              actionName="createFile"
              id="idCreateFile"
              icon=""
              disabled={files.length > 0 ? false : true}
              variant="outlined"
              color="primary"
              size="medium"
              buttonText="Save"
              buttonLoadingText="Loading..."
              onClick={createFileF}
              loading={loading}
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default CreateFile
