import React from 'react'
import { FormControl, InputLabel, TextField } from '@mui/material'
import UpdateCompanie from '../companie/form/UpdateCompanie'
import PhoneForm from '../user/single/phone/PhoneForm'
import { Companie } from '__generated__/graphql'
import DateComponentDropDown from 'components/nav/DateComponentDropDown'

type CompanieP = Pick<
  Companie,
  | 'id'
  | 'leadershipPhone'
  | 'leadershipPhoneCode'
  | 'leadershipTitle'
  | 'leadershipLastName'
  | 'leadershipFirstName'
  | 'leadershipEmail'
  | 'leadershipLast4'
  | 'leadershipDobYear'
  | 'leadershipDobMonth'
  | 'leadershipDobDay'
>

type Props = {
  textButton: string
  textCancelButton: string
  companie: CompanieP & Partial<Companie>
  onCancel: () => void
  onUpdate: () => void
}

const OnboardingleadershipNameForm: React.FC<Props> = (props: Props) => {
  const [companie, setCompanie] = React.useState(props.companie)

  const [dobValid, setDobValid] = React.useState(false)
  const [firstNameInit, setFirstNameInit] = React.useState(false)
  const [lastNameInit, setLastNameInit] = React.useState(false)

  const isFormValid = () => {
    return (
      companie.leadershipFirstName &&
      companie.leadershipLastName &&
      companie.leadershipTitle &&
      companie.leadershipPhoneCode &&
      companie.leadershipPhone &&
      dobValid
    )
  }

  return (
    <>
      <div>
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <InputLabel shrink htmlFor="leadershipFirstName">
            <b className="black2">First name</b>
          </InputLabel>
          <TextField
            id="leadershipFirstName"
            type="text"
            size="small"
            className="width100per spaceForm"
            value={companie.leadershipFirstName}
            error={!companie.leadershipFirstName && firstNameInit}
            onBlur={() => setFirstNameInit(true)}
            onChange={(e) =>
              setCompanie({
                ...companie,
                leadershipFirstName: e.target.value,
              })
            }
          />
        </FormControl>
      </div>

      <div>
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <InputLabel shrink htmlFor="leadershipLastName">
            <b className="black2">Last name</b>
          </InputLabel>
          <TextField
            id="leadershipLastName"
            type="text"
            size="small"
            className="width100per spaceForm"
            value={companie.leadershipLastName}
            error={!companie.leadershipLastName && lastNameInit}
            onBlur={() => setLastNameInit(true)}
            onChange={(e) =>
              setCompanie({
                ...companie,
                leadershipLastName: e.target.value,
              })
            }
          />
        </FormControl>
      </div>
      <div id="dobForm" style={{ marginTop: '10px' }}>
        <InputLabel shrink htmlFor="SSN">
          <b className="black2">Date of Birth</b>
        </InputLabel>
        <DateComponentDropDown
          year={companie.leadershipDobYear ? companie.leadershipDobYear : undefined}
          month={companie.leadershipDobMonth ? companie.leadershipDobMonth : undefined}
          day={companie.leadershipDobDay ? companie.leadershipDobDay : undefined}
          onUpdateYear={(dobYear) =>
            setCompanie({
              ...companie,
              leadershipDobYear: dobYear,
            })
          }
          onUpdateDay={(dobDay) =>
            setCompanie({
              ...companie,
              leadershipDobDay: dobDay,
            })
          }
          onUpdateMonth={(dobMonth) =>
            setCompanie({
              ...companie,
              leadershipDobMonth: dobMonth,
            })
          }
          isValid={(isValid) => setDobValid(isValid)}
        />
      </div>
      <div>
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <InputLabel shrink htmlFor="leadershipTitle">
            <b className="black2">Title</b>
          </InputLabel>
          <TextField
            id="leadershipTitle"
            type="text"
            size="small"
            className="width100per spaceForm"
            value={companie.leadershipTitle}
            onChange={(e) =>
              setCompanie({
                ...companie,
                leadershipTitle: e.target.value,
              })
            }
          />
        </FormControl>
      </div>
      <div>
        <PhoneForm
          phone={companie.leadershipPhone}
          phoneCode={companie.leadershipPhoneCode}
          onChangePhone={(phone) =>
            setCompanie({
              ...companie,
              leadershipPhone: phone,
            })
          }
          onChangePhoneCode={(phoneCode) =>
            setCompanie({
              ...companie,
              leadershipPhoneCode: phoneCode,
            })
          }
          onKeyPress={() => {}}
        />
      </div>

      <div style={{ height: '20px' }} />

      <div>
        <UpdateCompanie
          actionName="updateLeadership"
          disabled={!isFormValid()}
          showCancelButton={false}
          textButton={props.textButton}
          textCancelButton={props.textCancelButton}
          onUpdate={() => props.onUpdate()}
          onCancel={() => props.onCancel()}
          companie={{
            id: companie.id,
            leadershipFirstName: companie.leadershipFirstName,
            leadershipLastName: companie.leadershipLastName,
            leadershipTitle: companie.leadershipTitle,
            leadershipPhone: companie.leadershipPhone,
            leadershipPhoneCode: companie.leadershipPhoneCode,
            leadershipDobYear: companie.leadershipDobYear,
            leadershipDobMonth: companie.leadershipDobMonth,
            leadershipDobDay: companie.leadershipDobDay,
          }}
        />
      </div>
    </>
  )
}

export default OnboardingleadershipNameForm
