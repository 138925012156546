import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import SingleAddresseView from '../SingleAddresseView'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query addresses_AddressesQuery($where: AddresseWhereInput, $orderBy: AddresseOrderBy, $skip: Int, $first: Int) {
    addresses(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      type
      address1
      address2
      city
      zip
      state
      country
    }
  }
`)

type Props = {
  title: string
  variables: any
}

const AddressesQuery = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: props.variables,
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.addresses) return <NotFound />

  return (
    <>
      {data.addresses.map((addresse) =>
        addresse ? (
          <div key={addresse?.id}>
            <SingleAddresseView title={props.title} addresse={addresse} />
          </div>
        ) : null,
      )}
    </>
  )
}

export default AddressesQuery
