import { SxProps } from '@mui/material'
import React from 'react'

interface Theme {
  primary: React.CSSProperties
  yellow: React.CSSProperties
  mavenBlue: React.CSSProperties
  purple: React.CSSProperties
  reward: React.CSSProperties
  textField: React.CSSProperties
  bgGrey2: React.CSSProperties
  bgGrey3: React.CSSProperties
  bgGrey4: React.CSSProperties
  bgGrey5: React.CSSProperties
  greyN20: React.CSSProperties
  blueB50: React.CSSProperties
  black: React.CSSProperties
  white: React.CSSProperties
  greyNeutrals: React.CSSProperties
  greyBackground: React.CSSProperties
  bgGrey2background: React.CSSProperties
  lightGrey: React.CSSProperties
  lightOrange: React.CSSProperties
  grey: React.CSSProperties
  green: React.CSSProperties
  greenHover: React.CSSProperties
  bold: React.CSSProperties
  darkBlue: React.CSSProperties
  promotionChip: React.CSSProperties
  error: React.CSSProperties
  lightBlue: React.CSSProperties
  chatBlue: React.CSSProperties
  textFieldMultilines: React.CSSProperties
  borderColor: React.CSSProperties
  formLabel: SxProps
  formLabelDatePicker: SxProps
  chipCssText: SxProps
  chipCss: SxProps
  threeDots: SxProps
}

const color = {
  mavenBlue: {
    color: '#EDF3FF',
  },
  yellow: {
    color: '#fae83c',
  },
  primary: {
    color: '#1445FE',
  },
  purple: {
    color: '#6A24FF',
  },

  error: {
    color: '#F7315A',
  },

  darkBlue: {
    color: '#060452',
  },
  chatBlue: {
    color: '#E8ECFF',
  },
  lightBlue: {
    color: '#2475FF',
  },
  reward: {
    color: '#139313',
  },
  green: {
    color: '#159312',
  },
  greenHover: {
    color: '#0F670D',
  },
  grey: {
    color: '#C4C4C4',
  },
  lightGrey: {
    color: '#858580',
  },
  lightOrange: {
    color: 'rgba(255, 242, 230, 0.50)',
  },
  bgGrey2: {
    color: '#f4f1ee',
  },
  bgGrey3: {
    color: '#F4F4F7',
  },
  bgGrey4: {
    color: '#fafafa ',
  },
  bgGrey5: {
    color: '#676767',
  },
  greyN20: {
    color: '#F5F5F6',
  },
  blueB50: {
    color: '#E8ECFF',
  },
  black: {
    color: '#000000',
  },
  white: {
    color: '#ffffff',
  },
  greyBackground: {
    color: '#FAF8F7',
  },
  greyNeutrals: {
    color: '#596170',
  },
  borderColor: {
    color: '#EBECEE',
  },
}
const themeNN: Theme = {
  ...color,
  threeDots: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textField: {
    minHeight: '68px',
  },
  textFieldMultilines: {
    marginBottom: '20px',
  },

  formLabel: {
    textAlign: 'left',
    color: 'black',
    fontWeight: 700,
    mb: '5px',
    fontSize: '14px',
  },
  formLabelDatePicker: {
    color: 'black',
    fontWeight: 700,
    fontSize: '14px',
    position: 'relative',
    left: '-10px',
    top: '-14px',
  },
  bgGrey2background: {
    backgroundColor: color.bgGrey2.color,
  },
  bold: {
    fontWeight: 700,
  },
  promotionChip: {
    backgroundColor: color.yellow.color,
    color: 'black',
    fontFamily: 'Roobert',
    // fontSize: '12px',
    width: 'fit-content',
    borderRadius: '20px',
    padding: '7px 1px',
    textAlign: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 11,
    fontWeight: 400,
  },

  chipCssText: {
    display: 'flex',
    alignItems: 'center',
    ml: 2,
    mr: 2,
    fontWeight: 400,
    fontSize: 15,
    color: 'black',
  },
  chipCss: {
    // backgroundColor: color.bgGrey2.color,
    flexDirection: 'row',
    display: 'flex',
    p: 1,
    width: 'fit-content',
    borderRadius: 100,
  },
}
export default themeNN
