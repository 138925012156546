import React from 'react'
import { Companie, StripeBusinessType, StripeCompanyStructure } from '__generated__/graphql'
import { FormControl, FormHelperText, InputLabel, MenuItem, TextField, Tooltip } from '@mui/material'
import UpdateCompanie from 'components/companie/form/UpdateCompanie'
import UpdateLeadershipFromSelf from 'components/companie/form/UpdateLeadershipFromSelf'
import { useKYCContext } from 'components/onboarding/KYCContext'
import { Link } from 'react-router-dom'
import { URL_HOME } from 'config/config'

type Props = {
  companie: Pick<Companie, 'id' | 'stripeBusinessType' | 'stripeCompanyStructure' | 'registrationNumber'>
  onCreate: () => void
  onUpdate: () => void
  onCancel: () => void
}

const OnboardingCompanieType = (props: Props) => {
  const kycContext = useKYCContext()
  const [companie, setCompanie] = React.useState(props.companie)
  const [registrationNumberInit, setRegistrationNumberInit] = React.useState(true)
  const [einMasked, setEinMasked] = React.useState(props.companie.registrationNumber)

  const registrationNumberValid = () => {
    return companie.registrationNumber && companie.registrationNumber.replace(/[^0-9]/g, '').length === 9
  }

  const formatMask = (value: string) => {
    const einNumber = value.replace(/[^0-9]/g, '')
    if (einNumber.length > 2) {
      return `${einNumber.substring(0, 2)}-${einNumber.substring(2)}`
    }
  }

  return (
    <>
      <div className="titleStepKYC">Company</div>
      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="type">
              <b className="black2">Account type</b>
            </InputLabel>
            <TextField
              id="type"
              size="small"
              select
              className="tal width100per spaceForm"
              value={companie.stripeBusinessType}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  stripeBusinessType: e.target.value as StripeBusinessType,
                })
              }}
            >
              <MenuItem value={StripeBusinessType.Individual}>Individual</MenuItem>
              <MenuItem value={StripeBusinessType.Company}>Company</MenuItem>
              <MenuItem value={StripeBusinessType.GovernmentEntity}>Government Entity</MenuItem>
              <MenuItem value={StripeBusinessType.NonProfit}>Non-profit Organization</MenuItem>
            </TextField>
          </FormControl>
          {companie.stripeBusinessType === StripeBusinessType.Company && (
            <div>
              <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
                <InputLabel shrink htmlFor="type">
                  <b className="black2">Business structure</b>
                </InputLabel>
                <TextField
                  id="type"
                  size="small"
                  select
                  className="tal width100per spaceForm"
                  value={companie.stripeCompanyStructure}
                  onChange={(e) => {
                    setCompanie({
                      ...companie,
                      stripeCompanyStructure: e.target.value as StripeCompanyStructure,
                    })
                  }}
                >
                  <MenuItem value={StripeCompanyStructure.MultiMemberLlc}>Multi-Member LLC</MenuItem>
                  <MenuItem value={StripeCompanyStructure.PrivateCorporation}>Private Corporation</MenuItem>
                  <MenuItem value={StripeCompanyStructure.PrivatePartnership}>Private Partnership</MenuItem>
                  <MenuItem value={StripeCompanyStructure.PublicCorporation}>Public Corporation</MenuItem>
                  <MenuItem value={StripeCompanyStructure.PublicPartnership}>Public Partnership</MenuItem>
                  <MenuItem value={StripeCompanyStructure.SingleMemberLlc}>Single-Member LLC</MenuItem>
                  <MenuItem value={StripeCompanyStructure.SoleProprietorship}>Sole Proprietorship</MenuItem>
                  <MenuItem value={StripeCompanyStructure.UnincorporatedAssociation}>Unincorporated Association</MenuItem>
                </TextField>
              </FormControl>
            </div>
          )}
          {companie.stripeBusinessType === StripeBusinessType.NonProfit && (
            <div>
              <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
                <InputLabel shrink htmlFor="type">
                  <b className="black2">Business Structure</b>
                </InputLabel>
                <TextField
                  id="type"
                  size="small"
                  select
                  className="tal width100per spaceForm"
                  value={companie.stripeCompanyStructure}
                  onChange={(e) => {
                    setCompanie({
                      ...companie,
                      stripeCompanyStructure: e.target.value as StripeCompanyStructure,
                    })
                  }}
                >
                  <MenuItem value={StripeCompanyStructure.IncorporatedNonProfit}>Incorporated Non-Profit</MenuItem>
                  <MenuItem value={StripeCompanyStructure.UnincorporatedNonProfit}>Unincorporated Non-Profit</MenuItem>
                </TextField>
              </FormControl>
            </div>
          )}
          {companie.stripeBusinessType === StripeBusinessType.GovernmentEntity && (
            <div>
              <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
                <InputLabel shrink htmlFor="type">
                  <b className="black2">Business Structure</b>
                </InputLabel>
                <TextField
                  id="type"
                  size="small"
                  select
                  className="tal width100per spaceForm"
                  value={companie.stripeCompanyStructure}
                  onChange={(e) => {
                    setCompanie({
                      ...companie,
                      stripeCompanyStructure: e.target.value as StripeCompanyStructure,
                    })
                  }}
                >
                  <MenuItem value={StripeCompanyStructure.GovernmentalUnit}>Governmental Unit</MenuItem>
                  <MenuItem value={StripeCompanyStructure.GovernmentInstrumentality}>Government Instrumentality</MenuItem>
                  <MenuItem value={StripeCompanyStructure.TaxExemptGovernmentInstrumentality}>
                    Tax-Exempt Government Instrumentality
                  </MenuItem>
                </TextField>
              </FormControl>
            </div>
          )}
          {companie.stripeBusinessType && companie.stripeBusinessType !== StripeBusinessType.Individual && (
            <>
              <div>
                <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
                  <InputLabel shrink htmlFor="registrationNumber">
                    <b className="black2">EIN Number (USA only)</b>
                  </InputLabel>
                  <TextField
                    id="registrationNumber"
                    size="small"
                    className="width100per spaceForm"
                    value={einMasked}
                    error={!registrationNumberValid() && !registrationNumberInit}
                    onChange={(e) => {
                      setEinMasked(formatMask(e.target.value) ?? null)
                      setCompanie({
                        ...companie,
                        registrationNumber: e.target.value.replace(/[^0-9]/g, ''),
                      })
                      setRegistrationNumberInit(false)
                    }}
                  />
                </FormControl>

                <Tooltip
                  arrow
                  title={
                    <>
                      Please{' '}
                      <Link className="link" to={`${URL_HOME}/contact`} style={{ fontWeight: '700' }}>
                        contact us
                      </Link>{' '}
                      if you don't have a USA registered entity.
                    </>
                  }
                >
                  <FormHelperText style={{ cursor: 'default', width: 'fit-content' }} className="primary">
                    Not USA registered?
                  </FormHelperText>
                </Tooltip>
              </div>
              <div style={{ marginTop: 20 }}>
                <UpdateCompanie
                  actionName="updateLeadershipDetails"
                  disabled={!companie.stripeCompanyStructure || !companie.stripeBusinessType || !registrationNumberValid()}
                  showCancelButton={false}
                  textButton="Next"
                  textCancelButton=""
                  onUpdate={() => {
                    kycContext.reloadKYCState()
                    props.onUpdate()
                  }}
                  onCancel={props.onCancel}
                  companie={{
                    id: companie.id,
                    registrationNumber: companie.registrationNumber,
                    stripeBusinessType: companie.stripeBusinessType,
                    stripeCompanyStructure: companie.stripeCompanyStructure,
                  }}
                />
              </div>
            </>
          )}
          {companie.stripeBusinessType === StripeBusinessType.Individual && (
            <>
              <div style={{ height: '20px' }} />
              <UpdateLeadershipFromSelf
                disabled={!companie.stripeBusinessType}
                showCancelButton={false}
                textButton="Next"
                textCancelButton=""
                onUpdate={() => {
                  kycContext.reloadKYCState()
                  props.onCreate()
                }}
                onCancel={props.onCancel}
                companieId={companie.id}
                isIndividualCompany={true}
                leadershipIsOwner={true}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default OnboardingCompanieType
