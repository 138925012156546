import React from 'react'
import { FormControl, FormLabel, OutlinedInput, InputProps, FormHelperText } from '@mui/material'
import theme from 'components/themeNN'

type Props = {
  id: string
  label: string
  helperText?: string
  helperTextAlways?: string
} & InputProps

const TextFieldNN: React.FC<Props> = (props: Props) => {
  const { id, label, helperText, sx, ...inputProps } = props

  return (
    <FormControl fullWidth sx={{ minHeight: 90, ...sx }}>
      <FormLabel htmlFor={id} sx={theme.formLabel}>
        {label}
      </FormLabel>
      <OutlinedInput id={id} size="small" {...inputProps} />

      {props.error && helperText && (
        <FormHelperText error={props.error} sx={{ textAlign: 'left' }}>
          {helperText}
        </FormHelperText>
      )}
      {props.helperTextAlways && <FormHelperText sx={{ textAlign: 'left' }}>{props.helperTextAlways}</FormHelperText>}
    </FormControl>
  )
}

export default TextFieldNN
