import React from 'react'
import validator from 'email-validator'
import { FormControl, InputLabel, TextField } from '@mui/material'
import CreateInvitationOwnership from 'components/invitation/CreateInvitationOwnership'
import { Companie } from '__generated__/graphql'
import UpdateCompanie from 'components/companie/form/UpdateCompanie'

type Props = {
  companie: Pick<Companie, 'id' | 'leadershipEmail'>
  continueForm: () => void
  beneficiaryInvited: (email: string) => void
}
const OnboardingLeadershipFillMode = (props: Props) => {
  const [companie, setCompanie] = React.useState(props.companie)
  const [emailInit, setEmailInit] = React.useState(false)
  return (
    <>
      <div>
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <InputLabel shrink htmlFor="leadershipEmail">
            <b className="black2">Beneficiary Email</b>
          </InputLabel>
          <TextField
            id="leadershipEmail"
            type="text"
            size="small"
            className="width100per spaceForm"
            value={companie.leadershipEmail}
            error={!validator.validate(companie.leadershipEmail) && emailInit}
            onBlur={() => setEmailInit(true)}
            onChange={(e) =>
              setCompanie({
                ...companie,
                leadershipEmail: e.target.value,
              })
            }
          />
        </FormControl>
      </div>

      <div style={{ height: '20px' }} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CreateInvitationOwnership
          companieId={companie.id}
          email={companie.leadershipEmail}
          onCreate={() => props.beneficiaryInvited(companie.leadershipEmail)}
          disabled={!validator.validate(companie.leadershipEmail)}
        />
        <UpdateCompanie
          fullWidth
          actionName="updateLeadershipDetails"
          disabled={!validator.validate(companie.leadershipEmail)}
          showCancelButton={false}
          textButton="Fill on their behalf"
          textCancelButton=""
          onUpdate={() => {
            props.continueForm()
          }}
          onCancel={() => {}}
          companie={{ id: companie.id, leadershipEmail: companie.leadershipEmail }}
        />
      </div>
    </>
  )
}

export default OnboardingLeadershipFillMode
