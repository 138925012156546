import React from 'react'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { Product } from '__generated__/graphql'
import { Button } from '@mui/material'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import { FrontEndForm } from 'helpers/types'

const MUTATION = gql(/* GraphQL */ `
  mutation updateProduct_UpdateProductLight($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
      name
      urlName
      twitterLink
      instagramLink
      entityType
      linkedInLink
      workLocation
      facebookLink
      youtubeLink
      shortDescription
      productDescription
      keyBenefits
      features
      pricing
      nameFile
      visibility
      sellerLink
      typeProduct
      productFrequency
      creationType
      policyLink
      isChatEnabled
      productEditRequests {
        id
        updatedAt
        productFieldName
        productFieldValue
      }
    }
  }
`)

type Props = {
  onCancel: () => void
  onSaved: (product: Pick<Product, 'id' | 'nameFile'>) => void
  disabled: boolean
  product: Pick<Product, 'id'> & Partial<FrontEndForm<Product>>
  buttonText?: string
  hideCancel?: boolean
  fullWidth?: boolean
}

const UpdateProductLight: React.FC<Props> = ({ onCancel, onSaved, disabled, product, buttonText, hideCancel, fullWidth }) => {
  const showErrors = useShowErrors()
  const [updateProductMutation] = useMutation(MUTATION)
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const updateProduct = async () => {
    try {
      setLoading(true)
      const newProduct = await updateProductMutation({
        variables: {
          where: { id: product.id },
          data: {
            name: product.name,
            urlName: product.urlName,
            twitterLink: product.twitterLink,
            startedOperatingInWhichYear: product.startedOperatingInWhichYear
              ? Number(product.startedOperatingInWhichYear)
              : undefined,
            instagramLink: product.instagramLink,
            workLocation: product.workLocation,
            linkedInLink: product.linkedInLink,
            facebookLink: product.facebookLink,
            youtubeLink: product.youtubeLink,
            numberOfEmployees: product.numberOfEmployees ? Number(product.numberOfEmployees) : undefined,
            typicalProjectPriceRangeLow: product.typicalProjectPriceRangeLow
              ? Number(product.typicalProjectPriceRangeLow)
              : undefined,
            typicalProjectPriceRangeHigh: product.typicalProjectPriceRangeHigh
              ? Number(product.typicalProjectPriceRangeHigh)
              : undefined,
            averageMonthyRateRangeHigh: product.averageMonthyRateRangeHigh
              ? Number(product.averageMonthyRateRangeHigh)
              : undefined,
            averageMonthyRateRangeLow: product.averageMonthyRateRangeLow ? Number(product.averageMonthyRateRangeLow) : undefined,
            atWhatStageIsMostHelpful: product.atWhatStageIsMostHelpful,
            shortDescription: product.shortDescription,
            productDescription: product.productDescription,
            keyBenefits: product.keyBenefits,
            features: product.features,
            pricing: product.pricing,
            nameFile: product.nameFile,
            visibility: product.visibility,
            sellerLink: product.sellerLink,
            typeProduct: product.typeProduct,
            productFrequency: product.productFrequency,
            creationType: product.creationType,
            policyLink: product.policyLink,
            averageHourlyRate: product.averageHourlyRate ? Number(product.averageHourlyRate) : undefined,
            entityType: product.entityType,
            isChatEnabled: product.isChatEnabled,
          },
        },
      })
      if (newProduct?.data) {
        onSaved(newProduct.data.updateProduct)
      }
    } catch (e) {
      showErrors(e, setMessage)
    }
    setLoading(false)
  }

  return (
    <div>
      {!hideCancel && <Button onClick={onCancel}>Cancel</Button>}{' '}
      <ButtonLoadingAfterClick
        actionName="updateProduct"
        id="idButtonUpdateProduct"
        icon=""
        color="primary"
        disabled={disabled}
        variant="contained"
        size="medium"
        sx={fullWidth ? { width: '100%' } : {}}
        buttonText={buttonText || 'Save'}
        buttonLoadingText="Loading..."
        onClick={updateProduct}
        loading={loading}
      />
      {message && (
        <div id="idMessage" className="secondary">
          {message}
        </div>
      )}
    </div>
  )
}

export default UpdateProductLight
