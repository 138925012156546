import React from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderRightSideBar from 'components/application/HeaderRightSideBar'
import PaymentSourceBuyerBasicPlusSettings from 'components/paymentSource/list/PaymentSourceBuyerBasicPlusSettings'

type Props = {
  text: string
}
const BasicPlusDialogRight = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const parsed = queryString.parse(location.search)

  const closeDialog = () => {
    delete parsed.showDialog
    navigate('?' + queryString.stringify(parsed))
  }

  return (
    <div>
      <HeaderRightSideBar title={props.text} closeDialog={closeDialog} />

      <div className="paperOut">
        <PaymentSourceBuyerBasicPlusSettings onInvoiceCreated={closeDialog} />
        {/* <CreateNewReview /> */}
      </div>
    </div>
  )
}

export default BasicPlusDialogRight
