import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../../nav/ButtonLoadingAfterClick'
import { GraphQLError } from 'hooks/useShowErrors'
import { ButtonProps } from '@mui/material'
import { IssuedCard } from '__generated__/graphql'
import { gql } from '__generated__'

const CREATE_SUBSCRIPTION_CARD = gql(/* GraphQL */ `
  mutation createSubscriptionCard_CreateSubscriptionCard(
    $subscriptionId: String!
    $authorizedAmount: Float
    $authorizedAmountUnit: AuthorizedAmountUnitType
  ) {
    createSubscriptionCard(
      subscriptionId: $subscriptionId
      authorizedAmount: $authorizedAmount
      authorizedAmountUnit: $authorizedAmountUnit
    ) {
      id
    }
  }
`)

type Props = {
  subscriptionId: string
  authorizedAmount: IssuedCard['authorizedAmount']
  authorizedAmountUnit: IssuedCard['authorizedAmountUnit']
  buttonText: string
  disabled: boolean
  sx?: ButtonProps['sx']
  onCreate: (issuedCard: IssuedCard) => void
  id?: string
}

const CreateSubscriptionCard = (props: Props) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [createSubscriptionCard] = useMutation(CREATE_SUBSCRIPTION_CARD)

  const createSubscriptionCardF = async () => {
    setLoading(true)
    let newIssuedCard
    try {
      newIssuedCard = await createSubscriptionCard({
        variables: {
          subscriptionId: props.subscriptionId,
          authorizedAmount:
            props.authorizedAmount === null || props.authorizedAmount === undefined ? undefined : Number(props.authorizedAmount),
          authorizedAmountUnit: props.authorizedAmountUnit,
        },
      })
    } catch (e) {
      setLoading(false)
      let message = `That didn't work. Make sure everything looks good and try again.`
      const gqlErrors = (e as GraphQLError).graphQLErrors
      if (gqlErrors?.length) {
        message = gqlErrors[0].message
      }
      if (message === 'Must be at least 13 years of age to use Stripe') {
        message = 'User must be at least 13 years of age. Please edit your date of birth'
      }
      if (message === 'maxIssuedCardCountCreatedError') {
        message = `Your NachoCard could not be created.
        You have reached the limit of NachoCards without connecting a Payment Source.
        Please click on the Payment Source tab and connect a Payment Source before creating any further NachoCards.`
      }
      setMessage(message)
    }
    if (newIssuedCard) {
      setLoading(false)
      props.onCreate(newIssuedCard.data.createSubscriptionCard)
      await client.resetStore()
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createSubscriptionCard"
        id={props.id}
        icon=""
        color="primary"
        variant="contained"
        size="medium"
        disabled={props.disabled}
        buttonText={props.buttonText}
        buttonLoadingText="Setting up..."
        sx={props.sx}
        onClick={() => {
          createSubscriptionCardF()
        }}
        loading={loading}
      />
      {message && (
        <>
          <div style={{ height: '10px' }} />
          <div id="idMessage" className="secondary">
            {message}
          </div>
        </>
      )}
    </>
  )
}

export default CreateSubscriptionCard
