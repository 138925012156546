import React from 'react'
import { useQuery } from '@apollo/client'
import NotFound from '../../nav/error/NotFound'
import Error from '../../nav/error/Error'
import Loading from '../../nav/error/Loading'
import CreateReview from './CreateReview'
import { gql } from '__generated__'
import EditReview from './EditReview'

// review: Pick<Review, 'id' | 'content' | 'headline' | 'title' | 'userTypeReview' | 'rating'> & {
//   product: Pick<Product, 'id' | 'name' | 'nameFile' | 'urlName' | 'visibility'>
// }
// }

const QUERY_REVIEWS = gql(/* GraphQL */ `
  query reviewsConnection_CreateReviewParent($where: ReviewWhereInput, $orderBy: ReviewOrderBy, $first: Int, $skip: Int) {
    reviewsConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      edges {
        node {
          id
          title
          userTypeReview
          headline
          content
          rating
          status
          companieId
          product {
            id
            urlName
            nameFile
            name
            visibility
          }
        }
      }
    }
  }
`)

const PRODUCT_QUERY = gql(/* GraphQL */ `
  query productSimple_CreateReviewParent($where: ProductWhereUniqueInput!) {
    productSimple(where: $where) {
      id
      name
      urlName
      nameFile
      visibility
    }
  }
`)

type Props = {
  productId: string
  companieId: string
  userId: string
}

const ManageReviewParent = (props: Props) => {
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      where: {
        id: props.productId,
      },
    },
  })
  const reviews = useQuery(QUERY_REVIEWS, {
    variables: {
      where: {
        productId: props.productId,
        userId: props.userId,
        companieId: props.companieId,
      },
    },
  })

  if (reviews.error)
    return <Error message={reviews.error.graphQLErrors.length > 0 ? reviews.error.graphQLErrors[0].message : ''} />
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.productSimple) return <NotFound />

  return (
    <div className="paperOut">
      {reviews.data?.reviewsConnection.edges.length ? (
        <EditReview review={reviews.data.reviewsConnection.edges[0].node} />
      ) : (
        <CreateReview product={data.productSimple} />
      )}
    </div>
  )
}

export default ManageReviewParent
