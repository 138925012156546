import OnboardingValidaterPhoneUserQuery from 'components/onboarding/OnboardingValidaterPhoneUserQuery'
import React from 'react'
import OffsiteTemplate from '../profile/sectionDetails/OffsiteTemplate'

type Props = {
  userId: string
}

const UserPhoneVerificationOffsite = (props: Props) => {
  return (
    <OffsiteTemplate>
      <OnboardingValidaterPhoneUserQuery userId={props.userId} onUpdate={() => {}} />
    </OffsiteTemplate>
  )
}

export default UserPhoneVerificationOffsite
