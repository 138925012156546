import React from 'react'
import { Box, Button } from '@mui/material'
import { GoogleDomainUser } from '__generated__/graphql'
import ImageTemplate from 'components/nav/ImageTemplate'
import CheckIcon from '@mui/icons-material/Check'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'

type Props = {
  user: GoogleDomainUser
  onInviteClick: (event: React.MouseEvent<HTMLButtonElement>, user: GoogleDomainUser) => void
}

const GoogleAccountUserRow: React.FC<Props> = ({ user, onInviteClick }) => {
  const inviteButton = React.useMemo(() => {
    if (typeof user.isInvitationApproved !== 'boolean') {
      return (
        <Button variant="contained" onClick={(event) => onInviteClick(event, user)}>
          Invite
        </Button>
      )
    }

    return (
      <Box display="flex" alignItems="center" gap="4px">
        {user.isInvitationApproved ? (
          <>
            Accepted <CheckIcon fontSize="small" />
          </>
        ) : (
          <>
            Invited <HourglassEmptyIcon fontSize="small" />
          </>
        )}
      </Box>
    )
  }, [onInviteClick, user])

  return (
    <Box
      key={user.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: typeof user.isInvitationApproved === 'boolean' ? '0.4' : '1',
        my: '10px',
      }}
    >
      <Box flex={1}>
        <Box display="flex" alignItems="center" justifyContent="start" gap="10px">
          <ImageTemplate format="avatar" nameFile={user.thumbnailPhotoUrl || ''} />
          <Box>
            <div>
              <b>{user.name}</b>
            </div>
            <div>
              <i>{user.primaryEmail}</i>
            </div>
          </Box>
        </Box>
      </Box>
      <Box flex="0 0 85px" display="flex" justifyContent="flex-end">
        {inviteButton}
      </Box>
    </Box>
  )
}

export default GoogleAccountUserRow
