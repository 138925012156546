import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { GetStartedStep, GetStartedStepType } from '__generated__/graphql'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { URL_HOME } from 'config/config'
import { ReactComponent as VerifyAccount } from 'assets/icon/getStarted/verifyAccount.svg'
import { ReactComponent as AddPaymentMethod } from 'assets/icon/getStarted/addPaymentMethod.svg'
import { ReactComponent as AddProfilePicture } from 'assets/icon/getStarted/addProfilePicture.svg'
import { ReactComponent as CheckMarketplace } from 'assets/icon/getStarted/checkMarketplace.svg'
import { ReactComponent as CheckMaven } from 'assets/icon/getStarted/checkMaven.svg'
import { ReactComponent as CreateNachocard } from 'assets/icon/getStarted/createNachocard.svg'
import { ReactComponent as InviteTeamMember } from 'assets/icon/getStarted/inviteTeamMember.svg'
import { ReactComponent as SeamlessIntegration } from 'assets/icon/getStarted/seamlessIntegration.svg'
import { ReactComponent as PremiumBadgeDiamond } from 'assets/icon/getStarted/premiumBadgeDiamond.svg'
import { ReactComponent as Tutorial } from 'assets/icon/getStarted/tutorial.svg'
import utils from 'components/utils'

type Props = {
  getStartedSteps: Pick<GetStartedStep, 'name' | 'type' | 'done'>[]
  closeDialog: () => void
}

type StepInfo = {
  icon: JSX.Element
  title: string
  description: string
  action: () => void
}

const tabs: GetStartedStepType[] = [GetStartedStepType.Required, GetStartedStepType.Recommended, GetStartedStepType.Optional]

const getTabLabel = (type: GetStartedStepType) => {
  const map: Record<typeof type, string> = {
    [GetStartedStepType.Required]: 'Required',
    [GetStartedStepType.Recommended]: 'Recommended',
    [GetStartedStepType.Optional]: 'Other goodies',
  }

  return map[type]
}

const GetStartedTabs: React.FC<Props> = ({ getStartedSteps, closeDialog }) => {
  const navigate = useNavigate()
  const context = useAppContextWithCompany()
  const companieId = context.userRoleCompanie.companie.id
  const [searchParams, setSearchParams] = useSearchParams()

  const getStepInfo = (name: GetStartedStep['name']): StepInfo => {
    const map: Record<typeof name, StepInfo> = {
      VERIFY_ACCOUNT: {
        icon: <VerifyAccount />,
        title: 'Verify your account',
        description: 'This is a regulatory requirement for NachoNacho',
        action: () => {
          const searchParamsObj = Object.fromEntries(searchParams)
          setSearchParams({ ...searchParamsObj, showDialog: 'userVerification' })
        },
      },
      TUTORIAL: {
        icon: <Tutorial />,
        title: 'Watch the NachoNacho Buyer tutorial',
        description: "Learn all about NachoNacho's features.",
        action: () => {
          navigate(`/tutorial`)
        },
      },
      VERIFY_ACCOUNT_BASIC: {
        icon: <PremiumBadgeDiamond />,
        title: 'Access 500+ more deals with premium',
        description:
          "Upgrade to premium for NachoNacho's full SaaS Management and Discovery tools + access to 500+ more premium deals.",
        action: () => {
          const searchParamsObj = Object.fromEntries(searchParams)
          setSearchParams({ ...searchParamsObj, showDialog: 'userVerification' })
        },
      },
      ADD_PAYMENT_METHOD: {
        icon: <AddPaymentMethod />,
        title: 'Add payment method',
        description: 'Add a payment method securely to fund all your transactions.',
        action: () => {
          navigate(`/paymentSource/${companieId}`)
        },
      },
      CREATE_NACHOCARD: {
        icon: <CreateNachocard />,
        title: utils.getCreateNachoCardCopy(context),
        description:
          'NachoCards are unlimited virtual cards for any purchase, like SaaS from the marketplace or flights and meals.',
        action: () => {
          const searchParams = new URLSearchParams({ companyContext: companieId, showDialog: 'createCard' })
          navigate(`/nachoCards?${searchParams.toString()}`)
        },
      },
      INVITE_TEAM_MEMBER: {
        icon: <InviteTeamMember />,
        title: 'Invite team member',
        description: 'Invite a team member and create a new NachoCard for them.',
        action: () => {
          navigate(`/team/${companieId}`)
        },
      },
      CONNECT_INTEGRATION: {
        icon: <SeamlessIntegration />,
        title: 'Seamless integration',
        description: 'Connect your favorite tools (Google, LinkedIn, Slack, and many more).',
        action: () => {
          navigate(`/settings/integrations`)
        },
      },
      ADD_PROFILE_PICTURE: {
        icon: <AddProfilePicture />,
        title: 'Add profile picture',
        description: 'Personalize your profile by adding your profile photo',
        action: () => {
          const searchParams = new URLSearchParams({ companyContext: companieId, mode: 'profile' })
          navigate(`/settings/user?${searchParams.toString()}`)
        },
      },
      CHECK_MARKETPLACE: {
        icon: <CheckMarketplace />,
        title: 'NachoNacho SaaS marketplace',
        description: 'Check out NachoNacho’s vast SaaS marketplace with massive discounts.',
        action: () => {
          window.open(`${URL_HOME}/marketplace`, '_blank')
        },
      },
      CHECK_MAVENS: {
        icon: <CheckMaven />,
        title: 'Mavens',
        description: 'Check out professional service providers in the Maven Marketplace',
        action: () => {
          window.open(`${URL_HOME}/mavens/marketplace`, '_blank')
        },
      },
    }

    return map[name]
  }

  const [selectedTab, setSelectedTab] = React.useState(0)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={(_, i) => {
            setSelectedTab(i)
          }}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={getTabLabel(tab)} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`} />
          ))}
        </Tabs>
      </Box>

      <Box>
        {getStartedSteps.map((step, index) => {
          const stepInfo = getStepInfo(step.name)
          return (
            <Box
              key={step.name}
              display={step.type === tabs[selectedTab] ? 'flex' : 'none'}
              justifyContent="space-between"
              alignItems="center"
              sx={{
                p: '12px 16px',
                mt: '16px',
                cursor: step.done ? 'default' : 'pointer',
                opacity: step.done ? 0.5 : 1,
                '&:hover': {
                  ...(!step.done && {
                    bgcolor: 'var(--light-grey-3)',
                  }),
                },
              }}
              border="1px solid var(--light-grey-2)"
              borderRadius="4px"
              onClick={
                step.done
                  ? undefined
                  : () => {
                      stepInfo.action()
                      closeDialog()
                    }
              }
            >
              <Box>
                {/* <img src={stepInfo.icon} alt={stepInfo.title} /> */}
                {stepInfo.icon}

                <h5 className="fs-14 ff-roobert fw-700 m-0" style={{ marginTop: '4px', marginBottom: '8px' }}>
                  {stepInfo.title}
                </h5>

                <span className="fs-14 ff-roobert text-light-grey-1">{stepInfo.description}</span>
              </Box>

              <Box>{step.done ? <CheckCircleIcon sx={{ color: 'var(--green)', opacity: 1 }} /> : <KeyboardArrowRightIcon />}</Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default GetStartedTabs
