import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from 'components/AppContext'
import LogoNN from 'assets/logo/nn/nachonacho-full-blue-horizontal.svg'

const LogoCompose = () => {
  const context = useAppContext()

  return (
    <>
      {context.me.signupType === 'FORM_TECHSOUP' && (
        <>
          <div className="tac margin3" style={{ marginTop: '40px' }}>
            <Link to="/">
              <img alt="logo" className="logoNachoNacho" src={LogoNN} />
            </Link>
          </div>
          <div className="tac margin3">
            <a href="http://techsoup.org" rel="noopener noreferrer" target="_blank">
              <img
                alt="logo"
                className="logoTechSoup"
                src="https://files.nachonacho.com/sites/home/techsoup/techsoup-logo-blue.svg"
              />
            </a>
          </div>
        </>
      )}
      {context.me.signupType !== 'FORM_TECHSOUP' && (
        <div className="tac margin6">
          <Link to="/">
            <img alt="logo" className="logoNachoNacho" src={LogoNN} />
          </Link>
        </div>
      )}
    </>
  )
}

export default LogoCompose
