import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query Companie($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
    }
  }
`)

type Props = {
  companieId: string
}

const CompanieName = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        id: props.companieId,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.companie) return <NotFound />
  return <>{data.companie.name}</>
}

export default CompanieName
