import React from 'react'
import { Box, Button } from '@mui/material'
import { TypeCreationApp } from '__generated__/graphql'
import manageGoogle, { GOOGLE_SCOPES } from 'components/application/tools/manageGoogle'

type ScopeName = keyof typeof GOOGLE_SCOPES

type Props = {
  textButton: string
  typeCreationApp: TypeCreationApp
  width?: number
  id: string
  onlyBusinessDomain?: boolean
  scopes: ScopeName[]
}

const AuthWithGoogleButton = (props: Props) => {
  const onClick = () => {
    manageGoogle.connect({
      typeCreationApp: props.typeCreationApp,
      onlyBusinessDomain: props.onlyBusinessDomain,
      scopes: props.scopes,
    })
  }

  return (
    <Button onClick={onClick} id={props.id} style={{ width: props.width ?? 200 }} color="primary" variant="outlined">
      <Box display="flex" gap="8px">
        <img alt="imageSlack" src="/logo/google/google-square.svg" width="20px" />
        <span>{props.textButton}</span>
      </Box>
    </Button>
  )
}

export default AuthWithGoogleButton
