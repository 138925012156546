import { FormControl, InputLabel } from '@mui/material'
import React, { useCallback } from 'react'

type Props = {
  htmlFor: string
  title: string
  options: { value: string | number; label: string }[]
  value?: any
  onChange: (value: string | number) => void
}
const RadioSelectNN = (props: Props) => {
  const [refs, setRefs] = React.useState({})
  const onRefChange = useCallback((node) => {
    if (node) {
      setRefs((refs) => ({ ...refs, [node.id]: node }))
    }
  }, [])
  const current = () => {
    return props.options.find((el) => el.value === props.value)?.label
  }
  const leftOffset = () => {
    if (!props.value || !refs[props.value]) return 0
    return refs[props.value].offsetLeft
  }
  const styleSelectUnit = (value) => {
    return {
      zIndex: 15,
      color: value === props.value ? 'white' : '#858580',
      fontWeight: 700,
      cursor: 'pointer',
      transition: 'all 0.15s ease-in',
      padding: '17px 11px',
      borderRadius: '40px',
    }
  }
  return (
    <FormControl style={{ marginTop: '10px', marginRight: '5px' }} className="width100per" variant="standard">
      <InputLabel shrink htmlFor={props.htmlFor}>
        <b className="black2">{props.title}</b>
      </InputLabel>
      <div
        style={{
          display: 'flex',
          height: '56px',
          border: '1px solid #858580',
          borderRadius: '80px',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          marginTop: '20px',
          padding: '0 4px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: '48px',
            background: '#1445FE',
            borderRadius: '80px',
            color: '#1445FE',
            padding: '0 11px',
            zIndex: 10,
            transition: 'all 0.15s ease-in-out',
            fontWeight: 700,
            left: leftOffset(),
            display: props.value ? 'block' : 'none',
          }}
        >
          <span>{current()}</span>
        </div>
        {props.options.map((el) => (
          <div
            id={String(el.value)}
            key={el.value}
            ref={onRefChange}
            onClick={() => {
              props.onChange(el.value)
            }}
            style={styleSelectUnit(el.value)}
          >
            {el.label}
          </div>
        ))}
      </div>
    </FormControl>
  )
}
export default RadioSelectNN
