import React from 'react'
import { Companie } from '__generated__/graphql'
import UpdateCompanie from '../../../companie/form/UpdateCompanie'
import { useSellerKYCContext } from 'components/onboarding/kyc/seller/SellerKYCContext'
import RadioSelectNN from 'components/ui/RadioSelectNN'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  companie: Pick<Companie, 'id' | 'recommendationSellerRevshare'>
}

const OnboardingCompanieSellerFormRevshare = (props: Props) => {
  const [companie, setCompanie] = React.useState(props.companie)
  const sellerKYCContext = useSellerKYCContext()
  const onUpdate = () => {
    sellerKYCContext.reloadKYCState()
    props.onUpdate()
  }
  return (
    <>
      <div className="titleStepKYC">Revenue share you will pay NachoNacho</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        NachoNacho charges a perpetual revenue share if and only if we bring you net new paying subscribers. We pass on a portion
        of the revenue share to our customers as a discount/cashback. The higher revenue share you give NachoNacho, the more
        attractive discount we can offer our customers. This will make your product shine in the NachoNacho marketplace. What
        revenue share are you willing to give NachoNacho?
      </p>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <RadioSelectNN
            htmlFor="revShare"
            options={[
              { value: 15, label: '15%' },
              { value: 20, label: '20%' },
              { value: 25, label: '25%' },
              { value: 30, label: '30%' },
              { value: 35, label: '35%' },
              { value: 40, label: '40%' },
            ]}
            title="Revenue Share"
            value={companie.recommendationSellerRevshare}
            onChange={(newValue) => {
              setCompanie({
                ...companie,
                recommendationSellerRevshare: Number(newValue),
              })
            }}
          />
        </div>

        <div>
          <UpdateCompanie
            actionName="updateSellerCompanyRevshare"
            disabled={!companie.recommendationSellerRevshare}
            showCancelButton={false}
            textButton="Next"
            textCancelButton=""
            onUpdate={onUpdate}
            onCancel={props.onCancel}
            companie={{ id: companie.id, recommendationSellerRevshare: companie.recommendationSellerRevshare || 30 }}
          />
        </div>
      </div>
    </>
  )
}

export default OnboardingCompanieSellerFormRevshare
