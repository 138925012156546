import { useQuery } from '@apollo/client'
import { Box, Button, Popover } from '@mui/material'
import { gql } from '__generated__'
import { GoogleDomainUser } from '__generated__/graphql'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import React from 'react'
import TextFieldNN from 'components/ui/TextFieldNN'
import CreateGoogleInvitationButton from './CreateGoogleInvitationButton'
import GoogleAccountUserRow from './GoogleAccountUserRow'
import { Link } from 'react-router-dom'

const GET_GOOGLE_DOMAIN_USERS_QUERY = gql(/* GraphQL */ `
  query getGoogleDomainUsers_GoogleAccountUsersInvitation($companieId: String!) {
    getGoogleDomainUsers(companieId: $companieId) {
      name
      primaryEmail
      thumbnailPhotoUrl
      isInvitationApproved
    }
  }
`)

type Props = {
  companieId: string
  hideAnalyst?: boolean
}

const GoogleAccountUsersInvitation: React.FC<Props> = (props: Props) => {
  const context = useAppContextWithCompany()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [filterText, setFilterText] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState<GoogleDomainUser | null>(null)
  const { loading, error, data } = useQuery(GET_GOOGLE_DOMAIN_USERS_QUERY, {
    variables: { companieId: context.userRoleCompanie.companie.id },
  })

  const onInviteClick = (event: React.MouseEvent<HTMLButtonElement>, user: GoogleDomainUser) => {
    setSelectedUser(user)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)

  if (loading) return <Loading />
  if (error) return <Error message={error.message} />
  if (!data) return <Error message="No data" />

  const users = data?.getGoogleDomainUsers

  return (
    <Box height="600px">
      <TextFieldNN id="searchGoogleUser" label="Search" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
      {users.length === 0 && (
        <Box>
          <p>No users found! Does the connected Google account have administrator access?</p>
          <Link to="/settings/integrations">
            <Button variant="outlined">Manage accounts</Button>
          </Link>
        </Box>
      )}
      {users
        .filter((el) => el.primaryEmail && (el.name?.includes(filterText) || el.primaryEmail?.includes(filterText)))
        .map((user) => (
          <GoogleAccountUserRow key={user.primaryEmail} user={user} onInviteClick={onInviteClick} />
        ))}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedUser && (
          <Box padding="10px">
            <CreateGoogleInvitationButton
              buttonText="Invite as Purchaser"
              companieRole="PURCHASER"
              email={selectedUser.primaryEmail}
              companieId={props.companieId}
              onCreate={handleClose}
            />
            <CreateGoogleInvitationButton
              buttonText="Invite as Admin"
              companieRole="ADMIN"
              email={selectedUser.primaryEmail}
              companieId={props.companieId}
              onCreate={handleClose}
            />
            {!props.hideAnalyst && (
              <CreateGoogleInvitationButton
                buttonText="Invite as Analyst"
                companieRole="ANALYST"
                email={selectedUser.primaryEmail}
                companieId={props.companieId}
                onCreate={handleClose}
              />
            )}
          </Box>
        )}
      </Popover>
    </Box>
  )
}
export default GoogleAccountUsersInvitation
