import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AutocompleteProductsReview from 'components/product/list/autocomplete/AutocompleteProductsReview'
import utils from 'components/utils'

const CreateNewReview = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ width: '75%' }}>
      <h4>Product</h4>
      <AutocompleteProductsReview onUpdate={(productId) => utils.openEditReviewDialog(productId, navigate)} />
    </Box>
  )
}

export default CreateNewReview
