import { useSellerKYCContext } from 'components/onboarding/kyc/seller/SellerKYCContext'
import React from 'react'
import { Product, TypeMetaTag } from '__generated__/graphql'
import CompanyToMetaTagsManagement from 'components/metaTag/objectToMetaTagsManagement/CompanyToMetaTagsManagement'
import ProductICPLocationAtomic from 'components/product/single/edit/atomicField/ProductICPLocationAtomic'
import ProductICPIndustryAtomic from 'components/product/single/edit/atomicField/ProductICPIndustryAtomic'
import { Button } from '@mui/material'

type Props = {
  onUpdate: () => void
  product: Pick<Product, 'id' | 'isIcpAllIndustries' | 'isIcpAllLocations'>
}

const SellerTargetMarketForm = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()

  return (
    <>
      <div className="titleStepKYC">Your target market</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Tell us about your ICP (Ideal Customer Profile). This will help us match your product with the most relevant customers.
      </p>
      <div style={{ margin: '20px 30px 0' }}>
        <CompanyToMetaTagsManagement
          multiple
          productId={props.product.id}
          title="Company Size (number of employees)"
          type={TypeMetaTag.IcpCompanySize}
          onUpdate={() => sellerKYCContext.reloadKYCState()}
        />
        <ProductICPLocationAtomic product={props.product} onUpdate={() => sellerKYCContext.reloadKYCState()} />
        <ProductICPIndustryAtomic product={props.product} onUpdate={() => sellerKYCContext.reloadKYCState()} />
      </div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div style={{ marginTop: '20px' }}>
          <Button
            id="idButtonUpdateProduct"
            data-action="updateProduct"
            size="medium"
            disabled={!sellerKYCContext.getStepStatus('targetMarket')}
            color="primary"
            variant="contained"
            onClick={props.onUpdate}
            sx={{ width: '100%' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}

export default SellerTargetMarketForm
