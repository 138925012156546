import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import { addressInit } from '../Addresse.type'
import AddresseForm from '../form/AddresseForm'
import { gql } from '__generated__'
import { TypeAddresse } from '__generated__/graphql'

const QUERY = gql(/* GraphQL */ `
  query addresses_ShippingAddressesIssuedCard($where: AddresseWhereInput, $orderBy: AddresseOrderBy, $skip: Int, $first: Int) {
    addresses(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      type
      address1
      address2
      city
      zip
      state
      country
    }
  }
`)

type Props = {
  companieId: string
  userId: string
  onCreate: () => void
  onUpdate: () => void
  onCancel: () => void
}

const ShippingAddressesIssuedCard = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        companie: { id: props.companieId },
        user: { id: props.userId },
        type: TypeAddresse.Shipping,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.addresses) return <NotFound />

  return (
    <>
      {data.addresses.length === 0 && (
        <AddresseForm
          textButtonUpdate="Next"
          textButtonCreate="Save"
          textButtonCancel="Back"
          companieId={props.companieId}
          userId={props.userId}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          onCancel={props.onCancel}
          addresse={{ ...addressInit, type: TypeAddresse.Shipping }}
        />
      )}
      {data.addresses.map((addresse) =>
        addresse ? (
          <div key={addresse?.id}>
            <AddresseForm
              textButtonUpdate="Next"
              textButtonCreate="Save"
              textButtonCancel="Back"
              companieId=""
              userId=""
              onCreate={props.onCreate}
              onUpdate={props.onUpdate}
              onCancel={props.onCancel}
              addresse={addresse}
            />
          </div>
        ) : null,
      )}
    </>
  )
}

export default ShippingAddressesIssuedCard
