import React from 'react'
import { Box, SxProps } from '@mui/material'
import { format } from 'date-fns'

type Props = {
  date?: Date | string | null
  formatString?: string
  sx?: SxProps
}

const DateComponent = (props: Props) => {
  if (!props.date) return null

  const date = typeof props.date === 'string' ? new Date(props.date) : props.date

  return (
    <Box display="inline-block" sx={{ ...props.sx }}>
      {props.formatString && format(date, props.formatString)}
      {!props.formatString && format(date, 'MMM dd, yyyy')}
    </Box>
  )
}

export default DateComponent
