import React from 'react'
import { Box } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import themeNN from 'components/themeNN'
import utils from 'components/utils'
import { type CategorieProduct } from '../context/CategorieProductSelectorContext'
import { useCategorieProductSelector } from '../context/useCategorieProductSelector'

const { getTree } = utils.getCategorieProductTreeTransformers()

const SelectedCategorieProductsBox: React.FC = () => {
  const { selectedCategorieProducts, currentCategorieProductLevel1 } = useCategorieProductSelector()

  const categorieProductsTree = React.useMemo(() => {
    const tree = getTree(selectedCategorieProducts)
    return tree.filter((cp) => cp.id === currentCategorieProductLevel1?.id)
  }, [currentCategorieProductLevel1?.id, selectedCategorieProducts])

  if (!categorieProductsTree.length) return null

  const categorieProductBox = (categorieProduct: CategorieProduct) => (
    <Box sx={{ bgcolor: 'white', padding: '10px', fontSize: '10px', borderRadius: '12px', cursor: 'default' }}>
      {categorieProduct.name}
    </Box>
  )

  return (
    <Box sx={{ borderRadius: '20px', bgcolor: themeNN.blueB50.color, padding: '16px' }}>
      <Box className="ff-roobert fs-14 fw-700 text-black" sx={{ mb: '16px' }}>
        Selected:
      </Box>

      {categorieProductsTree.map((level1) => (
        <Box key={level1.id} display="flex" alignItems="center" gap="5px" flexWrap="wrap" mb="5px">
          {categorieProductBox(level1)}
          {level1.children && level1.children.length > 0 && <ChevronRightIcon style={{ fontSize: '12px' }} />}
          {level1.children?.map((level2, index) => (
            <>
              {index > 0 && (
                <>
                  {categorieProductBox(level1)}
                  {level1.children && level1.children.length > 0 && <ChevronRightIcon style={{ fontSize: '12px' }} />}
                </>
              )}
              {categorieProductBox(level2)}
              {level2.children && level2.children.length > 0 && <ChevronRightIcon style={{ fontSize: '12px' }} />}
              {level2.children?.map((level3) => categorieProductBox(level3))}
              <Box height="0px" flexBasis="100%" />
            </>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default SelectedCategorieProductsBox
