import React from 'react'
import { Icon } from '@mui/material'
import { Source } from '__generated__/graphql'

type Props = {
  source: Pick<Source, 'name'>
}

const WireTransfer = (props: Props) => {
  return (
    <div>
      <Icon color="primary">swap_horizontal_circle</Icon> Wire Transfer: {props.source.name}
    </div>
  )
}

export default WireTransfer
