import React from 'react'
import DrawerLeftSeller from './DrawerLeftSeller'
import DrawerLeftNNAnalyst from './DrawerLeftNNAnalyst'
import DrawerLeftReviewer from './DrawerLeftReviewer'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import { Grid } from '@mui/material'
import MenuLeftDesktopWithTooltip from './topLeft/MenuLeftDesktopWithTooltip'
import AddIcon from '@mui/icons-material/Add'
import utils from 'components/utils'
import DrawerLeftTribe from './DrawerLeftTribe'
import DrawerLeftNoCompanie from './DrawerLeftNoCompanie'
import { gql } from '__generated__'
import { useAppContext } from 'components/AppContext'
import DrawerLeftMaven from './DrawerLeftMaven'
import DrawerLeftBuyer from './DrawerLeftBuyer'
import GetStartedStatusParent from '../../../wizard/GetStartedStatusParent'

const COMPANIE_QUERY = gql(/* GraphQL */ `
  query companie_DrawerLeftChild($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      typeCompanie
      isPerkEnabledForSeller
      isProductEnabledForSeller
      ownerPartnerId
      statusVerification
      tierCompany
      showGoogleAnalyticsGraph
      ownedProducts {
        id
        name
      }
      partnerSignup {
        id
        nameFile
      }
    }
  }
`)

const DrawerLeftChild = () => {
  const { userRoleCompanie } = useAppContext()
  const companieId = userRoleCompanie?.companie?.id

  const { loading, error, data } = useQuery(COMPANIE_QUERY, {
    variables: {
      where: { id: companieId },
    },
    skip: !companieId,
  })

  const companie = data?.companie

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />

  return (
    <>
      {companie?.partnerSignup?.nameFile && (
        <Grid container>
          <Grid item xs={3}>
            <div className="tac">
              <AddIcon />
            </div>
          </Grid>
          <Grid item xs={9}>
            <img alt="partner" style={{ width: '164px' }} src={utils.getUrlFileMedia(companie.partnerSignup.nameFile)} />
          </Grid>
        </Grid>
      )}

      <MenuLeftDesktopWithTooltip />

      {companie ? (
        <>
          {companie.typeCompanie === 'NN_ANALYST' && <DrawerLeftNNAnalyst />}
          {companie.typeCompanie === 'BUYER' && <DrawerLeftBuyer companie={companie} />}
          {companie.typeCompanie === 'SELLER' && <DrawerLeftSeller companie={companie} />}
          {companie.typeCompanie === 'MAVEN' && <DrawerLeftMaven companie={companie} />}
          {companie.typeCompanie === 'REVIEWER' && <DrawerLeftReviewer />}
          {companie.typeCompanie === 'TRIBE' && <DrawerLeftTribe companie={companie} />}
        </>
      ) : (
        <DrawerLeftNoCompanie />
      )}
      {userRoleCompanie?.companie.typeCompanie === 'BUYER' && <GetStartedStatusParent />}
    </>
  )
}

export default DrawerLeftChild
