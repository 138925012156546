import React from 'react'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import { TypeMetaTag } from '__generated__/graphql'

const MUTATION = gql(/* GraphQL */ `
  mutation updateMetaTagsToObject_UpdateCompanyToMetaTag(
    $metaTagIds: [String!]!
    $companieId: String
    $userRoleCompanieId: String
    $type: TypeMetaTag!
    $productId: String
  ) {
    updateMetaTagsToObject(
      metaTagIds: $metaTagIds
      companieId: $companieId
      userRoleCompanieId: $userRoleCompanieId
      type: $type
      productId: $productId
    )
  }
`)

type Props = {
  updateTextButton: string
  disabled: boolean
  companieId?: string
  productId?: string
  userRoleCompanieId?: string
  fullWidth?: boolean
  metaTagIds: string[]
  onUpdate: () => void
  type: TypeMetaTag
}

const UpdateCompanyToMetaTag = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [updateCategorieToUserPreference] = useMutation(MUTATION)

  const updateUserRoleCompanieF = async () => {
    setLoading(true)
    try {
      const userRoleCompanie = await updateCategorieToUserPreference({
        variables: {
          metaTagIds: props.metaTagIds,
          companieId: props.companieId,
          userRoleCompanieId: props.userRoleCompanieId,
          productId: props.productId,
          type: props.type,
        },
      })

      setLoading(false)
      if (userRoleCompanie) {
        props.onUpdate()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="updateCategorieToUserPreference"
        id="updateCategorieToUserPreference"
        icon=""
        color="primary"
        disabled={props.disabled}
        variant="contained"
        size="medium"
        sx={props.fullWidth ? { width: '100%' } : {}}
        buttonText={props.updateTextButton}
        buttonLoadingText="Loading..."
        onClick={() => updateUserRoleCompanieF()}
        loading={loading}
      />{' '}
      {errorMessage && <div className="secondary">{errorMessage}</div>}
    </>
  )
}

export default UpdateCompanyToMetaTag
