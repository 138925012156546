import React from 'react'
import { Button, Theme, useMediaQuery } from '@mui/material'
import { useKYCContext } from './KYCContext'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import { TypeFile } from '__generated__/graphql'
import Loading from 'components/nav/error/Loading'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import ManageFileGrid from 'components/file/ManageFileGrid'

type Props = {
  companieId: string
  onCreate: () => void
}

const QUERY = gql(/* GraphQL */ `
  query filesConnection_LeadershipDocumentsForm($where: FileWhereInput!, $orderBy: FileOrderByInput, $skip: Int, $first: Int) {
    filesConnection(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      edges {
        node {
          id
          description
          typeFile
          shortNameFile
          type
        }
      }
      aggregate {
        count
      }
    }
  }
`)

const LeadershipDocumentsForm = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const kycContext = useKYCContext()
  const queryDocumentFront = useQuery(QUERY, {
    variables: {
      where: {
        isDeleted: false,
        typeFile: TypeFile.LeadershipIdentityDocumentFront,
        companie: { id: props.companieId },
      },
    },
  })
  const queryDocumentBack = useQuery(QUERY, {
    variables: {
      where: {
        isDeleted: false,
        typeFile: TypeFile.LeadershipIdentityDocumentBack,
        companie: { id: props.companieId },
      },
    },
  })
  if (queryDocumentFront.error)
    return (
      <Error
        message={queryDocumentFront.error.graphQLErrors.length > 0 ? queryDocumentFront.error.graphQLErrors[0].message : ''}
      />
    )
  if (queryDocumentBack.error)
    return (
      <Error message={queryDocumentBack.error.graphQLErrors.length > 0 ? queryDocumentBack.error.graphQLErrors[0].message : ''} />
    )
  if (queryDocumentFront.loading || queryDocumentBack.loading) return <Loading />
  if (!queryDocumentFront.data?.filesConnection || !queryDocumentBack.data?.filesConnection) return <NotFound />

  return (
    <div>
      <div className="titleStepKYC">Beneficiary documents upload</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Please upload a govt. issued photo-ID (passport, drivers license, etc.) that shows the beneficiary{`'`}s date of birth. If
        the ID has two sides, upload images of both sides (PDF, JPG or PNG).
      </p>
      <div className="tac" style={{ maxWidth: '350px', margin: '0 auto' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <div style={{ padding: '10px', width: '250px' }}>
            <div className="titleStepKYC" style={{ fontSize: '12px' }}>
              Front
            </div>
            <ManageFileGrid
              onGetFilesLength={() => {}}
              companieId={props.companieId}
              showDownload={false}
              onCreate={() => {
                kycContext.reloadKYCState()
              }}
              onDelete={() => {
                kycContext.reloadKYCState()
              }}
              typeFile={TypeFile.LeadershipIdentityDocumentFront}
              errorMessageFileNotPermited="Only PDF, JPG or PNG formats are permitted."
              textDragNDrop="Click to upload or drop a file"
              acceptedImageTypes={['image/jpeg', 'image/png', 'application/pdf']}
              fileSizeMax={5}
            />
          </div>
          <div style={{ padding: '10px', width: '250px' }}>
            <div className="titleStepKYC" style={{ fontSize: '12px' }}>
              Back
            </div>
            <ManageFileGrid
              onGetFilesLength={() => {}}
              companieId={props.companieId}
              showDownload={false}
              onCreate={() => {
                kycContext.reloadKYCState()
              }}
              onDelete={() => {
                kycContext.reloadKYCState()
              }}
              typeFile={TypeFile.LeadershipIdentityDocumentBack}
              errorMessageFileNotPermited="Only PDF, JPG or PNG formats are permitted."
              textDragNDrop="Click to upload or drop a file"
              acceptedImageTypes={['image/jpeg', 'image/png', 'application/pdf']}
              fileSizeMax={5}
            />
          </div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <Button
            id="idButtonUserDocumentsForm"
            disabled={!kycContext.filledSteps['leadershipDocuments']}
            size="medium"
            sx={{ width: '100%' }}
            color="primary"
            variant="contained"
            onClick={() => {
              kycContext.reloadKYCState()
              props.onCreate()
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
export default LeadershipDocumentsForm
