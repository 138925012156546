import React from 'react'
import { Box, Button, Grid, Theme, Tooltip, useMediaQuery } from '@mui/material'
import VerifySource from './VerifySource'
import { useAppContextWithCompany } from '../../AppContext'
import ActionSource from './ActionSource'
import PaymentMethodFormatSource from '../../paymentSource/single/PaymentMethodFormatSource'
import AddPlaidRefreshLogin from '../../plaidData/plaid/AddPlaidRefreshLogin'
import VerifySourceCard from './VerifySourceCard'
import { Companie, Maybe, PlaidData, Source } from '__generated__/graphql'
import { ReactComponent as InformationIcon } from 'assets/icon/informationIcon.svg'

type Props = {
  source: Pick<
    Source,
    | 'id'
    | 'typeSource'
    | 'object'
    | 'country'
    | 'statusVerificationSource'
    | 'status'
    | 'funding'
    | 'brand'
    | 'last4'
    | 'exp_month'
    | 'exp_year'
    | 'bank_name'
    | 'nickname'
    | 'name'
    | 'isDefaultSource'
  > & {
    companie: Pick<Companie, 'id' | 'incomingPaymentForeignFeeCard' | 'allowPrepaidCardFunding'>
    plaidData?: Maybe<Pick<PlaidData, 'id' | 'resetLogin' | 'publicTokenFresh'>>
  }
  onSucceed: () => void
}

const SingleSource = (props: Props) => {
  const [mode, setMode] = React.useState('')
  const context = useAppContextWithCompany()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={8} className={isMobile ? '' : 'marginAuto'} mb={isMobile ? '10px' : 0}>
          <Box display="flex" alignItems="center" gap="6px">
            {props.source.object === 'card' && props.source.funding === 'credit' && <h4>Credit card</h4>}
            {props.source.object === 'card' && props.source.funding === 'debit' && <h4>Credit card</h4>}
            {props.source.object === 'bank_account' && <h4>Bank account</h4>}
            {props.source.typeSource === 'STRIPE' &&
              props.source.object === 'card' &&
              props.source.companie.incomingPaymentForeignFeeCard > 0 &&
              props.source.country !== 'US' && (
                <Tooltip
                  title={`There is an extra ${props.source.companie.incomingPaymentForeignFeeCard}% charge for non-US cards.`}
                  placement="top"
                  arrow
                >
                  <InformationIcon style={{ cursor: 'pointer' }} />
                </Tooltip>
              )}
            {props.source.typeSource === 'STRIPE' &&
              props.source.object === 'card' &&
              props.source.brand === 'American Express' && (
                <Tooltip title="There is an extra 1% charge for American Express cards" placement="top" arrow>
                  <InformationIcon style={{ cursor: 'pointer' }} />
                </Tooltip>
              )}

            {props.source.object === 'bank_account' && context.userRoleCompanie.companie.typeCompanie === 'BUYER' && (
              <Tooltip title="An $8 fee will be charged if your ACH payment fails." placement="top" arrow>
                <InformationIcon style={{ cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Box>
          <PaymentMethodFormatSource showIcon={true} source={props.source} />

          {props.source.typeSource === 'STRIPE' &&
            props.source.object === 'card' &&
            props.source.statusVerificationSource === 'REQUIRED' && (
              <>
                <div style={{ height: '10px' }} />
                {mode === '' && (
                  <>
                    <div className="secondary">
                      We charged your card US$1.00. Please log into your card account, and find the Card Verification Code from
                      the statement descriptor next to the US$1.00 charge. It starts with NN and has 4 further characters.
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Button variant="outlined" color="primary" onClick={() => setMode('verifyCard')}>
                        Verify account
                      </Button>
                    </div>
                  </>
                )}

                {mode === 'verifyCard' && (
                  <VerifySourceCard onCancel={() => setMode('')} onVerified={() => setMode('')} sourceId={props.source.id} />
                )}
              </>
            )}

          {props.source.typeSource === 'STRIPE' && props.source.object === 'bank_account' && (
            <>
              {'; Status: '}
              {props.source.status === 'new' ? (
                <>
                  <span className="red">Pending verification</span>
                  <br />
                  <br />
                  <span className="textSize7 secondary">
                    We made two small deposits in your bank account. After receiving these deposits, please verify your account.
                  </span>
                </>
              ) : (
                'Verified'
              )}
              <br />
              {props.source.status === 'new' && (
                <div style={{ marginTop: 20 }}>
                  <Button variant="outlined" color="primary" onClick={() => setMode('verifyBank')}>
                    Verify account
                  </Button>
                </div>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={4} className={isMobile ? 'tal' : 'tar'}>
          {mode === '' && <ActionSource source={props.source} onSucceed={props.onSucceed} />}
        </Grid>
        <Grid item xs={12} sm={12}>
          {mode === 'verifyBank' && (
            <VerifySource onCancel={() => setMode('')} onVerified={() => setMode('')} sourceId={props.source.id} />
          )}
        </Grid>
        {props.source.plaidData && props.source.plaidData.resetLogin && (
          <Grid item xs={12} sm={12}>
            <div style={{ height: '10px' }} />
            <div className="secondary">
              We were not able to connect to your bank account. Please click the button below to reconnect.
            </div>
            <div style={{ height: '10px' }} />
            <AddPlaidRefreshLogin plaidData={props.source.plaidData} />
          </Grid>
        )}
      </Grid>
      {(props.source.statusVerificationSource === 'BACKBURNER' || props.source.statusVerificationSource === 'NOT_APPROVED') && (
        <div className="secondary">Your Payment Source was not approved. Please check all the details and try again.</div>
      )}
      {props.source.companie.allowPrepaidCardFunding === false && props.source.funding === 'prepaid' && (
        <div className="secondary">NachoNacho does not accept prepaid cards.</div>
      )}
    </>
  )
}

export default SingleSource
