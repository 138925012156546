import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { useLocation } from 'react-router-dom'
import { ReactComponent as SettingIcon } from 'assets/icon/settingIcon.svg'
import { Divider, ListItem, ListItemIcon } from '@mui/material'
import { ReactComponent as HomeIcon } from 'assets/icon/homeIcon.svg'
import { ReactComponent as InvitationIcon } from 'assets/icon/invitationIcon.svg'
import { ReactComponent as CompanyIcon } from 'assets/icon/companyIcon.svg'
import { ReactComponent as StaffIcon } from 'assets/icon/staffIcon.svg'
import { ReactComponent as MarketplaceIcon } from 'assets/icon/marketplaceIcon.svg'
import { ReactComponent as InvoiceIcon } from 'assets/icon/invoiceIcon.svg'
import { ReactComponent as VendorsTribeIcon } from 'assets/icon/vendorsTribeIcon.svg'
import { ReactComponent as PaymentsSellerIcon } from 'assets/icon/paymentsSellerIcon.svg'
import { Companie, TierCompany } from '__generated__/graphql'

type Props = {
  companie: Pick<Companie, 'id' | 'ownerPartnerId' | 'tierCompany'>
}

const DrawerLeftTribe = (props: Props) => {
  const context = useAppContextWithCompany()

  const location = useLocation()

  const pathnames = location.pathname.split('/')
  let baseURL = ''
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  if (pathnames.length > 2) {
    if (pathnames[1] === 'tribe') {
      baseURL = pathnames[2]
    }
  }

  const marginDidivder = { marginTop: 1, marginBottom: 1 }

  const listItemStyle = {
    fontWeight: 'bold',
    fontSize: '0.78rem !important',
    height: 42,
    marginBottom: '0px !important',
    borderRadius: 20,
  }

  const listItemStyleSelected = {
    backgroundColor: '#2475ff',
    color: 'white',
    fill: 'white',
  }
  const listItemStyleNotSelected = {
    '&:hover': {
      color: '#1445fe!important',
      backgroundColor: '#faf8f7',
      fill: '#1445fe',
    },
  }

  const columnMenuDrawer = {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 20,
  }

  const listItemIconClass = {
    marginLeft: 8,
    minWidth: 35,
  }

  const fontListItemText = {
    fontSize: '0.75rem',
    fontWeight: 400,
  }

  return (
    <div style={columnMenuDrawer}>
      <Link to="/">
        <ListItem
          id="ldrawer-home"
          sx={{
            ...listItemStyle,
            ...(baseURL === '' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
          onClick={() => context.setDrawerLeftMobile(false)}
        >
          <ListItemIcon style={listItemIconClass}>
            <HomeIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Home</div>
        </ListItem>
      </Link>

      <Link to={`/tribe/staff/${props.companie.id}`}>
        <ListItem
          onClick={() => context.setDrawerLeftMobile(false)}
          sx={{
            ...listItemStyle,
            ...(baseURL === 'staff' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
        >
          <ListItemIcon style={listItemIconClass}>
            <StaffIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Staff</div>
        </ListItem>
      </Link>

      <Divider sx={marginDidivder} />
      {props.companie.ownerPartnerId && (
        <>
          <Link to={`/tribe/invitations/${props.companie.ownerPartnerId}`}>
            <ListItem
              id="ldrawer-invitations"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'invitations' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <InvitationIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Invitations</div>
            </ListItem>
          </Link>
          <Link to={`/tribe/companies/${props.companie.ownerPartnerId}/${props.companie.id}`}>
            <ListItem
              id="ldrawer-companies"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'companies' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <CompanyIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Companies</div>
            </ListItem>
          </Link>

          {[TierCompany.Standard, TierCompany.Light].includes(props.companie.tierCompany) && (
            <>
              <Link to={`/tribe/vendors/${props.companie.ownerPartnerId}`}>
                <ListItem
                  id="ldrawer-vendors"
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'vendors' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                  onClick={() => context.setDrawerLeftMobile(false)}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <VendorsTribeIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Vendors</div>
                </ListItem>
              </Link>
              <Link to={`/tribe/transactions/${props.companie.ownerPartnerId}`}>
                <ListItem
                  id="ldrawer-transactions"
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'transactions' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                  onClick={() => context.setDrawerLeftMobile(false)}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <InvoiceIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Transactions</div>
                </ListItem>
              </Link>
            </>
          )}

          <Link to={`/tribe/redemptions/${props.companie.ownerPartnerId}`}>
            <ListItem
              id="ldrawer-redemptions"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'redemptions' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <PaymentsSellerIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Redemptions</div>
            </ListItem>
          </Link>
          <Divider sx={marginDidivder} />
          <Link to="/tribe/marketplace">
            <ListItem
              id="ldrawer-marketplace"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'marketplace' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <MarketplaceIcon />
              </ListItemIcon>
              <div style={fontListItemText}>SaaS & Service Deals</div>
            </ListItem>
          </Link>
          {/* <Link to={`/tribe/perks/${props.companie.ownerPartnerId}`}>
            <ListItem
              id="ldrawer-perks"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'perks' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <PerkIcon />
              </ListItemIcon>
              <div style={fontListItemText}>PerkHub</div>
            </ListItem>
          </Link> */}
          {/* <Link to={`/tribe/perkDetailsOffer/${props.companie.ownerPartnerId}`}>
            <ListItem
              id="ldrawer-perkDetailsOffer"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'perkDetailsOffer' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <PerkIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Perk Offers</div>
            </ListItem>
          </Link> */}
        </>
      )}

      <Link to="/settings/company">
        <ListItem
          onClick={() => context.setDrawerLeftMobile(false)}
          sx={{
            ...listItemStyle,
            ...(baseURL === 'settings' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
        >
          <ListItemIcon style={listItemIconClass}>
            <SettingIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Settings</div>
        </ListItem>
      </Link>
    </div>
  )
}

export default DrawerLeftTribe
