import React from 'react'
import { Grid, Button } from '@mui/material'
import theme from 'components/themeNN'
import utils from 'components/utils'
import { Product } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'
import { gql, useMutation } from '@apollo/client'

type Props = {
  productName: string
  onCreate: (product: Pick<Product, 'id' | 'name' | 'urlName' | 'nameFile'>) => void
  onCancel: () => void
}

const MUTATION = gql(/* GraphQL */ `
  mutation createProductMaven_ProductPageCreate($name: String!, $link: String!) {
    createProductMaven(name: $name, link: $link) {
      id
      name
      urlName
    }
  }
`)

const AddProductsRelationshipWithProductCreationSeller = (props: Props) => {
  const [createProduct] = useMutation(MUTATION)
  const [productName, setProductName] = React.useState(props.productName)
  const [nameValidation, setNameValidation] = React.useState(productName.length >= 3)
  const [productWebsite, setproductWebsite] = React.useState('')
  const [websiteValid, setWebsiteValid] = React.useState(true)

  const onAdd = async () => {
    const isValid = productName.length >= 3 && utils.isURL(productWebsite)
    setWebsiteValid(isValid)

    if (isValid) {
      try {
        const dataProduct = await createProduct({
          variables: {
            name: productName,
            link: productWebsite,
          },
        })
        if (dataProduct?.data) {
          props.onCreate(dataProduct.data.createProductMaven)
          // const product = dataProduct.data.createProduct
          // navigate(`/admin/product/${product.id}`)
        }
      } catch (e) {
        // showErrors(e, setMessage)
      }
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={8}>
        <div>
          <div style={theme.textField}>
            <TextFieldNN
              label="Product Name"
              size="small"
              id="id-perk-name"
              className="width100per"
              value={productName}
              error={!nameValidation}
              onBlur={() => setNameValidation(productName.length >= 3)}
              onChange={(e) => setProductName(e.target.value)}
              required={true}
              type="text"
              helperText={!nameValidation ? `Min. 3 characters` : ''}
            />
          </div>
          <div style={theme.textField}>
            <TextFieldNN
              label="Product Website"
              size="small"
              error={!websiteValid}
              helperText={!websiteValid ? `Please provide a valid website URL` : ''}
              required={true}
              onBlur={() => setWebsiteValid(utils.isURL(productWebsite))}
              id="id-perk-perkProviderWebsite"
              className="width100per"
              value={productWebsite}
              onChange={(e) => {
                setproductWebsite(e.target.value)
              }}
              type="text"
            />
          </div>
          <div>
            <Button onClick={props.onCancel}>Cancel</Button>{' '}
            <Button onClick={onAdd} variant="contained">
              Add
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default AddProductsRelationshipWithProductCreationSeller
