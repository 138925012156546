import { AuthDeviceContext } from 'components/Context.type'

export interface AuthDeviceCookie {
  id: string
  deviceToken: string
  isVerified?: boolean | null
  isLoggedIn: boolean
}

export const authDeviceInit: AuthDeviceContext = {
  id: '',
  deviceToken: '',
  referrer: '',
  previousSites: 0,
  pageon: '',
  timeZone: '',
  timeOpened: new Date().toISOString(),
  timeZoneOffset: 0,
  browserName: '',
  browserEngine: '',
  browserVersion1a: '',
  browserVersion1b: '',
  browserLanguage: '',
  browserPlatform: '',
  browserOnline: true,
  javaEnabled: true,
  dataCookiesEnabled: true,
  isVerified: true,
  sizeScreenW: 0,
  sizeScreenH: 0,
  sizeInW: 0,
  sizeInH: 0,
  sizeAvailW: 0,
  sizeAvailH: 0,
  scrColorDepth: 0,
  scrPixelDepth: 0,
}
