import React from 'react'
import { Paper, Box } from '@mui/material'
import LogoCompose from '../../../../onboarding/LogoCompose'
import LogOutCross from 'components/nav/LogoutCross'
import MenuLeftDesktop from '../../drawer/topLeft/MenuLeftDesktop'

type Props = {
  children: any
  hideNNLogo?: boolean
}

const OffsiteTemplate = (props: Props) => {
  const { hideNNLogo, children } = props

  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <>
            <Box display="flex" gap="8px" justifyContent="space-between" alignItems="center" flexWrap="wrap">
              <MenuLeftDesktop />
              <LogOutCross />
            </Box>

            {!hideNNLogo && <LogoCompose />}

            <div className="tac responsiveMargin2">{children}</div>
          </>
        </Paper>
      </div>
    </div>
  )
}

export default OffsiteTemplate
