import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { useAppContextWithCompany } from '../../../AppContext'
import ButtonLoadingAfterClick from '../../../nav/ButtonLoadingAfterClick'
import { GraphQLError } from 'hooks/useShowErrors'
import { ButtonProps } from '@mui/material'
import { IssuedCard, IssuedCardTypeCreation, TypeIssuedCard } from '__generated__/graphql'
import { gql } from '__generated__'

const CREATE_ISSUED_CARD = gql(/* GraphQL */ `
  mutation createIssuedCard_CreateIssuedCard(
    $data: IssuedCardCreateInput
    $discoveryProductId: String
    $duplicateIssuedCardId: String
  ) {
    createIssuedCard(data: $data, discoveryProductId: $discoveryProductId, duplicateIssuedCardId: $duplicateIssuedCardId) {
      id
    }
  }
`)

type Props = {
  userId: string
  companieId: string
  productId?: string
  discoveryProductId?: string
  type: TypeIssuedCard
  buttonText: string
  disabled: boolean
  typeCreation: IssuedCardTypeCreation
  sx?: ButtonProps['sx']
  issuedCard: Pick<
    IssuedCard,
    'name' | 'issuedCardType' | 'authorizedAmount' | 'authorizedAmountUnit' | 'testMode' | 'dateValidFrom' | 'dateValidTo'
  >

  onCreate: (issuedCard: Pick<IssuedCard, 'id'>) => void
  id?: string
}

const CreateIssuedCard = (props: Props) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const context = useAppContextWithCompany()
  const [createIssuedCard] = useMutation(CREATE_ISSUED_CARD)

  const createIssuedCardF = async () => {
    setLoading(true)

    try {
      const newIssuedCard = await createIssuedCard({
        variables: {
          data: {
            type: props.type,
            typeCreation: props.typeCreation,
            user: { connect: { id: props.userId } },
            companie: { connect: { id: props.companieId } },

            initProduct: props.productId ? { connect: { id: props.productId } } : undefined,

            name: props.issuedCard.name,
            issuedCardType: props.issuedCard.issuedCardType,
            description: '',
            issuedCardCode: '',

            authorizedAmount:
              props.issuedCard.authorizedAmount === null || props.issuedCard.authorizedAmount === undefined
                ? undefined
                : Number(props.issuedCard.authorizedAmount),
            authorizedAmountUnit: props.issuedCard.authorizedAmountUnit,
            dateValidFrom: props.issuedCard.dateValidFrom,
            dateValidTo: props.issuedCard.dateValidTo,
          },
          discoveryProductId: props.discoveryProductId,
        },
      })
      if (newIssuedCard?.data) {
        setLoading(false)
        props.onCreate(newIssuedCard.data.createIssuedCard)
        await client.resetStore()
        if (context.me.id !== props.userId) {
          context.openSnackbar(`The user has been notified of their new NachoCard`, 'info')
        }
      }
    } catch (e) {
      setLoading(false)
      let message = `That didn't work. Make sure everything looks good and try again.`
      const gqlErrors = (e as GraphQLError).graphQLErrors
      if (gqlErrors?.length) {
        message = gqlErrors[0].message
      }
      if (message === 'Must be at least 13 years of age to use Stripe') {
        message = 'User must be at least 13 years of age. Please edit your date of birth'
      }
      if (message === 'maxIssuedCardCountCreatedError') {
        message = `Your NachoCard could not be created.
        You have reached the limit of NachoCards without connecting a Payment Source.
        Please click on the Payment Source tab and connect a Payment Source before creating any further NachoCards.`
      }
      setMessage(message)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createIssuedCard"
        id={props.id}
        icon=""
        color="primary"
        variant="contained"
        size="medium"
        disabled={props.disabled}
        buttonText={props.buttonText}
        buttonLoadingText="Setting up..."
        sx={props.sx}
        onClick={() => {
          createIssuedCardF()
        }}
        loading={loading}
      />
      {message && (
        <>
          <div style={{ height: '10px' }} />
          <div id="idMessage" className="secondary">
            {message}
          </div>
        </>
      )}
    </>
  )
}

export default CreateIssuedCard
