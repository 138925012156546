import React from 'react'
import { Product } from '__generated__/graphql'
import MavenPricingForm from './MavenPricingForm'

type Props = {
  product: Pick<
    Product,
    | 'id'
    | 'averageMonthyRateRangeHigh'
    | 'averageMonthyRateRangeLow'
    | 'averageHourlyRate'
    | 'typicalProjectPriceRangeHigh'
    | 'typicalProjectPriceRangeLow'
  >
  onUpdate: () => void
}

const MavenPricingKYCForm = (props: Props) => {
  return (
    <>
      <div className="titleStepKYC">Pricing</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Specify at least one pricing mechanism.
      </p>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <MavenPricingForm product={props.product} onUpdate={props.onUpdate} />
      </div>
    </>
  )
}
export default MavenPricingKYCForm
