import React from 'react'
import { Button, Paper } from '@mui/material'
import { useAppContextWithCompany } from 'components/AppContext'
import KnowYourCustomerDialog from 'components/onboarding/KnowYourCustomerDialog'
import utils from 'components/utils'
import { Companie } from '__generated__/graphql'

const SellerVerificationWarning = (props: { companie: Pick<Companie, 'isProductEnabledForSeller'> }) => {
  const context = useAppContextWithCompany()
  const { isProductEnabledForSeller } = props.companie
  const isVerified = utils.getVerifiedState(context)
  const isSubmitted = utils.getSubmittedState(context)
  if (isVerified) return null
  if (isSubmitted && !isProductEnabledForSeller) return null

  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn" style={{ backgroundColor: '#1445FE' }}>
          <div style={{ color: 'white', fontFamily: 'Roobert' }}>
            {isProductEnabledForSeller ? (
              <>
                {!isSubmitted ? (
                  <>
                    <b style={{ fontSize: 18 }}>List your product in the NachoNacho marketplace</b>
                    <p style={{ fontSize: 14 }}>
                      Welcome to the NachoNacho B2B SaaS marketplace. After your product is listed, it will become discoverable by
                      hundreds of thousands of SaaS buyers. To get started, please provide details about your company and your
                      product.
                    </p>
                    <KnowYourCustomerDialog title="Start product listing" size="small" variant="outlined" />
                  </>
                ) : (
                  <>
                    <b style={{ fontSize: 18 }}>We have received your Seller application</b>
                    <p style={{ fontSize: 14 }}>
                      We have received your Seller application and will review it asap. Once your application is approved, your
                      product will be discoverable by hundreds of thousands of SaaS buyers.
                    </p>
                    <p style={{ fontSize: 14 }}>
                      In the meantime, feel free to set up your Buyer account to help you manage all your subscriptions in one
                      place, discover new SaaS specifically relevant to your business, and get deep discounts on hundreds of SaaS
                      products.
                    </p>
                    <Button size="small" variant="outlined" href="/company/createCompany">
                      <span>Create a Buyer Account</span>
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <b style={{ fontSize: 18 }}>Create your Perk Offer</b>
                <p style={{ fontSize: 14 }}>
                  Welcome to the NachoNacho B2B SaaS marketplace. After your product is created, it will become discoverable by
                  hundreds of thousands of SaaS buyers. To get started, please provide details about your company and your
                  product.
                </p>
                <KnowYourCustomerDialog title="Start Perk Offer listing" size="small" variant="outlined" />
              </>
            )}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default SellerVerificationWarning
