import { useMutation, useQuery } from '@apollo/client'
import Loading from '../../nav/error/Loading'
import Error from '../../nav/error/Error'
import { Box, Chip, Paper } from '@mui/material'
import React from 'react'
import AddProductsRelationshipManagementSeller from './AddProductsRelationshipManagementSeller'
import { gql } from '__generated__'
import themeNN from 'components/themeNN'
import { Product, ProductsRelationship, ProductsRelationshipsType } from '__generated__/graphql'
import EditHeaderSection from 'components/nav/EditHeaderSection'

const QUERY = gql(/* GraphQL */ `
  query productsRelationshipSellerConnection_ListProductRelationshipsSeller(
    $where: ProductsRelationshipWhereInput!
    $orderBy: ProductsRelationshipOrderByInput
    $take: Int!
    $skip: Int!
  ) {
    productsRelationshipSellerConnection(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      nodes {
        id
        productB {
          id
          name
          nameFile
          urlName
        }
      }
    }
  }
`)

const MUTATION = gql(/* GraphQL */ `
  mutation setProductsRelationshipMaven_ListProductRelationshipsMavenManagement(
    $type: ProductsRelationshipsType!
    $productAId: String!
    $productBIds: [String!]!
  ) {
    setProductsRelationshipMaven(type: $type, productAId: $productAId, productBIds: $productBIds) {
      id
    }
  }
`)

type Props = {
  type: ProductsRelationshipsType
  title: string
  productId: string
  noMargin?: boolean
  onUpdate?: (boolean) => void
}

const ListProductRelationshipsMavenManagement = (props: Props) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const [productsRelationshipSellerSelected, setProductsRelationshipMavenSelected] = React.useState<
    Array<
      Pick<ProductsRelationship, 'id'> & {
        productB: Pick<Product, 'id' | 'name' | 'urlName' | 'nameFile'>
      }
    >
  >([])

  const [setProductsRelationshipMaven] = useMutation(MUTATION)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      take: 100,
      skip: 0,

      where: {
        type: props.type,
        productAId: props.productId,
      },
      orderBy: { createdAt: 'desc' },
    },
  })

  const setProductsRelationshipMavenF = async () => {
    try {
      const response = await setProductsRelationshipMaven({
        variables: {
          type: props.type,
          productAId: props.productId,
          productBIds: productsRelationshipSellerSelected.map((relationship) => relationship.productB.id),
        },
      })

      if (response) {
        setProductsRelationshipMavenSelected([])
        refetch()
        setIsEdit(false)
      }
    } catch (e) {
      console.log(e)
    }
    setIsEdit(false)
  }

  React.useEffect(() => {
    if (data?.productsRelationshipSellerConnection) {
      setProductsRelationshipMavenSelected(data.productsRelationshipSellerConnection.nodes)
    }
  }, [data])

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />

  return (
    <div className={props.noMargin ? '' : 'paperOut'}>
      <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
        {!isEdit ? (
          <>
            <EditHeaderSection
              canEdit={true}
              id="id-ListProductRelationshipsMavenManagement"
              title={props.title}
              onClick={() => setIsEdit(true)}
              isEdit={isEdit}
            />
            <Box display="flex" gap={1} flexWrap="wrap">
              {productsRelationshipSellerSelected.map((relation) => (
                <Chip key={relation.id} label={relation.productB.name} />
              ))}
            </Box>
          </>
        ) : (
          <>
            <h4>{props.title}</h4>
            <AddProductsRelationshipManagementSeller
              relationType={props.type}
              productId={props.productId}
              productsRelationshipSellerSelected={productsRelationshipSellerSelected}
              onUpdate={(relations) => {
                setProductsRelationshipMavenSelected(relations)
                props.onUpdate && props.onUpdate(relations.length > 0)
              }}
              onCancel={() => setIsEdit(false)}
              onSave={() => setProductsRelationshipMavenF()}
            />
          </>
        )}
      </Paper>
    </div>
  )
}

export default ListProductRelationshipsMavenManagement
