import React from 'react'
import { FormControl } from '@mui/material'
import { Product } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'
import UpdateProductLight from 'components/product/single/edit/UpdateProductLight'
import utils from 'components/utils'

type Props = {
  product: Pick<Product, 'id' | 'linkedInLink' | 'twitterLink' | 'facebookLink' | 'youtubeLink' | 'instagramLink'>
  onUpdate: () => void
}

const MavenSocialMediaForm = (props: Props) => {
  const [product, setProduct] = React.useState(props.product)
  const [linkedInLinkInit, setLinkedInLinkInit] = React.useState(false)
  const [youtubeLinkInit, setYoutubeLinkInit] = React.useState(false)
  const [facebookLinkInit, setFacebookLinkInit] = React.useState(false)
  const [twitterLinkInit, setTwitterLinkInit] = React.useState(false)
  const [instagramLinkInit, setInstagramLinkInit] = React.useState(false)

  const linkedInLinkValid = (link) => {
    return link.length > 0 && utils.isValidLinkedInUrl(link)
  }

  return (
    <>
      <div className="titleStepKYC">Social media</div>
      <p className="tac">Social media presence of your business</p>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div id="linkedinLink">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Linkedin profile URL"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  linkedInLink: e.target.value,
                })
              }}
              error={!linkedInLinkValid(product.linkedInLink) && linkedInLinkInit}
              onBlur={() => setLinkedInLinkInit(true)}
              type="text"
              value={product.linkedInLink}
            />
          </FormControl>
        </div>
        <div id="twitterLink">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Twitter profile URL (optional)"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  twitterLink: e.target.value,
                })
              }}
              error={product.twitterLink.length > 0 && !utils.isValidTwitterUrl(product.twitterLink) && twitterLinkInit}
              onBlur={() => setTwitterLinkInit(true)}
              type="text"
              value={product.twitterLink}
            />
          </FormControl>
        </div>
        <div id="facebookLink">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Facebook profile URL (optional)"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  facebookLink: e.target.value,
                })
              }}
              error={product.facebookLink.length > 0 && !utils.isValidFacebookUrl(product.facebookLink) && facebookLinkInit}
              onBlur={() => setFacebookLinkInit(true)}
              type="text"
              value={product.facebookLink}
            />
          </FormControl>
        </div>
        <div id="youtubeLink">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Youtube profile URL (optional)"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  youtubeLink: e.target.value,
                })
              }}
              error={product.youtubeLink.length > 0 && !utils.isValidYoutubeUrl(product.youtubeLink) && youtubeLinkInit}
              onBlur={() => setYoutubeLinkInit(true)}
              type="text"
              value={product.youtubeLink}
            />
          </FormControl>
        </div>
        <div id="instagramLink">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <TextFieldNN
              id="name"
              label="Instagram profile URL (optional)"
              sx={{ minHeight: 'unset' }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  instagramLink: e.target.value,
                })
              }}
              error={product.instagramLink.length > 0 && !utils.isValidInstagramUrl(product.instagramLink) && instagramLinkInit}
              onBlur={() => setInstagramLinkInit(true)}
              type="text"
              value={product.instagramLink}
            />
          </FormControl>
        </div>

        <div style={{ marginTop: '20px' }}>
          <UpdateProductLight
            buttonText="Continue"
            hideCancel
            fullWidth
            onCancel={() => {}}
            product={{
              id: props.product.id,
              linkedInLink: product.linkedInLink,
              twitterLink: product.twitterLink,
              facebookLink: product.facebookLink,
              youtubeLink: product.youtubeLink,
              instagramLink: product.instagramLink,
            }}
            disabled={
              !linkedInLinkValid(product.linkedInLink) ||
              (product.twitterLink.length > 0 && !utils.isValidTwitterUrl(product.twitterLink)) ||
              (product.facebookLink.length > 0 && !utils.isValidFacebookUrl(product.facebookLink)) ||
              (product.youtubeLink.length > 0 && !utils.isValidYoutubeUrl(product.youtubeLink)) ||
              (product.instagramLink.length > 0 && !utils.isValidInstagramUrl(product.instagramLink))
            }
            onSaved={() => {
              props.onUpdate && props.onUpdate()
            }}
          />
        </div>
      </div>
    </>
  )
}
export default MavenSocialMediaForm
