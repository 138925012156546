import React from 'react'
import Icon from '@mui/material/Icon'
import PaypalSource from 'components/source/single/PaypalSource'
import WireTransfer from 'components/source/single/WireTransfer'
import { Source } from '__generated__/graphql'

type Props = {
  showIcon: boolean
  source: Pick<
    Source,
    'id' | 'typeSource' | 'object' | 'brand' | 'funding' | 'last4' | 'exp_month' | 'exp_year' | 'bank_name' | 'nickname' | 'name'
  >
}

const PaymentMethodFormatSource = (props: Props) => {
  if (props.source?.typeSource === 'PAYPAL') return <PaypalSource source={props.source} />
  if (props.source?.typeSource === 'WIRE_TRANSFER') return <WireTransfer source={props.source} />

  return (
    <>
      {props.source.object === 'issuing.card' && (
        <span>
          {props.source.brand} {props.source.funding ? '(' + props.source.funding + ') card' : ''}{' '}
          {`ending in ${props.source.last4} expiring ${props.source.exp_month}/${props.source.exp_year}`}
        </span>
      )}
      {props.source.object === 'card' && (
        <span>
          {props.showIcon && <Icon className="iconAlignText">credit_card</Icon>} {props.source.brand}{' '}
          {props.source.funding ? '(' + props.source.funding + ') card' : ''}{' '}
          {`ending in ${props.source.last4} expiring ${props.source.exp_month}/${props.source.exp_year}`}
        </span>
      )}
      {props.source.object === 'bank_account' && (
        <span>
          {props.showIcon && <Icon className="iconAlignText">account_balance</Icon>} {props.source.bank_name} account ending in{' '}
          {props.source.last4}
        </span>
      )}{' '}
      {props.source.nickname && <>{`"${props.source.nickname}"`}</>}
    </>
  )
}

export default PaymentMethodFormatSource
