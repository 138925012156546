import React from 'react'
import ManageReviewParent from './ManageReviewParent'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import HeaderRightSideBar from 'components/application/HeaderRightSideBar'

const ManageReviewDialog = () => {
  const context = useAppContextWithCompany()
  const navigate = useNavigate()
  const location = useLocation()

  const parsed = queryString.parse(location.search)
  const productId = parsed.productId?.toString()

  const closeDialog = () => {
    delete parsed.showDialog
    navigate('?' + queryString.stringify(parsed))
  }
  if (!productId) return null

  return (
    <div>
      <HeaderRightSideBar title="Review" closeDialog={closeDialog} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ManageReviewParent productId={productId} userId={context.me.id} companieId={context.userRoleCompanie.companie.id} />
      </div>
    </div>
  )
}

export default ManageReviewDialog
