import React from 'react'
import UpdatePhone from './UpdatePhone'
import VerifyPhone from './VerifyPhone'
import { User } from '__generated__/graphql'

type Props = {
  user: Pick<User, 'id' | 'phoneChangeRequested' | 'phoneCodeChangeRequested' | 'isPhoneChangeRequestedPending'>
  showCancelButton: boolean
  onUpdate: () => void
  onCancel: () => void
}

const PhoneLogic: React.FC<Props> = (props: Props) => {
  const [step, setStep] = React.useState(1)

  return (
    <>
      {step === 1 && (
        <>
          <h3>Phone number verification</h3>
          <p>As a security measure, we require you to verify your phone number.</p>
          <div style={{ maxWidth: '350px', margin: '0 auto' }}>
            <UpdatePhone
              showCancelButton={props.showCancelButton}
              onUpdate={() => setStep(2)}
              onCancel={props.onCancel}
              user={props.user}
            />

            {props.user.isPhoneChangeRequestedPending && (
              <>
                <div
                  style={{
                    height: '20px',
                  }}
                />
                <div onClick={() => setStep(2)} className="link cursor tar">
                  Already have a code?
                </div>
              </>
            )}
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <h3>Confirm your phone number</h3>

          <p>{`Please enter the 6-digit code that we just sent to phone number ${props.user.phoneCodeChangeRequested} ${props.user.phoneChangeRequested}`}</p>
          <div style={{ maxWidth: '350px', margin: '0 auto' }}>
            <VerifyPhone
              onUpdate={() => {
                props.onUpdate()
                setStep(1)
              }}
              onCancel={() => {
                props.onCancel()
                setStep(1)
              }}
              userId={props.user.id}
            />
          </div>
        </>
      )}
    </>
  )
}

export default PhoneLogic
