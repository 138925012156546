import React from 'react'
import { Tooltip, IconButton, Icon, Dialog, DialogContent } from '@mui/material'
import PaymentMethodFormatSource from 'components/paymentSource/single/PaymentMethodFormatSource'
import DeleteSourceLogically from './DeleteSourceLogically'
import { Source } from '__generated__/graphql'

type Props = {
  source: Pick<
    Source,
    'id' | 'typeSource' | 'object' | 'brand' | 'funding' | 'last4' | 'exp_month' | 'exp_year' | 'bank_name' | 'nickname' | 'name'
  >
  onDelete: () => void
}

const DeleteSourceLogicallyDialog = (props: Props) => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      <Tooltip arrow title="Delete">
        <IconButton color="default" size="small" onClick={() => setShow(true)}>
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
      <Dialog open={show} maxWidth="sm" fullWidth onClose={() => setShow(false)}>
        <DialogContent>
          <h3>Confirmation</h3>
          <p>
            <PaymentMethodFormatSource showIcon={false} source={props.source} /> will be deleted as a payment source.
          </p>
          <p>Are you sure?</p>
          <br />
          <DeleteSourceLogically
            buttonText="Yes, delete"
            sourceId={props.source.id}
            onCancel={() => setShow(false)}
            onDelete={() => {
              setShow(false)
              props.onDelete()
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteSourceLogicallyDialog
