import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '__generated__'
import { File } from '__generated__/graphql'
import ButtonLoadingAfterClick from '../ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'

const QUERY_SIGNED_URL = gql(/* GraphQL */ `
  query generateGetSignedUrl_DownloadFile($fileId: String!) {
    generateGetSignedUrl(fileId: $fileId)
  }
`)

type Props = {
  file: Pick<File, 'id'>
}

const DownloadFile = (props: Props) => {
  const showErrors = useShowErrors()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const [getAndConsumePresignedUrl] = useLazyQuery(QUERY_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      try {
        window.open(data.generateGetSignedUrl)
      } catch (e) {
        console.log(e)
      }
    },
    onError: (err) => {
      setMessage('Failed downloading file')
      console.log(err)
    },
  })

  const handleDownloadFile = () => {
    try {
      getAndConsumePresignedUrl({
        variables: {
          fileId: props.file.id,
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="updateAddress"
        id="idButtonUpdateAddress"
        disabled={false}
        icon="get_app"
        size="medium"
        color="primary"
        variant="outlined"
        buttonText=""
        buttonLoadingText="Setting up..."
        onClick={handleDownloadFile}
        loading={loading}
      />

      {message && (
        <div id="error-DownloadFile" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default DownloadFile
