import React from 'react'
import { ReactComponent as FilledProgressCircle } from 'assets/icon/filledProgressCircle.svg'
import { ReactComponent as EmptyProgressCircle } from 'assets/icon/emptyProgressCircle.svg'
import { useSellerKYCContext } from './SellerKYCContext'

const SellerKYCStepsProgress = () => {
  const sellerKYCContext = useSellerKYCContext()
  const getStepStatus = (step) => {
    return sellerKYCContext.getStepStatus(step)
  }
  const stepStyle = { display: 'flex', alignItems: 'center', margin: '20px 0 0 -12.5px' }
  return (
    <div style={{ width: '321px', marginLeft: '50px' }}>
      <div style={{ fontWeight: 'bold' }}>Personal</div>
      <div style={{ margin: '10px 12px', paddingLeft: '40px', borderLeft: '1px dashed black' }}>
        <div style={stepStyle}>
          <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
            {getStepStatus('personalPhoneNumber') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
          </div>
          <div
            style={{
              cursor: getStepStatus('personalPhoneNumber') ? 'pointer' : 'unset',
              fontWeight: sellerKYCContext.currentStep() === 'personalPhoneNumber' ? 700 : 400,
            }}
            onClick={() => sellerKYCContext.setCurrentStep('personalPhoneNumber')}
          >
            Personal phone number
          </div>
        </div>
      </div>
      <div style={{ fontWeight: 'bold' }}>Company</div>
      <div style={{ margin: '10px 12px', paddingLeft: '40px', borderLeft: '1px dashed black' }}>
        <div style={{ borderLeft: '1px dashed black' }}>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('companyDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('companyDetails') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'companyDetails' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('companyDetails')}
            >
              Company details
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('companyAddress') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('companyAddress') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'companyAddress' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('companyAddress')}
            >
              Company address
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('businessDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('businessDetails') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'businessDetails' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('businessDetails')}
            >
              Business details
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('targetMarket') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('targetMarket') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'targetMarket' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('targetMarket')}
            >
              Target market
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('productDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('productDetails') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'productDetails' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('productDetails')}
            >
              Product details
            </div>
          </div>

          {sellerKYCContext.sellerType === 'product' && (
            <div style={stepStyle}>
              <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
                {getStepStatus('revShare') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
              </div>
              <div
                style={{
                  cursor: getStepStatus('revShare') ? 'pointer' : 'unset',
                  fontWeight: sellerKYCContext.currentStep() === 'revShare' ? 700 : 400,
                }}
                onClick={() => sellerKYCContext.setCurrentStep('revShare')}
              >
                Revenue share
              </div>
            </div>
          )}
          {/* {sellerKYCContext.sellerType === 'product' && (
            <div style={stepStyle}>
              <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
                {getStepStatus('paymentSource') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
              </div>
              <div
                style={{
                  cursor: getStepStatus('paymentSource') ? 'pointer' : 'unset',
                  fontWeight: sellerKYCContext.currentStep() === 'paymentSource' ? 700 : 400,
                }}
                onClick={() => sellerKYCContext.setCurrentStep('paymentSource')}
              >
                Payment source
              </div>
            </div>
          )} */}
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('submitForm') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('submitForm') ? 'pointer' : 'unset',
                fontWeight: sellerKYCContext.currentStep() === 'submitForm' ? 700 : 400,
              }}
              onClick={() => sellerKYCContext.setCurrentStep('submitForm')}
            >
              Confirm submission
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerKYCStepsProgress
