import React from 'react'
import SubmitVerification from './SubmitVerification'

interface Props {
  onUpdate: () => void
}
const SubmitVerificationForm = (props: Props) => {
  return (
    <>
      <div className="titleStepKYC">Confirm verification</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        By clicking Submit you agree to the{' '}
        <a style={{ color: '#1445FE' }} href="https://stripe.com/legal/connect-account" target="_blank" rel="noreferrer">
          Account Agreement
        </a>{' '}
        and{' '}
        <a style={{ color: '#1445FE' }} href="https://stripe.com/legal/ssa#services-terms" target="_blank" rel="noreferrer">
          Services Agreement
        </a>
        , confirm that the information provided is accurate, correct and complete, and that the documents submitted are genuine.
        You agree that providing inaccurate or fraudulent information may lead to denial of your request or suspension of your
        account.
      </p>
      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <SubmitVerification onUpdate={props.onUpdate} />
      </div>
    </>
  )
}
export default SubmitVerificationForm
