import React from 'react'
import { Box, Skeleton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { CategorieProduct } from '../context/CategorieProductSelectorContext'
import { useCategorieProductSelector } from '../context/useCategorieProductSelector'

type Props = {
  items?: Array<CategorieProduct>
  setCurrentCategorieProduct: (categorieProduct: CategorieProduct | null) => void
  categorieLevel1Fixed: boolean
}

const CategorieProductsBreadcrumb: React.FC<Props> = (props) => {
  const { setCurrentCategorieProductLevel1, setSelectedCategorieProducts, savedCategorieProducts } = useCategorieProductSelector()

  const items = [
    {
      name: 'Categories',
      onClick: () => {
        props.setCurrentCategorieProduct(null)
        setCurrentCategorieProductLevel1(null)
        setSelectedCategorieProducts(savedCategorieProducts)
      },
    },
  ]

  if (!props.items)
    return (
      <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
        <Skeleton width="70px" height="16px" />
        <ChevronRightIcon style={{ fontSize: '12px' }} />
        <Skeleton width="100px" height="16px" />
      </Box>
    )

  if (props.items) {
    items.push(
      ...props.items.map((item) => ({
        name: item.name,
        onClick: () => props.setCurrentCategorieProduct(item),
      })),
    )
  }

  return (
    <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        if (isLast) {
          return (
            <Box key={index} className="fs-14 ff-roobert fw-700 text-black" sx={{ whiteSpace: 'nowrap' }}>
              {item.name}
            </Box>
          )
        }

        const isNotClickableCategories = props.categorieLevel1Fixed && index === 0
        if (isNotClickableCategories) {
          return (
            <Box key={index} display="flex" alignItems="center" gap="8px">
              <Box className="fs-14 ff-roobert fw-700 text-black" sx={{ whiteSpace: 'nowrap' }}>
                {item.name}
              </Box>
              <ChevronRightIcon style={{ fontSize: '12px' }} />
            </Box>
          )
        }

        return (
          <Box key={index} display="flex" alignItems="center" gap="8px">
            <Box className="fs-14 ff-roobert fw-700 text-blue cursor" onClick={item.onClick} sx={{ whiteSpace: 'nowrap' }}>
              {item.name}
            </Box>
            <ChevronRightIcon style={{ fontSize: '12px' }} />
          </Box>
        )
      })}
    </Box>
  )
}

export default CategorieProductsBreadcrumb
