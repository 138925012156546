import React from 'react'
import { Box } from '@mui/material'
import PencilEdit from './PencilEdit'

type Props = {
  isEdit: boolean
  title: string
  id: string
  onClick: () => void
  canEdit: boolean
}
const EditHeaderSection = (props: Props) => {
  return (
    <Box display="flex" gap="6px">
      <h4>{props.title}</h4>
      {props.canEdit && (
        <>
          {!props.isEdit && (
            <div className="tar">
              <PencilEdit onClick={props.onClick} id={props.id} />
            </div>
          )}
        </>
      )}
    </Box>
  )
}

export default EditHeaderSection
