import { Box, Divider } from '@mui/material'
import { Notification } from '__generated__/graphql'
import utils from 'components/utils'
import React from 'react'
import { Link } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PushPinIcon from '@mui/icons-material/PushPin'

type Props = {
  notifications: Array<Pick<Notification, 'id' | 'startAt' | 'pinned' | 'title' | 'link' | 'description'>>
  lastSeen: string | null
  onClick: () => void
}

const Notifications = (props: Props) => {
  const { notifications, lastSeen } = props

  return (
    <Box pt="8px">
      {notifications.map((notification) => {
        const isSeen = !!lastSeen && new Date(notification.startAt) < new Date(lastSeen)

        const notificationBox = (
          <Box
            p="12px 16px"
            bgcolor={isSeen ? 'transparent' : '#E8ECFF'}
            sx={{
              cursor: notification.link ? 'pointer' : 'default',
              '&:hover': { bgcolor: 'var(--light-grey-3)' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                position: 'relative',
              }}
            >
              <Box flex="1">
                <Box className="fs-14 fw-700 text-black" mb="6px">
                  {notification.title}
                </Box>

                <Box className="fs-14 fw-400 text-black" mb="6px">
                  {notification.description}
                </Box>
                <Box className="fs-14 text-light-grey-1">{utils.relativeDate(new Date(notification.startAt))}</Box>
              </Box>

              <Box display="flex" flexDirection="column" gap="12px" alignItems="center">
                {notification.pinned && <PushPinIcon sx={{ fontSize: '18px' }} />}
                {notification.link && <OpenInNewIcon color="primary" sx={{ fontSize: '16px' }} />}
                {!isSeen && <Box height="10px" width="10px" borderRadius="50%" bgcolor="var(--red)" />}
              </Box>
            </Box>
          </Box>
        )

        return (
          <React.Fragment key={notification.id}>
            {notification.link ? (
              <Link onClick={props.onClick} to={notification.link}>
                {notificationBox}
              </Link>
            ) : (
              notificationBox
            )}

            <Divider sx={{ borderStyle: 'dashed' }} variant="fullWidth" />
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default Notifications
