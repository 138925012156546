import React from 'react'
import { Dialog, Grid, Button, FormControl, DialogContent, Radio, RadioGroup, Tabs, Tab } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import utils from '../utils'
import { useQuery } from '@apollo/client'
import validator from 'email-validator'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import Loading from 'components/nav/error/Loading'
import { useAppContextWithCompany } from 'components/AppContext'
import TextFieldNN from 'components/ui/TextFieldNN'
import { gql } from '__generated__'
import { UserRoleCompanieOrderByInput } from '__generated__/graphql'
import GoogleInvitation from './GoogleInvitation'
import CreateInvitationButton from './CreateInvitationButton'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompaniesTeamConnection_CreateInvitation(
    $orderBy: UserRoleCompanieOrderByInput
    $where: UserRoleCompanieWhereInput
    $skip: Int
    $first: Int
  ) {
    userRoleCompaniesTeamConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      aggregate {
        count
      }
    }
  }
`)

type Props = {
  open: boolean
  companieId: string
  hideAnalyst?: boolean
  setOpen: (open: boolean) => void
  onCreate?: (userId: string) => void
}

const CreateInvitation = (props: Props) => {
  const context = useAppContextWithCompany()
  const location = useLocation()
  const navigate = useNavigate()
  const parsed = queryString.parse(location.search)

  const [companieRole, setCompanieRole] = React.useState('PURCHASER')
  const [email, setEmail] = React.useState('')
  const [isEmailValidated, setIsEmailValidated] = React.useState(true)
  const mode = parsed.inviteMode ? parsed.inviteMode : 'google'

  const setMode = (mode) => {
    const parsed = queryString.parse(location.search)
    delete parsed.inviteMode
    if (mode) {
      parsed.inviteMode = mode
    }
    navigate('?' + queryString.stringify(parsed))
  }

  const userRoleCompanieQuery = useQuery(QUERY, {
    variables: {
      orderBy: UserRoleCompanieOrderByInput.FirstNameAsc,
      where: {
        companieId: props.companieId,
      },
    },
  })
  if (userRoleCompanieQuery.error)
    return (
      <Error
        message={userRoleCompanieQuery.error.graphQLErrors.length > 0 ? userRoleCompanieQuery.error.graphQLErrors[0].message : ''}
      />
    )
  if (userRoleCompanieQuery.loading) return <Loading />
  if (!userRoleCompanieQuery.data?.userRoleCompaniesTeamConnection) return <NotFound />
  const isVerified = utils.getVerifiedState(context)
  const memberCount = userRoleCompanieQuery.data.userRoleCompaniesTeamConnection.aggregate.count

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompanieRole(e.target.value)
  }
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value)
    setIsEmailValidated(validator.validate(e.target.value))
  }

  return (
    <Dialog onClose={() => props.setOpen(false)} aria-labelledby="simple-dialog-title" open={props.open} fullWidth maxWidth="sm">
      <DialogContent>
        <Tabs
          orientation="horizontal"
          value={mode}
          onChange={(e, value) => setMode(value)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: '24px', position: 'relative' }}
        >
          <Tab className="ff-roobert" value="google" label="Google" />
          <Tab className="ff-roobert" value="email" label="Email" />
        </Tabs>
        {isVerified || memberCount < 11 ? (
          <>
            {mode === 'email' && (
              <Grid container>
                <Grid item xs={12} sm={12} className="tal">
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup name="companieRole1" value={companieRole} onChange={handleChange}>
                        <FormControlLabel
                          value="ADMIN"
                          control={<Radio />}
                          label={
                            <div>
                              Admin{' '}
                              <span className="textSize6 grey">
                                (Can add payment source, create NachoCards, set budgets, invite members, assign roles)
                              </span>
                            </div>
                          }
                        />

                        <FormControlLabel
                          value="PURCHASER"
                          control={<Radio />}
                          label={
                            <div>
                              Purchaser <span className="textSize6 grey">(Can use NachoCards created by Admins)</span>
                            </div>
                          }
                        />

                        {!props.hideAnalyst && (
                          <FormControlLabel
                            value="ANALYST"
                            control={<Radio />}
                            label={
                              <div>
                                Analyst <span className="textSize6 grey">(Can view everything, cannot use NachoCards)</span>
                              </div>
                            }
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div style={{ height: 15 }} />
                  <TextFieldNN
                    label={`Member's email address`}
                    id="email"
                    placeholder="johndoe@gmail.com"
                    error={!isEmailValidated}
                    className="inputResponsive"
                    onChange={onChangeEmail}
                    type="text"
                    value={email}
                  />

                  <div>
                    <Button onClick={() => props.setOpen(!props.open)}>Cancel</Button>{' '}
                    <CreateInvitationButton
                      companieId={props.companieId}
                      companieRole={companieRole}
                      email={email}
                      onCreate={(userId) => {
                        props.onCreate && props.onCreate(userId)
                        props.setOpen(false)
                        setEmail('')
                        setIsEmailValidated(true)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {mode === 'google' && <GoogleInvitation companieId={props.companieId} />}
          </>
        ) : (
          <div>
            <h3>Invite member</h3>
            <p>Please finish your account verification before inviting more member.</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateInvitation
