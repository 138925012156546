import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

const RedirectPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const parsed = queryString.parse(location.search)
  if (!parsed.redirect) {
    return null
  }
  if (typeof parsed.redirect !== 'string') return null

  if (parsed.redirect.startsWith('http://') || parsed.redirect.startsWith('https://')) {
    window.location.href = parsed.redirect
    return null
  }

  navigate(parsed.redirect)

  return null
}

export default RedirectPage
