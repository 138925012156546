import React from 'react'
import { AtWhatStageIsMostHelpful, Product } from '__generated__/graphql'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import UpdateProductLight from '../UpdateProductLight'
import { MenuItem, Paper } from '@mui/material'
import SelectFieldNN from 'components/ui/SelectFieldNN'
import themeNN from 'components/themeNN'

interface Props {
  product: Pick<Product, 'id' | 'atWhatStageIsMostHelpful'>
  onUpdate?: () => void
}

const ProductAtWhatStageIsMostHelpfulAtomic = (props: Props) => {
  const [value, setValue] = React.useState(props.product.atWhatStageIsMostHelpful)
  const [error, setError] = React.useState('')
  const [isEdit, setIsEdit] = React.useState(false)

  const getFriendlyName = (data: AtWhatStageIsMostHelpful | undefined | null) => {
    if (data === AtWhatStageIsMostHelpful.Both) return 'Both pre-purchase & post-purchase'
    if (data === AtWhatStageIsMostHelpful.PostPurchase) return 'Post-purchase (help implement software)'
    if (data === AtWhatStageIsMostHelpful.PrePurchase) return 'Pre-purchase (help make software purchase decisions)'
    if (data === AtWhatStageIsMostHelpful.NotApplicable) return 'Not applicable'
    return data
  }

  return (
    <div className="paperOut">
      <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
        {!isEdit ? (
          <>
            <EditHeaderSection
              canEdit={true}
              id="id-ProductAtWhatStageIsMostHelpfulAtomic"
              title="At what stage are you most helpful?"
              onClick={() => setIsEdit(true)}
              isEdit={isEdit}
            />
            {getFriendlyName(value)}
          </>
        ) : (
          <>
            <SelectFieldNN
              label="At what stage are you most helpful?"
              id="atWhatStageIsMostHelpful"
              value={value}
              onChange={(e) => setValue(e.target.value as AtWhatStageIsMostHelpful)}
            >
              <MenuItem value={AtWhatStageIsMostHelpful.PrePurchase}>
                {getFriendlyName(AtWhatStageIsMostHelpful.PrePurchase)}
              </MenuItem>
              <MenuItem value={AtWhatStageIsMostHelpful.PostPurchase}>
                {getFriendlyName(AtWhatStageIsMostHelpful.PostPurchase)}
              </MenuItem>
              <MenuItem value={AtWhatStageIsMostHelpful.Both}>{getFriendlyName(AtWhatStageIsMostHelpful.Both)}</MenuItem>
              <MenuItem value={AtWhatStageIsMostHelpful.NotApplicable}>
                {getFriendlyName(AtWhatStageIsMostHelpful.NotApplicable)}
              </MenuItem>
            </SelectFieldNN>

            <UpdateProductLight
              onCancel={() => {
                setIsEdit(false)
                setValue(props.product.atWhatStageIsMostHelpful)
                setError('')
              }}
              product={{ id: props.product.id, atWhatStageIsMostHelpful: value }}
              disabled={!!error}
              onSaved={() => {
                setIsEdit(false)
                props.onUpdate && props.onUpdate()
              }}
            />
          </>
        )}
      </Paper>
    </div>
  )
}

export default ProductAtWhatStageIsMostHelpfulAtomic
