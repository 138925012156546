import React from 'react'
import { Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogContent, FormControlLabel, Skeleton } from '@mui/material'
import CloseButton from 'components/buttons/CloseButton'
import CategorieProductsBreadcrumb from './CategorieProductsBreadcrumb'
import { useMutation, useQuery } from '@apollo/client'
import { gql } from '__generated__'
import SelectedCategorieProductsBox from './SelectedCategorieProductsBox'
import useShowErrors from 'hooks/useShowErrors'
import type { CategorieProduct, DialogMode } from '../context/CategorieProductSelectorContext'
import { useCategorieProductSelector } from '../context/useCategorieProductSelector'
import { Info } from '@mui/icons-material'

const CATEGORIE_PRODUCTS_SELECTOR_QUERY = gql(/* GraphQL */ `
  query categorieProductsSelector_CategorieProductsSelectorDialog($level: Int!, $parentCategorieProductId: String) {
    categorieProductsSelector(level: $level, parentCategorieProductId: $parentCategorieProductId) {
      categorieProducts {
        id
        name
        level
        hasChildren
        parentCategorieProductId
      }
      parentCategorieProducts {
        id
        name
        level
        hasChildren
        parentCategorieProductId
      }
    }
  }
`)

const UPDATE_CATEGORIE_PRODUCTS_MUTATION = gql(/* GraphQL */ `
  mutation setProductToCategorieProducts_CategorieProductsSelectorDialog($productId: String!, $categorieProductIds: [String!]!) {
    setProductToCategorieProducts(productId: $productId, categorieProductIds: $categorieProductIds) {
      id
    }
  }
`)

type Props = {
  open: boolean
  onClose: () => void
  mode: DialogMode
  productId: string
}

const CategorieProductsSelectorDialog: React.FC<Props> = (props) => {
  const { open, onClose, mode, productId } = props

  const showErrors = useShowErrors()
  const {
    selectedCategorieProducts,
    addCategorieProductsToSelection,
    removeCategorieProductFromSelection,
    removeCategorieProductsFromSelection,
    currentCategorieProductLevel1,
    setCurrentCategorieProductLevel1,
    refetchSavedCategorieProducts,
  } = useCategorieProductSelector()

  const [isLoadingSave, setIsLoadingSave] = React.useState(false)
  const [currentCategorieProduct, setCurrentCategorieProduct] = React.useState<CategorieProduct | null>(null)

  React.useEffect(() => {
    setCurrentCategorieProduct(currentCategorieProductLevel1)
  }, [currentCategorieProductLevel1])

  const closeAndReset = React.useCallback(() => {
    refetchSavedCategorieProducts()
    setCurrentCategorieProductLevel1(null)
    onClose()
  }, [onClose, refetchSavedCategorieProducts, setCurrentCategorieProductLevel1])

  // Query to get the data based on the current categorie product
  const { loading, data } = useQuery(CATEGORIE_PRODUCTS_SELECTOR_QUERY, {
    variables: {
      level: currentCategorieProduct ? currentCategorieProduct.level + 1 : 1,
      parentCategorieProductId: currentCategorieProduct ? currentCategorieProduct.id : null,
    },
  })

  // Mutation (save)
  const [mutate] = useMutation(UPDATE_CATEGORIE_PRODUCTS_MUTATION)
  const save = React.useCallback(
    async (selected: CategorieProduct[]) => {
      try {
        setIsLoadingSave(true)
        await mutate({
          variables: {
            productId,
            categorieProductIds: selected.map(({ id }) => id),
          },
        })
        await refetchSavedCategorieProducts()
        onClose()
        setCurrentCategorieProduct(null)
      } catch (error) {
        showErrors(error)
      } finally {
        setIsLoadingSave(false)
      }
    },
    [mutate, onClose, productId, refetchSavedCategorieProducts, showErrors],
  )

  // Enable save button only if there are changes
  const saveButtonState = React.useMemo(() => {
    if (loading || !data || isLoadingSave) return { disabled: true, warnings: [] }
    const pendingSubcategorie: CategorieProduct[] = []
    for (const categorieProduct of selectedCategorieProducts) {
      if (categorieProduct.hasChildren) {
        const hasChildrenSelected = selectedCategorieProducts.some((cp) => cp.parentCategorieProductId === categorieProduct.id)
        if (!hasChildrenSelected) pendingSubcategorie.push(categorieProduct)
      }
    }
    return {
      disabled: pendingSubcategorie.length > 0,
      warnings: pendingSubcategorie.map((cp, index) => {
        const cpName = (
          <span className="fw-700 underline cursor" onClick={() => setCurrentCategorieProduct(cp)}>
            {cp.name}
          </span>
        )

        return (
          <Box display="flex" gap="5px" key={index}>
            <Info sx={{ fontSize: '15px' }} color="error" />
            <p className="fs-12 text-red m-0" style={{ marginBottom: '8px' }}>
              Please select at least one subcategory for {cpName}.
            </p>
          </Box>
        )
      }),
    }
  }, [data, isLoadingSave, loading, selectedCategorieProducts])

  // Show select all checkbox only if there are no children
  const showSelectAll = React.useMemo(() => {
    if (loading || !data) return false
    const { categorieProducts } = data.categorieProductsSelector
    const noChildren = categorieProducts.every(({ hasChildren }) => !hasChildren)
    return noChildren && currentCategorieProduct?.level === 3
  }, [currentCategorieProduct?.level, data, loading])

  // Get the categorie products displayed in the chips
  const categorieProductsDisplayed = React.useMemo(() => {
    if (!data) return []
    // On ADD mode, we filter out the L1 categories that are already selected
    return data.categorieProductsSelector.categorieProducts.filter((categorieProduct) => {
      if (categorieProduct.level !== 1) return true
      if (mode === 'EDIT') return true
      return !selectedCategorieProducts.some(({ id }) => id === categorieProduct.id)
    })
  }, [data, mode, selectedCategorieProducts])

  return (
    <Dialog open={open} onClose={closeAndReset} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: '8px' } }}>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="24px">
          <h3 className="text-black fs-14 ff-roobert m-0">Your category preferences</h3>
          <CloseButton onClick={closeAndReset} />
        </Box>

        <CategorieProductsBreadcrumb
          items={data?.categorieProductsSelector.parentCategorieProducts}
          setCurrentCategorieProduct={setCurrentCategorieProduct}
          categorieLevel1Fixed={mode === 'EDIT'}
        />

        <Box height="24px" />

        {/* Chips selectable */}
        <Box display="flex" gap="14px" flexWrap="wrap" mb="24px">
          {loading || !data ? (
            <>
              <Skeleton width="140px" height="38px" variant="rounded" sx={{ borderRadius: '99px' }} />
              <Skeleton width="95px" height="38px" variant="rounded" sx={{ borderRadius: '99px' }} />
              <Skeleton width="105px" height="38px" variant="rounded" sx={{ borderRadius: '99px' }} />
              <Skeleton width="120px" height="38px" variant="rounded" sx={{ borderRadius: '99px' }} />
            </>
          ) : (
            <>
              {categorieProductsDisplayed.map((categorieProduct) => {
                const isSelected = selectedCategorieProducts.some(({ id }) => id === categorieProduct.id)

                return (
                  <Chip
                    key={categorieProduct.id}
                    label={categorieProduct.name}
                    variant="outlined"
                    sx={{
                      height: '38px',
                      borderRadius: '99px',
                      ...(isSelected && {
                        color: 'white',
                        bgcolor: 'var(--blue)',
                        borderColor: 'var(--blue)',
                        '&:hover': {
                          bgcolor: '#0134f6 !important',
                        },
                        '& .MuiChip-deleteIcon': {
                          color: 'rgba(255, 255, 255, 0.75)',
                          '&:hover': { color: 'white' },
                        },
                      }),
                    }}
                    onClick={() => {
                      if (categorieProduct.level === 1) setCurrentCategorieProductLevel1(categorieProduct)
                      if (categorieProduct.hasChildren) setCurrentCategorieProduct(categorieProduct)
                      if (!isSelected)
                        addCategorieProductsToSelection([
                          categorieProduct,
                          ...data.categorieProductsSelector.parentCategorieProducts,
                        ])
                    }}
                    onDelete={
                      categorieProduct.level === 1 || !isSelected
                        ? undefined
                        : () => {
                            if (!isSelected) return
                            removeCategorieProductFromSelection(categorieProduct)
                          }
                    }
                  />
                )
              })}

              {categorieProductsDisplayed.length === 0 && (
                <p className="fs-14 text-dark-grey-1 m-0">No more categories available.</p>
              )}
            </>
          )}
        </Box>

        {/* Checkbox "select all" */}
        {showSelectAll && data && (
          <Box mt="-8px" mb="24px">
            <FormControlLabel
              label="Select all"
              className="fs-14"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' }, '& .MuiCheckbox-root': { padding: '8px' } }}
              control={
                <Checkbox
                  size="small"
                  checked={data.categorieProductsSelector.categorieProducts.every((categorieProduct) =>
                    selectedCategorieProducts.some(({ id }) => id === categorieProduct.id),
                  )}
                  onChange={(e) => {
                    if (e.target.checked)
                      addCategorieProductsToSelection([
                        ...data.categorieProductsSelector.categorieProducts,
                        ...data.categorieProductsSelector.parentCategorieProducts,
                      ])
                    else {
                      removeCategorieProductsFromSelection(data.categorieProductsSelector.categorieProducts)
                    }
                  }}
                />
              }
            />
          </Box>
        )}

        {/* List of selected */}
        <SelectedCategorieProductsBox />

        {/* Error warnings that are preventing save */}
        <Box my="16px">{saveButtonState.warnings}</Box>

        {/* Buttons */}
        <Box display="flex" justifyContent="space-between" gap="8px">
          {mode === 'EDIT' && (
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                if (!currentCategorieProductLevel1) {
                  showErrors('Something went wrong.')
                  return
                }
                const selected = removeCategorieProductFromSelection(currentCategorieProductLevel1)
                save(selected)
              }}
            >
              Remove all categories
            </Button>
          )}
          <Button variant="outlined" onClick={closeAndReset} sx={{ ml: 'auto' }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => save(selectedCategorieProducts)} disabled={saveButtonState.disabled}>
            {isLoadingSave ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CategorieProductsSelectorDialog
