import React from 'react'
import { Box, Button, Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import GetStartedTabs from './GetStartedTabs'
import { GetStartedStep } from '__generated__/graphql'

type Props = {
  getStartedSteps: Pick<GetStartedStep, 'name' | 'type' | 'done'>[]
  refetch: () => void
}

const GetStartedDialog: React.FC<Props> = ({ getStartedSteps, refetch }) => {
  const [open, setOpen] = React.useState(false)

  const openDialog = () => {
    refetch()
    setOpen(true)
  }
  const closeDialog = () => setOpen(false)

  return (
    <>
      <Button
        onClick={openDialog}
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          bgcolor: 'transparent',
          color: 'white',
          border: '1px solid white',
          '&:hover': {
            bgcolor: 'white',
            color: 'var(--blue)',
            border: '1px solid white',
          },
        }}
      >
        Get Started
      </Button>

      <Dialog open={open} onClose={closeDialog}>
        <Box p="24px" width="min(100vw, 500px)" minHeight="550px">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
            <Box className="fs-14 ff-roobert fw-700">Welcome! Let's get you started.</Box>

            <Box onClick={closeDialog} sx={{ border: '1px solid var(--black)', borderRadius: '99px', cursor: 'pointer' }}>
              <CloseIcon sx={{ mb: '-2px', color: 'var(--blue)', fontSize: '18px' }} />
            </Box>
          </Box>

          <Box className="fs-14 ff-roobert text-black" mb="30px">
            Within a few minutes, you’ll be able to leverage the full power of the NachoNacho platform.
          </Box>

          <GetStartedTabs getStartedSteps={getStartedSteps} closeDialog={closeDialog} />
        </Box>
      </Dialog>
    </>
  )
}

export default GetStartedDialog
