import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import UpdateCompanie from '../../../companie/form/UpdateCompanie'
import FormHelperText from '@mui/material/FormHelperText'
import utils from '../../../utils'
import { TextField, Tooltip } from '@mui/material'
import { useKYCContext } from 'components/onboarding/KYCContext'
import { Companie } from '__generated__/graphql'
import AutocompleteMcc from 'components/companie/mcc/AutocompleteMcc'
import PhoneForm from 'components/user/single/phone/PhoneForm'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'registeredBusinessName'
    | 'name'
    | 'website'
    | 'linkedin'
    | 'mcc'
    | 'companyPhone'
    | 'companyPhoneCode'
    | 'emailCompany'
  >
}

const OnboardingCompanieForm = (props: Props) => {
  const kycContext = useKYCContext()
  const [companie, setCompanie] = React.useState(props.companie)
  const [companieNameValid, setCompanieNameValid] = React.useState(true)
  const [websiteValid, setWebsiteValid] = React.useState(true)
  const [linkedinValid, setLinkedinValid] = React.useState(true)
  const [registeredBusinessNameValid, setRegisteredBusinessNameValid] = React.useState(true)
  const [emailCompanyInit, setEmailCompanyInit] = React.useState(false)
  const isFormValid = () => {
    return (
      companieNameValid &&
      registeredBusinessNameValid &&
      companie.name &&
      companie.registeredBusinessName &&
      companie.mcc &&
      companie.companyPhoneCode &&
      companie.companyPhone &&
      companie.emailCompany &&
      companie.website &&
      websiteValid &&
      linkedinValid
    )
  }
  const mccTooltipText =
    'Yes, we know this question and the list makes no sense. Except our banking partner requires it. Like everyone else, we are waiting for the US banking system to come out of the dark ages. In the meantime, please bear with us.  '
  return (
    <>
      <div className="titleStepKYC">Company details</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div>
          <FormControl className="width100per" variant="standard">
            <InputLabel shrink htmlFor="name">
              <b className="black2">Company name</b>
            </InputLabel>
            <TextField
              id="companyName"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.name}
              error={!companieNameValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  name: e.target.value,
                })

                setCompanieNameValid(e.target.value.length >= 3)
              }}
            />
            {!companieNameValid && <FormHelperText error>Min. 3 characters</FormHelperText>}
          </FormControl>
        </div>

        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">Company legal business name</b>
            </InputLabel>
            <TextField
              id="registeredBusinessName"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.registeredBusinessName}
              error={!registeredBusinessNameValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  registeredBusinessName: e.target.value,
                })

                setRegisteredBusinessNameValid(e.target.value.length >= 3 ? true : false)
              }}
            />
            {!registeredBusinessNameValid && <FormHelperText error>Min. 3 characters</FormHelperText>}
          </FormControl>
        </div>

        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="website">
              <b className="black2">Merchant category</b>
            </InputLabel>
            <AutocompleteMcc
              mcc={companie.mcc}
              onChangeMcc={(mcc: string) => {
                setCompanie({
                  ...companie,
                  mcc,
                })
              }}
            />
          </FormControl>
          <Tooltip arrow title={mccTooltipText}>
            <FormHelperText style={{ cursor: 'default', width: 'fit-content' }} className="primary">
              What's this?
            </FormHelperText>
          </Tooltip>
        </div>

        <div>
          <PhoneForm
            phone={companie.companyPhone as string}
            phoneCode={companie.companyPhoneCode as string}
            onChangePhone={(phone) => {
              setCompanie({
                ...companie,
                companyPhone: phone,
              })
            }}
            onChangePhoneCode={(phoneCode) => {
              setCompanie({
                ...companie,
                companyPhoneCode: phoneCode,
              })
            }}
            onKeyPress={() => {}}
          />
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="website">
              <b className="black2">Email</b>
            </InputLabel>
            <TextField
              id="emailCompany"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.emailCompany}
              error={!companie.emailCompany && emailCompanyInit}
              onBlur={() => setEmailCompanyInit(true)}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  emailCompany: e.target.value,
                })
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="website">
              <b className="black2">Website</b>
            </InputLabel>
            <TextField
              id="website"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.website}
              error={!websiteValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  website: e.target.value,
                })
                setWebsiteValid(utils.isURL(e.target.value) && e.target.value.length > 0)
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="linkedin">
              <b className="black2">Company LinkedIn profile (optional)</b>
            </InputLabel>
            <TextField
              id="linkedin"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.linkedin}
              error={!linkedinValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  linkedin: e.target.value,
                })
                setLinkedinValid((utils.isURL(e.target.value) && e.target.value.length > 0) || e.target.value.length === 0)
              }}
            />
          </FormControl>
        </div>

        <div style={{ height: '20px' }} />

        <div>
          <UpdateCompanie
            actionName="updateCompanyOnboarding"
            disabled={!isFormValid()}
            showCancelButton={false}
            textButton="Next"
            textCancelButton=""
            onUpdate={() => {
              kycContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            companie={{
              id: companie.id,
              name: companie.name,
              registeredBusinessName: companie.registeredBusinessName,
              mcc: companie.mcc,
              companyPhone: companie.companyPhone,
              companyPhoneCode: companie.companyPhoneCode,
              emailCompany: companie.emailCompany,
              website: companie.website,
              linkedin: companie.linkedin,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default OnboardingCompanieForm
