import React, { useEffect } from 'react'
import { FormControl, OutlinedInput, Grid, useMediaQuery, Theme } from '@mui/material'

type Numbers = { num1: string; num2: string; num3: string; num4: string; num5: string; num6: string }

type Props = {
  onVerify: (phoneValidationToken: string) => void
}

const VerifyPhoneForm: React.FC<Props> = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [numbers, setNumbers] = React.useState<Numbers>({ num1: '', num2: '', num3: '', num4: '', num5: '', num6: '' })
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const refNum1 = React.useRef<HTMLInputElement>()
  const refNum2 = React.useRef<HTMLInputElement>()
  const refNum3 = React.useRef<HTMLInputElement>()
  const refNum4 = React.useRef<HTMLInputElement>()
  const refNum5 = React.useRef<HTMLInputElement>()
  const refNum6 = React.useRef<HTMLInputElement>()

  useEffect(() => {
    refNum1.current?.focus()
  }, [refNum1])

  const onPaste = (code: string) => {
    const isValidCode = code.length === 6 && !!Number(code)
    if (!isValidCode) {
      setErrorMessage('Wrong code')
      setNumbers({ num1: '', num2: '', num3: '', num4: '', num5: '', num6: '' })
      return true
    }
    refNum6.current?.focus()
    const [num1, num2, num3, num4, num5, num6] = code.split('')
    setNumbers({ num1, num2, num3, num4, num5, num6 })
    setErrorMessage('')
    props.onVerify(code)
  }

  const areNumbersValid = (numbers: Numbers) => {
    const { num1, num2, num3, num4, num5, num6 } = numbers
    const numArray = [num1, num2, num3, num4, num5, num6]
    for (const num of numArray) {
      if (!num) return false
      if (isNaN(Number(num))) return false
    }
    return true
  }

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: keyof Numbers,
    nextRef: React.MutableRefObject<HTMLInputElement | undefined> | null,
  ) => {
    const isEmptyString = event.target.value === ''
    const number = Number(event.target.value)
    const isValidNumber = !isNaN(number) && number >= 0 && number <= 9
    if (isValidNumber || isEmptyString) {
      setNumbers((numbers) => {
        const newNumbers = { ...numbers, [label]: event.target.value }
        if (areNumbersValid(newNumbers)) {
          const { num1, num2, num3, num4, num5, num6 } = newNumbers
          const fullNumber = `${num1}${num2}${num3}${num4}${num5}${num6}`
          props.onVerify(fullNumber)
        }
        return newNumbers
      })
    }
    if (isValidNumber && !isEmptyString) nextRef?.current?.focus()
  }

  return (
    <Grid container spacing={isMobile ? 1 : 2}>
      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            onPaste={(e) => onPaste(e.clipboardData.getData('Text'))}
            name="num1"
            classes={{ input: 'tac' }}
            inputProps={{ autoComplete: 'num-phone-verification' }}
            id="num1"
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum1.current = input)}
            value={numbers.num1}
            onChange={(e) => onChange(e, 'num1', refNum2)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            classes={{ input: 'tac' }}
            id="num2"
            inputProps={{ autoComplete: 'num-phone-verification' }}
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum2.current = input)}
            value={numbers.num2}
            onChange={(event) => onChange(event, 'num2', refNum3)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            classes={{ input: 'tac' }}
            id="num3"
            inputProps={{ autoComplete: 'num-phone-verification' }}
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum3.current = input)}
            value={numbers.num3}
            onChange={(event) => onChange(event, 'num3', refNum4)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            classes={{ input: 'tac' }}
            id="num4"
            inputProps={{ autoComplete: 'num-phone-verification' }}
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum4.current = input)}
            value={numbers.num4}
            onChange={(event) => onChange(event, 'num4', refNum5)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            classes={{ input: 'tac' }}
            id="num5"
            inputProps={{ autoComplete: 'num-phone-verification' }}
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum5.current = input)}
            value={numbers.num5}
            onChange={(event) => onChange(event, 'num5', refNum6)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl variant="outlined">
          <OutlinedInput
            classes={{ input: 'tac' }}
            id="num6"
            inputProps={{ autoComplete: 'num-phone-verification' }}
            type="number"
            onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
            inputRef={(input) => (refNum6.current = input)}
            value={numbers.num6}
            onChange={(event) => onChange(event, 'num6', null)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} className="secondary">
        {errorMessage}
      </Grid>
    </Grid>
  )
}

export default VerifyPhoneForm
