import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppContextWithCompany } from '../../AppContext'
import { AppBar, Icon, BottomNavigation, BottomNavigationAction, useMediaQuery, Theme } from '@mui/material'
import { Permission } from '__generated__/graphql'

const FooterBuyer = () => {
  let baseURL = ''
  const location = useLocation()
  const pathnames = location.pathname.split('/')
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const context = useAppContextWithCompany()
  if (!isMobile) return null
  return (
    <>
      <div style={{ height: '100px' }} className="bgSalmonGrey" />

      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto !important',
          bottom: '0 !important',
        }}
      >
        <BottomNavigation showLabels className="">
          <BottomNavigationAction onClick={() => context.setDrawerLeftMobile(true)} label="Menu" icon={<Icon>menu</Icon>} />

          <BottomNavigationAction
            onClick={() => navigate('/nachoCards')}
            className={baseURL === 'nachoCards' ? 'bgGrey primary' : ''}
            label="NachoCards"
            icon={<Icon>payment</Icon>}
          />

          {context.userRoleCompanie && context.userRoleCompanie.permissions.includes(Permission.CanSeeInvoicesInCompanie) && (
            <BottomNavigationAction
              onClick={() => navigate('/invoices')}
              className={baseURL === 'invoicesCompany' ? 'bgGrey primary' : ''}
              label="Transactions"
              icon={<Icon>view_list</Icon>}
            />
          )}

          {context.userRoleCompanie.permissions.includes(Permission.CanSeeMyInvoices) &&
            !context.userRoleCompanie.permissions.includes(Permission.CanSeeInvoicesInCompanie) && (
              <BottomNavigationAction
                onClick={() => navigate(`/invoices/${context.userRoleCompanie.companie.id}/${context.me.id}`)}
                className={baseURL === 'invoices' ? 'bgGrey primary' : ''}
                label="Transactions"
                icon={<Icon>view_list</Icon>}
              />
            )}
        </BottomNavigation>
      </AppBar>
    </>
  )
}

export default FooterBuyer
