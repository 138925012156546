import React from 'react'
import DateComponentDropDown from 'components/nav/DateComponentDropDown'
import UpdateUserCreationIssuedCard from './UpdateUserCreationIssuedCard'
import { User } from '__generated__/graphql'

type Props = {
  user: Pick<User, 'id' | 'dobYear' | 'dobMonth' | 'dobDay' | 'firstName' | 'lastName'>
  onUpdate: () => void
}

const DobUserIssuedCardCreation = (props: Props) => {
  const [user, setUser] = React.useState(props.user)
  const [isValid, setIsValid] = React.useState(false)

  return (
    <>
      <DateComponentDropDown
        year={user.dobYear}
        month={user.dobMonth}
        day={user.dobDay}
        onUpdateYear={(dobYear) => setUser({ ...user, dobYear })}
        onUpdateDay={(dobDay) => setUser({ ...user, dobDay })}
        onUpdateMonth={(dobMonth) => setUser({ ...user, dobMonth })}
        isValid={(isValid) => setIsValid(isValid)}
      />
      <div style={{ height: '20px' }} />
      <UpdateUserCreationIssuedCard disabled={!isValid} user={user} onUpdate={props.onUpdate} />
    </>
  )
}

export default DobUserIssuedCardCreation
