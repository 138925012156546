import React from 'react'
import { Button, Icon, IconButton, CircularProgress, ButtonProps } from '@mui/material'

type Props = {
  actionName: string
  id?: string
  type?: string
  size: ButtonProps['size']
  color: ButtonProps['color']
  sx?: ButtonProps['sx']
  variant?: ButtonProps['variant']
  icon?: string
  buttonText: any
  onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  buttonLoadingText: string
  loading: boolean
  disabled: boolean
  fullWidth?: boolean
}

const ButtonLoadingAfterClick = (props: Props) => {
  return (
    <>
      {props.loading ? (
        <>
          {props.icon ? (
            <>
              {props.buttonLoadingText} <CircularProgress size={20} /> <div style={{ width: '10px' }} />
            </>
          ) : (
            <Button
              id={props.id}
              size={props.size}
              type={props.type ? 'submit' : undefined}
              disabled={true}
              fullWidth={props.fullWidth}
              color={props.color}
              variant={props.variant}
              onClick={() => {}}
              sx={props.sx}
            >
              <CircularProgress size={20} /> <div style={{ width: '10px' }} /> {props.buttonLoadingText}
            </Button>
          )}
        </>
      ) : (
        <>
          {props.icon ? (
            <IconButton id={props.id} onClick={props.onClick} color={props.color} size="small">
              <Icon>{props.icon}</Icon>
            </IconButton>
          ) : (
            <Button
              fullWidth={props.fullWidth}
              data-action={props.actionName}
              id={props.id}
              size={props.size}
              type={props.type ? 'submit' : undefined}
              disabled={props.disabled}
              color={props.color}
              variant={props.variant}
              onClick={props.onClick}
              sx={props.sx}
            >
              {props.buttonText}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default ButtonLoadingAfterClick
