import React from 'react'
import Paper from '@mui/material/Paper'
import { Link } from 'react-router-dom'

const NotAuth = () => {
  return (
    <div className="paperOut">
      <Paper className="paperIn">
        Not authenticated!
        <br />
        <br />
        <Link to="/login">Login</Link>
      </Paper>
    </div>
  )
}

export default NotAuth
