import { useApolloClient, useMutation } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import React from 'react'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation submitVerification_SubmitVerification($userId: String!, $companieId: String!) {
    submitVerification(userId: $userId, companieId: $companieId)
  }
`)

interface Props {
  onUpdate: () => void
  textButton?: string
}
const SubmitVerification = (props: Props) => {
  const client = useApolloClient()
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [submitVerification] = useMutation(MUTATION)
  const context = useAppContextWithCompany()

  const submitVerificationF = async () => {
    setLoading(true)
    try {
      const { data } = await submitVerification({
        variables: {
          userId: context.me.id,
          companieId: context.userRoleCompanie.companie.id,
        },
      })
      if (data) {
        client.resetStore()
        context.updateUserRoleCompanieViaQuery()
        props.onUpdate()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
    setLoading(false)
  }
  return (
    <>
      <ButtonLoadingAfterClick
        actionName="submitVerification"
        id="idButtonUpdateCompanie"
        icon=""
        color="primary"
        disabled={false}
        sx={{ width: '100%' }}
        variant="contained"
        size="medium"
        buttonText={props.textButton ? props.textButton : 'Submit'}
        buttonLoadingText="Loading..."
        onClick={submitVerificationF}
        loading={loading}
      />
      <p className="secondary">{errorMessage}</p>
    </>
  )
}
export default SubmitVerification
