import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__/gql'
import { Addresse } from '__generated__/graphql'
import { Button } from '@mui/material'

const MUTATION = gql(/* GraphQL */ `
  mutation createAddresse_CreateAddresse($data: AddresseCreateInput!) {
    createAddresse(data: $data) {
      id
    }
  }
`)

type Props = {
  addresse: Pick<Addresse, 'type' | 'name' | 'address1' | 'address2' | 'city' | 'zip' | 'state' | 'country'>
  userId: string
  companieId?: string
  productId?: string
  disabled: boolean
  onCreate: () => void
  onCancel: () => void
  textButton: string
  textButtonCancel: string
  hideCancel?: boolean
}

const CreateAddresse = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [createAddresseMutation] = useMutation(MUTATION)

  const createAddresse = async () => {
    setLoading(true)
    try {
      await createAddresseMutation({
        variables: {
          data: {
            type: props.addresse.type,
            name: props.addresse.name,
            address1: props.addresse.address1,
            address2: props.addresse.address2,
            city: props.addresse.city,
            zip: props.addresse.zip,
            state: props.addresse.state,
            country: props.addresse.country,
            userId: props.userId,
            companieId: props.companieId,
            productId: props.productId,
          },
        },
      })
    } catch (e) {
      setLoading(false)
      console.log(e)
      showErrors(e, setMessage)
      return
    }
    props.onCreate()
    await client.resetStore()
    setLoading(false)
  }

  return (
    <>
      {!props.hideCancel && <Button onClick={props.onCancel}>{props.textButtonCancel}</Button>}{' '}
      <ButtonLoadingAfterClick
        actionName="createAddress"
        id="idButtonCreateAddress"
        disabled={props.disabled}
        icon=""
        size="medium"
        sx={{ width: props.hideCancel ? '100%' : 'unset' }}
        color="primary"
        variant={props.hideCancel ? 'contained' : 'outlined'}
        buttonText={props.textButton}
        buttonLoadingText="Setting up..."
        onClick={createAddresse}
        loading={loading}
      />
      <div id="idMessage" className="secondary">
        {message}
      </div>
    </>
  )
}

export default CreateAddresse
