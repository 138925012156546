import React from 'react'
import NotFound from '../../../nav/error/NotFound'
import Error from '../../../nav/error/Error'
import queryString from 'query-string'
import { useQuery, useMutation } from '@apollo/client'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { Button, Icon } from '@mui/material'
import DobUserIssuedCardCreation from 'components/user/single/profile/DobUserIssuedCardCreationChild'
import AddAddresseContainer from 'components/addresse/AddAddresseContainer'
import { TypeAddresse } from '__generated__/graphql'
import { gql } from '__generated__'
import useShowErrors from 'hooks/useShowErrors'

const ACTIVATE_PREVIEW_ISSUED_CARD = gql(/* GraphQL */ `
  mutation activatePreviewIssuedCard_ActivatePreviewIssuedCard($issuedCardId: String!) {
    activatePreviewIssuedCard(issuedCardId: $issuedCardId) {
      id
    }
  }
`)

const QUERY = gql(/* GraphQL */ `
  query getStatusToActivateIssuedCard_ActivatePreviewIssuedCardForm($issuedCardId: String!) {
    getStatusToActivateIssuedCard(issuedCardId: $issuedCardId)
  }
`)

const ActivatePreviewIssuedCardForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const showErrors = useShowErrors()
  const context = useAppContextWithCompany()
  const parsed = queryString.parse(location.search)
  const issuedCardId: string = parsed.issuedCardId as string
  const [activated, setActivated] = React.useState(false)
  const [activationError, setActivationError] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      issuedCardId,
    },
    fetchPolicy: 'network-only',
  })

  const [activatePreviewIssuedCard] = useMutation(ACTIVATE_PREVIEW_ISSUED_CARD)
  const activatePreviewIssuedCardF = async () => {
    try {
      await activatePreviewIssuedCard({
        variables: {
          issuedCardId,
        },
        onCompleted: () => {
          delete parsed.showDialog
          delete parsed.issuedCardId
          parsed.refetch = 'TRUE'
          navigate(`/issuedCard/${issuedCardId}?${queryString.stringify(parsed)}`)
        },
      })
    } catch (e) {
      showErrors(e, setMessage)
      setActivationError(true)
    }
  }
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <></>
  if (!data?.getStatusToActivateIssuedCard) return <NotFound />
  const status = data.getStatusToActivateIssuedCard
  if (status === 'ok' && !activationError && !activated) {
    setActivated(true)
    activatePreviewIssuedCardF()
  }
  return (
    <>
      <div style={{ background: '#1445FE', height: '84px', textAlign: 'center', marginBottom: '24px' }}>
        <span style={{ fontFamily: 'Roobert', fontSize: '24px', color: 'white', lineHeight: '84px' }}>Activate NachoCard</span>
      </div>

      <div className="paperOut">
        {status === 'ok' && message && (
          <>
            {message === 'issuedCardStripeError' && (
              <p className="tac">You account is not ready for issuing. Please contact us.</p>
            )}
          </>
        )}
        {status === 'noCardholder' && <p className="tac">Only the cardholder is allowed to activate this card.</p>}
        {status === 'issuingNotEnabled' && <p className="tac">You account is not ready for issuing. Please contact us.</p>}
        {status === 'nameLengthError' && (
          <>
            <p>To create a card, the user name must be at most 24 characters</p>

            <Link to="/settings/company">
              <Button variant="outlined">
                <Icon className=" ">settings</Icon>
                <span className="white">_</span> Go to Settings
              </Button>
            </Link>
          </>
        )}
        {status === 'accountOnHold' && (
          <>
            <h3>NachoCard setup</h3>
            <p>Your account is on hold! You cannot create New Card for now.</p>
          </>
        )}
        {status === 'noBalance' && (
          <>
            <h3>NachoCard setup</h3>
            <p>Error. You account is not ready (Balance issue). Please contact us</p>
          </>
        )}
        {status === 'dobError' && (
          <>
            <h3>Add your Date of Birth</h3>
            <p>
              Add your date of birth. NachoNacho has a regulatory requirement to conduct ongoing monitoring and due diligence of
              users on our platform.
            </p>
            <DobUserIssuedCardCreation onUpdate={refetch} user={context.me} />
          </>
        )}
        {status === 'billingAddresseError' && (
          <>
            {context.userRoleCompanie.companieRole === 'PURCHASER' ? (
              <>
                <p>
                  <b>Unable to proceed! Add a billing address and try again later.</b>
                </p>
                <p>
                  To create a card, a billing address must have been registered in the company account. Please ask a company admin
                  to add a billing address.
                </p>
              </>
            ) : (
              <>
                <h2>NachoCard setup</h2>
                <h4>
                  A billing address is required before accessing NachoCards. This is the address you will enter if a vendor asks
                  you for a billing address when making an online payment with your Nachocard.
                </h4>
                <p>You only need to add your Billing address once - it will be saved for future cards.</p>
                <AddAddresseContainer
                  companieId={context.userRoleCompanie.companie.id}
                  type={TypeAddresse.Billing}
                  userId={context.me.id}
                  onCancel={() => {}}
                  onCreate={refetch}
                />
              </>
            )}
          </>
        )}
        {status === 'maxIssuedCardCountCreatedError' && (
          <>
            <h3>NachoCard setup</h3>
            <p>
              You have reached the limit of NachoCards without connecting a Payment Source. Please click on the Payment Source tab
              and connect a Payment Source before activating any further NachoCards.
            </p>
          </>
        )}
        {status === 'maxIssuedCardCountTotalReachedError' && (
          <>
            <h3>NachoCard setup</h3>
            <p>Maximum number of permitted NachoCards reached. Please contact us.</p>
          </>
        )}
        {status === 'maxIssuedCardsWithinTimeLimitError' && (
          <>
            <h3>NachoCard setup</h3>
            <p>You created too many cards too quickly! Please contact us.</p>
          </>
        )}
        {status === 'maxInactiveLiveCardsError' && (
          <>
            <h3>NachoCard setup</h3>
            <p>
              You already have too many unused cards. Please use the existing cards before activating more of them. Alternatively,
              please contact us.
            </p>
          </>
        )}
      </div>
    </>
  )
}

export default ActivatePreviewIssuedCardForm
