import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { ButtonProps } from '@mui/material'

const SEND_LINK_VALIDATE_EMAIL_MUTATION = gql(/* GraphQL */ `
  mutation sendLinkValidateEmail_ResendEmailValidation($userId: String!) {
    sendLinkValidateEmail(userId: $userId) {
      email
    }
  }
`)

type Props = {
  userId: string
  buttonSize?: ButtonProps['size']
}

const ResendEmailValidation: React.FC<Props> = (props: Props) => {
  const showErrors = useShowErrors()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [sendLinkValidateEmailMutation] = useMutation(SEND_LINK_VALIDATE_EMAIL_MUTATION, {
    variables: { userId: props.userId },
  })

  const sendEmail = async () => {
    setMessage('')
    setLoading(true)
    try {
      const { data } = await sendLinkValidateEmailMutation()
      setMessage(`Email sent successfully to ${data?.sendLinkValidateEmail.email}!`)
    } catch (e) {
      showErrors(e, setMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="resendEmailValidation"
        id="idResendEmailValidation"
        disabled={false}
        icon=""
        size={props.buttonSize || 'medium'}
        color="primary"
        variant="outlined"
        buttonText="Resend"
        buttonLoadingText="Setting up..."
        onClick={sendEmail}
        loading={loading}
      />
      {message && (
        <div id="idMessage" className="primary" style={{ marginTop: 10 }}>
          {message}
        </div>
      )}
    </>
  )
}

export default ResendEmailValidation
