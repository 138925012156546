import { AuthorizedAmountUnitType, IssuedCardType, StatusBox, TypeIssuedCard, IssuedCard } from '__generated__/graphql'

export const issuedCardInit: Pick<
  IssuedCard,
  | 'name'
  | 'type'
  | 'statusBox'
  | 'last4'
  | 'name'
  | 'issuedCardType'
  | 'authorizedAmount'
  | 'authorizedAmountUnit'
  | 'testMode'
  | 'dateValidFrom'
  | 'dateValidTo'
> = {
  statusBox: StatusBox.Inactive,
  name: '',
  testMode: false,
  issuedCardType: IssuedCardType.Standard,
  dateValidFrom: null,
  dateValidTo: null,
  last4: '0000',
  authorizedAmount: null,
  type: TypeIssuedCard.Virtual,
  authorizedAmountUnit: AuthorizedAmountUnitType.PerMonth,
}
