import React from 'react'
import { useKYCContext } from './KYCContext'
import OnboardingleadershipNameForm from './OnboardingLeadershipForm'
import OnboardingAddresses from 'components/addresse/list/OnboardingAddresses'
import { Companie, TypeAddresse } from '__generated__/graphql'
import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material'
import UpdateLeadershipFromSelf from 'components/companie/form/UpdateLeadershipFromSelf'
import OnboardingLeadershipFillMode from './OnboardingLeadershipFillMode'
import { URL_HOME } from 'config/config'
import UpdateCompanie from 'components/companie/form/UpdateCompanie'

type Props = {
  userId: string
  companie: Pick<
    Companie,
    | 'id'
    | 'leadershipPhone'
    | 'leadershipPhoneCode'
    | 'leadershipTitle'
    | 'leadershipLastName'
    | 'leadershipFirstName'
    | 'leadershipEmail'
    | 'stripeBusinessType'
    | 'stripeCompanyStructure'
    | 'leadershipIsDirector'
    | 'leadershipIsExecutive'
    | 'leadershipIsOwner'
    | 'leadershipPercentOwnership'
    | 'registrationNumber'
    | 'leadershipDobDay'
    | 'leadershipDobMonth'
    | 'leadershipDobYear'
    | 'leadershipLast4'
  >
  onCreate: () => void
  onCancel: () => void
}

const BeneficiaryDetailsForm = (props: Props) => {
  const kycContext = useKYCContext()
  const [step, setStep] = React.useState<
    'beneficiarySelect' | 'beneficiaryFillSelect' | 'beneficiaryForm' | 'beneficiaryAddress' | 'beneficiaryInvited'
  >('beneficiarySelect')
  const [sentEmail, setSentEmail] = React.useState('')
  const [userOwnsPercent, setUserOwnsPercent] = React.useState(props.companie.leadershipPercentOwnership >= 25)
  const [someoneOwnsPercent, setSomeoneOwnsPercent] = React.useState(false)
  const [controllingPerson, setControllingPerson] = React.useState(false)

  return (
    <>
      <div className="titleStepKYC">{step === 'beneficiaryAddress' ? 'Beneficiary home address' : 'Beneficiary details'}</div>
      {step === 'beneficiaryFillSelect' && (
        <div
          style={{
            maxWidth: '560px',
            margin: '30px auto',
            fontFamily: 'Roobert',
            fontSize: '16px',
            lineHeight: '22px',
            color: '#858580',
          }}
        >
          <p>Provide details of one person who owns 25% or more of the Company/Group.</p>
          <p>
            If no one owns 25% or more, enter the managing director or controlling person of the Company/Group. You may fill out
            information on their behalf, or send them an email invitation to let them signup and complete the identity
            verification.
          </p>
        </div>
      )}
      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
        {step === 'beneficiarySelect' && (
          <div>
            <FormControl className="width100per" variant="standard" style={{ marginTop: '20px' }}>
              <InputLabel shrink htmlFor="name">
                <b className="black2">Do you own 25% or more of the Company?</b>
              </InputLabel>
              <RadioGroup
                aria-label="authorizedAmountUnit"
                className="width100per spaceForm"
                name="authorizedAmountUnit"
                value={userOwnsPercent}
                row
                onChange={(event) => {
                  setUserOwnsPercent(event.target.value === 'true')
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label={<span>Yes</span>} />
                <FormControlLabel value={false} control={<Radio />} label={<span>No</span>} />
              </RadioGroup>
            </FormControl>
            <FormControl
              className="width100per"
              variant="standard"
              style={{ marginTop: '20px', opacity: !userOwnsPercent ? 1 : 0.4 }}
            >
              <InputLabel shrink htmlFor="name">
                <b className="black2">Does anyone in your Company have 25% or more ownership?</b>
              </InputLabel>
              <RadioGroup
                aria-label="authorizedAmountUnit"
                className="width100per spaceForm"
                name="authorizedAmountUnit"
                value={someoneOwnsPercent}
                row
                onChange={(event) => {
                  if (event.target.value === 'true') {
                    setControllingPerson(false)
                  }
                  setSomeoneOwnsPercent(event.target.value === 'true')
                }}
              >
                <FormControlLabel disabled={userOwnsPercent} value={true} control={<Radio />} label={<span>Yes</span>} />
                <FormControlLabel disabled={userOwnsPercent} value={false} control={<Radio />} label={<span>No</span>} />
              </RadioGroup>
            </FormControl>
            <FormControl
              className="width100per"
              variant="standard"
              style={{
                marginTop: '20px',
                opacity: !userOwnsPercent && !someoneOwnsPercent ? 1 : 0.4,
              }}
            >
              <InputLabel shrink htmlFor="name">
                <b className="black2">Are you a controlling person of the Company?</b>
              </InputLabel>
              <RadioGroup
                aria-label="authorizedAmountUnit"
                className="width100per spaceForm"
                name="authorizedAmountUnit"
                value={controllingPerson}
                row
                onChange={(event) => {
                  setControllingPerson(event.target.value === 'true')
                }}
              >
                <FormControlLabel
                  disabled={userOwnsPercent || someoneOwnsPercent}
                  value={true}
                  control={<Radio />}
                  label={<span>Yes</span>}
                />
                <FormControlLabel
                  disabled={userOwnsPercent || someoneOwnsPercent}
                  value={false}
                  control={<Radio />}
                  label={<span>No</span>}
                />
              </RadioGroup>
            </FormControl>

            <div style={{ height: '20px' }} />
            {userOwnsPercent || (!someoneOwnsPercent && controllingPerson) ? (
              <UpdateLeadershipFromSelf
                disabled={false}
                showCancelButton={false}
                textButton="Next"
                textCancelButton=""
                onUpdate={() => {
                  kycContext.reloadKYCState()
                  props.onCreate()
                }}
                onCancel={props.onCancel}
                companieId={props.companie.id}
                isIndividualCompany={false}
                leadershipIsOwner={userOwnsPercent}
              />
            ) : (
              <div>
                <UpdateCompanie
                  actionName="updateLeadershipDetails"
                  disabled={false}
                  showCancelButton={false}
                  textButton="Next"
                  textCancelButton=""
                  onUpdate={() => {
                    setStep('beneficiaryFillSelect')
                  }}
                  onCancel={props.onCancel}
                  companie={{
                    id: props.companie.id,
                    leadershipPercentOwnership: someoneOwnsPercent ? 25 : 0,
                    leadershipIsOwner: someoneOwnsPercent,
                  }}
                />
              </div>
            )}
          </div>
        )}
        {step === 'beneficiaryFillSelect' && (
          <OnboardingLeadershipFillMode
            companie={props.companie}
            continueForm={() => setStep('beneficiaryForm')}
            beneficiaryInvited={(email) => {
              setSentEmail(email)
              setStep('beneficiaryInvited')
            }}
          />
        )}
        {step === 'beneficiaryInvited' && (
          <div
            style={{
              maxWidth: '560px',
              margin: '30px auto',
              fontFamily: 'Roobert',
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '22px',
              color: '#858580',
            }}
          >
            <p>
              An invitation email has been sent to <b>{sentEmail}</b>
            </p>
            <p style={{ textAlign: 'left' }}>
              Once the beneficiary details have been filled by them, you'll be able to proceed with your submission. In the
              meantime, feel free to:
            </p>
            <p style={{ textAlign: 'left' }}>
              <ul style={{ paddingLeft: '15px' }}>
                <li>Browse around your account and get ready to be amazed</li>
                <li>
                  Check out our robust{' '}
                  <a className="link" href={`${URL_HOME}/marketplace`} target="_blank" rel="noreferrer">
                    SaaS Marketplace
                  </a>
                </li>
                <li>
                  Participate in conversations with 20,000 SaaS users in our{' '}
                  <a className="link" href={`${URL_HOME}/mastermind`} target="_blank" rel="noreferrer">
                    Mastermind community
                  </a>
                </li>
              </ul>
            </p>
          </div>
        )}
        {step === 'beneficiaryForm' && (
          <>
            <div style={{ height: '20px' }} />
            <OnboardingleadershipNameForm
              textButton="Continue"
              textCancelButton="Back"
              onUpdate={() => {
                setStep('beneficiaryAddress')
              }}
              onCancel={props.onCancel}
              companie={props.companie}
            />
          </>
        )}
        {step === 'beneficiaryAddress' && (
          <div style={{ marginTop: '20px' }}>
            <OnboardingAddresses
              type={TypeAddresse.LeadershipCompanie}
              onCreate={() => {
                kycContext.reloadKYCState()
                props.onCreate()
              }}
              onUpdate={() => {
                kycContext.reloadKYCState()
                props.onCreate()
              }}
              onCancel={props.onCancel}
              userId={props.userId}
              companieId={props.companie.id}
              variables={{
                where: {
                  companie: {
                    id: props.companie.id,
                  },
                  type: 'LEADERSHIP_COMPANIE',
                },
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default BeneficiaryDetailsForm
