import React from 'react'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import utils from 'components/utils'
import { ReactComponent as SuitcaseIcon } from 'assets/icon/suitcaseIcon.svg'
import { gql } from '__generated__'
import { Chip } from '@mui/material'
import theme from 'components/themeNN'

const PRODUCT_QUERY = gql(/* GraphQL */ `
  query product_ProductImage($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      id
      name
      nameFile
      promotions {
        id
        text1
        estimateSavingText
      }
    }
  }
`)

type Props = {
  productId: string
  style?: React.CSSProperties
  boxStyle?: React.CSSProperties
  showPromotion?: boolean
}

const ProductImage = (props: Props) => {
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      where: {
        id: props.productId,
      },
    },
    skip: !props.productId,
  })
  const placeholderImage = (
    <div style={{ ...props.boxStyle }}>
      <div style={{ ...props.style, overflow: 'hidden' }}>
        <div
          style={{
            background: '#D3D3D3',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SuitcaseIcon />
        </div>
      </div>
    </div>
  )
  if (!props.productId) return placeholderImage
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return placeholderImage
  if (!data?.product) return <NotFound />
  const product = data.product

  return (
    <div style={props.boxStyle}>
      <img style={props.style} src={utils.getUrlFileMedia(product.nameFile)} alt={product.name} />
      {props.showPromotion && (
        <>
          {product.promotions.map((promotion) => (
            <Chip style={theme.promotionChip} key={promotion.id} label={promotion.text1} />
          ))}
        </>
      )}
    </div>
  )
}
export default ProductImage
