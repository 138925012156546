import React from 'react'
import ResendEmailValidation from '../nav/emailValidation/ResendEmailValidation'
import LogoCompose from './LogoCompose'
import { Button } from '@mui/material'
import LogOutCross from 'components/nav/LogoutCross'
import { ReactComponent as OutlookIcon } from 'assets/icon/outlookIcon.svg'
import { ReactComponent as GmailIcon } from 'assets/icon/gmailIcon.svg'
import { ReactComponent as EmailVerificationIcon } from 'assets/icon/emailVerificationIcon.svg'
import { User } from '__generated__/graphql'

type Props = {
  user: Pick<User, 'id' | 'email'>
}

const OnboardingValidateEmail = (props: Props) => {
  return (
    <>
      <div className="tar">
        <LogOutCross />
      </div>

      <LogoCompose />

      <div className="tac responsiveMargin2">
        <EmailVerificationIcon />

        <h3 style={{ margin: 30 }}>Confirm your email address</h3>

        <p style={{ maxWidth: 400, margin: 'auto' }}>
          An email has been sent to {props.user.email}. Please check your inbox for the confirmation email that has been sent. Don
          {`'`}t forget to check your SPAM folder!
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap' }}>
          <div style={{ margin: 15 }}>
            <a href="https://www.gmail.com/" target="_blank" rel="noreferrer">
              <Button startIcon={<GmailIcon />} variant="outlined">
                Open Gmail
              </Button>
            </a>
          </div>
          <div style={{ margin: 15 }}>
            <a href="https://outlook.live.com/" target="_blank" rel="noreferrer">
              <Button startIcon={<OutlookIcon />} variant="outlined">
                Open Outlook
              </Button>
            </a>
          </div>
        </div>
        <p>Didn't get email?</p>
        <ResendEmailValidation userId={props.user.id} />
      </div>
    </>
  )
}

export default OnboardingValidateEmail
