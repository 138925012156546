import React from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { Paper, Divider } from '@mui/material'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import SingleInvoiceNotPaid from 'components/invoice/single/listSingle/SingleInvoiceNotPaid'
import WarningAction from 'components/subscription/single/action/WarningAction'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query invoicesConnection_ListInvoicesNotPaidQuery(
    $where: InvoiceWhereInput
    $skip: Int
    $orderBy: InvoiceOrderByInput
    $first: Int
  ) {
    invoicesConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      edges {
        node {
          id
          type
          smallId
          companyType
          product {
            id
            name
            nameFile
          }
          buyerFinalPrice
          status
          companie {
            id
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`)

type Props = {
  companieId: string
  variables: any
}

const ListInvoicesNotPaidQuery: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  let shwowActionButton = true
  if (location.pathname.includes('paymentSource')) {
    shwowActionButton = false
  }
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(QUERY, { variables: props.variables })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.invoicesConnection) return <NotFound />

  const { edges } = data.invoicesConnection

  if (!edges.length) return null

  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper className="paperIn" style={{ backgroundColor: '#1445FE' }}>
          <div className="white">
            <WarningAction
              message="Your account is on hold. Please clear your outstanding balance to reactivate your account. Consider adding a new Payment Source."
              actionText="Go to Payment Source"
              shwowActionButton={shwowActionButton}
              onClick={() => {
                navigate('/paymentSource/' + props.companieId)
              }}
            />
            <div className="dividerSection">
              <Divider />
            </div>

            {edges.map((invoice) => (
              <div key={invoice.node.id}>
                <SingleInvoiceNotPaid invoice={invoice.node} />
              </div>
            ))}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default ListInvoicesNotPaidQuery
