import React from 'react'
import { Box, ListItem, Menu } from '@mui/material'
import { useAppContext } from 'components/AppContext'
import MenuListLeft from './MenuListLeft'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import { gql } from '__generated__'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompanies_MenuLeftDesktopWithTooltip($where: UserRoleCompanieWhereInput!) {
    userRoleCompanies(where: $where) {
      id
      companie {
        id
        name
        typeCompanie
      }
    }
  }
`)

const MenuLeftDesktopWithTooltip = () => {
  const context = useAppContext()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        userId: context.me.id,
        isInvitationApproved: true,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.userRoleCompanies) return null

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <ListItem
        id="buttonSelectCompanie"
        className="parentDropDownMenu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        style={{ marginBottom: '0px', backgroundColor: 'white' }}
      >
        <Box
          id="DropDownCompanies"
          onClick={handleClick}
          display="flex"
          justifyContent="space-between"
          width="100%"
          bgcolor="#F2F2F2"
          borderRadius="30px"
          padding="12px"
          className="cursor"
          alignItems="center"
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: 'black',
              whiteSpace: 'nowrap',
              width: '165px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}
          >
            {context.userRoleCompanie?.companie.name ?? 'Select a company'}
          </div>

          <ExpandMoreIcon className="primary" />
        </Box>
      </ListItem>

      <Menu
        className="menuAvatarList"
        classes={{ paper: 'paperMenuLeft' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuListLeft onClose={handleClose} userRoleCompanies={data.userRoleCompanies} />
      </Menu>
    </div>
  )
}

export default MenuLeftDesktopWithTooltip
