import React from 'react'
import { IconButton } from '@mui/material'
import { ReactComponent as EditIcon } from 'assets/icon/editIcon.svg'
import themeNN from 'components/themeNN'

type Props = {
  id: string
  onClick: () => void
}

const PencilEdit = (props: Props) => {
  return (
    <IconButton size="small" color="primary" onClick={props.onClick} id={props.id}>
      <EditIcon height="11" style={{ fill: themeNN.primary.color }} />
    </IconButton>
  )
}

export default PencilEdit
