import React from 'react'
import { useAppContextWithCompany } from 'components/AppContext'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import SellerVerificationWarning from './SellerVerificationWarning'
import MavenVerificationWarning from './MavenVerificationWarning'

const QUERY = gql(/* GraphQL */ `
  query companie_UserVerificationWarning($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      isProductEnabledForSeller
      isPerkEnabledForSeller
      stripeCapabilityIssuing
      useNNStripeAccountId
      typeCompanie
      statusVerification
    }
  }
`)

const UserVerificationWarning = () => {
  const context = useAppContextWithCompany()
  const companieData = useQuery(QUERY, {
    variables: { where: { id: context.userRoleCompanie.companie.id } },
  })
  if (companieData.error)
    return <Error message={companieData.error.graphQLErrors.length ? companieData.error.graphQLErrors[0].message : ''} />
  if (companieData.loading) return null
  if (!companieData.data?.companie) return <NotFound />
  const typeCompanie = companieData.data.companie.typeCompanie
  const statusVerification = companieData.data.companie.statusVerification

  if (typeCompanie === 'BUYER' && statusVerification === 'NOT_REQUIRED') return null
  return (
    <>
      {typeCompanie === 'SELLER' && <SellerVerificationWarning companie={companieData.data.companie} />}
      {/* {typeCompanie === 'BUYER' && !companieData.data.companie.useNNStripeAccountId && <BuyerVerificationWarning />} */}
      {typeCompanie === 'MAVEN' && <MavenVerificationWarning />}
    </>
  )
}

export default UserVerificationWarning
