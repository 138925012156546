import React from 'react'
import queryString from 'query-string'
import { ReactComponent as NachoCardIcon } from 'assets/icon/nachoCardIcon.svg'
import { Theme, useMediaQuery } from '@mui/material'
import { TypeIssuedCard } from '__generated__/graphql'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
  onChange: (type: TypeIssuedCard) => void
  isDisabled?: boolean
}

const CardTypeSelect = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const parsed = queryString.parse(location.search)
  const cardType = parsed.cardType ? parsed.cardType : 'virtual'

  const selectButtonStyle = {
    border: '1px solid #1445FE',
    height: '56px',
    width: isMobile ? 185 : 210,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '700',
    padding: '10px',
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          ...selectButtonStyle,
          borderRadius: '20px 0px 0px 20px',
          background: cardType === 'virtual' ? '#1445FE' : 'white',
          color: cardType === 'virtual' ? 'white' : '#1445FE',
          cursor: 'pointer',
          boxSizing: 'border-box',
        }}
        onClick={() => {
          props.onChange(TypeIssuedCard.Virtual)
          parsed.cardType = 'virtual'
          navigate('?' + queryString.stringify(parsed))
        }}
      >
        <NachoCardIcon
          style={{ marginRight: '10px', height: 16, width: 21, fill: cardType === 'virtual' ? 'white' : '#1445FE' }}
        />
        Virtual NachoCard
      </div>
      <div
        style={{
          ...selectButtonStyle,
          borderRadius: '0px 20px 20px 0px',
          background: cardType === 'physical' ? '#1445FE' : 'white',
          color: cardType === 'physical' ? 'white' : '#1445FE',
          cursor: props.isDisabled ? 'default' : 'pointer',
          opacity: props.isDisabled ? 0.5 : 1,
          boxSizing: 'border-box',
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          if (!props.isDisabled) {
            props.onChange(TypeIssuedCard.Physical)
            parsed.cardType = 'physical'
            navigate('?' + queryString.stringify(parsed))
          }
        }}
      >
        <NachoCardIcon
          style={{ marginRight: '10px', height: 16, width: 21, fill: cardType === 'physical' ? 'white' : '#1445FE' }}
        />
        Physical NachoCard
      </div>
    </div>
  )
}
export default CardTypeSelect
