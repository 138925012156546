import React from 'react'
import NotFound from '../../../nav/error/NotFound'
import Error from '../../../nav/error/Error'
import { useQuery } from '@apollo/client'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { Button, FormControl, Icon, InputAdornment, InputLabel, TextField } from '@mui/material'
import { AuthorizedAmountUnitType, TypeIssuedCard } from '__generated__/graphql'
import UserDobForm from 'components/user/single/profile/sectionDetails/UserDobForm'
import UserName from 'components/nav/layout/titlePage/UserName'
import ProductImage from 'components/product/single/ProductImage'
import { gql } from '__generated__'
import CreateSubscriptionCard from 'components/issuedCard/single/createIssuedCard/CreateSubscriptionCard'
import RadioSelectNN from 'components/ui/RadioSelectNN'
import HeaderRightSideBar from 'components/application/HeaderRightSideBar'

const SUBSCRIPTION_QUERY = gql(/* GraphQL */ `
  query subscription_ActivateSubscriptionForm($where: SubscriptionWhereUniqueInput!) {
    subscription(where: $where) {
      id
      companie {
        id
      }
      user {
        id
        firstName
        lastName
        nameFile
      }
      issuedCard {
        id
      }
      product {
        id
        nameFile
        showMarketplace
        name
        urlName
      }
    }
  }
`)

const STATUS_QUERY = gql(/* GraphQL */ `
  query getStatusToCreateIssueCard_ActivateSubscriptionForm($companieId: String!, $userId: String!, $type: TypeIssuedCard!) {
    getStatusToCreateIssueCard(companieId: $companieId, userId: $userId, type: $type)
  }
`)

const ActivateSubscriptionForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { me, userRoleCompanie } = useAppContextWithCompany()
  const searchParams = new URLSearchParams(location.search)
  const subscriptionId = searchParams.get('subscriptionId')
  const [authorizedAmount, setAuthorizedAmount] = React.useState<number | null>(null)
  const [authorizedAmountUnit, setAuthorizedAmountUnit] = React.useState<AuthorizedAmountUnitType>(AuthorizedAmountUnitType.None)

  const statusQuery = useQuery(STATUS_QUERY, {
    variables: {
      userId: me.id,
      companieId: userRoleCompanie.companie.id,
      type: TypeIssuedCard.Virtual,
    },
    fetchPolicy: 'network-only',
  })
  const subscriptionQuery = useQuery(SUBSCRIPTION_QUERY, {
    variables: {
      where: {
        id: subscriptionId,
      },
    },
  })

  if (subscriptionQuery.error)
    return (
      <Error message={subscriptionQuery.error.graphQLErrors.length > 0 ? subscriptionQuery.error.graphQLErrors[0].message : ''} />
    )
  if (subscriptionQuery.loading) return <></>
  if (!subscriptionQuery.data?.subscription) return <NotFound />
  const subscription = subscriptionQuery.data.subscription
  const subscriptionUser = subscription.user
  const product = subscription.product
  const issuedCard = subscription.issuedCard
  if (statusQuery.error)
    return <Error message={statusQuery.error.graphQLErrors.length > 0 ? statusQuery.error.graphQLErrors[0].message : ''} />
  if (statusQuery.loading) return <></>
  if (!statusQuery.data?.getStatusToCreateIssueCard) return <NotFound />
  const status = statusQuery.data.getStatusToCreateIssueCard

  const closeDialog = () => {
    searchParams.delete('showDialog')
    navigate('?' + searchParams.toString())
  }
  return (
    <div>
      <HeaderRightSideBar title="Activate Subscription" closeDialog={closeDialog} />

      {status !== 'ok' && (
        <div style={{ width: '350px', margin: '32px auto 0' }}>
          {status === 'nameLengthError' && (
            <>
              <p>
                To activate a subscription, the name "{subscriptionUser?.id && <UserName userId={subscriptionUser.id} />}" must be
                at most 24 characters
              </p>

              <Link to="/settings/company">
                <Button variant="outlined">
                  <Icon className=" ">settings</Icon>
                  <span className="white">_</span> Go to Settings
                </Button>
              </Link>
            </>
          )}
          {status === 'dobError' && (
            <>
              <h3>Add your Date of Birth</h3>
              <p>
                Add your date of birth. NachoNacho has a regulatory requirement to conduct ongoing monitoring and due diligence of
                users on our platform.
              </p>
              <UserDobForm
                showCancel={false}
                user={me}
                onUpdate={() => {
                  statusQuery.refetch()
                }}
                onCancel={() => {}}
                buttonSave="Save"
                buttonCancel="Cancel"
              />
            </>
          )}
          {status === 'billingAddresseError' && (
            <>
              <p>
                <b>Unable to proceed! Add a billing address and try again later.</b>
              </p>
              <p>
                To create a subscription, a billing address must have been registered in the company account. Please ask a company
                admin to add a billing address.
              </p>
            </>
          )}
          {status === 'accountOnHold' && (
            <>
              <h3>NachoCard setup</h3>
              <p>Your account is on hold! You cannot create a new subscription for now.</p>
            </>
          )}
          {status === 'noBalance' && (
            <>
              <h3>NachoCard setup</h3>
              <p>Error. You account is not ready (Balance issue). Please contact us</p>
            </>
          )}
        </div>
      )}
      {status === 'ok' && (
        <div>
          <div style={{ width: '350px', margin: '32px auto 0' }}>
            <div>
              <ProductImage
                style={{
                  display: 'block',
                  marginBottom: '15px',
                  width: '100px',
                  height: '100px',
                  borderRadius: '18px',
                  border: '1px solid black',
                }}
                boxStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginBottom: '15px',
                }}
                showPromotion
                productId={product.id}
              />
            </div>
            {issuedCard ? (
              <div style={{ marginTop: '30px' }} className="tac">
                <div style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>
                  Your subscription to {product.name} is already active!
                </div>
                <div style={{ color: '#858580', fontSize: '16px', marginTop: '15px' }}>
                  Go to the subscription page by clicking the button below.
                </div>
                <div style={{ height: '20px' }} />
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  sx={{ width: '100%' }}
                  onClick={() => {
                    navigate(`/subscription/${subscriptionId}`, { replace: true })
                  }}
                >
                  Go to issued card
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: '30px' }} className="tac">
                <div style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>
                  You are activating a subscription for {product.name}
                </div>
                <div style={{ color: '#858580', fontSize: '16px', marginTop: '15px' }}>
                  Learn how to redeem your reward by going to your subscription below.
                </div>
                <Button variant="text" href="https://blog.nachonacho.com/fintech/new-way-to-buy-saas/" target="_blank">
                  How does this work?
                </Button>
                <div style={{ marginTop: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: '8px 0' }}>
                    <div style={{ color: '#858580' }}>Assigned to</div>
                    <div style={{ color: 'black' }}>{subscriptionUser?.id && <UserName userId={subscriptionUser.id} />}</div>
                  </div>
                  <RadioSelectNN
                    options={[
                      { value: AuthorizedAmountUnitType.PerMonth, label: 'Per Month' },
                      { value: AuthorizedAmountUnitType.PerYear, label: 'Per Year' },
                      { value: AuthorizedAmountUnitType.Total, label: 'Cumulative' },
                      { value: AuthorizedAmountUnitType.None, label: 'No Limit' },
                    ]}
                    value={authorizedAmountUnit}
                    htmlFor="authorizedAmountUnit"
                    title="Subscription limit"
                    onChange={(unit) => setAuthorizedAmountUnit(unit as AuthorizedAmountUnitType)}
                  />
                  {authorizedAmountUnit !== AuthorizedAmountUnitType.None && (
                    <FormControl style={{ marginLeft: '5px' }} className="width100per" variant="standard">
                      <InputLabel shrink htmlFor="authorizedAmountUnit">
                        <b className="black2" />
                      </InputLabel>
                      <TextField
                        id="authorizedAmount"
                        size="small"
                        className="width100per spaceForm"
                        placeholder=""
                        disabled={false}
                        InputProps={{
                          sx: { '& .MuiInputBase-input': { borderLeft: '1px solid #858580', paddingLeft: '5px' } },
                          startAdornment: (
                            <InputAdornment position="start">
                              <div>USD</div>
                            </InputAdornment>
                          ),
                        }}
                        onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
                        onChange={(e) => {
                          setAuthorizedAmount(
                            e.target.value === ''
                              ? null
                              : Number(e.target.value) < 0
                                ? Number(e.target.value) * -1
                                : Number(e.target.value),
                          )
                        }}
                        type="number"
                        value={authorizedAmount || undefined}
                      />
                    </FormControl>
                  )}
                </div>
                <div style={{ height: '20px' }} />
                <CreateSubscriptionCard
                  subscriptionId={subscription.id}
                  authorizedAmount={authorizedAmount}
                  authorizedAmountUnit={authorizedAmountUnit}
                  disabled={false}
                  sx={{ width: '100%' }}
                  onCreate={() => {
                    navigate(`/subscription/${subscriptionId}`, { replace: true })
                  }}
                  buttonText="Activate subscription"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivateSubscriptionForm
