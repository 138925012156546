import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { useAppContextWithCompany } from '../../AppContext'
import { Grid, FormControl, InputLabel, Input, InputAdornment, Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation verifySourceCard_VerifySourceCard($sourceId: String!, $code: String!) {
    verifySourceCard(sourceId: $sourceId, code: $code) {
      id
    }
  }
`)

type Props = {
  sourceId: string
  onVerified: () => void
  onCancel: () => void
}

const VerifySourceCard = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const context = useAppContextWithCompany()
  const [verifySourceCard] = useMutation(MUTATION)
  const [code, setCode] = React.useState('')

  const [message, setMessage] = React.useState('')

  const verifySourceF = async () => {
    try {
      const card = await verifySourceCard({
        variables: {
          code,
          sourceId: props.sourceId,
        },
      })

      if (card) {
        context.openSnackbar(`Your new payment source is verified & is now the Primary Payment Source`, 'success')
        props.onVerified()
        await client.resetStore()
      }
    } catch (e) {
      showErrors(e, setMessage)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={3}>
        <FormControl variant="standard">
          <InputLabel htmlFor="code">Code</InputLabel>
          <Input
            id="code"
            placeholder="- - - - -"
            startAdornment={<InputAdornment position="start">NN</InputAdornment>}
            onChange={(e) => {
              const value = e.target.value.toUpperCase()
              if (value.length <= 4) {
                setCode(value)
              }
            }}
            type="text"
            value={code}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ height: '20px' }} />
        <div>
          <Button onClick={props.onCancel}>Cancel</Button>{' '}
          <Button variant="outlined" color="primary" id="verifyMicroDepositsStripe" onClick={() => verifySourceF()}>
            Verify
          </Button>
        </div>
        {message && (
          <div id="idMessage" className="secondary">
            {message}
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default VerifySourceCard
