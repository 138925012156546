import React from 'react'
import { Button } from '@mui/material'
import { Product, ProductsRelationshipsType, Visibility } from '__generated__/graphql'
import PositionProductsAutocomplete from 'components/positionProduct/list/PositionProductsAutocomplete'
import ProductAtWhatStageIsMostHelpfulAtomic from 'components/product/single/edit/atomicField/ProductAtWhatStageIsMostHelpfulAtomic'
import { URL_HOME } from 'config/config'
import { useMavenKYCContext } from './MavenKYCContext'
import CategorieProductMultiSelector from 'components/categorieProduct/list/multiSelector/components/CategorieProductMultiSelector'
import ListProductRelationshipsMavenManagement from 'components/productRecommendation/seller/ListProductRelationshipsMavenManagement'

type Props = {
  product: Pick<Product, 'id' | 'atWhatStageIsMostHelpful'>
  onUpdate: () => void
}

const MavenValueProvidedForm = (props: Props) => {
  const mavenKYCContext = useMavenKYCContext()

  return (
    <>
      <div className="titleStepKYC">Value proposition</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        If you are involved in helping businesses with software purchase decisions or implementing software, provide more details.
      </p>
      <div style={{ marginTop: '20px' }}>
        <div>
          <ProductAtWhatStageIsMostHelpfulAtomic product={props.product} onUpdate={() => mavenKYCContext.reloadKYCState()} />
        </div>

        {/* Keep both (superadmin only) for testing/migrating purposes */}
        {/* <div>
          <PositionProductsAutocomplete
            productId={props.product.id}
            visibility={Visibility.Maven}
            title="In what SaaS product category or categories do you have expertise?"
          />
        </div> */}
        <CategorieProductMultiSelector
          productId={props.product.id}
          title="In what SaaS product category or categories do you have expertise?"
        />
        {/* We'll remove <PositionProductsAutocomplete /> after the migration is completed */}

        <div>
          <ListProductRelationshipsMavenManagement
            productId={props.product.id}
            title="SaaS products you can help with"
            type={ProductsRelationshipsType.Maven}
          />
        </div>
        <div>
          <PositionProductsAutocomplete
            productId={props.product.id}
            visibility={Visibility.MavenSkill}
            subtext="Don't see your Skill? Contact us."
            sublink={`${URL_HOME}/contact`}
            title="Skills (tools, technologies, languages, frameworks)"
          />
        </div>
      </div>

      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div style={{ marginTop: '20px' }}>
          <Button
            id="idButtonUpdateProduct"
            data-action="updateProduct"
            size="medium"
            color="primary"
            variant="contained"
            disabled={!mavenKYCContext.getStepStatus('valueProposition')}
            onClick={props.onUpdate}
            sx={{ width: '100%' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}
export default MavenValueProvidedForm
