import React from 'react'
import { Box, Grid } from '@mui/material'
import { nachoNachoProductId } from 'config/config'
import { Product, Review, VisibilityProduct } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'
import RatingNN from './RatingNN'

type Props = {
  product: Pick<Product, 'id' | 'visibility'>
  review: Pick<Review, 'rating' | 'content' | 'title' | 'headline'>
  onFormValidation: (formValid: boolean) => void
  setReview: (review: Props['review']) => void
}

const ReviewForm = (props: Props) => {
  const isNNProduct = nachoNachoProductId === props.product.id
  // const [review, setReview] = React.useState(props.review)
  const [headlineValid, setHeadlineValid] = React.useState(false)
  const [headlineInit, setHeadlineInit] = React.useState(true)
  const [contentValid, setContentValid] = React.useState(false)
  const [titleValid, setTitleValid] = React.useState(false)
  const [contentInit, setContentInit] = React.useState(true)
  const [titleInit, setTitleInit] = React.useState(true)
  const [hover, setHover] = React.useState(-1)
  const labels: { [index: string]: string } = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'OK',
    3: 'OK+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  }

  const maxHeadline = 60
  const review = props.review
  const onFormValidation = props.onFormValidation
  React.useEffect(() => {
    const newRatingValid = review.rating > 0
    const newContentValid = review.content.length > 0
    const newTitleValid = review.title.length > 0
    const newHeadlineValid = review.headline.length > 0 && review.headline.length <= maxHeadline
    setHeadlineValid(newHeadlineValid)
    setContentValid(newContentValid)
    setTitleValid(newTitleValid)

    onFormValidation(newContentValid && newHeadlineValid && newRatingValid && newTitleValid)
  }, [review, onFormValidation])

  const getReviewText = () => {
    if (isNNProduct) {
      return 'Tell us what you like about NachoNacho. How were you managing your subscriptions before using NachoNacho. How much time/money are you saving by using NachoNacho? Give specific examples where you saved time and/or money.  What new features or improvements would you like to see?'
    }
    if (props.product.visibility === VisibilityProduct.Maven) {
      return 'Why did you choose this Maven? What other Mavens did you consider before deciding? What do you like best? What would you like to see improved?'
    }
    return 'Why did you choose this product? What other products did you consider before deciding? Did you switch from a competing product? What do you like best? What would you like to see improved?'
  }
  return (
    <div>
      <div className="">
        <Grid container>
          <Grid item>
            <RatingNN
              name="rating"
              precision={0.5}
              className=""
              onChangeActive={(_event, newHover) => {
                setHover(newHover)
              }}
              value={props.review.rating}
              onChange={(_event, newValue) => {
                props.setReview({
                  ...props.review,
                  rating: Number(newValue),
                })
              }}
            />
          </Grid>
          <Grid item className="marginVerticalAuto">
            {props.review.rating !== undefined && props.review.rating !== null && (
              <Box ml={2}>{labels[hover !== -1 ? hover : props.review.rating]}</Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div style={{ height: '20px' }} />

      <div>
        <TextFieldNN
          id="id-headline"
          label={`Headline (${props.review.headline.length}/${maxHeadline})`}
          className="width100per"
          onBlur={() => setHeadlineInit(false)}
          error={!headlineValid && !headlineInit}
          value={props.review.headline}
          onChange={(e) => {
            props.setReview({
              ...props.review,
              headline: e.target.value,
            })
          }}
          helperTextAlways={`What's most important to know?`}
        />
      </div>

      <div style={{ height: '20px' }} />

      <div>
        <TextFieldNN
          id="id-content"
          label="Content"
          error={!contentValid && !contentInit}
          onBlur={() => setContentInit(false)}
          type="text"
          multiline
          value={props.review.content}
          onChange={(e) =>
            props.setReview({
              ...props.review,
              content: e.target.value,
            })
          }
          helperTextAlways={getReviewText()}
        />
      </div>

      <div style={{ height: 20 }} />

      <div>
        <TextFieldNN
          label="Your job title or function"
          id="id-title"
          onBlur={() => setTitleInit(false)}
          error={!titleValid && !titleInit}
          value={props.review.title}
          onChange={(e) => {
            props.setReview({
              ...props.review,
              title: e.target.value,
            })
          }}
        />
      </div>
    </div>
  )
}

export default ReviewForm
