import React from 'react'

export type SellerKYCStep =
  | 'personalPhoneNumber'
  | 'companyDetails'
  | 'companyAddress'
  | 'businessDetails'
  | 'targetMarket'
  | 'productDetails'
  | 'revShare'
  // | 'paymentSource'
  | 'submitForm'
  | 'requestSent'

export type SellerPerkKYCStep =
  | 'personalPhoneNumber'
  | 'companyDetails'
  | 'companyAddress'
  | 'businessDetails'
  | 'targetMarket'
  | 'productDetails'
  | 'submitForm'
  | 'requestSent'
export interface SellerKYCContextType {
  filledSteps: object
  sellerType: 'perk' | 'product'
  currentStep: () => SellerKYCStep
  nextStep: () => void
  previousStep: () => void
  setCurrentStep: (step: SellerKYCStep) => void
  setSellerType: (type: 'perk' | 'product') => void
  getStepStatus: (step: SellerKYCStep) => boolean
  reloadKYCState: () => void
}

export const contextInit: SellerKYCContextType = {
  filledSteps: {},
  sellerType: 'product',
  currentStep: () => 'companyDetails',
  nextStep: () => {},
  previousStep: () => {},
  setCurrentStep: () => {},
  setSellerType: () => {},
  getStepStatus: () => false,
  reloadKYCState: () => {},
}

export const SellerKYCContext = React.createContext({ ...contextInit })

export const useSellerKYCContext = (): SellerKYCContextType => React.useContext(SellerKYCContext)
