import React from 'react'
import Paper from '@mui/material/Paper'
import Icon from '@mui/material/Icon'
import ResendEmailValidation from './ResendEmailValidation'
import { Box } from '@mui/material'
import { User } from '__generated__/graphql'

type Props = {
  user: Pick<User, 'id' | 'isEmailValidated'>
}

const isEmailValidated = (props: Props) => {
  if (props.user.isEmailValidated) return null
  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn" style={{ backgroundColor: '#1445FE' }}>
          <>
            <Box sx={{ color: 'white', fontFamily: 'Roobert' }}>
              <Box sx={{ fontSize: 14, marginBottom: '15px', alignItems: 'center', display: 'flex' }}>
                <Icon className="textSize12 mr-5">error_outline</Icon>
                <div>Your email has not been verified. Please check your email inbox (or perhaps your spam folder)!</div>
              </Box>
              <ResendEmailValidation buttonSize="small" userId={props.user.id} />
            </Box>
          </>
        </Paper>
      </div>
    </div>
  )
}

export default isEmailValidated
