import React from 'react'
import { FormControl, InputLabel, TextField } from '@mui/material'
import utils from 'components/utils'
import { User } from '__generated__/graphql'
import UpdateUser from 'components/user/single/action/UpdateUser'

type Props = {
  user: Pick<User, 'id' | 'firstName' | 'lastName' | 'linkedInLink'>
  onUpdate: () => void
}

const AdditionalDetailsFormMaven = (props: Props) => {
  const [user, setUser] = React.useState(props.user)

  const [linkedInLinkInit, setLinkedInLinkInit] = React.useState(false)

  const firstNameValid = () => {
    return user.firstName.length > 0
  }
  const lastNameValid = () => {
    return user.lastName.length > 0
  }
  const linkedInLinkValid = () => {
    return (
      user.linkedInLink.length > 0 && utils.isURL(user.linkedInLink) && user.linkedInLink.toLowerCase().includes('linkedin.com')
    )
  }

  const isFormValid = () => {
    return linkedInLinkValid() && firstNameValid() && lastNameValid()
  }

  return (
    <>
      <div className="titleStepKYC">About you</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div id="linkedinProfile">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="firstName">
              <b className="black2">First name</b>
            </InputLabel>
            <TextField
              id="firstName"
              size="small"
              className="width100per spaceForm"
              placeholder="First name"
              error={!firstNameValid()}
              onChange={(e) => {
                const newName = e.target.value
                setUser({
                  ...user,
                  firstName: newName,
                })
              }}
              type="text"
              value={user.firstName}
            />
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="lastName">
              <b className="black2">Last name</b>
            </InputLabel>
            <TextField
              id="lastName"
              size="small"
              className="width100per spaceForm"
              placeholder="Last name"
              error={!lastNameValid()}
              onChange={(e) => {
                const newName = e.target.value
                setUser({
                  ...user,
                  lastName: newName,
                })
              }}
              type="text"
              value={user.lastName}
            />
          </FormControl>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="linkedinProfile">
              <b className="black2">Your personal LinkedIn profile</b>
            </InputLabel>
            <TextField
              id="linkedInLink"
              size="small"
              className="width100per spaceForm"
              placeholder="LinkedIn profile URL"
              error={!linkedInLinkValid() && linkedInLinkInit}
              onBlur={() => setLinkedInLinkInit(true)}
              onChange={(e) => {
                const newLinkedInLink = e.target.value
                setUser({
                  ...user,
                  linkedInLink: newLinkedInLink,
                })
              }}
              type="text"
              value={user.linkedInLink}
            />
          </FormControl>
        </div>

        <div style={{ marginTop: '20px' }}>
          <UpdateUser
            fullWidth
            disabled={!isFormValid()}
            user={{
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              linkedInLink: user.linkedInLink,
            }}
            updateTextButton="Continue"
            onUpdate={() => props.onUpdate()}
          />
        </div>
      </div>
    </>
  )
}
export default AdditionalDetailsFormMaven
