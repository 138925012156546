import React from 'react'
import { Box } from '@mui/material'
import themeNN from 'components/themeNN'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  onClick: () => void
}

const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '22px',
        width: '22px',
        border: '1px solid var(--black)',
        borderRadius: '99px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <CloseIcon style={{ fill: themeNN.lightBlue.color, fontSize: '20px' }} />
    </Box>
  )
}

export default CloseButton
