import React from 'react'
import { useMutation } from '@apollo/client'
import { Button, Grid } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { useAppContextWithCompany } from 'components/AppContext'
import VerifyPhoneForm from './VerifyPhoneForm'
import { URL_HOME } from 'config/config'
import { gql } from '__generated__'

const VERIFY_PHONE_MUTATION = gql(/* GraphQL */ `
  mutation verifyPhone_VerifyPhone($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    verifyPhone(data: $data, where: $where) {
      id
      phoneChangeRequested
      phoneCodeChangeRequested
      isPhoneValidated
      isPhoneChangeRequestedPending
      phone
      phoneCode
    }
  }
`)

type Props = {
  userId: string
  onUpdate: () => void
  onCancel: () => void
}

const VerifyPhone: React.FC<Props> = (props: Props) => {
  const showErrors = useShowErrors()

  const context = useAppContextWithCompany()

  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [updatePhoneMutation] = useMutation(VERIFY_PHONE_MUTATION)

  const updatePhone = async (phoneValidationToken: string) => {
    setLoading(true)
    try {
      await updatePhoneMutation({
        variables: {
          where: { id: props.userId },
          data: { phoneValidationToken },
        },
      })
      context.openSnackbar('Phone Verified!', 'success', true, 2000)
      props.onUpdate()
    } catch (e) {
      showErrors(e, setErrorMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <VerifyPhoneForm onVerify={(phoneValidationToken: string) => updatePhone(phoneValidationToken)} />
      <div style={{ height: '10px' }} />
      <div>{errorMessage && <span className="secondary">{errorMessage}</span>}</div>
      <div style={{ height: '10px' }} />
      <div>
        <Grid container>
          <Grid item xs={12} sm={4} className="tal">
            <Button onClick={props.onCancel}>Back</Button>
          </Grid>
          <Grid item xs={12} sm={8} className="tar">
            <Button target="_blank" rel="noreferrer" href={`${URL_HOME}/contact`} color="primary" disabled={loading}>
              {`Contact us if you don't get the SMS`}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default VerifyPhone
