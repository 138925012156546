import React from 'react'
import { Button } from '@mui/material'
import { Product, Visibility } from '__generated__/graphql'
import PositionProductsAutocomplete from 'components/positionProduct/list/PositionProductsAutocomplete'
import { useMavenKYCContext } from './MavenKYCContext'
import { URL_HOME } from 'config/config'

type Props = {
  product: Pick<Product, 'id'>
  onUpdate: () => void
}

const MavenServiceCategoriesForm = (props: Props) => {
  const mavenKYCContext = useMavenKYCContext()
  return (
    <>
      <div className="titleStepKYC">Service Categories</div>
      <div style={{ margin: '20px 30px 0' }}>
        <PositionProductsAutocomplete
          noMargin
          productId={props.product.id}
          visibility={Visibility.MavenService}
          title="Service Categories"
          subtext="Don't see your Service Category? Contact us."
          sublink={`${URL_HOME}/contact`}
          onUpdate={() => mavenKYCContext.reloadKYCState()}
        />
      </div>

      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div style={{ marginTop: '20px' }}>
          <Button
            id="idButtonUpdateProduct"
            data-action="updateProduct"
            size="medium"
            disabled={!mavenKYCContext.getStepStatus('serviceCategories')}
            color="primary"
            variant="contained"
            onClick={props.onUpdate}
            sx={{ width: '100%' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}
export default MavenServiceCategoriesForm
