import React from 'react'
import { useAppContextWithCompany } from '../../AppContext'
import { AppBar, Icon, BottomNavigation, BottomNavigationAction, useMediaQuery, Theme } from '@mui/material'

const FooterBurger = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const context = useAppContextWithCompany()
  if (!isMobile) return null
  return (
    <>
      <div style={{ height: '100px' }} className="bgSalmonGrey" />

      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <BottomNavigation showLabels>
          <BottomNavigationAction onClick={() => context.setDrawerLeftMobile(true)} label="Menu" icon={<Icon>menu</Icon>} />
        </BottomNavigation>
      </AppBar>
    </>
  )
}

export default FooterBurger
