import React from 'react'
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg'

type Props = {
  title: string
  closeDialog: () => void
}
const HeaderRightSideBar = (props: Props) => {
  return (
    <div style={{ background: '#1445FE', height: '84px', textAlign: 'center', marginBottom: '24px', position: 'relative' }}>
      <CloseIcon
        onClick={() => props.closeDialog()}
        style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translate(0, -50%)', cursor: 'pointer' }}
      />
      <span style={{ fontFamily: 'Roobert', fontSize: '24px', color: 'white', lineHeight: '84px' }}>{props.title}</span>
    </div>
  )
}

export default HeaderRightSideBar
