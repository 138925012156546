import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation updateFile_DeleteFile($data: FileUpdateInput!, $where: FileWhereUniqueInput!) {
    updateFile(data: $data, where: $where) {
      id
    }
  }
`)

type Props = {
  fileId: string
  fullButton?: boolean
  onDelete: () => void
}

const DeleteFile = (props: Props) => {
  const showErrors = useShowErrors()
  const [updatefile] = useMutation(MUTATION)

  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const updatefileF = async () => {
    setLoading(true)

    try {
      const data = await updatefile({
        variables: {
          data: {
            isDeleted: true,
          },
          where: {
            id: props.fileId,
          },
        },
      })

      setLoading(false)
      if (data) {
        props.onDelete()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="deleteFile"
        id="idButton"
        icon={props.fullButton ? '' : 'delete'}
        color="primary"
        disabled={false}
        variant="outlined"
        size="medium"
        buttonText="Delete"
        buttonLoadingText="Loading..."
        onClick={() => updatefileF()}
        loading={loading}
      />
      {message && (
        <div id="idMessage-DeleteFile" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default DeleteFile
