import React from 'react'
import { Product } from '__generated__/graphql'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import UpdateProductLight from '../UpdateProductLight'
import { Paper } from '@mui/material'
import UploadFile from 'components/nav/file/UploadFile'
import ImageTemplate from 'components/nav/ImageTemplate'
import themeNN from 'components/themeNN'

interface Props {
  title: string
  product: Pick<Product, 'id' | 'nameFile'>
  // onUpdate: () => void
}

const ProductNameFileAtomic = (props: Props) => {
  const [value, setValue] = React.useState(props.product.nameFile)
  const [error, setError] = React.useState('')
  const [isEdit, setIsEdit] = React.useState(false)

  return (
    <div className="paperOut">
      <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
        {!isEdit ? (
          <>
            <EditHeaderSection
              canEdit={true}
              id="id-ProductNameFileAtomic"
              title={props.title}
              onClick={() => setIsEdit(true)}
              isEdit={isEdit}
            />

            {value && <ImageTemplate nameFile={value} format="roundMedium" />}
          </>
        ) : (
          <>
            <h4>{props.title}</h4>
            200x200px
            <UploadFile
              format="SQUARE"
              textDragNDrop="Drag 'n' drop a file here, or click to select file"
              deleteImageText="Delete image"
              nameFile={value}
              onSelectFile={(nameFile) => setValue(nameFile)}
            />
            <UpdateProductLight
              onCancel={() => {
                setIsEdit(false)
                setValue(props.product.nameFile)
                setError('')
              }}
              product={{ id: props.product.id, nameFile: value }}
              disabled={!!error}
              onSaved={(product) => {
                setIsEdit(false)
                setValue(product.nameFile)
                // props.onUpdate && props.onUpdate()
              }}
            />
          </>
        )}
      </Paper>
    </div>
  )
}

export default ProductNameFileAtomic
