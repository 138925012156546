import {
  Box,
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import theme from 'components/themeNN'
import TextFieldNN from 'components/ui/TextFieldNN'
import OffsiteTemplate from 'components/user/single/profile/sectionDetails/OffsiteTemplate'
import React, { useCallback, useMemo } from 'react'
import { gql } from '__generated__'
import { useMutation } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import useShowErrors from 'hooks/useShowErrors'
import utils from 'components/utils'
import { BankCountriesHome } from 'components/addresse/bankAddress/BankCountriesHome'
import { BankAddress } from 'components/addresse/BankAddress.type'

const noEmployeesOptions = ['', '1-15', '16-50', '51-200', '201-500', '501-2000', '2000+']
const monthlySpendOptions = [
  '',
  '< $5,000/month',
  '$5k/month - $20k/month',
  '$20k/month - $50k/month',
  '$50k/month - $100k/month',
  '$100k/month - $200k/month',
  '> $200k/month',
]
const noSubscriptionsOptions = ['', '1-10', '11-20', '21-50', '51-100', '100+']

const MUTATION = gql(/* GraphQL */ `
  mutation submitCompaniePreDemo_PreDemoForm(
    $companieId: String!
    $website: String!
    $linkedin: String!
    $numberOfEmployees: String!
    $registeredCountry: String!
    $approximateMonthlySpending: String!
    $numberOfSubscriptions: String!
    $whereDidYouHearAboutUs: String!
  ) {
    submitCompaniePreDemo(
      companieId: $companieId
      website: $website
      linkedin: $linkedin
      numberOfEmployees: $numberOfEmployees
      registeredCountry: $registeredCountry
      approximateMonthlySpending: $approximateMonthlySpending
      numberOfSubscriptions: $numberOfSubscriptions
      whereDidYouHearAboutUs: $whereDidYouHearAboutUs
    ) {
      id
      statusVerification
    }
  }
`)

type FormField<T> = {
  value: T
  error: boolean
  touched: boolean
}

type Form = {
  website: FormField<string>
  linkedin: FormField<string>
  noEmployees: FormField<string>
  country: FormField<BankAddress>
  approximateMonthlySpending: FormField<string>
  noSubscriptions: FormField<string>
  whereDidYouHearAboutUs: FormField<string>
}

const countries = [...BankCountriesHome]
const initialCountry = countries.find((country) => country.code === 'US') as BankAddress

const PreDemoForm: React.FC = () => {
  const context = useAppContextWithCompany()
  const showErrors = useShowErrors()

  const [form, setForm] = React.useState<Form>({
    website: { value: '', error: false, touched: false },
    linkedin: { value: '', error: false, touched: false },
    noEmployees: { value: '', error: false, touched: false },
    country: { value: initialCountry, error: false, touched: false },
    approximateMonthlySpending: { value: '', error: false, touched: false },
    noSubscriptions: { value: '', error: false, touched: false },
    whereDidYouHearAboutUs: { value: '', error: false, touched: false },
  })
  const [loading, setLoading] = React.useState(false)

  const [submitCompaniePreDemoMutation] = useMutation(MUTATION)

  const isValidString = useCallback((value: unknown) => typeof value === 'string' && value.length > 0, [])

  const validate = useMemo(
    () => ({
      website: (value: Form['website']['value']) => {
        return utils.isURL(value)
      },
      linkedin: (value: Form['linkedin']['value']) => {
        return value === '' || utils.isValidLinkedInUrl(value)
      },
      noEmployees: (value: Form['noEmployees']['value']) => {
        return isValidString(value)
      },
      country: (value: Form['country']['value']) => {
        return isValidString(value.label)
      },
      approximateMonthlySpending: (value: Form['approximateMonthlySpending']['value']) => {
        return isValidString(value)
      },
      noSubscriptions: (value: Form['noSubscriptions']['value']) => {
        return isValidString(value)
      },
      whereDidYouHearAboutUs: (value: Form['whereDidYouHearAboutUs']['value']) => {
        return isValidString(value)
      },
    }),
    [isValidString],
  )

  const validateForm = useCallback(() => {
    const { website, linkedin, noEmployees, country, approximateMonthlySpending, noSubscriptions, whereDidYouHearAboutUs } = form
    const newForm: Form = JSON.parse(JSON.stringify(form))
    let isValid = true

    const handleInvalid = (field: keyof Form) => {
      newForm[field].error = true
      newForm[field].touched = true
      isValid = false
    }
    if (!validate['website'](website.value)) handleInvalid('website')
    if (!validate['linkedin'](linkedin.value)) handleInvalid('linkedin')
    if (!validate['noEmployees'](noEmployees.value)) handleInvalid('noEmployees')
    if (!validate['country'](country.value)) handleInvalid('country')
    if (!validate['approximateMonthlySpending'](approximateMonthlySpending.value)) handleInvalid('approximateMonthlySpending')
    if (!validate['noSubscriptions'](noSubscriptions.value)) handleInvalid('noSubscriptions')
    if (!validate['whereDidYouHearAboutUs'](whereDidYouHearAboutUs.value)) handleInvalid('whereDidYouHearAboutUs')

    return { isValid, newForm }
  }, [form, validate])

  const setField = <T extends keyof Form>(
    field: T,
    data: { value?: Form[T]['value']; error?: Form[T]['error']; touched?: Form[T]['touched'] },
  ) => {
    setForm({
      ...form,
      [field]: {
        value: data.value ?? form[field].value,
        error: data.error ?? form[field].error,
        touched: data.touched ?? form[field].touched,
      },
    })
  }

  const submitButtonDisabled = useMemo(() => {
    const { isValid } = validateForm()
    return loading || !isValid
  }, [loading, validateForm])

  const submitCompaniePreDemo = async () => {
    const { isValid, newForm } = validateForm()
    if (!isValid) {
      setForm(newForm)
      return
    }
    setLoading(true)
    try {
      const { data } = await submitCompaniePreDemoMutation({
        variables: {
          companieId: context.userRoleCompanie.companie.id,
          website: form.website.value,
          linkedin: form.linkedin.value,
          numberOfEmployees: form.noEmployees.value,
          registeredCountry: form.country.value.label,
          approximateMonthlySpending: form.approximateMonthlySpending.value,
          numberOfSubscriptions: form.noSubscriptions.value,
          whereDidYouHearAboutUs: form.whereDidYouHearAboutUs.value,
        },
      })
      if (!data) throw new Error('No data')
      context.updateUserRoleCompanieViaQuery()
    } catch (error) {
      showErrors(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <OffsiteTemplate hideNNLogo>
      <Box mb="48px" mt="24px">
        <LinearProgress
          variant="determinate"
          value={50}
          sx={{
            height: '15px',
            borderRadius: '20px',
            bgcolor: '#F4F1EE',
            '& .MuiLinearProgress-barColorPrimary': { bgcolor: '#49E145' },
            mb: '7px',
          }}
        />
        <Box display="flex" justifyContent="space-between">
          <span className="fs-12 text-light-grey-1">Verify your number</span>
          <span className="fs-12 fw-700 text-light-grey-1">Company details</span>
          <span className="fs-12 text-light-grey-1">Book a demo</span>
        </Box>
      </Box>

      <Box textAlign="center" mb="48px">
        <h2 className="fs-22 text-black fw-700 m-0" style={{ marginBottom: 8 }}>
          Company details
        </h2>
        <span className="fs-14 text-light-grey-1">Tell us a bit about your company</span>
      </Box>

      <TextFieldNN
        id="website"
        label="Company Website"
        value={form.website.value}
        onChange={(e) => setField('website', { value: e.target.value })}
        onBlur={(e) => {
          setField('website', {
            touched: true,
            error: !validate.website(e.target.value),
          })
        }}
        onFocus={() => setField('website', { error: false })}
        error={form.website.touched && form.website.error}
        helperText={form.website.touched && form.website.error ? 'Invalid website' : ''}
      />

      <TextFieldNN
        id="linkedin"
        label="Company LinkedIn Page (optional)"
        value={form.linkedin.value}
        onChange={(e) => setField('linkedin', { value: e.target.value })}
        onBlur={(e) => {
          setField('linkedin', {
            touched: true,
            error: !validate.linkedin(e.target.value),
          })
        }}
        onFocus={() => setField('linkedin', { error: false })}
        error={form.linkedin.touched && form.linkedin.error}
        helperText={form.linkedin.touched && form.linkedin.error ? 'Invalid LinkedIn URL' : ''}
      />

      <FormControl fullWidth sx={{ minHeight: '90px' }}>
        <FormLabel sx={theme.formLabel} id="noEmployees-label">
          No. of employees
        </FormLabel>
        <Select
          id="noEmployees"
          size="small"
          value={form.noEmployees.value}
          label=""
          onChange={(e) => setField('noEmployees', { value: e.target.value })}
          onBlur={(e) => {
            setField('noEmployees', {
              touched: true,
              error: !validate.noEmployees(e.target.value),
            })
          }}
          onFocus={() => setField('noEmployees', { error: false })}
          sx={{ '& > .MuiSelect-select': { textAlign: 'left' } }}
          error={form.noEmployees.touched && form.noEmployees.error}
        >
          {noEmployeesOptions.map((option) => (
            <MenuItem key={option} value={option} sx={{ ...(option === '' && { display: 'none' }) }}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {form.noEmployees.touched && form.noEmployees.error && <FormHelperText error>Field required</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ minHeight: '90px' }}>
        <FormLabel sx={theme.formLabel} id="country">
          Which country is your company registered in?
        </FormLabel>
        <Autocomplete
          id="country"
          size="small"
          options={countries}
          value={form.country.value}
          onChange={(_, value) => setField('country', { value: value ?? undefined })}
          onBlur={() => {
            setField('country', {
              touched: true,
              error: !validate.country(form.country.value),
            })
          }}
          onFocus={() => setField('country', { error: false })}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} error={form.country.touched && form.country.error} />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
        />
        {form.country.touched && form.country.error && <FormHelperText error>Field required</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ minHeight: '90px' }}>
        <FormLabel sx={theme.formLabel} id="approximateMonthlySpending-label">
          What is your company&apos;s approximate monthly spend on software?
        </FormLabel>
        <Select
          size="small"
          id="approximateMonthlySpending"
          value={form.approximateMonthlySpending.value}
          label=""
          onChange={(e) => setField('approximateMonthlySpending', { value: e.target.value })}
          onBlur={(e) => {
            setField('approximateMonthlySpending', {
              touched: true,
              error: !validate.approximateMonthlySpending(e.target.value),
            })
          }}
          onFocus={() => setField('approximateMonthlySpending', { error: false })}
          sx={{ '& > .MuiSelect-select': { textAlign: 'left' } }}
          error={form.approximateMonthlySpending.touched && form.approximateMonthlySpending.error}
        >
          {monthlySpendOptions.map((option) => (
            <MenuItem key={option} value={option} sx={{ ...(option === '' && { display: 'none' }) }}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {form.approximateMonthlySpending.touched && form.approximateMonthlySpending.error && (
          <FormHelperText error>Field required</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ minHeight: '90px' }}>
        <FormLabel sx={theme.formLabel} id="noSubscriptions-label">
          How many subscriptions does your company pay for?
        </FormLabel>
        <Select
          size="small"
          id="noSubscriptions"
          value={form.noSubscriptions.value}
          label=""
          onChange={(e) => setField('noSubscriptions', { value: e.target.value })}
          onBlur={(e) => {
            setField('noSubscriptions', {
              touched: true,
              error: !validate.noSubscriptions(e.target.value),
            })
          }}
          onFocus={() => setField('noSubscriptions', { error: false })}
          sx={{ '& > .MuiSelect-select': { textAlign: 'left' } }}
          error={form.noSubscriptions.touched && form.noSubscriptions.error}
        >
          {noSubscriptionsOptions.map((option) => (
            <MenuItem key={option} value={option} sx={{ ...(option === '' && { display: 'none' }) }}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {form.noSubscriptions.touched && form.noSubscriptions.error && <FormHelperText error>Field required</FormHelperText>}
      </FormControl>

      <TextFieldNN
        id="whereDidYouHearAboutUs"
        label="Where did you hear about us?"
        value={form.whereDidYouHearAboutUs.value}
        onChange={(e) => setField('whereDidYouHearAboutUs', { value: e.target.value })}
        onBlur={(e) => {
          setField('whereDidYouHearAboutUs', {
            touched: true,
            error: !validate.whereDidYouHearAboutUs(e.target.value),
          })
        }}
        onFocus={() => setField('whereDidYouHearAboutUs', { error: false })}
        error={form.whereDidYouHearAboutUs.touched && form.whereDidYouHearAboutUs.error}
        helperText={form.whereDidYouHearAboutUs.touched && form.whereDidYouHearAboutUs.error ? 'Field required' : ''}
        onKeyDown={(e) => e.key === 'Enter' && submitCompaniePreDemo()}
      />

      <Button
        onClick={submitCompaniePreDemo}
        disabled={submitButtonDisabled}
        variant="contained"
        color="primary"
        sx={{ mt: '32px' }}
      >
        {loading ? <CircularProgress size={20} /> : 'Submit'}
      </Button>
    </OffsiteTemplate>
  )
}

export default PreDemoForm
