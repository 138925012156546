import React from 'react'
import { useMediaQuery, Theme, Drawer } from '@mui/material'
import { useAppContext } from 'components/AppContext'
import DrawerLeftChild from './DrawerLeftChild'
import LogoApp from './LogoApp'
import DrawerLeftProfile from './DrawerLeftProfile'

const DrawerLeft = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { isSideBarOpenLeftMobile, setDrawerLeftMobile } = useAppContext()

  const closeDrawer = () => setDrawerLeftMobile(false)

  if (isMobile)
    return (
      <Drawer open={isSideBarOpenLeftMobile} anchor="left" onClose={closeDrawer} variant="temporary">
        <LogoApp />
        <DrawerLeftChild />
        <DrawerLeftProfile />
      </Drawer>
    )

  return (
    <div
      style={{ overflowY: 'scroll', top: 0, bottom: 0, width: 240, display: 'flex', flexDirection: 'column' }}
      className="hideScrollBar"
    >
      <LogoApp />
      <DrawerLeftChild />
      <DrawerLeftProfile />
    </div>
  )
}

export default DrawerLeft
