import { createTheme } from '@mui/material/styles'
declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    borderless: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1445FE',
    },
    secondary: {
      main: '#F7315A',
    },
    success: {
      main: '#159312',
    },
    action: {
      disabledBackground: '#B8C7FF',
      // disabled: '#FFFFFF',
    },
    error: {
      main: '#F7315A',
    },
  },
  typography: {
    fontFamily: ['Roobert', 'sans-serif'].join(','),
  },

  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottom: '1px #D3D3D3 dotted',
        },
      },
    },
    MuiIconButton: {
      // variants: [
      //   // {
      //   //   props: { color: 'white' },
      //   //   style: {
      //   //     // backgroundColor: 'white',
      //   //   },
      //   // },
      // ],

      styleOverrides: {
        colorPrimary: {},
        sizeLarge: {
          backgroundColor: '#1445FE',
          color: 'white',
          border: '1px solid;',
          borderColor: 'black',

          '&:hover': {
            backgroundColor: '#2475ff',
          },
          // color: blue
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 20,
        },
      },
      variants: [
        {
          props: { variant: 'borderless' },
          style: {
            border: '1px solid white',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {},
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {},
        root: {
          borderRadius: '20px!important',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          paddingTop: '10px',
          paddingBottom: '10px',
          color: 'black',
          fontSize: 14,

          backgroundColor: '#fae83c',
        },
        arrow: {
          color: '#fae83c',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // sizeMedium: {
        // },
        outlinedPrimary: {
          backgroundColor: 'white',
        },

        filledPrimary: {
          backgroundColor: 'white',
        },
        filledSecondary: {
          color: 'white',
        },
        filled: {
          color: '#1445FE',
          fontWeight: 600,
        },
        root: {
          height: 24,
        },
      },
    },

    MuiButton: {
      // variants: [
      //   // {
      //   //   props: { color: 'white' },
      //   //   style: {
      //   //     // backgroundColor: 'white',
      //   //   },
      //   // },
      // ],
      styleOverrides: {
        root: {
          // [`&.${buttonClasses.disabled}`]: {
          //   border: 'none',
          //   backgroundColor: '#B8C7FF',
          // },
          textTransform: 'unset',
          borderRadius: '99px',
          '&, &:hover': {
            boxShadow: 'none',
          },
        },

        textPrimary: {
          zIndex: 999,
          fontWeight: 'bold',
        },
        sizeMedium: {
          fontWeight: 'bold',
          height: 40,
          fontSize: 12,
        },
        sizeSmall: {
          fontWeight: 'bold',
          height: 26,
          fontSize: 12,
        },

        containedPrimary: {
          ':disabled': {
            color: 'white',
          },
        },
        outlinedPrimary: {
          // ':disabled': {
          //   color: 'red',
          //   // backgroundColor: '#B8C7FF',
          // },

          backgroundColor: 'white',
          ':hover': {
            backgroundColor: '#F1F1F7',
          },
        },
        outlinedSizeMedium: {
          zIndex: 999,
        },
        containedSizeMedium: {
          zIndex: 999,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Roobert',
          fontWeight: 'bold',
          minWidth: 0,
          minHeight: 0,
          padding: 0,
          marginRight: '24px',
          marginBottom: '3px',
        },
      },
    },
  },
})

export default theme
