import React from 'react'
import { AuthorizedAmountUnitType, Maybe } from '__generated__/graphql'

type Props = {
  authorizedAmountUnit?: Maybe<AuthorizedAmountUnitType>
}

const MappingAuthorizedAmountUnit = (props: Props) => {
  return (
    <>
      {props.authorizedAmountUnit === 'NONE' && <span>no limit</span>}
      {props.authorizedAmountUnit === 'PER_MONTH' && <span>per month</span>}
      {props.authorizedAmountUnit === 'PER_YEAR' && <span>per year</span>}
      {props.authorizedAmountUnit === 'TOTAL' && <span>cumulative</span>}
    </>
  )
}

export default MappingAuthorizedAmountUnit
