import React from 'react'
import Error from '../../nav/error/Error'
import Loading from '../../nav/error/Loading'
import NotFound from '../../nav/error/NotFound'
import SingleSource from '../single/SingleSource'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { Companie, SourceOrderByInput } from '__generated__/graphql'
import themeNN from 'components/themeNN'
import AddPaymentSourceLogicStripeBuyerBasicPlus from 'components/paymentSource/list/AddPaymentSourceLogicStripeBuyerBasicPlus'
import { InvoicesListQueryBuyerBasicPlusPayment } from 'components/invoice/list/sellerPaymentFees/InvoicesListQueryBuyerBasicPlusPayment'
import VerifiedIcon from '@mui/icons-material/Verified'

const SOURCES_QUERY = gql(/* GraphQL */ `
  query sourcesConnection_SourcesQuery($where: SourceWhereInput, $orderBy: SourceOrderByInput, $first: Int, $skip: Int) {
    sourcesConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      edges {
        node {
          id
          typeSource
          isDefaultSource
          last4
          status
          country
          nickname
          statusVerificationSource
          brand
          bank_name
          object
          funding
          exp_month
          exp_year
          name
          plaidData {
            id
            resetLogin
            publicTokenFresh
          }
          companie {
            id
            incomingPaymentForeignFeeCard
            allowPrepaidCardFunding
          }
        }
      }
    }
  }
`)

type Props = {
  companie: Pick<
    Companie,
    | 'id'
    | 'requestCardVerification'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
  onInvoiceCreated: () => void
}

const SourcesQueryBasicPlusBuyer = (props: Props) => {
  const { loading, error, data, refetch } = useQuery(SOURCES_QUERY, {
    variables: {
      orderBy: SourceOrderByInput.IsDefaultSourceDesc,
      where: {
        companie: { id: props.companie.id },
        object: 'card',
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.sourcesConnection) return <NotFound />

  return (
    <>
      <h2>Basic Plus</h2>

      {/* <div style={{ height: 10 }} /> */}
      <p>Save millions of dollars per year on all your software needs</p>
      <div style={{ height: 15 }} />
      <p>
        <b>$99</b> per year, cancel any time
      </p>

      <ul>
        <li>All Basic features, plus:</li>
        <li>Access to hundreds more software discounts/perks.</li>
        <li>Savings of {`>`} $3mln/year available!</li>
        <li>Unlimited team members.</li>
        <li>Sherlock: continuous monitoring of your company-wide software payments & usage.</li>
      </ul>

      <div style={{ marginTop: 30, marginBottom: 30, display: 'flex', alignItems: 'center' }}>
        <VerifiedIcon sx={{ color: themeNN.green.color }} />
        <p style={{ marginLeft: 10 }}>Membership Fee is fully refundable if you don't receive the listed discount/cashback.</p>
      </div>
      {data.sourcesConnection.edges.map((sourceNode) => (
        <div key={sourceNode.node.id}>
          <div className="">
            <div style={{ backgroundColor: themeNN.greyBackground.color, borderRadius: 25, padding: 10 }}>
              <SingleSource source={sourceNode.node} onSucceed={refetch} />
            </div>
          </div>
        </div>
      ))}

      {data.sourcesConnection.edges.length === 0 && <AddPaymentSourceLogicStripeBuyerBasicPlus companie={props.companie} />}
      {data.sourcesConnection.edges.length > 0 && (
        <div className="">
          <InvoicesListQueryBuyerBasicPlusPayment
            page={1}
            first={50}
            companie={props.companie}
            onInvoiceCreated={props.onInvoiceCreated}
          />
        </div>
      )}
    </>
  )
}

export default SourcesQueryBasicPlusBuyer
