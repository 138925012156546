import React from 'react'
import { Grid, Paper } from '@mui/material'
import MenuLeftDesktop from '../drawer/topLeft/MenuLeftDesktop'
import ContactUsOnboardingLink from '../../../onboarding/ContactUsOnboardingLink'
import DateComponent from '../../../nav/DateComponent'
import Error from '../../../nav/error/Error'
import NotFound from '../../../nav/error/NotFound'
import Loading from '../../../nav/error/Loading'
import { useQuery } from '@apollo/client'
import LogoCompose from '../../../onboarding/LogoCompose'
import LogOutCross from 'components/nav/LogoutCross'
import { useAppContextWithCompany } from 'components/AppContext'
import { URL_HOME } from 'config/config'
import { gql } from '__generated__'

const COMPANIE_QUERY_TEAM = gql(/* GraphQL */ `
  query companie_CompanieVerificationPageSplash($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      statusVerification
      typeCompanie
      verificationDateSubmission
    }
  }
`)

const CompanieVerificationPageSplash = () => {
  const context = useAppContextWithCompany()

  const { me: user, userRoleCompanie } = context

  const { loading, error, data } = useQuery(COMPANIE_QUERY_TEAM, {
    variables: {
      where: {
        id: userRoleCompanie.companie.id,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.companie) return <NotFound />
  const companie = data.companie

  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="borderless" className="paperIn">
          <>
            <Grid container>
              <Grid item xs={6}>
                <MenuLeftDesktop />
              </Grid>
              <Grid item xs={6} className="tar">
                <LogOutCross />
              </Grid>
            </Grid>

            <LogoCompose />
            <div className="tal responsiveMargin2">
              {companie.statusVerification === 'SUBMITED' && companie.typeCompanie === 'BUYER' && (
                <>
                  <h3>Company Verification</h3>
                  {user.firstName}, we have received verification information for {companie.name} on{' '}
                  {companie.verificationDateSubmission && <DateComponent date={companie.verificationDateSubmission} />}. Thank you
                  for submitting it. We will review it and get back to you asap.
                  <p>
                    In the meantime, please visit our{' '}
                    <a className="link" target="_blank" rel="noopener noreferrer" href={URL_HOME}>
                      home page
                    </a>
                    !
                  </p>
                </>
              )}
              {companie.statusVerification === 'SUBMITED' && companie.typeCompanie === 'SELLER' && (
                <>
                  <h3>Seller Company Verification</h3>
                  {user.firstName}, we have received verification information for {companie.name} on{' '}
                  {companie.verificationDateSubmission && <DateComponent date={companie.verificationDateSubmission} />}. Thank you
                  for submitting it. We will review it and get back to you asap.
                  <p>
                    In the meantime, please visit our{' '}
                    <a className="link" target="_blank" rel="noopener noreferrer" href={URL_HOME}>
                      home page
                    </a>
                    !
                  </p>
                </>
              )}
              {companie.statusVerification === 'BACKBURNER' && (
                <>
                  <h3>Company Verification</h3>
                  {user.firstName}, we have received verification information for {companie.name} on{' '}
                  {companie.verificationDateSubmission && <DateComponent date={companie.verificationDateSubmission} />}. Thank you
                  for submitting it. We will review it and get back to you asap.
                  <p>
                    In the meantime, please visit our{' '}
                    <a className="link" target="_blank" rel="noopener noreferrer" href={URL_HOME}>
                      home page
                    </a>
                    !
                  </p>
                </>
              )}
              {companie.statusVerification === 'NOT_APPROVED' && (
                <>
                  <h3>Company Verification</h3>
                  We′re sorry, but your company was not approved. This could be for several reasons, including incomplete
                  information. Please contact us if you believe this was an error and would like us to reconsider our decision.
                </>
              )}
              {companie.statusVerification === 'SUSPENDED_BY_USER' && (
                <>
                  <h3>Account Status</h3>
                  The account named "{companie.name}" has been removed from NachoNacho.
                </>
              )}
              {companie.statusVerification === 'SUSPENDED_BY_ADMIN' && (
                <>
                  <h3>Account Status</h3>
                  The account named "{companie.name}" has been removed from NachoNacho.
                </>
              )}
            </div>

            <Grid container>
              <Grid item xs={12} sm={12} className="tar">
                <ContactUsOnboardingLink />
              </Grid>
            </Grid>
          </>
        </Paper>
      </div>
    </div>
  )
}

export default CompanieVerificationPageSplash
