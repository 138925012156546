import React from 'react'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import PayInvoiceButton from '../action/PayInvoiceButton'
import ImageTemplate from '../../../nav/ImageTemplate'
import utils from '../../../utils'
import { Product, Invoice, Companie } from '__generated__/graphql'

type Props = {
  invoice: Pick<Invoice, 'id' | 'smallId' | 'type' | 'status' | 'buyerFinalPrice' | 'companyType'> & {
    product?: Pick<Product, 'name' | 'nameFile'> | null
    companie: Pick<Companie, 'id'>
  }
}

const SingleInvoiceNotPaid = (props: Props) => {
  return (
    <div className="paperOut fontRoobert white">
      <Grid container>
        <Grid item xs={11} sm={11}>
          <Grid container>
            <Grid item xs={12} sm={3} className="marginAuto">
              {props.invoice.product && <ImageTemplate format="roundMediumWhite" nameFile={props.invoice.product.nameFile} />}
            </Grid>

            <Grid item xs={12} sm={3} className="marginAuto">
              {props.invoice.product && <span>{props.invoice.product.name}</span>}
              {(props.invoice.type === 'TOP_UP' ||
                props.invoice.type === 'AUTO_TOP_UP' ||
                props.invoice.type === 'REFUND_CASH_OUT') && (
                <span>{utils.mappingTypeInvoice(props.invoice.type, props.invoice.companyType)}</span>
              )}
            </Grid>

            <Grid item xs={12} sm={3} className="marginAuto">
              <Link className="linkWhite" to={'/invoice/' + props.invoice.id}>
                {utils.smallIdFormat(props.invoice.smallId)}
              </Link>
            </Grid>
            <Grid item xs={12} sm={3} className="marginAuto">
              {props.invoice.status === 'ERROR_PAYMENT' && (
                <PayInvoiceButton invoice={props.invoice} size="small" variant="outlined" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SingleInvoiceNotPaid
