import React from 'react'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  isValidated: boolean
  textValidated: string
  icon: string
  iconNotValidated: string
  textNotValidated: string
}

const IsValidated: React.FC<Props> = (props: Props) => {
  const { isValidated, textValidated, icon, iconNotValidated, textNotValidated } = props

  return isValidated ? (
    <Tooltip arrow title={textValidated}>
      <Icon className="primary iconAlignTextBottom textSize11">{icon}</Icon>
    </Tooltip>
  ) : (
    <Tooltip arrow title={textNotValidated}>
      <Icon className="secondary iconAlignTextBottom textSize11">{iconNotValidated}</Icon>
    </Tooltip>
  )
}

export default IsValidated
