import React from 'react'
import TotpLoginVerify from '../../single/phone/totp/TotpLoginVerify'
import Check2FAPhone from './Check2FAPhone'
import { Button } from '@mui/material'
import { User } from '@sentry/react'

type Props = {
  user: Pick<
    User,
    | 'id'
    | 'isPhoneValidated'
    | 'enabled2FAPhone'
    | 'isEmailValidated'
    | 'enabled2FAEmail'
    | 'isTwoFactorTotpVerified'
    | 'enabled2FATotp'
  >
}

const Check2FAPageChild = (props: Props) => {
  const showSms = props.user.isPhoneValidated && props.user.enabled2FAPhone
  const showEmail = props.user.isEmailValidated && props.user.enabled2FAEmail
  const showTotp = props.user.isTwoFactorTotpVerified && props.user.enabled2FATotp

  const getInitMode = () => {
    if (showSms && !showTotp && !showEmail) return 'phone'
    if (!showSms && showTotp && !showEmail) return 'totp'
    if (!showSms && !showTotp && showEmail) return 'email'
    return ''
  }

  const [mode, setMode] = React.useState(getInitMode())

  return (
    <>
      {mode === '' && (
        <>
          {showSms && (
            <div className="paperOut">
              <Button color="primary" variant="outlined" onClick={() => setMode('phone')}>
                Via SMS
              </Button>
            </div>
          )}

          {showEmail && (
            <div className="paperOut">
              <Button color="primary" variant="outlined" onClick={() => setMode('email')}>
                Via Email
              </Button>
            </div>
          )}
          {showTotp && (
            <div className="paperOut">
              <Button color="primary" variant="outlined" onClick={() => setMode('totp')}>
                Via Authenticator App
              </Button>
            </div>
          )}
        </>
      )}
      {mode === 'phone' && <Check2FAPhone method="phone" onCancel={() => setMode('')} />}
      {mode === 'totp' && <TotpLoginVerify onUpdate={() => setMode('')} onCancel={() => setMode('')} />}
      {mode === 'email' && <Check2FAPhone method="email" onCancel={() => setMode('')} />}
    </>
  )
}

export default Check2FAPageChild
