import { URL_HOME } from 'config/config'
import React from 'react'

const VerificationRequestSubmitted = () => {
  return (
    <>
      <div className="titleStepKYC">Verification in process</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        <p>Thank you!</p>
        <p>
          We have received your information and will process it asap. Expect to hear from us in less than 1 week. In the meantime,
          feel free to:
        </p>
        <p style={{ textAlign: 'left' }}>
          <ul>
            <li>Browse around your account and get ready to be amazed</li>
            <li>
              Check out our robust{' '}
              <a className="link" href={`${URL_HOME}/marketplace`} target="_blank" rel="noreferrer">
                SaaS Marketplace
              </a>
            </li>
            <li>
              Participate in conversations with 20,000 SaaS users in our{' '}
              <a className="link" href={`${URL_HOME}/mastermind`} target="_blank" rel="noreferrer">
                Mastermind community
              </a>
            </li>
          </ul>
        </p>
      </div>
    </>
  )
}
export default VerificationRequestSubmitted
