import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as TutorialIcon } from 'assets/icon/tutorial/tutorialIcon.svg'
import { IconButton } from '@mui/material'

const TutorialButton = () => {
  const navigate = useNavigate()

  const openTutorial = () => {
    navigate('/tutorial')
  }

  return (
    <IconButton size="small" onClick={openTutorial}>
      <TutorialIcon />
    </IconButton>
  )
}

export default TutorialButton
