import React from 'react'
import UploadFile from '../../../nav/file/UploadFile'
import { TextField, Paper, Alert, FormControl, InputLabel } from '@mui/material'
import { Product, ProductEditRequest, ProductFieldName } from '__generated__/graphql'
import EditField from './wysiwyg/EditField'
import utils from '../../../utils'

type Props = {
  onUpdate: (product: Props['product']) => void
  onFormValidation: (isFormValid: boolean) => void
  product: Pick<
    Product,
    | 'id'
    | 'name'
    | 'shortDescription'
    | 'sellerLink'
    | 'twitterLink'
    | 'instagramLink'
    | 'linkedInLink'
    | 'facebookLink'
    | 'youtubeLink'
    | 'shortDescription'
    | 'productDescription'
    | 'keyBenefits'
    | 'features'
    | 'pricing'
    | 'nameFile'
  > & {
    productEditRequests: Array<Pick<ProductEditRequest, 'id' | 'productFieldName' | 'updatedAt' | 'productFieldValue'>>
  }
}

const MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH = 120
const MAX_NAME_PRODUCT_LENGTH = 25

const ProductPageForm = (props: Props) => {
  const { product, onUpdate, onFormValidation } = props

  const [nameError, setNameError] = React.useState('')
  const [sellerLinkInit, setSellerLinkInit] = React.useState(false)
  const validateName = (name: string) => {
    if (name.length > MAX_NAME_PRODUCT_LENGTH) {
      setNameError(`Product name can't be longer than ${MAX_NAME_PRODUCT_LENGTH} characters`)
      return false
    }
    if (name.length === 0) {
      setNameError('Product name can not be empty')
      return false
    }
    setNameError('')
    return true
  }
  const [shortDescriptionError, setShortDescriptionError] = React.useState('')
  const validateShortDescription = (shortDescription: string) => {
    if (!shortDescription) {
      setShortDescriptionError(`Required field`)
      return false
    }
    if (shortDescription.length > MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH) {
      setShortDescriptionError(`Short description can't be longer than ${MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH} characters`)
      return false
    }
    setShortDescriptionError('')
    return true
  }
  const [sellerLinkError, setSellerLinkError] = React.useState('')
  const validateSellerLink = (sellerLink?: string | null) => {
    if (!sellerLink) {
      setSellerLinkError(`Required field`)
      return false
    }
    if (!utils.isURL(sellerLink)) {
      setSellerLinkError('Not a valid URL')
      return false
    }
    setSellerLinkError('')
    return true
  }

  React.useEffect(() => {
    const { name, shortDescription, sellerLink } = product
    const isFormValid = validateName(name) && validateSellerLink(sellerLink) && validateShortDescription(shortDescription)
    onFormValidation(isFormValid)
  }, [product, onFormValidation])

  const getEditRequest = (fieldName: ProductFieldName) => {
    return product.productEditRequests.find((request) => request.productFieldName === fieldName)
  }

  const getEditRequestAlert = (fieldName: ProductFieldName) => {
    const request = getEditRequest(fieldName)
    if (!request) return null
    return (
      <Alert severity="warning" sx={{ mt: 2 }}>
        This field has an update request pending created by you on {new Date(request.updatedAt).toLocaleString()}. If you make
        changes and save, the update request will be overwritten.
      </Alert>
    )
  }

  return (
    <>
      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">
            Product name (required) {product.name.length}/{MAX_NAME_PRODUCT_LENGTH}
          </b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="name"
          onChange={(e) => onUpdate({ ...product, name: e.target.value })}
          error={!!nameError}
          helperText={nameError}
          type="text"
          value={product.name}
        />
      </FormControl>

      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">URL for landing page (required)</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="sellerLink"
          placeholder="https://"
          onChange={(e) => onUpdate({ ...product, sellerLink: e.target.value })}
          onBlur={() => setSellerLinkInit(true)}
          error={!!sellerLinkError && sellerLinkInit}
          helperText={sellerLinkError}
          type="text"
          value={product.sellerLink}
        />
      </FormControl>

      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">Twitter profile URL</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="twitterLink"
          onChange={async (e) => onUpdate({ ...product, twitterLink: e.target.value })}
          type="text"
          value={product.twitterLink}
        />
      </FormControl>
      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">Instagram profile URL</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="instagramLink"
          onChange={async (e) => onUpdate({ ...product, instagramLink: e.target.value })}
          type="text"
          value={product.instagramLink}
        />
      </FormControl>
      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">LinkedIn profile URL</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="linkedInLink"
          onChange={async (e) => onUpdate({ ...product, linkedInLink: e.target.value })}
          type="text"
          value={product.linkedInLink}
        />
      </FormControl>

      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">Facebook profile URL</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="facebookLink"
          onChange={(e) => onUpdate({ ...product, facebookLink: e.target.value })}
          type="text"
          value={product.facebookLink}
        />
      </FormControl>

      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">Youtube profile URL</b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          id="youtubeLink"
          onChange={(e) => onUpdate({ ...product, youtubeLink: e.target.value })}
          type="text"
          value={product.youtubeLink}
        />
      </FormControl>

      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="registeredBusinessName">
          <b className="black2">
            Short description (required) {product.shortDescription.length}/{MAX_SHORT_DESCRIPTION_PRODUCT_LENGTH}
          </b>
        </InputLabel>
        <TextField
          fullWidth
          className="width100per spaceForm"
          size="small"
          classes={{ root: 'width100per' }}
          id="shortDescription"
          multiline
          maxRows="10"
          error={!!shortDescriptionError}
          helperText={shortDescriptionError}
          value={getEditRequest(ProductFieldName.ShortDescription)?.productFieldValue ?? product.shortDescription}
          onChange={(e) => onUpdate({ ...product, shortDescription: e.target.value })}
        />
        {getEditRequestAlert(ProductFieldName.ShortDescription)}
      </FormControl>

      <div>
        <h3>Description</h3>
        <EditField
          options={['inline', 'blockType', 'list']}
          text={getEditRequest(ProductFieldName.ProductDescription)?.productFieldValue ?? product.productDescription ?? ''}
          onChange={(productDescription) => onUpdate({ ...product, productDescription })}
        />
        {getEditRequestAlert(ProductFieldName.ProductDescription)}
      </div>

      <div>
        <h3>Key benefits</h3>
        <EditField
          options={['inline', 'blockType', 'list']}
          text={getEditRequest(ProductFieldName.KeyBenefits)?.productFieldValue ?? product.keyBenefits ?? ''}
          onChange={(keyBenefits) => onUpdate({ ...product, keyBenefits })}
        />
        {getEditRequestAlert(ProductFieldName.KeyBenefits)}
      </div>

      <div>
        <h3>Features</h3>
        <EditField
          options={['inline', 'blockType', 'list']}
          text={getEditRequest(ProductFieldName.Features)?.productFieldValue ?? product.features ?? ''}
          onChange={(features) => onUpdate({ ...product, features })}
        />
        {getEditRequestAlert(ProductFieldName.Features)}
      </div>

      <div>
        <h3>Pricing</h3>
        <EditField
          options={['inline', 'blockType', 'list']}
          text={getEditRequest(ProductFieldName.Pricing)?.productFieldValue ?? product.pricing ?? ''}
          onChange={(pricing) => onUpdate({ ...product, pricing })}
        />
        {getEditRequestAlert(ProductFieldName.Pricing)}
      </div>

      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <h4>Logo</h4>
          200x200px
          <UploadFile
            format="SQUARE"
            textDragNDrop="Drag 'n' drop a file here, or click to select file"
            deleteImageText="Delete image"
            nameFile={product.nameFile}
            onSelectFile={(nameFile) => onUpdate({ ...product, nameFile })}
          />
        </Paper>
      </div>
    </>
  )
}

export default ProductPageForm
