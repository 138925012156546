import React from 'react'
import { Button } from '@mui/material'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { Source } from '__generated__/graphql'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'

const MUTATION = gql(/* GraphQL */ `
  mutation updateSource_UpdateSource($data: SourceUpdateInput!, $where: SourceWhereUniqueInput!) {
    updateSource(data: $data, where: $where) {
      id
      nickname
    }
  }
`)

type Props = {
  onCancel: () => void
  onUpdate: () => void
  source: Pick<Source, 'id' | 'nickname'>
  disabled: boolean
}

const UpdateSource = (props: Props) => {
  const [loading, setLoading] = React.useState(false)
  const showErrors = useShowErrors()
  const [message, setMessage] = React.useState('')
  const [updateSource] = useMutation(MUTATION)

  const updateSourceF = async () => {
    setLoading(true)
    try {
      const data = await updateSource({
        variables: {
          data: {
            nickname: props.source.nickname,
          },
          where: {
            id: props.source.id,
          },
        },
      })
      if (data) {
        props.onUpdate()
      }
    } catch (e) {
      showErrors(e, setMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <Button onClick={props.onCancel}>Cancel</Button>{' '}
      <ButtonLoadingAfterClick
        variant="outlined"
        disabled={props.disabled}
        color="primary"
        onClick={updateSourceF}
        actionName="addAcceptEmailListEntries"
        id="idButtonApproveUpdateIssuedCard"
        icon=""
        size="medium"
        buttonText={<>Save Entries</>}
        buttonLoadingText="Loading..."
        loading={loading}
      />
      {message && (
        <div id="idMessage" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default UpdateSource
