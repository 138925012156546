import React from 'react'

export type KYCStep =
  | 'personalPhoneNumber'
  | 'personalAddress'
  | 'personalDetails'
  | 'companyType'
  | 'companyAddress'
  | 'companyDetails'
  | 'beneficiaryDetails'
  | 'leadershipDocuments'
  | 'leadershipIdNumber'
  | 'submitForm'
  | 'requestSent'
export interface KYCContextType {
  filledSteps: object
  currentStep: () => KYCStep
  nextStep: () => void
  previousStep: () => void
  setCurrentStep: (step: KYCStep, force?: boolean) => void
  getStepStatus: (step: KYCStep) => boolean
  reloadKYCState: () => void
}

export const contextInit: KYCContextType = {
  filledSteps: {},
  currentStep: () => 'personalAddress',
  nextStep: () => {},
  previousStep: () => {},
  setCurrentStep: () => {},
  getStepStatus: () => false,
  reloadKYCState: () => {},
}

export const KYCContext = React.createContext({ ...contextInit })

export const useKYCContext = (): KYCContextType => React.useContext(KYCContext)
