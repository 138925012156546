import React from 'react'
import { Link } from 'react-router-dom'
import { MenuItem, Icon, Grid } from '@mui/material'
import { useAppContext } from 'components/AppContext'
import { UserRoleCompanie } from '__generated__/graphql'

type Props = {
  userRoleCompanies: Array<
    Pick<UserRoleCompanie, 'id'> & {
      companie: Pick<UserRoleCompanie['companie'], 'id' | 'name' | 'typeCompanie'>
    }
  >
  onClose: () => void
}

const UserRoleCompanieMenuListX = (props: Props) => {
  const context = useAppContext()

  return (
    <>
      {props.userRoleCompanies.map((userRoleCompanie) => (
        <div key={userRoleCompanie.id}>
          <Link to={`/?companyContext=${userRoleCompanie.companie.id}`}>
            <MenuItem className="menuItemUserRoleCompanie" onClick={() => props.onClose()}>
              <Grid container>
                <Grid item xs={2}>
                  {context.userRoleCompanie?.companie.id === userRoleCompanie.companie.id && (
                    <Icon className="iconAlignTextBottom textSize11">done</Icon>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      width: 150,
                      overflow: 'hidden',
                    }}
                  >
                    {userRoleCompanie.companie.name}
                    {userRoleCompanie.companie.typeCompanie === 'BUYER' && (
                      <div className="primary textSize6">(Buyer Account)</div>
                    )}
                    {userRoleCompanie.companie.typeCompanie === 'SELLER' && (
                      <div className="primary textSize6">(Seller Station)</div>
                    )}
                    {userRoleCompanie.companie.typeCompanie === 'NN_ANALYST' && (
                      <div className="primary textSize6">(NN Analyst)</div>
                    )}
                    {userRoleCompanie.companie.typeCompanie === 'TRIBE' && (
                      <div className="primary textSize6">(Tribe Account)</div>
                    )}
                    {userRoleCompanie.companie.typeCompanie === 'REVIEWER' && (
                      <div className="primary textSize6">(Reviewer Account)</div>
                    )}
                    {userRoleCompanie.companie.typeCompanie === 'MAVEN' && (
                      <div className="primary textSize6">(Maven Account)</div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </MenuItem>
          </Link>
        </div>
      ))}
    </>
  )
}

export default UserRoleCompanieMenuListX
