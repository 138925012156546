import React from 'react'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import { gql } from '__generated__'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { useAppContextWithCompany } from 'components/AppContext'

const MUTATION = gql(/* GraphQL */ `
  mutation createInvoiceBuyerBasicPlus_CreateInvoiceBuyerBasicPlus($companieId: String!) {
    createInvoiceBuyerBasicPlus(companieId: $companieId) {
      id
    }
  }
`)

type Props = {
  companieId: string
  onInvoiceCreated: () => void
}

const CreateInvoiceBuyerBasicPlus = (props: Props) => {
  const context = useAppContextWithCompany()
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [createInvoiceBuyerBasicPlus] = useMutation(MUTATION)
  const [message, setMessage] = React.useState('')

  const createInvoiceBuyerBasicPlusF = async () => {
    setLoading(true)
    try {
      await createInvoiceBuyerBasicPlus({
        variables: {
          companieId: props.companieId,
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
      // return
    }
    setLoading(false)
    props.onInvoiceCreated()
    context.openSnackbar('Payment Successful', 'success')
    context.updateUserRoleCompanieViaQuery()
    // await client.resetStore()
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="createInvoiceBuyerBasicPlus"
        id="id-CreateInvoiceBuyerBasicPlus"
        disabled={false}
        icon=""
        size="medium"
        color="primary"
        variant="contained"
        buttonText="Pay now"
        buttonLoadingText="Setting up..."
        onClick={createInvoiceBuyerBasicPlusF}
        loading={loading}
      />
      <div id="idMessage-CreateInvoiceBuyerBasicPlus" className="secondary">
        {message}
      </div>
    </>
  )
}

export default CreateInvoiceBuyerBasicPlus
