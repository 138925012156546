import React from 'react'
import { SENTRY_DSN, URL_SERVER_GRAPHQL } from './config/config'
import ReactDOM from 'react-dom/client'
import { GOOGLE_TAG_MANAGER } from './config/config'
import './index.css'
import * as Sentry from '@sentry/react'
import AppProviders from 'components/AppProviders'
import TagManager from 'react-gtm-module'

declare module 'react-router-dom' {
  export function useParams<
    P extends Record<string, string> = {
      [key: string]: string
    },
  >(): P
}

const ENV = process.env.REACT_APP_ENV || 'local'

if (ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,

    // Integrations
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [URL_SERVER_GRAPHQL],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const gtmId = GOOGLE_TAG_MANAGER
const tagManagerArgs = { gtmId }
TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <AppProviders />
    </React.StrictMode>,
  )
}
