import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem } from '@mui/material'
import { Product } from '__generated__/graphql'
import UpdateProductLight from 'components/product/single/edit/UpdateProductLight'
import SelectFieldNN from 'components/ui/SelectFieldNN'
import { transformToFrontEndForm } from 'helpers/types'

type Props = {
  product: Pick<
    Product,
    | 'id'
    | 'averageMonthyRateRangeHigh'
    | 'averageMonthyRateRangeLow'
    | 'averageHourlyRate'
    | 'typicalProjectPriceRangeHigh'
    | 'typicalProjectPriceRangeLow'
  >
  onUpdate: () => void
}

const MavenPricingForm = (props: Props) => {
  const [product, setProduct] = React.useState(transformToFrontEndForm(props.product))
  const [avgHourlyRateChecked, setAvgHourlyRateChecked] = React.useState(true)
  const [monthlyRateChecked, setMonthlyRateChecked] = React.useState(true)
  const [projectPriceChecked, setProjectPriceChecked] = React.useState(true)

  const isValid = () => {
    const hasAvgHourlyRate = !avgHourlyRateChecked || product.averageHourlyRate
    const hasMonthlyRate =
      !monthlyRateChecked ||
      (product.averageMonthyRateRangeHigh &&
        product.averageMonthyRateRangeLow &&
        product.averageMonthyRateRangeHigh > product.averageMonthyRateRangeLow)
    const hasProjectPrice =
      !projectPriceChecked ||
      (product.typicalProjectPriceRangeHigh &&
        product.typicalProjectPriceRangeLow &&
        product.typicalProjectPriceRangeHigh > product.typicalProjectPriceRangeLow)
    const hasPricingType =
      hasAvgHourlyRate && hasMonthlyRate && hasProjectPrice && (avgHourlyRateChecked || monthlyRateChecked || projectPriceChecked)
    return hasPricingType
  }
  const priceRangeArray = [
    { value: 1, label: '< $25' },
    { value: 25, label: '$25 - $50' },
    { value: 50, label: '$50 - $100' },
    { value: 100, label: '$100 - $150' },
    { value: 150, label: '$150 - $200' },
    { value: 200, label: '$200 - $300' },
    { value: 300, label: '$300 - $400' },
    { value: 400, label: '$400 - $500' },
    { value: 500, label: '$500 - $600' },
    { value: 600, label: '> $600' },
  ]

  const projectPriceRangeArray = [
    { value: 1, label: '<$1k' },
    { value: 1000, label: '$1k' },
    { value: 2000, label: '$2k' },
    { value: 3000, label: '$3k' },
    { value: 4000, label: '$4k' },
    { value: 6000, label: '$6k' },
    { value: 8000, label: '$8k' },
    { value: 10000, label: '$10k' },
    { value: 12000, label: '$12k' },
    { value: 15000, label: '$15k' },
    { value: 18000, label: '$18k' },
    { value: 20000, label: '$20k' },
    { value: 25000, label: '$25k' },
    { value: 30000, label: '$30k' },
    { value: 40000, label: '$40k' },
    { value: 50000, label: '$50k' },
    { value: 60000, label: '$60k' },
    { value: 77000, label: '$75k' },
    { value: 100000, label: '$100k' },
    { value: 200000, label: '$200k' },
    { value: 300000, label: '> $200k' },
  ]
  return (
    <>
      <div id="averageHourlyRate">
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                value={avgHourlyRateChecked}
                onChange={(e) => {
                  setAvgHourlyRateChecked(e.target.checked)
                }}
              />
            }
            label="Hourly Rate"
          />
        </FormControl>

        {avgHourlyRateChecked && (
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <SelectFieldNN
              sx={{ minHeight: 'unset' }}
              label="Average hourly rate"
              id="averageHourlyRate"
              value={product.averageHourlyRate}
              onChange={(e) =>
                setProduct({
                  ...product,
                  averageHourlyRate: e.target.value,
                })
              }
            >
              {priceRangeArray.map((priceRange) => (
                <MenuItem key={priceRange.value} value={priceRange.value}>
                  {priceRange.label}
                </MenuItem>
              ))}
            </SelectFieldNN>
          </FormControl>
        )}
      </div>

      <div id="monthlyRate">
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                value={monthlyRateChecked}
                onChange={(e) => {
                  setMonthlyRateChecked(e.target.checked)
                }}
              />
            }
            label="Monthly Rate"
          />
        </FormControl>
        {monthlyRateChecked && (
          <>
            <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
              <SelectFieldNN
                sx={{ minHeight: 'unset' }}
                label="Average monthly rate: low"
                id="averageMonthlyRateLow"
                value={product.averageMonthyRateRangeLow}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    averageMonthyRateRangeLow: e.target.value,
                  })
                }
              >
                {projectPriceRangeArray.map((priceRange) => (
                  <MenuItem key={priceRange.value} value={priceRange.value}>
                    {priceRange.label}
                  </MenuItem>
                ))}
              </SelectFieldNN>
            </FormControl>
            <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
              <SelectFieldNN
                sx={{ minHeight: 'unset' }}
                label="Average monthly rate: high"
                id="averageMonthlyRateHigh"
                value={product.averageMonthyRateRangeHigh}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    averageMonthyRateRangeHigh: e.target.value,
                  })
                }
              >
                {projectPriceRangeArray.map((priceRange) => (
                  <MenuItem key={priceRange.value} value={priceRange.value}>
                    {priceRange.label}
                  </MenuItem>
                ))}
              </SelectFieldNN>
            </FormControl>
            {product.averageMonthyRateRangeHigh &&
              product.averageMonthyRateRangeLow &&
              product.averageMonthyRateRangeHigh < product.averageMonthyRateRangeLow && (
                <FormHelperText error>High cannot be lower than low</FormHelperText>
              )}
          </>
        )}
      </div>
      <div id="averageProjectPrice">
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                value={projectPriceChecked}
                onChange={(e) => {
                  setProjectPriceChecked(e.target.checked)
                }}
              />
            }
            label="Typical Project Price"
          />
        </FormControl>
        {projectPriceChecked && (
          <>
            <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
              <SelectFieldNN
                sx={{ minHeight: 'unset' }}
                label="Typical project price: low"
                id="typicalProjectPriceRangeLow"
                value={product.typicalProjectPriceRangeLow}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    typicalProjectPriceRangeLow: e.target.value,
                  })
                }
              >
                {projectPriceRangeArray.map((priceRange) => (
                  <MenuItem key={priceRange.value} value={priceRange.value}>
                    {priceRange.label}
                  </MenuItem>
                ))}
              </SelectFieldNN>
            </FormControl>
            <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
              <SelectFieldNN
                sx={{ minHeight: 'unset' }}
                label="Typical project price: high"
                id="typicalProjectPriceRangeHigh"
                value={product.typicalProjectPriceRangeHigh}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    typicalProjectPriceRangeHigh: e.target.value,
                  })
                }
              >
                {projectPriceRangeArray.map((priceRange) => (
                  <MenuItem key={priceRange.value} value={priceRange.value}>
                    {priceRange.label}
                  </MenuItem>
                ))}
              </SelectFieldNN>
            </FormControl>
            {product.typicalProjectPriceRangeLow &&
              product.typicalProjectPriceRangeHigh &&
              product.typicalProjectPriceRangeHigh < product.typicalProjectPriceRangeLow && (
                <FormHelperText error>High cannot be lower than low</FormHelperText>
              )}
          </>
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <UpdateProductLight
          buttonText="Continue"
          hideCancel
          fullWidth
          onCancel={() => {}}
          product={{
            id: props.product.id,
            averageHourlyRate: avgHourlyRateChecked ? product.averageHourlyRate : null,
            averageMonthyRateRangeHigh: monthlyRateChecked ? product.averageMonthyRateRangeHigh : null,
            averageMonthyRateRangeLow: monthlyRateChecked ? product.averageMonthyRateRangeLow : null,
            typicalProjectPriceRangeHigh: projectPriceChecked ? product.typicalProjectPriceRangeHigh : null,
            typicalProjectPriceRangeLow: projectPriceChecked ? product.typicalProjectPriceRangeLow : null,
          }}
          disabled={!isValid()}
          onSaved={() => {
            props.onUpdate && props.onUpdate()
          }}
        />
      </div>
    </>
  )
}
export default MavenPricingForm
