import React from 'react'
import { FormControl, InputLabel, TextField } from '@mui/material'
import utils from 'components/utils'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import DateComponentDropDown from 'components/nav/DateComponentDropDown'
import { useKYCContext } from './KYCContext'
import { useMutation } from '@apollo/client'
import useShowErrors from 'hooks/useShowErrors'
import { User, UserRoleCompanie } from '__generated__/graphql'
import { gql } from '__generated__'

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUserInContext_AdditionalDetailsForm($userRoleCompanieId: String!, $dataUser: UserUpdateInput!) {
    updateUserInContext(userRoleCompanieId: $userRoleCompanieId, dataUser: $dataUser) {
      id
      user {
        id
        linkedInLink
        dobYear
        dobMonth
        dobDay
      }
    }
  }
`)

type Props = {
  user: Pick<User, 'id' | 'linkedInLink' | 'dobYear' | 'dobMonth' | 'dobDay'>
  userRoleCompanie: Pick<UserRoleCompanie, 'id'>
  onUpdate: () => void
}

const AdditionalDetailsForm = (props: Props) => {
  const kycContext = useKYCContext()
  const showErrors = useShowErrors()
  const [user, setUser] = React.useState(props.user)
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() - 365 * 13)
  console.log(user)
  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 365 * 100)

  const [linkedInLinkInit, setLinkedInLinkInit] = React.useState(false)
  const [dobValid, setDobValid] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const linkedInLinkValid = () => {
    return (
      user.linkedInLink.length > 0 && utils.isURL(user.linkedInLink) && user.linkedInLink.toLowerCase().includes('linkedin.com')
    )
  }
  const isFormValid = () => {
    return linkedInLinkValid() && dobValid
  }

  const [updateUserContext] = useMutation(UPDATE_USER_MUTATION)
  const updateUserF = async () => {
    setLoading(true)
    try {
      await updateUserContext({
        variables: {
          userRoleCompanieId: props.userRoleCompanie.id,
          dataUser: {
            linkedInLink: user.linkedInLink,
            dobYear: user.dobYear,
            dobMonth: user.dobMonth,
            dobDay: user.dobDay,
          },
        },
      })
      props.onUpdate()
      kycContext.reloadKYCState()
    } catch (error) {
      showErrors(error, setErrorMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="titleStepKYC">Additional details</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div id="dobForm">
          <InputLabel shrink htmlFor="SSN">
            <b className="black2">Date of birth</b>
          </InputLabel>
          <DateComponentDropDown
            year={user.dobYear ? user.dobYear : undefined}
            month={user.dobMonth ? user.dobMonth : undefined}
            day={user.dobDay ? user.dobDay : undefined}
            onUpdateYear={(dobYear) => setUser({ ...user, dobYear })}
            onUpdateDay={(dobDay) => setUser({ ...user, dobDay })}
            onUpdateMonth={(dobMonth) => setUser({ ...user, dobMonth })}
            isValid={(isValid) => setDobValid(isValid)}
          />
        </div>

        <div id="linkedinProfile">
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="linkedinProfile">
              <b className="black2">Your LinkedIn profile</b>
            </InputLabel>
            <TextField
              id="linkedInLink"
              size="small"
              className="width100per spaceForm"
              placeholder="LinkedIn profile URL"
              error={!linkedInLinkValid() && linkedInLinkInit}
              onBlur={() => setLinkedInLinkInit(true)}
              onChange={(e) => {
                const newLinkedInLink = e.target.value
                setUser({
                  ...user,
                  linkedInLink: newLinkedInLink,
                })
              }}
              type="text"
              value={user.linkedInLink}
            />
          </FormControl>
        </div>

        <div style={{ marginTop: '20px' }}>
          <ButtonLoadingAfterClick
            actionName="updateUser"
            id="idButtonAdditionalDetails"
            disabled={!isFormValid()}
            icon=""
            size="medium"
            sx={{ width: '100%' }}
            color="primary"
            variant="contained"
            buttonText="Continue"
            buttonLoadingText="Setting up..."
            onClick={updateUserF}
            loading={loading}
          />
          <span className="secondary">{errorMessage}</span>{' '}
        </div>
      </div>
    </>
  )
}
export default AdditionalDetailsForm
