import React, { FC } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { mccList, MerchantCategoryCode } from './mccList'

type Props = {
  mcc: string | undefined | null
  onChangeMcc: (mcc: string) => void
}

const AutocompleteMcc: FC<Props> = (props: Props) => {
  const [categoryName, setCategoryName] = React.useState('')
  const [defaultValue] = React.useState<MerchantCategoryCode | undefined>(mccList.find((item) => item.mcc === props.mcc))

  return (
    <Autocomplete
      disablePortal
      id="suggested-mcc"
      size="small"
      className="width100per spaceForm"
      inputValue={categoryName}
      disableClearable={true}
      defaultValue={defaultValue}
      onInputChange={(_event, newValue) => {
        const mccItem = mccList.find((el) => el.category === newValue)
        if (mccItem) props.onChangeMcc(mccItem.mcc)
        else props.onChangeMcc('')
        setCategoryName(newValue)
      }}
      options={mccList}
      isOptionEqualToValue={(option, value) => option.category === value.category}
      getOptionLabel={(option) => option.category}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

export default AutocompleteMcc
