import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import Button, { ButtonProps } from '@mui/material/Button'
import useShowErrors from 'hooks/useShowErrors'
import { useAppContextWithCompany } from 'components/AppContext'
import { gql } from '__generated__'
import { Addresse } from '__generated__/graphql'
import { SxProps } from '@mui/material'

const MUTATION = gql(/* GraphQL */ `
  mutation deleteAddresse_DeleteAddresse($where: AddresseWhereUniqueInput!) {
    deleteAddresse(where: $where) {
      id
    }
  }
`)

type Props = {
  addresse: Pick<Addresse, 'id'>
  text: string
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
  size?: ButtonProps['size']
  sx?: SxProps
}

const DeleteAddresse: React.FC<Props> = (props: Props) => {
  const showErrors = useShowErrors()

  const context = useAppContextWithCompany()

  const [deleteAddresse] = useMutation(MUTATION)
  const client = useApolloClient()

  const deleteAddresseF = async () => {
    let addresse
    try {
      addresse = await deleteAddresse({
        variables: {
          where: {
            id: props.addresse.id,
          },
        },
      })
    } catch (error) {
      showErrors(error)
    }

    if (addresse) {
      context.openSnackbar('Deleted!', 'success')
      await client.resetStore()
    }
  }

  return (
    <Button sx={props.sx} size={props.size} variant={props.variant} color={props.color} onClick={deleteAddresseF}>
      {props.text}
    </Button>
  )
}

export default DeleteAddresse
