import React from 'react'
import { Box, Button, Dialog, DialogContent } from '@mui/material'
import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import useShowErrors from 'hooks/useShowErrors'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUserRoleCompanie_DismissGetStartedDialog(
    $data: UserRoleCompanieUpdateInput!
    $where: UserRoleCompanieWhereUniqueInput!
  ) {
    updateUserRoleCompanie(data: $data, where: $where) {
      id
      showSetupGuide
    }
  }
`)

type Props = {
  userRoleCompanieId: string
  open: boolean
  closeDialog: () => void
}

const DismissGetStartedDialog: React.FC<Props> = (props: Props) => {
  const showErrors = useShowErrors()

  const [updateUserRoleCompanieMutation] = useMutation(UPDATE_USER_MUTATION)
  const [loading, setLoading] = React.useState(false)

  const updateUserRoleCompanie = async () => {
    try {
      setLoading(true)
      await updateUserRoleCompanieMutation({
        variables: {
          where: { id: props.userRoleCompanieId },
          data: { showSetupGuide: false },
        },
      })
      props.closeDialog()
    } catch (e) {
      console.error(e)
      showErrors(e)
    }
    setLoading(false)
  }

  return (
    <Dialog open={props.open} maxWidth="sm" onClose={props.closeDialog}>
      <DialogContent>
        <h3>Get started with NachoNacho</h3>
        <Box className="fs-14">
          Are you sure you want to hide this tab from your sidebar? You will still be able to access this page from the settings
          page.
        </Box>

        <Box display="flex" justifyContent="center" gap="10px" mt="20px">
          <Button disabled={loading} onClick={props.closeDialog} variant="text">
            Cancel
          </Button>{' '}
          <ButtonLoadingAfterClick
            actionName="dismissGetStartedDialog"
            id="id-DismissGetStartedDialog"
            icon=""
            color="primary"
            disabled={false}
            variant="contained"
            size="medium"
            buttonText="Hide"
            buttonLoadingText="Loading..."
            onClick={updateUserRoleCompanie}
            loading={loading}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DismissGetStartedDialog
