import React from 'react'
import { USER_ROLE_COMPANIE } from 'config/config'
import { useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { useAppContext } from 'components/AppContext'
import { UserRoleCompanieContext } from 'components/Context.type'
import { useNavigate } from 'react-router-dom'
import { gql } from '__generated__'
import { GraphQLError } from 'graphql'
import { UserRoleCompanie } from '__generated__/graphql'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompanies_ChangeCompanieContext($where: UserRoleCompanieWhereInput!) {
    userRoleCompanies(where: $where) {
      id
      companieRole
      permissions
      companie {
        id
        name
        partnerSignupId
        ownerPartnerId
        typeCompanie
        statusVerification
      }
    }
  }
`)

const ChangeCompanieContext = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const client = useApolloClient()
  const context = useAppContext()

  const { me, userRoleCompanie: contextUserRoleCompanie, updateUserRoleCompanie, updateIsUserRoleCompanieLoading } = context

  const fetchUserRoleCompanies = React.useCallback(async (): Promise<UserRoleCompanieContext[]> => {
    const { data } = await client.query({
      query: QUERY,
      variables: {
        where: {
          userId: me.id,
          isInvitationApproved: true,
        },
      },
    })
    return data.userRoleCompanies
  }, [client, me?.id])

  const getLocalStorageUserRoleCompanie = React.useCallback(() => {
    let storedUserRoleCompanie: UserRoleCompanie | null = null
    try {
      const storedUserRoleCompanieString = localStorage.getItem(USER_ROLE_COMPANIE)
      if (storedUserRoleCompanieString) {
        storedUserRoleCompanie = JSON.parse(storedUserRoleCompanieString)
      }
    } catch (error) {
      console.log(error)
    }
    return storedUserRoleCompanie
  }, [])

  React.useEffect(() => {
    const changeCompanieContext = async () => {
      // user is not logged in
      if (!me?.id) return

      // get data from url and localstorage
      const searchParams = new URLSearchParams(location.search)
      const companyContext = searchParams.get('companyContext')
      const storedUserRoleCompanie = getLocalStorageUserRoleCompanie()

      // no change in context
      if (companyContext === storedUserRoleCompanie?.companie.id && companyContext === contextUserRoleCompanie?.companie.id)
        return

      const companieId = companyContext || storedUserRoleCompanie?.companie.id

      let userRoleCompanies: UserRoleCompanieContext[] = []
      try {
        userRoleCompanies = await fetchUserRoleCompanies()
      } catch (error) {
        // if user got disconnect due to inactivity, we don't do anything (he'll be redirected to login page)
        if (error instanceof GraphQLError && error.message === 'NOT_AUTH') return
      }

      const userRoleCompanie: UserRoleCompanieContext | null =
        userRoleCompanies.find(({ companie }) => companie.id === companieId) || userRoleCompanies[0] || null

      localStorage.setItem(USER_ROLE_COMPANIE, JSON.stringify(userRoleCompanie))
      searchParams.set('companyContext', userRoleCompanie?.companie.id || 'null')
      navigate({ search: searchParams.toString() }, { replace: true, state: location.state })
      updateUserRoleCompanie(userRoleCompanie)
      updateIsUserRoleCompanieLoading(false)
    }

    changeCompanieContext()
  }, [
    contextUserRoleCompanie?.companie.id,
    fetchUserRoleCompanies,
    getLocalStorageUserRoleCompanie,
    navigate,
    location.search,
    location.state,
    me?.id,
    updateIsUserRoleCompanieLoading,
    updateUserRoleCompanie,
  ])

  return null
}

export default ChangeCompanieContext
