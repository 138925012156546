export interface MerchantCategoryCode {
  category: string
  mcc: string
}

export const mccList: MerchantCategoryCode[] = [
  { category: 'Accounting/Bookkeeping Services', mcc: '8931' },
  { category: 'Advertising Services', mcc: '7311' },
  { category: 'Computer Network Services', mcc: '4816' },
  { category: 'Computer Programming', mcc: '7372' },
  { category: 'Computer Software Stores', mcc: '5734' },
  { category: 'Consulting, Public Relations', mcc: '7392' },
  { category: 'Digital Goods – Applications (Excludes Games)', mcc: '5817' },
  { category: 'Digital Goods Media – Books, Movies, Music', mcc: '5815' },
  { category: 'Direct Marketing - Other', mcc: '5969' },
  { category: 'Electrical Services', mcc: '1731' },
  { category: 'Employment/Temp Agencies', mcc: '7361' },
  { category: 'Financial Institutions', mcc: '6012' },
  { category: 'General Services', mcc: '1520' },
  { category: 'Government Services (Not Elsewhere Classified)', mcc: '9399' },
  { category: 'Hardware Stores', mcc: '5251' },
  { category: 'Legal Services, Attorneys', mcc: '8111' },
  { category: 'Medical Services', mcc: '8099' },
  { category: 'Miscellaneous Business Services', mcc: '7399' },
  { category: 'Miscellaneous General Merchandise', mcc: '5399' },
  { category: 'Miscellaneous General Services', mcc: '7299' },
  { category: 'Miscellaneous Recreation Services', mcc: '7999' },
  { category: 'Miscellaneous Repair Shops', mcc: '7699' },
  { category: 'Professional Services', mcc: '8999' },
  { category: 'Real Estate Agents and Managers - Rentals', mcc: '6513' },
  { category: 'Special Trade Services', mcc: '1799' },
  { category: 'Telecommunication Services', mcc: '4814' },
  { category: 'Utilities', mcc: '4900' },
]
