import React from 'react'
import { useLocation } from 'react-router-dom'
import CountUpFormatted from './CountUpFormatted'
import queryString from 'query-string'
import { Box, Grid, Theme, Tooltip, useMediaQuery } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAppContext } from 'components/AppContext'
import { IssuedCardStripe, Maybe } from '__generated__/graphql'

type Props = {
  issuedCard: {
    issuedCardStripe?: Maybe<Pick<IssuedCardStripe, 'number'>>
  }
  showCopyToClipboard?: boolean
}

const NumberCardFormat = (props: Props) => {
  const context = useAppContext()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const location = useLocation()
  const parsed = queryString.parse(location.search)
  const isNewCard = parsed.isNewCard === 'true'

  const number = props.issuedCard.issuedCardStripe?.number || ''

  const num1 = number.substring(0, 4)
  const num2 = number.substring(4, 8)
  const num3 = number.substring(8, 12)
  const num4 = number.substring(12, 16)
  const lastDigit = number.substring(15, 16)
  const enableCopy = lastDigit === '-' ? false : true
  const onCopy = () => {
    if (!enableCopy) return
    context.openSnackbar('Copied to clipboard', 'success', false, 2000)
  }

  const marginRight = isMobile ? 16 : 29
  if (!props.showCopyToClipboard) {
    return (
      <Grid container>
        <Grid style={{ marginRight }}>{num1}</Grid>
        <Grid style={{ marginRight }}>{num2}</Grid>
        <Grid style={{ marginRight }}>{num3}</Grid>
        <Grid>{num4}</Grid>
      </Grid>
    )
  }
  return (
    <div>
      <Box
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(20, 69, 254, 0.5)',
          },
        }}
        style={{ paddingTop: 4 }}
        className="cursor"
      >
        <Tooltip arrow placement="bottom" title={enableCopy ? `Copy the 16 digit number to clipboard.` : ''}>
          <div>
            <CopyToClipboard text={number} onCopy={onCopy}>
              <div>
                {isNewCard ? (
                  <Grid container>
                    <Grid style={{ marginRight }}>
                      {isNaN(Number(num1)) ? <>{num1}</> : <CountUpFormatted number={Number(num1)} />}
                    </Grid>
                    <Grid style={{ marginRight }}>
                      {isNaN(Number(num2)) ? <>{num2}</> : <CountUpFormatted number={Number(num2)} />}
                    </Grid>
                    <Grid style={{ marginRight }}>
                      {isNaN(Number(num3)) ? <>{num3}</> : <CountUpFormatted number={Number(num3)} />}
                    </Grid>
                    <Grid>{isNaN(Number(num4)) ? <>{num4}</> : <CountUpFormatted number={Number(num4)} />}</Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid style={{ marginRight }}>{num1}</Grid>
                    <Grid style={{ marginRight }}>{num2}</Grid>
                    <Grid style={{ marginRight }}>{num3}</Grid>
                    <Grid>{num4}</Grid>
                  </Grid>
                )}
              </div>
            </CopyToClipboard>
          </div>
        </Tooltip>
      </Box>
    </div>
  )
}

export default NumberCardFormat
