import React from 'react'
import Loading from '../../nav/error/Loading'
import NotFound from '../../nav/error/NotFound'
import Error from '../../nav/error/Error'
import { useAppContextWithCompany } from '../../AppContext'
import { useQuery } from '@apollo/client'
import AddAddresseContainer from '../../addresse/AddAddresseContainer'
import AddCard from './AddCard'
import { gql } from '__generated__'
import { Companie, TypeAddresse, User } from '__generated__/graphql'

const QUERY = gql(/* GraphQL */ `
  query addresses_AddCardAddressesCheck($where: AddresseWhereInput, $orderBy: AddresseOrderBy, $skip: Int, $first: Int) {
    addresses(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      type
      address1
      address2
      city
      zip
      state
      country
    }
  }
`)

type Props = {
  onCancel: () => void
  onSuccess: () => void
  companie: Pick<Companie, 'id' | 'requestCardVerification'>
  user: Pick<User, 'firstName' | 'lastName'>
}

const AddCardAddressesCheck = (props: Props) => {
  const context = useAppContextWithCompany()
  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: { companie: { id: props.companie.id }, type: TypeAddresse.PaymentSourceBillingAddress }, first: 1 },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.addresses) return <NotFound />

  if (!data.addresses) {
    return <Loading />
  }

  const addresses = data.addresses

  if (!addresses.length) {
    return (
      <div>
        <h2>Card setup</h2>
        <h4>Payment Source Billing Address is required before creating cards.</h4>
        <p>You only need to add your Billing address once - it will be saved for future cards.</p>
        <AddAddresseContainer
          companieId={props.companie.id}
          type={TypeAddresse.PaymentSourceBillingAddress}
          userId={context.me.id}
          onCancel={props.onCancel}
        />
      </div>
    )
  }

  return addresses[0] ? (
    <AddCard
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      addresse={addresses[0]}
      companie={props.companie}
      user={props.user}
    />
  ) : null
}

export default AddCardAddressesCheck
