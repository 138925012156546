import React from 'react'
import VerifyAuthDevicePhoneConfirmation from '../../../authDevice/single/VerifyAuthDevicePhoneConfirmation'
import RequestVerifyAuthDevicePhone from '../../../authDevice/single/RequestVerifyAuthDevicePhone'

type Props = {
  method: 'phone' | 'totp' | 'email'
  onCancel: () => void
}

const Check2FAPhone = (props: Props) => {
  const [mode, setMode] = React.useState('typeRequest')

  return (
    <>
      {mode === 'typeRequest' && (
        <RequestVerifyAuthDevicePhone method={props.method} onCancel={props.onCancel} onUpdate={() => setMode('confirmation')} />
      )}

      {mode === 'confirmation' && (
        <>
          <h3>Confirmation</h3>

          {props.method === 'email' && <p>Please check your emails for the 6-digit code that we just sent to you.</p>}
          {props.method === 'phone' && <p>Please check your phone for the 6-digit code that we just sent to you.</p>}

          <VerifyAuthDevicePhoneConfirmation onUpdate={() => {}} onCancel={props.onCancel} />
        </>
      )}
    </>
  )
}

export default Check2FAPhone
