import React from 'react'
import { COOKIE_OPTIONS, REFERRER } from '../config/config'
import cookie from 'cookie'

const Referrer = () => {
  React.useEffect(() => {
    const referrer = cookie.parse(document.cookie)[REFERRER]

    if (!referrer) {
      document.cookie = cookie.serialize(REFERRER, document.referrer, COOKIE_OPTIONS)
    }
  }, [])

  return null
}
export default Referrer
