import React from 'react'
import Button from '@mui/material/Button'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const REQUEST_VERIFY_AUTH_DEVICE_MUTATION = gql(/* GraphQL */ `
  mutation requestVerifyAuthDevice_RequestVerifyAuthDevicePhone($method: String!) {
    requestVerifyAuthDevice(method: $method) {
      id
    }
  }
`)

type Props = {
  method: string

  onUpdate: () => void
  onCancel: () => void
}

const RequestVerifyAuthDevicePhone = (props: Props) => {
  const showErrors = useShowErrors()
  const [requestVerifyAuthDevice] = useMutation(REQUEST_VERIFY_AUTH_DEVICE_MUTATION)

  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const requestVerifyAuthDeviceF = async () => {
    setLoading(true)
    let data
    try {
      data = await requestVerifyAuthDevice({
        variables: {
          method: props.method,
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
    if (data) {
      setLoading(false)
      props.onUpdate()
    }
  }
  return (
    <>
      <p>
        {props.method === 'phone' && `Send an SMS now to verify your account.`}
        {props.method === 'email' && `Send an Email now to verify your account.`}
      </p>
      <Button onClick={props.onCancel}>Cancel</Button>{' '}
      <ButtonLoadingAfterClick
        actionName="requestVerifyAuthDevicePhone"
        id="idButtonRequestVerifyAuthDevicePhone"
        disabled={false}
        icon=""
        size="medium"
        color="primary"
        variant="outlined"
        buttonText="Request Verification Code"
        buttonLoadingText="Setting up..."
        onClick={requestVerifyAuthDeviceF}
        loading={loading}
      />
      <div style={{ height: '10px' }} />
      <div className="secondary">{errorMessage}</div>
    </>
  )
}

export default RequestVerifyAuthDevicePhone
