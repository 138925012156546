import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from 'components/nav/error/Loading'
import Error from 'components/nav/error/Error'
import { gql } from '__generated__'
import { Box } from '@mui/material'
import AuthWithGoogleButton from 'components/user/auth/google/AuthWithGoogleButton'
import { TypeCreationApp } from '__generated__/graphql'
import GoogleAccountUsersInvitation from './GoogleAccountUsersInvitation'
import { GOOGLE_SCOPES } from 'components/application/tools/manageGoogle'

const GET_GOOGLE_APPLICATIONS_QUERY = gql(/* GraphQL */ `
  query userApplications_SuggestedInvitationsGoogle {
    userApplications(where: { typeApp: { equals: GOOGLE } }) {
      id
      scope
    }
  }
`)

type Props = {
  companieId: string
}

const GoogleInvitation: React.FC<Props> = (props: Props) => {
  const { loading, error, data } = useQuery(GET_GOOGLE_APPLICATIONS_QUERY)

  if (loading) return <Loading />
  if (error) return <Error message={error.message} />

  const application = data?.userApplications?.[0] ?? null

  const notConnected = (
    <Box>
      <h3>Invite via Google Workspace</h3>

      <p>Connect your Google Workspace account to see a list of team members.</p>

      <AuthWithGoogleButton
        id="id-NotConnectedPage"
        textButton="Invite with Google"
        typeCreationApp={TypeCreationApp.InviteContactsPage}
        scopes={['admin.directory.user']}
      />
    </Box>
  )

  if (!application) return notConnected

  const hasGoogleContactsScope = application.scope.split(' ').includes(GOOGLE_SCOPES['admin.directory.user'])

  if (!hasGoogleContactsScope) return notConnected

  return <GoogleAccountUsersInvitation companieId={props.companieId} />
}
export default GoogleInvitation
