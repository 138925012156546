import React from 'react'
import { useQuery } from '@apollo/client'
import NotFound from '../../nav/error/NotFound'
import Error from '../../nav/error/Error'
import Loading from '../../nav/error/Loading'
import FooterBuyer from './FooterBuyer'
import FooterBurger from './FooterBurger'
import { gql } from '__generated__'

const COMPANIE_QUERY = gql(/* GraphQL */ `
  query companie_Footer($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      typeCompanie
    }
  }
`)

type Props = {
  companieId: string
}

const Footer = (props: Props) => {
  const { loading, error, data } = useQuery(COMPANIE_QUERY, {
    variables: {
      where: { id: props.companieId },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.companie) return <NotFound />

  if (data.companie.typeCompanie === 'BUYER') return <FooterBuyer />
  if (data.companie.typeCompanie === 'SELLER') return <FooterBurger />
  if (data.companie.typeCompanie === 'MAVEN') return <FooterBurger />
  if (data.companie.typeCompanie === 'TRIBE') return <FooterBurger />
  if (data.companie.typeCompanie === 'NN_ANALYST') return <FooterBurger />
  return <FooterBurger />
}

export default Footer
