import React, { useState } from 'react'
import { CategorieProduct, CategorieProductOrderByInput, PositionProduct, Visibility } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { Box, Autocomplete, TextField } from '@mui/material'
import Error from 'components/nav/error/Error'
import useDebounce from 'hooks/useDebounce'

const QUERY = gql(/* GraphQL */ `
  query categorieProductsConnection_AddPositionProduct(
    $where: CategorieProductWhereInput
    $orderBy: CategorieProductOrderByInput
    $skip: Int
    $first: Int
  ) {
    categorieProductsConnection(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      categorieProducts {
        id
        name
      }
    }
  }
`)

type Props = {
  visibility: Visibility
  positionProductsSelected: Array<
    Pick<PositionProduct, 'id'> & {
      categorieProduct: Pick<CategorieProduct, 'id' | 'name'>
    }
  >
  onUpdate: (values: Props['positionProductsSelected']) => void
  error?: boolean
}

const AddPositionProduct = (props: Props) => {
  const [input, setInput] = useState('')
  const debouncedInput = useDebounce(input, 100)

  const notCategorieIds = props.positionProductsSelected.map((singlePositionProduct) => {
    return {
      id: singlePositionProduct.categorieProduct.id,
    }
  })

  const { error, data } = useQuery(QUERY, {
    variables: {
      first: 30,
      orderBy: CategorieProductOrderByInput.NameAsc,
      where: {
        visibility: { in: [props.visibility] },
        name: { contains: debouncedInput },
        NOT: notCategorieIds.length ? notCategorieIds : undefined,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />

  const dataAugmented = data?.categorieProductsConnection
    ? data.categorieProductsConnection.categorieProducts.map((categorieProduct) => {
        return {
          id: '',
          categorieProduct,
        }
      })
    : []

  return (
    <div>
      {/* <FormLabel sx={theme.formLabel}>Skills categories</FormLabel> */}
      <Box mt={1}>
        <div style={{ height: 20 }} />
        <Autocomplete
          multiple
          id="id-autocomplete"
          disableClearable={true}
          onChange={(_e, values) => {
            props.onUpdate(values)
          }}
          isOptionEqualToValue={(option, value) => option.categorieProduct.id === value.categorieProduct.id}
          options={dataAugmented}
          inputValue={input}
          onInputChange={(_e, newInputValue) => setInput(newInputValue)}
          getOptionLabel={(option) => option.categorieProduct.name}
          value={props.positionProductsSelected}
          renderInput={(params) => <TextField placeholder="Start typing..." {...params} error={props.error} />}
        />
      </Box>
      {/* <CategorieProductAutocomplete
          visibilityArray={[Visibility.Btobe, Visibility.Collection, Visibility.Launch, Visibility.Public, Visibility.Deal]}
          onClick={createPositionProductF}
          categorieProducts={props.categoriesProductsIdToExclude}
        /> */}
    </div>
  )
}

export default AddPositionProduct
