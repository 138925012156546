import React from 'react'
import { Grid, Divider, Chip } from '@mui/material/'
import { Product, Promotion } from '__generated__/graphql'
import ImageTemplate from '../../../nav/ImageTemplate'
import theme from 'components/themeNN'

type Props = {
  product: Pick<Product, 'id' | 'nameFile' | 'urlName' | 'name'> & {
    promotions: Array<Pick<Promotion, 'id' | 'text1'>>
  }
  onClick: (product: Props['product']) => void
  showPromotions?: boolean
}

const SingleProductLightAutocomplete = (props: Props) => {
  return (
    <div className="cursor">
      <div style={{ margin: '10px' }} className=" bgHover" onClick={() => props.onClick(props.product)}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <ImageTemplate format="verySmall" nameFile={props.product.nameFile} />
          </Grid>
          <Grid item>{props.product.name}</Grid>
          <Grid item>
            {props.showPromotions &&
              props.product.promotions.map((promotion) => (
                <Chip style={theme.promotionChip} key={promotion.id} label={promotion.text1} />
              ))}
          </Grid>
        </Grid>
      </div>
      <Divider />
    </div>
  )
}

export default SingleProductLightAutocomplete
