import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import AddresseForm from '../form/AddresseForm'
import DeleteAddresse from '../DeleteAddresse'
import { addressInit } from '../../addresse/Addresse.type'
import { useAppContextWithCompany } from 'components/AppContext'
import { gql } from '__generated__'
import { Addresse } from '__generated__/graphql'
import AddresseFormShort from '../form/AddresseFormShort'

const QUERY = gql(/* GraphQL */ `
  query addresses_OnboardingAddresses($where: AddresseWhereInput, $orderBy: AddresseOrderBy, $skip: Int, $first: Int) {
    addresses(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      type
      address1
      address2
      city
      zip
      state
      country
    }
  }
`)

type Props = {
  variables: any

  onCreate: () => any
  onCancel: () => any
  onUpdate: () => any
  short?: boolean
  userId: string
  companieId?: string
  productId?: string
  type: Addresse['type']
}

const OnboardingAddresses = (props: Props) => {
  const context = useAppContextWithCompany()
  const { loading, error, data } = useQuery(QUERY, {
    variables: props.variables,
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.addresses) return <NotFound />

  return (
    <>
      <div>
        {!data.addresses.length && (
          <>
            {props.short ? (
              <AddresseFormShort
                hideCancel
                textButtonUpdate="Continue"
                textButtonCreate="Continue"
                textButtonCancel="Back"
                onUpdate={() => {}}
                companieId={props.companieId}
                productId={props.productId}
                userId={props.userId}
                onCreate={() => props.onCreate()}
                onCancel={() => props.onCancel()}
                addresse={{
                  ...addressInit,
                  type: props.type,
                  country: 'US',
                }}
              />
            ) : (
              <AddresseForm
                hideCancel
                textButtonUpdate="Continue"
                textButtonCreate="Continue"
                textButtonCancel="Back"
                onUpdate={() => {}}
                companieId={props.companieId}
                productId={props.productId}
                userId={props.userId}
                onCreate={() => props.onCreate()}
                onCancel={() => props.onCancel()}
                addresse={{
                  ...addressInit,
                  type: props.type,
                  country: 'US',
                }}
              />
            )}
          </>
        )}
      </div>
      {data.addresses.map((addresse) =>
        addresse ? (
          <div key={addresse.id}>
            {context.me.role === 'ADMIN' && <DeleteAddresse text="Delete (admin)" addresse={addresse} />}

            {props.short ? (
              <AddresseFormShort
                hideCancel
                textButtonUpdate="Continue"
                textButtonCreate="Continue"
                textButtonCancel="Back"
                companieId={props.companieId}
                productId={props.productId}
                userId={props.userId}
                onCreate={() => {}}
                onUpdate={() => props.onUpdate()}
                onCancel={() => props.onCancel()}
                addresse={addresse}
              />
            ) : (
              <AddresseForm
                hideCancel
                textButtonUpdate="Continue"
                textButtonCreate="Continue"
                textButtonCancel="Back"
                companieId={props.companieId}
                productId={props.productId}
                userId={props.userId}
                onCreate={() => {}}
                onUpdate={() => props.onUpdate()}
                onCancel={() => props.onCancel()}
                addresse={addresse}
              />
            )}
          </div>
        ) : null,
      )}
    </>
  )
}

export default OnboardingAddresses
