import React from 'react'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import utils from 'components/utils'
import { ReactComponent as SuitcaseIcon } from 'assets/icon/suitcaseIcon.svg'
import { gql } from '__generated__'

const PRODUCT_QUERY = gql(/* GraphQL */ `
  query productPublic_ProductImagePublic($where: ProductWhereUniqueInput!) {
    productPublic(where: $where) {
      id
      name
      nameFile
    }
  }
`)

type Props = {
  productId: string
  style?: React.CSSProperties
  boxStyle?: React.CSSProperties
}

const ProductImagePublic = (props: Props) => {
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      where: {
        id: props.productId,
      },
    },
    skip: !props.productId,
  })

  const placeholderImage = (
    <div style={{ ...props.boxStyle }}>
      <div style={{ ...props.style, overflow: 'hidden' }}>
        <div
          style={{
            background: '#D3D3D3',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SuitcaseIcon />
        </div>
      </div>
    </div>
  )

  if (!props.productId) return placeholderImage
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return placeholderImage
  if (!data?.productPublic) return <NotFound />
  const product = data.productPublic

  return (
    <div style={props.boxStyle}>
      <img style={props.style} src={utils.getUrlFileMedia(product.nameFile)} alt={product.name} />
    </div>
  )
}
export default ProductImagePublic
