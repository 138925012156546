import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { useAppContext } from '../../AppContext'
import Button from '@mui/material/Button'
import VerifyPhoneForm from '../../user/single/phone/VerifyPhoneForm'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation verifyAuthDevice_VerifyAuthDevicePhoneConfirmation(
    $data: AuthDeviceUpdateInput!
    $where: AuthDeviceWhereUniqueInput!
  ) {
    verifyAuthDevice(data: $data, where: $where) {
      id
      deviceToken
      isVerified
      email
    }
  }
`)

type Props = {
  onUpdate: () => void
  onCancel: () => void
}

const VerifyAuthDevicePhoneConfirmation = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const [verifyAuthDevice] = useMutation(MUTATION)
  const context = useAppContext()

  const [message, setMessage] = React.useState('')

  const verifyAuthDeviceF = async (validationToken: string) => {
    let data
    try {
      data = await verifyAuthDevice({
        variables: {
          where: { id: context.authDevice.id },
          data: {
            validationToken,
          },
        },
      })
    } catch (e) {
      showErrors(e, setMessage)
    }
    if (data) {
      props.onUpdate()
      context.updateAuthDevice(data.data.verifyAuthDevice)
      await client.resetStore()
    }
  }

  return (
    <>
      <VerifyPhoneForm onVerify={(validationToken) => verifyAuthDeviceF(validationToken)} />
      <div style={{ height: '10px' }} />
      <div className="secondary">{message}</div>
      <div style={{ height: '10px' }} />
      <div>
        <Button onClick={props.onCancel}>Cancel</Button>
      </div>
    </>
  )
}

export default VerifyAuthDevicePhoneConfirmation
