import React from 'react'
import { Box, Button, Divider } from '@mui/material'
import { useApolloClient } from '@apollo/client'
import { SPOOFED_USER_ID, URL_FRONTEND_ADMIN, USER_ROLE_COMPANIE, USER_ROLE_COMPANIE_ADMIN_WHILE_SPOOFING } from 'config/config'
import { useAppContext } from 'components/AppContext'
import { useNavigate } from 'react-router-dom'
import { OpenInNew } from '@mui/icons-material'

const SpoofUserStatusAdmin: React.FC = () => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const context = useAppContext()

  const unSpoofUser = async () => {
    localStorage.setItem(SPOOFED_USER_ID, '')

    const userRoleCompanie = JSON.parse(localStorage.getItem(USER_ROLE_COMPANIE_ADMIN_WHILE_SPOOFING) || '{}')
    localStorage.setItem(USER_ROLE_COMPANIE, JSON.stringify(userRoleCompanie))

    navigate('/?companyContext=' + userRoleCompanie.companie.id)
    await client.resetStore()
  }

  const spoofedUserId = localStorage.getItem(SPOOFED_USER_ID)
  // if (context.me.role !== 'ADMIN') return null
  if (!spoofedUserId) return null
  return (
    <div>
      <Box display="flex" style={{ marginTop: 4, marginBottom: 4 }} gap={1} alignItems="center">
        <h1 style={{ marginLeft: 10, marginRight: 30, marginTop: 0, marginBottom: 0 }}>LoggedIn as: {context.me.firstName}</h1>
        <Button onClick={unSpoofUser} color="primary" variant="contained">
          Cancel
        </Button>
        <a href={`${URL_FRONTEND_ADMIN}`} target="_blank" rel="noreferrer">
          <Button startIcon={<OpenInNew />} color="primary" variant="outlined">
            Admin
          </Button>
        </a>
        <a href={`${URL_FRONTEND_ADMIN}/admin/user/${context.me.id}`} target="_blank" rel="noreferrer">
          <Button startIcon={<OpenInNew />} color="primary" variant="outlined">
            Admin User
          </Button>
        </a>
        {context.userRoleCompanie && (
          <a
            href={`${URL_FRONTEND_ADMIN}/admin/company/${context.userRoleCompanie.companie.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button startIcon={<OpenInNew />} color="primary" variant="outlined">
              Admin Company
            </Button>
          </a>
        )}
      </Box>
      <Divider />
    </div>
  )
}

export default SpoofUserStatusAdmin
