import React from 'react'
import SubApp from './SubApp'
import { AppContext, useAppContext } from './AppContext'
import MyMe from './MyMe'
import { Alert, Box, Icon, Snackbar } from '@mui/material'
import MyAuthDevice from './MyAuthDevice'
import Referrer from './Referrer'
import utils from './utils'
import { BrowserRouter } from 'react-router-dom'
import WarningPhoneNotVerifiedQuery from './user/single/phone/WarningPhoneNotVerifiedQuery'
import Footer from './nav/footer/Footer'
import EmailValidatedQuery from './nav/emailValidation/EmailValidatedQuery'
import ChangeCompanieContext from './user/auth/actionUrl/ChangeCompanieContext'
import DrawerLeft from './user/single/drawer/DrawerLeft'
import ListInvoicesNotPaid from './invoice/list/ListInvoicesNotPaid'
import RedirectPage from './nav/layout/RedirectPage'
import MyIdle from './MyIdle'
import HotJarComponent from './nav/HotJarComponent'
import ScrollToTop from './ScrollToTop'
import UserVerificationWarning from './user/single/userVerification/userVerificationWarning/UserVerificationWarning'
import DialogsProvider from './application/DialogsProvider'
import FreshChatWidget from './nav/chat/FreshChatWidget'
import ProfileWrapper from './profile/ProfileWrapper'
import SpoofUserStatusAdmin from './user/single/action/spoofUser/SpoofUserStatusAdmin'

const App = () => {
  const context = useAppContext()
  const { me, isMeLoading, userRoleCompanie, snackbar, closeSnackbar } = context

  const authState = utils.getAuthState(context)
  const unverified = !utils.getVerifiedState(context)

  return (
    <AppContext.Provider value={context}>
      {isMeLoading ? (
        <>...</>
      ) : (
        <BrowserRouter>
          <>
            {me?.id && authState !== 'deviceNotVerified' && <ChangeCompanieContext />}
            {me?.id && <RedirectPage />}

            <div className="components">
              {authState === 'loggedin' && <HotJarComponent />}
              {authState === 'loggedin' && <FreshChatWidget />}
              <ScrollToTop />

              <MyIdle />
              <SpoofUserStatusAdmin />

              <div className="root" style={{ display: 'flex', height: '100vh' }}>
                {authState === 'loggedin' && <DrawerLeft />}
                {authState === 'loggedin' && <DialogsProvider />}
                <main style={{ flexGrow: 1, width: 0, height: '100%', overflowY: 'scroll' }} className="bgSalmonGrey">
                  {authState === 'loggedin' && context.userRoleCompanie?.companie.typeCompanie === 'BUYER' && (
                    <ListInvoicesNotPaid />
                  )}
                  {authState === 'loggedin' && !unverified && <WarningPhoneNotVerifiedQuery />}
                  {authState === 'loggedin' && <EmailValidatedQuery userId={context.me.id} />}
                  {authState === 'loggedin' &&
                    context.userRoleCompanie?.companie &&
                    ['BUYER', 'SELLER', 'MAVEN'].includes(context.userRoleCompanie.companie.typeCompanie) && (
                      <UserVerificationWarning />
                    )}

                  <Box position="relative">
                    {authState === 'loggedin' && <ProfileWrapper />}

                    <SubApp />
                  </Box>
                </main>
              </div>
            </div>

            {authState === 'loggedin' && userRoleCompanie?.companie.id && <Footer companieId={userRoleCompanie.companie.id} />}
          </>
        </BrowserRouter>
      )}

      <MyMe />
      <Referrer />
      <MyAuthDevice />

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar.open}
        onClose={closeSnackbar}
        action={
          snackbar.showCloseIcon ? (
            <Icon className="cursor" onClick={closeSnackbar}>
              clear
            </Icon>
          ) : null
        }
      >
        <Alert severity={snackbar.type} onClose={snackbar.showCloseIcon ? closeSnackbar : undefined}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  )
}

export default App
