import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useAppContext } from '../AppContext'
import axios from 'axios'
import useShowErrors from 'hooks/useShowErrors'
import { useLazyQuery } from '@apollo/client'
import LinearProgress from '@mui/material/LinearProgress'
import { gql } from '__generated__'
import { File as FileGen } from '__generated__/graphql'

export type FileContainer = Pick<FileGen, 'nameFile' | 'type' | 'shortNameFile'>

type Props = {
  textDragNDrop: string
  fileSizeMax: number
  errorMessageFileNotPermited: string
  acceptedImageTypes: string[]
  privateFileType: string
  maxFiles: number
  onSelectFileContainer: (fileContainers: FileContainer[]) => void
}

const QUERY_SIGNED_URL = gql(/* GraphQL */ `
  query generatePostSignedUrl_UploadFilePrivate($data: [GeneratePostPresignedUrl!]!) {
    generatePostSignedUrl(data: $data) {
      id
      presignedurl
    }
  }
`)

const UploadFilePrivate = (props: Props) => {
  const context = useAppContext()
  const showErrors = useShowErrors()
  const [messages, setMessages] = React.useState<Array<string>>([])
  const [backendError, setBackendError] = React.useState('')
  const [filesMapping, setFilesMapping] = React.useState({})
  const [files, setFiles] = React.useState<Array<File>>([])
  const [loading, setLoading] = React.useState(false)

  const loadToServerInput = (presignedUrlData: { generatePostSignedUrl: string }) => {
    const presigned_url = JSON.parse(presignedUrlData.generatePostSignedUrl)
    const post_form = new FormData()
    for (const key in presigned_url.fields) {
      post_form.append(key, presigned_url.fields[key as keyof typeof presigned_url.fields])
    }
    return { url: presigned_url.url, form: post_form }
  }

  const uploadFileToServer = async (file: File, presignedUrlInput: { url: string; form: FormData }) => {
    presignedUrlInput.form.append(
      'tagging',
      `<Tagging><TagSet><Tag><Key>userId</Key><Value>${context.me.id}</Value></Tag></TagSet></Tagging>`,
    )
    presignedUrlInput.form.append('Content-Type', file.type)
    presignedUrlInput.form.append('file', file)
    try {
      await axios(presignedUrlInput.url, {
        method: 'POST',
        data: presignedUrlInput.form,
      })
      return true
    } catch (e) {
      showErrors(e, setBackendError)
      return false
    }
  }

  const [getAndConsumePresignedUrl] = useLazyQuery(QUERY_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      const uploaded: any = []
      await Promise.all(
        data.generatePostSignedUrl.map(async (response) => {
          const uploadedSuccessfully = await uploadFileToServer(
            filesMapping[response.id],
            loadToServerInput({ generatePostSignedUrl: response.presignedurl }),
          )
          const presigned_url = JSON.parse(response.presignedurl)
          if (uploadedSuccessfully)
            uploaded.push({
              name: filesMapping[response.id].name,
              type: filesMapping[response.id].type,
              key: presigned_url.fields.key,
            })
        }),
      )
      setLoading(false)
      props.onSelectFileContainer(
        uploaded.map((file) => {
          return {
            type: file.type,
            nameFile: file.key,
            shortNameFile: file.name,
          }
        }),
      )
    },
    onError: (err) => {
      showErrors(err, setBackendError)
    },
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setMessages([])
      const input: any = []
      const entries = filesMapping
      const filesToUpload: File[] = files
      const errors: Array<string> = []
      setLoading(true)
      if (acceptedFiles.length > props.maxFiles) {
        errors.push(`Max ${props.maxFiles} Files.`)
        setLoading(false)
        setMessages(errors)
        return
      }
      acceptedFiles.map((file) => {
        if (file.size > props.fileSizeMax * 1000000) {
          errors.push(`${file.name} : File is too big. Max ${props.fileSizeMax.toString()}Mbs`)
          return null
        }
        if (!props.acceptedImageTypes.includes(file.type)) {
          errors.push(`${file.name} : ${props.errorMessageFileNotPermited}`)
          return null
        }
        // set file name to lowercase to avoid case sensitive extension problem
        const fileName = file.name.toLowerCase()
        input.push({
          fileName: fileName,
          contentType: file.type,
          typeMedia: props.privateFileType,
          id: fileName,
        })
        entries[fileName] = file
        files.push(file)
        return null
      })
      setFilesMapping(entries)
      setFiles(filesToUpload)
      setMessages(errors)
      getAndConsumePresignedUrl({
        variables: { data: input },
      })
    },
    [props, setFilesMapping, getAndConsumePresignedUrl, setFiles, files, filesMapping],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div style={{ borderStyle: 'dashed', padding: '20px' }} className={isDragActive ? 'primaryBG' : ''}>
            {isDragActive ? <div>Drop the files here ...</div> : <div style={{ cursor: 'pointer' }}>{props.textDragNDrop}</div>}
          </div>
        </div>
      )}
      {messages.map((error, index) => (
        <p className="secondary" key={index}>
          {error}
        </p>
      ))}
      <p className="secondary">{backendError}</p>
    </>
  )
}

export default UploadFilePrivate
