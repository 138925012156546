import { AUTH_DEVICE, AUTH_FRONTEND_URL, env, EXTENSION_ID, SPOOFED_USER_ID, URL_FRONTEND, URL_HOME } from '../config/config'
import { URL_SERVER_MEDIA } from '../config/config'
import { bankStatesCA, bankStatesUS, bankCountries } from './addresse/bankAddress/BankAddress'
import { format, setHours, setSeconds, setMinutes } from 'date-fns'
import emailValidator from 'email-validator'
import { AuthState, Context } from './Context.type'
import {
  StatusStripe,
  TypeInvoice,
  StatusVerification,
  CustomLink,
  DiscoveryProductPaymentFrequency,
  Maybe,
  Partner,
  SignupType,
  SignupTypeUserRoleCompanie,
  StatusInvoice,
  TypeAddresse,
  TypeCreation,
  Invoice,
  TypeCompanie,
  StatusDownloadFile,
  TypeDownloadFile,
  CompanieRole,
  ChargeStatus,
  EntityType,
  WorkLocation,
  Product,
  EstimatedPriceUnit,
  EstimatedTimeToCompletionUnit,
  VisibilityProduct,
  DataSource,
  User,
  IssuedCard,
  Subscription,
  StatusSubscriptionManagement,
  PartnerFontStyle,
  StatusRedeemOffer,
  ProductsRelationshipApprovalStatus,
  WhereDidYouHearAboutUsSource,
} from '__generated__/graphql'
import Cookies from 'js-cookie'
import { NavigateFunction } from 'react-router-dom'
import { AuthDeviceCookie } from './authDevice/AuthDevice.type'
import { Permission } from '__generated__/graphql'
import { CategorieProduct } from './categorieProduct/list/multiSelector/context/CategorieProductSelectorContext'

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
        ? Subset<K[attr]> | null | undefined
        : K[attr]
}

const utils = {
  getFriendlyWhereDidYouHearAboutUsSource: (whereDidYouHearAboutUsSource: WhereDidYouHearAboutUsSource) => {
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Partner) return 'Partner'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Advertising) return 'Advertising'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Email) return 'Email'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Other) return 'Other'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Site) return 'Referring website'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.SocialMedia) return 'Social Media'
    if (whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.SearchEngine) return 'Google (or other search engine)'
    return ''
  },
  getFriendlyStatusRedeemOffer: (statusRedeemOffer: StatusRedeemOffer) => {
    if (statusRedeemOffer === StatusRedeemOffer.Interested) return 'Interested'
    if (statusRedeemOffer === StatusRedeemOffer.Redeemed) return 'Redeemed'
    if (statusRedeemOffer === StatusRedeemOffer.Requested) return 'Requested'
    return statusRedeemOffer
  },
  getEstimateSavingSmart: (product: Pick<Product, 'estimateSavingText' | 'estimatedSavingMax'>) => {
    if (product.estimateSavingText) return product.estimateSavingText
    return `Save up to ${utils.priceFormated(product.estimatedSavingMax, 'usd')}`
  },
  getFriendlyDiscoveryProductDataSource: (dataSource: DataSource) => {
    if (dataSource === DataSource.ChromeExtension) return 'NachoNacho Sidekick'
    if (dataSource === DataSource.Google) return 'Google Workspace'
    if (dataSource === DataSource.Quickbooks) return 'Quickbooks'
    if (dataSource === DataSource.Plaid) return 'Bank Accounts'
    if (dataSource === DataSource.DomainLookup) return 'Website analysis'
    return dataSource
  },
  getNameUrlProduct: (productName: string): string => {
    return productName
      .replace(/\s/g, '-')
      .toLowerCase()
      .replace(/[^a-zA-Z0-9-]/g, '-')
      .replace('--', '-')
      .replace('--', '-')
      .replace('--', '-')
      .replace('--', '-')
  },
  getUrlProductMarketplace: (product: Pick<Product, 'urlName' | 'visibility'>, source: string) => {
    if (product.visibility === VisibilityProduct.Maven) return `${URL_HOME}/maven/${product.urlName}?source=${source}`
    return `${URL_HOME}/product/${product.urlName}?source=${source}`
  },
  getEstimatedTimeToCompletionUnit: (estimatedTimeToCompletionUnit: EstimatedTimeToCompletionUnit): string => {
    if (estimatedTimeToCompletionUnit === EstimatedTimeToCompletionUnit.Days) return 'day'
    if (estimatedTimeToCompletionUnit === EstimatedTimeToCompletionUnit.Hours) return 'hour'
    if (estimatedTimeToCompletionUnit === EstimatedTimeToCompletionUnit.Months) return 'month'
    if (estimatedTimeToCompletionUnit === EstimatedTimeToCompletionUnit.Weeks) return 'week'
    return estimatedTimeToCompletionUnit
  },
  getEstimatedPriceUnit: (estimatedPriceUnit: EstimatedPriceUnit): string => {
    if (estimatedPriceUnit === EstimatedPriceUnit.Flat) return 'total'
    if (estimatedPriceUnit === EstimatedPriceUnit.Daily) return 'per day'
    if (estimatedPriceUnit === EstimatedPriceUnit.Hourly) return 'per hour'
    if (estimatedPriceUnit === EstimatedPriceUnit.Weekly) return 'per week'
    if (estimatedPriceUnit === EstimatedPriceUnit.Monthly) return 'per month'
    return estimatedPriceUnit
  },
  getLinkProductMarketplace: (isNNProduct: boolean, product: Pick<Product, 'id' | 'urlName' | 'visibility'>) => {
    if (isNNProduct) return `${URL_HOME}/userFeedback`
    if (product.visibility === 'MAVEN') return `${URL_HOME}/maven/${product.urlName}`
    return `${URL_HOME}/product/${product.urlName}`
  },

  getFriendlyNameEntityTypeProduct: (entityType: EntityType) => {
    if (entityType === EntityType.IndependantConsultant) return 'Independent Consultant'
    if (entityType === EntityType.Company) return 'Company'
    if (entityType === EntityType.Fractional) return 'Fractional'
    return entityType
  },
  getLoginPageLink: (): string => {
    const { pathname, search } = window.location
    const redirectUrl = `${URL_FRONTEND}${pathname === '/login' ? '' : pathname}${search}`
    const searchParams = new URLSearchParams({ redirectUrl })
    return `${AUTH_FRONTEND_URL}/login?${searchParams.toString()}`
  },

  getFriendlyNameIssuedCard: (cardName: string) => {
    if (cardName.length > 15) {
      return `${cardName.substring(0, 14)}...`
    }
    return cardName
  },
  getFriendlyNameFile: (nameFile: string) => {
    return nameFile.slice(17)
  },

  getSignupPartner: (partner: Pick<Partner, 'handle' | 'partnerType'>) => {
    if (partner.partnerType === 'TRIBE') {
      return `${URL_FRONTEND}/signup/tribe/${partner.handle}`
    }
    return `${URL_FRONTEND}/signup/partner/${partner.handle}`
  },

  getUrlDomainFromEmail: (email: string) => {
    const lastSign = email.lastIndexOf('@')
    if (lastSign === -1) return undefined
    return email.substring(lastSign + 1)
  },

  setChromeExtension: (deviceToken: string) => {
    window.chrome?.runtime?.sendMessage(EXTENSION_ID, {
      type: 'SET_AUTH_DEVICE_TOKEN',
      deviceToken,
      domain: window.location.hostname,
    })
  },

  getPhoneFormatted: (phoneCode: string, phone: string) => {
    if (phoneCode === '+1') {
      return `(+1) ${phone.substring(0, 3)} ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
    } else {
      return `(${phoneCode}) ${phone}`
    }
  },

  getFriendlyTypeCreationAdmin: (typeCreation: TypeCreation): string => {
    if (typeCreation === 'SIGNUP') return 'SIGNUP (Buyer self serve signup)'
    if (typeCreation === 'SIGNUP_REVIEWER') return 'SIGNUP_REVIEWER (self serve reviewer signup)'
    if (typeCreation === 'SELLER_INVITE_REVIWER') return 'SELLER_INVITE_REVIWER'
    if (typeCreation === 'SIGNUP_ADMIN') return 'SIGNUP_ADMIN (Admin Form)'
    if (typeCreation === 'SIGNUP_ADMIN_NACHOCARD') return 'SIGNUP_ADMIN_NACHOCARD (Admin Form w/ NachoCard)'
    if (typeCreation === 'SIGNUP_ADMIN_SELLER') return 'SIGNUP_ADMIN_SELLER (NN appAdmin invite seller)'
    if (typeCreation === 'SIGNUP_INVITE_SELLER') return 'SIGNUP_INVITE_SELLER (Temporarily disabled) related to showInviteSeller'
    if (typeCreation === 'SIGNUP_SELLER') return 'SIGNUP_SELLER (Seller self serve signup)'
    if (typeCreation === 'USER_SELLER_CREATION')
      return 'USER_SELLER_CREATION (Existing NN User (Buyer or seller) create seller account)'
    if (typeCreation === 'USER_AFFILIATE_CREATION') return 'USER_AFFILIATE_CREATION (Deprecated)'
    if (typeCreation === 'USER_INVITATION') return 'USER_INVITATION (Existing NN Buyer invites Buyer)'
    if (typeCreation === 'SIGNUP_PARTNER') return 'SIGNUP_PARTNER'
    if (typeCreation === 'USER_CREATION') return 'USER_CREATION (NN Buyer creates another Buyer Account)'
    if (typeCreation === 'CONTACTS_PAGE_EMAIL') return 'Contacts page (email)'
    if (typeCreation === 'CONTACTS_PAGE_GOOGLE_CONTACT') return 'Contacts page (Google contact)'
    if (typeCreation === 'SUBSCRIBE_TO_PRODUCT') return 'SUBSCRIBE_TO_PRODUCT (user clicked "subscribe" on marketplace)'
    return typeCreation
  },

  getFriendlySignupTypeAdmin: (signupType: SignupType): string => {
    if (signupType === 'MEMBER_INVITATION') return 'MEMBER_INVITATION (user inviting member)'
    if (signupType === 'MEMBER_INVITATION_SELLER') return 'MEMBER_INVITATION_SELLER'
    if (signupType === 'SELLERFORM') return 'SELLERFORM (User Self serve seller signup)'
    if (signupType === 'ADMINFORMSELLER') return 'ADMINFORMSELLER (NN appAdmin invites sellers)'
    if (signupType === 'ADMINFORM') return 'ADMINFORM (NN app admin invites buyer)'
    if (signupType === 'ADMINFORM_NACHOCARD') return 'ADMINFORM_NACHOCARD (NN app admin invites buyer w/ NachoCard)'
    if (signupType === 'USER_FORM_SELLER') return 'USER_FORM_SELLER (NN User invite External seller)'
    if (signupType === 'USERFORM') return 'USERFORM (NN User invite External buyer)'
    if (signupType === 'FORM') return 'FORM (Buyer Self serve signup)'
    if (signupType === 'FORM_NACHOCARD') return 'FORM_NACHOCARD (Buyer Self serve signup with NachoCard)'
    if (signupType === 'FORM_SLACK') return 'FORM_SLACK (FORM from SLACK)'
    if (signupType === 'FORM_GOOGLE') return 'FORM_GOOGLE (FORM from GOOGLE)'
    if (signupType === 'FORM_LINKEDIN') return 'FORM_LINKEDIN (FORM from LINKEDIN)'
    if (signupType === 'FORM_LINK_INVITATION') return 'FORM_LINK_INVITATION (FORM with a token in the URL to get the inviter)'
    if (signupType === 'FORM_TECHSOUP') return 'FORM_TECHSOUP (FORM via Techsoup) (DEPRECATED)'
    if (signupType === 'FORM_PARTNER') return 'FORM_PARTNER (FORM via Partner)'
    if (signupType === 'FORM_USER_REVIEW') return 'FORM_USER_REVIEW'
    if (signupType === 'REVIEWER') return 'REVIEWER'
    if (signupType === 'REVIEWER_LINK_INVITATION') return 'REVIEWER_LINK_INVITATION'
    if (signupType === 'CONTACTS_PAGE_EMAIL') return 'CONTACTS_PAGE_EMAIL (email)'
    if (signupType === 'CONTACTS_PAGE_GOOGLE_CONTACT') return 'CONTACTS_PAGE_GOOGLE_CONTACT (Google contact)'
    if (signupType === 'REVIEWER_ADMIN') return 'REVIEWER_ADMIN (Super Admin create a review)'
    if (signupType === 'MASTERMIND') return 'MASTERMIND (Mastermind sign up)'
    if (signupType === 'SUBSCRIBE_TO_PRODUCT') return 'SUBSCRIBE_TO_PRODUCT (user clicked "subscribe" on marketplace)'
    return signupType
  },
  getTypeDownloadFileFriendly: (typeDownloadFile: TypeDownloadFile): string => {
    if (typeDownloadFile === 'SELLER_INVOICES') return 'Payments'
    if (typeDownloadFile === 'BUYER_INVOICES') return 'Invoices'
    if (typeDownloadFile === 'BUYER_SUBSCRIPTIONS') return 'Subscriptions'
    if (typeDownloadFile === 'SELLER_SUBSCRIPTIONS') return 'Subscriptions'
    if (typeDownloadFile === 'SELLER_LEADS') return 'Leads'
    if (typeDownloadFile === 'BUYER_ISSUED_CARDS') return 'NachoCards'
    return typeDownloadFile
  },

  getFriendlyIssuedCardType: (type: IssuedCard['type']): string => {
    if (type === 'virtual') return 'Virtual Card'
    if (type === 'physical') return 'Physical Card'
    return ''
  },

  getValueObjectConnection: (id: string | null | undefined): undefined | null | string => {
    if (id) {
      if (id === 'NULL') return null
      return id
    }
    return undefined
  },

  getStatusDownloadFileFriendly: (status: StatusDownloadFile): string => {
    if (status === 'ERROR') return 'Error'
    if (status === 'FAILED') return 'Failed'
    if (status === 'PENDING') return 'Pending'
    if (status === 'SUCCEEDED') return 'Succeeded'
    return ''
  },

  getCompanieRoleTribeFriendly: (companieRole: CompanieRole): string => {
    if (companieRole === 'OWNER') return 'Tribe OWNER'
    if (companieRole === 'ADMIN') return 'Tribe ADMIN'

    return ''
  },
  getCompanieRoleSellerFriendly: (companieRole: CompanieRole): string => {
    if (companieRole === 'ANALYST') return 'PRODUCT ANALYST'
    if (companieRole === 'ADMIN') return 'PRODUCT ADMIN'
    if (companieRole === 'OWNER') return 'PRODUCT OWNER'

    return ''
  },
  getCompanieRoleMavenFriendly: (companieRole: CompanieRole): string => {
    if (companieRole === 'OWNER') return 'OWNER'
    if (companieRole === 'ADMIN') return 'TEAM MEMBER'

    return ''
  },
  getCompanieRoleBuyerFriendly: (companieRole: CompanieRole): string => {
    return companieRole
  },
  getCompanieRoleFriendlyContainer: (typeCompanie: TypeCompanie, companieRole: CompanieRole): string => {
    if (typeCompanie === 'BUYER') utils.getCompanieRoleBuyerFriendly(companieRole)
    if (typeCompanie === 'TRIBE') utils.getCompanieRoleTribeFriendly(companieRole)
    if (typeCompanie === 'MAVEN') utils.getCompanieRoleMavenFriendly(companieRole)
    if (typeCompanie === 'SELLER') utils.getCompanieRoleSellerFriendly(companieRole)
    return companieRole
  },

  getFriendlyLabel: (label: string): string => {
    if (label === 'questionUserAcquisitionChannelsDoYouUseToday') return `What user acquisition channels do you use today?`
    if (label === 'questionSizeCustomersTargeting') return `What size customers do you target?`
    if (label === 'questionHowGeographiesInterest') return `Which geographies do you target?`
    if (label === 'questionPricingPage') return `Your pricing page?`
    if (label === 'questionPayingCustomers') return `How many paying customers do you have?`
    if (label === 'questionHowManyUsersPerAccount') return `On average, how many users per customer?`
    return label
  },
  getFriendlySignupTypeUserRoleCompanie: (label: SignupTypeUserRoleCompanie): string => {
    if (label === 'INVITATION_BUYER_AS_TRIBE') return `Tribe > Member Co.`
    if (label === 'FORM_PARTNER') return `Tribe > Member Co.`
    if (label === 'INVITATION_PERK_PROVIDER_BY_TRIBE') return `Tribe > Deal Provider`
    if (label === 'INVITATION_PERK_PROVIDER_BY_TRIBE_LINK') return `Tribe > Deal Provider (Link)`
    if (label === 'MEMBER_INVITATION_EXISTING_USER') return `Buyer > Member`
    if (label === 'MEMBER_INVITATION') return `Buyer > Member`
    if (label === 'OWNERSHIP_INVITATION') return `Buyer > Member`
    if (label === 'OWNERSHIP_INVITATION_EXISTING_USER') return `Buyer > Member`
    if (label === 'MEMBER_INVITATION_SELLER') return `Seller > Member`
    if (label === 'MEMBER_INVITATION_SELLER_EXISTING_USER') return `Seller > Member`
    if (label === 'MEMBER_INVITATION_TRIBE') return `Tribe > Staff`
    if (label === 'MEMBER_INVITATION_TRIBE_EXISTING_USER') return `Tribe > Staff`
    if (label === 'USERFORM') return `User > Buyer`
    if (label === 'FORM') return `User > Buyer`
    if (label === 'REVIEWER') return `User > Reviewer`
    if (label === 'USER_FORM_SELLER') return `User > Seller`
    if (label === 'FORM_USER_REVIEW') return `Seller > Reviewer`
    if (label === 'ADMINFORM') return `SuperAdmin > Buyer`
    if (label === 'ADMINFORM_NACHOCARD') return `Admin NachoCard > Buyer`
    if (label === 'MEMBER_INVITATION_MAVEN') return `Maven > Member `
    if (label === 'MEMBER_INVITATION_MAVEN_EXISTING_USER') return `Maven > Member `
    if (label === 'INVITATION_MAVEN_SUPER_ADMIN') return `SuperAdmin > Maven `

    return label
  },

  getDisputeIssuingStatusStripe: (statusStripe: StatusStripe | undefined | null) => {
    if (statusStripe === 'lost') return 'Lost'
    if (statusStripe === 'expired') return 'Expired'
    if (statusStripe === 'unsubmitted') return 'Submitted'
    if (statusStripe === 'submitted') return 'Submitted'
    if (statusStripe === 'won') return 'Won'
    if (!statusStripe) return 'Dispute submission incomplete'
  },

  getFriendlyTypeSubscription: (typeSubscription: Subscription['typeSubscription']) => {
    if (typeSubscription === 'SUBSCRIPTION') return 'Subscription'
    if (typeSubscription === 'ONE_OFF') return 'One Off'

    return typeSubscription
  },

  getFriendlyTypePayment: (typePayment: Invoice['typePayment']) => {
    if (typePayment === 'REWARD') return 'Reward'

    return typePayment
  },

  getFriendlyWalletProvider: (walletProvider: string) => {
    if (walletProvider === 'apple_pay') return 'ApplePay'
    if (walletProvider === 'google_pay') return 'GooglePay'
    if (walletProvider === 'samsung_pay') return 'SamsungPay'

    return 'Unknown'
  },

  capitalizeFirstLetter: (string: string) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  getShortYear: (year: string) => {
    return year ? year.toString().substring(2) : ''
  },

  getFriendlyPaymentFrequency: (paymentFrequencyUserInput: Subscription['paymentFrequencyUserInput']) => {
    if (paymentFrequencyUserInput === 'EVERY_1_MONTHS') return 'Monthly'
    if (paymentFrequencyUserInput === 'EVERY_2_MONTHS') return 'Every 2 Months'
    if (paymentFrequencyUserInput === 'EVERY_3_MONTHS') return 'Quarterly'
    if (paymentFrequencyUserInput === 'EVERY_4_MONTHS') return 'Every 4 Months'
    if (paymentFrequencyUserInput === 'EVERY_6_MONTHS') return 'Semiannual'
    if (paymentFrequencyUserInput === 'EVERY_12_MONTHS') return 'Yearly'
    if (paymentFrequencyUserInput === 'EVERY_24_MONTHS') return 'Every 2 Years'

    return paymentFrequencyUserInput
  },

  getAnchorString: (anchor: CustomLink['anchor']) => {
    if (anchor === 'ABOUT') return 'About'
    if (anchor === 'PRICING') return 'Pricing'
    if (anchor === 'CONTACT') return 'Contact Vendor'
    if (anchor === 'SUPPORT') return 'Support'
    if (anchor === 'PRIVACY_POLICY') return 'Privacy Policy'
    if (anchor === 'TERMS_AND_CONDITIONS') return 'Terms and Conditions'
    if (anchor === 'FAQ') return 'FAQ'
    if (anchor === 'BLOG') return 'Blog'
    if (anchor === 'DOCUMENTATION') return 'Documentation'
    if (anchor === 'TERMS_OF_SERVICE') return 'Terms of Service'
    if (anchor === 'LOGIN') return 'Login'

    return anchor
  },

  smallSubscriptionId: (subscriptionId: string) => {
    return `${subscriptionId.substring(0, 4)}......${subscriptionId.substring(subscriptionId.length - 4, subscriptionId.length)}`
  },

  getAuthState: (context: Context): AuthState => {
    const { me: user, userRoleCompanie, authDevice, isMeLoading, isUserRoleCompanieLoading } = context

    if (!authDevice?.isVerified && user) {
      if (user.enabled2FATotp || user.enabled2FAPhone || user.enabled2FAEmail) {
        const spoofedUserId = localStorage.getItem(SPOOFED_USER_ID)
        if (!spoofedUserId) return 'deviceNotVerified'
      }
    }

    if (isMeLoading || isUserRoleCompanieLoading) return 'loading'

    if (!user?.id) return 'logout'

    if (!user.firstName || !user.lastName) return 'noName'

    if (userRoleCompanie?.companie?.id && userRoleCompanie.companie.name === '') return 'noCompanieName'

    if (!user.isEmailValidated && user.requestEmailValidatedOffSite) return 'emailNotVerified'

    if (!user.isPhoneValidated && user.isPhoneValidationRequired) return 'phoneNotVerified'

    if (userRoleCompanie?.companie.typeCompanie === 'BUYER') {
      if (userRoleCompanie?.companie.statusVerification === StatusVerification.PreDemoRequired) return 'preDemoRequired'
      if (userRoleCompanie?.companie.statusVerification === StatusVerification.PreDemoSubmited) return 'preDemoSubmited'
    }

    if (user.requestBirthdayOffSite && !user.dobYear && !user.dobMonth && !user.dobDay) return 'requestBirthdayOffSite'

    if (user.requestWhereDidyouHearAboutUs && !user.whereDidyouHearAboutUs) return 'requestWhereDidyouHearAboutUs'

    if (user.verificationStatus === 'REQUIRED') return 'loggedin'
    if (user.verificationStatus === 'NOT_APPROVED') return 'userVerificationSplash'
    if (user.verificationStatus === 'REVOKE_ACCESS') return 'userVerificationSplash'
    if (user.verificationStatus === 'BACKBURNER') return 'userVerificationSplash'
    if (user.verificationStatus === 'PENDING_ONBOARDING_CALL') return 'userVerificationSplash'

    if (!userRoleCompanie?.companie.id) return 'loggedin'

    if (userRoleCompanie.companie.statusVerification === 'APPROVED') return 'loggedin'
    if (userRoleCompanie.companie.statusVerification === 'REQUIRED') return 'loggedin'
    if (userRoleCompanie.companie.statusVerification === 'SUBMITED') return 'loggedin'
    if (userRoleCompanie.companie.statusVerification === 'NOT_APPROVED') return 'companiePageSplash'
    if (userRoleCompanie.companie.statusVerification === 'SUSPENDED_BY_USER') return 'companiePageSplash'
    if (userRoleCompanie.companie.statusVerification === 'SUSPENDED_BY_ADMIN') return 'companiePageSplash'
    if (userRoleCompanie.companie.statusVerification === 'BACKBURNER') return 'companiePageSplash'
    if (userRoleCompanie.companie.statusVerification === 'NOT_REQUIRED') return 'loggedin'

    return 'loggedin'
  },

  isJsonString: (str: string | undefined | null) => {
    if (str === undefined || str === null) return false

    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  },

  getISOStringDate(date: Date): string {
    return date.toISOString().split('T')[0]
  },

  twoDigits(number: number) {
    return ('0' + number).slice(-2)
  },

  getProductFrequency: (productFrequency: string): string => {
    if (productFrequency === 'SUBSCRIPTION') return 'Recurring'
    if (productFrequency === 'ONE_OFF') return 'One Off'
    return ''
  },

  getPlaidVerificationStatus: (verificationStatus: string): string => {
    if (verificationStatus === '') return '1. and 2. Instant Auth/Match'
    if (verificationStatus === 'pending_automatic_verification') return '3. Automated Micro-D: pending_automatic_verification'
    if (verificationStatus === 'automatic_verification') return '3. Automated Micro-D: automatic_verification'
    if (verificationStatus === 'pending_manual_verification') return '4. Same Day Micro-D: pending_manual_verification'
    if (verificationStatus === 'manually_verified') return '4. Same Day Micro-D: manually_verified'
    return verificationStatus
  },

  isURL(str: string | undefined) {
    if (!str) return false
    const regexp =
      /^((http(s)?:\/\/)?(www.|WWW.)?)((?!(www|WWW))[a-zA-Z0-9])+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,63}((?!.*(?:\/\+))[a-zA-Z\d/?=\-_#&+.:%]*)?(\.[a-zA-Z\d]*)?$/gm
    return Boolean(regexp.test(str))
  },

  getLabelState: (countryCode: string | undefined | null, stateCode: string | undefined | null) => {
    if (countryCode === 'US') {
      const stateObj = bankStatesUS.find((el) => el.code === stateCode)
      if (stateObj) {
        return stateObj.label
      } else {
        return stateCode
      }
    } else if (countryCode === 'CA') {
      const stateObj = bankStatesCA.find((el) => el.code === stateCode)
      if (stateObj) {
        return stateObj.label
      } else {
        return stateCode
      }
    } else {
      return stateCode
    }
  },

  getDomainCookie: () => {
    let domain = ''
    if (env === 'local') domain = 'localhost'
    if (env === 'dev') domain = '.nachonacho.co'
    if (env === 'production') domain = '.nachonacho.com'
    return domain
  },

  getAuthDeviceCookie: (): string | undefined => {
    return Cookies.get(AUTH_DEVICE)
  },

  setAuthDeviceCookie: (authDevices: AuthDeviceCookie[]) => {
    Cookies.remove(AUTH_DEVICE, { secure: true })
    const devices = [...authDevices]

    // make sure the cookie doesn't exceed 4kb
    while (encodeURI(JSON.stringify(devices)).length > 3800) {
      devices.shift()
    }

    Cookies.set(AUTH_DEVICE, JSON.stringify(devices), { secure: true, domain: utils.getDomainCookie(), expires: 10000 })
  },

  getFullAuthDeviceData: () => {
    const authDevices = utils.getParsedAuthDevicesFromCookie()
    const authDevice = authDevices.find((authDevice) => authDevice.isLoggedIn)

    return {
      id: authDevice?.id ?? '',
      deviceToken: authDevice?.deviceToken ?? '',
      isVerified: authDevice?.isVerified ?? false,
      timeOpened: new Date().toISOString(),
      timeZoneOffset: new Date().getTimezoneOffset() / 60,
      timeZone: utils.getUserTimeZone(),
      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: window.history.length,
      browserName: navigator.appName,
      browserEngine: navigator.product,
      browserVersion1a: navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      sizeScreenW: window.screen.width,
      sizeScreenH: window.screen.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: window.screen.availWidth,
      sizeAvailH: window.screen.availHeight,
      scrColorDepth: window.screen.colorDepth,
      scrPixelDepth: window.screen.pixelDepth,
    }
  },

  getParsedAuthDevicesFromCookie: (): AuthDeviceCookie[] => {
    const cookie = utils.getAuthDeviceCookie()
    if (!cookie) return []

    let authDevices: AuthDeviceCookie[] = []
    try {
      authDevices = JSON.parse(cookie)
    } catch (error) {
      console.error('Error parsing authDevices. Resetting cookie.')
      utils.setAuthDeviceCookie([])
    }
    return authDevices
  },

  getVerifiedState: (context: Context): boolean => {
    const { userRoleCompanie } = context
    if (!userRoleCompanie) return false
    return [StatusVerification.Approved].includes(userRoleCompanie.companie.statusVerification)
  },

  getSubmittedState: (context: Context): boolean => {
    const { userRoleCompanie } = context
    const isSubmitted = !!userRoleCompanie && userRoleCompanie.companie.statusVerification === 'SUBMITED'
    return isSubmitted
  },
  mapStatusVerificationName: (statusVerification: StatusVerification, typeCompanie: TypeCompanie) => {
    if (statusVerification === StatusVerification.Approved) return 'Approved'
    if (statusVerification === StatusVerification.Backburner) return 'Backburner'
    if (statusVerification === StatusVerification.NotApproved) return 'Not Approved'
    if (statusVerification === StatusVerification.NotRequired) {
      if (typeCompanie === 'BUYER') return 'Not Required'
      return 'KYC Not Required'
    }
    if (statusVerification === StatusVerification.PreDemoQualified) return 'PreDemo Qualified'
    if (statusVerification === StatusVerification.PreDemoRequired) return 'PreDemo Required'
    if (statusVerification === StatusVerification.PreDemoSubmited) return 'PreDemo Submited'
    if (statusVerification === StatusVerification.SuspendedByAdmin) return 'Suspended By Admin'
    if (statusVerification === StatusVerification.SuspendedByUser) return 'Suspended By User'
    if (statusVerification === StatusVerification.KycError) return 'KYC Error'
    if (statusVerification === StatusVerification.Submited) {
      if (typeCompanie === 'BUYER') return 'KYC Submitted'
      return 'Onboarding Submited'
    }
    if (statusVerification === StatusVerification.Required) {
      if (typeCompanie === 'BUYER') return 'KYC Required'
      return 'Onboarding Required'
    }
    return statusVerification
    // if ([StatusVerification.Required, StatusVerification.Submited].includes(statusVerification)) {
    //   return statusVerification + '_KYC'
    // }
    // return statusVerification
  },
  getNameOrEmail: (user: Pick<User, 'firstName' | 'lastName' | 'email'>, type: 'firstName' | 'both') => {
    if (type === 'firstName') {
      if (!user.firstName) {
        return user.email
      }
      return `${user.firstName}`
    }
    if (!user.firstName && !user.lastName) {
      return user.email
    }
    return `${user.firstName} ${user.lastName}`
  },

  getLabelCountry(countryCode: string | undefined | null) {
    if (!countryCode) return ''

    const countryObj = bankCountries.find((el) => el.code === countryCode)
    if (countryObj) {
      return countryObj.label
    } else {
      return countryCode
    }
  },

  padZerros: (element: number, size: number) => {
    let s = String(element)
    while (s.length < (size || 2)) {
      s = '0' + s
    }
    return s
  },

  getUrlFileMedia(urlFile: string) {
    if (!urlFile) return ''
    if (urlFile.substring(0, 4) === 'http') return urlFile
    if (urlFile.substring(0, 7) === '/static') return urlFile
    return URL_SERVER_MEDIA + '/' + urlFile
  },

  getUniversalLink(link: string) {
    if (!link) {
      return link
    }
    if (link.substring(0, 4) === 'http') {
      return link
    } else {
      return 'http://' + link
    }
  },

  mappingTypeInvoice: (typeInvoice: string | TypeInvoice, typeCompany?: string | Invoice['companyType'] | undefined): string => {
    if (typeInvoice === TypeInvoice.VirtualCard) return `Purchase`
    if (typeInvoice === TypeInvoice.NnAsReseller) return `Purchase`
    if (typeInvoice === TypeInvoice.ListingSellerFee) return `Seller Booster Fee`
    if (typeInvoice === TypeInvoice.RecuringPlatformFeesBasicPlus) return `Membership Fee - Basic Plus account`
    if (typeInvoice === TypeInvoice.ListingMavenFee) return `Maven Booster Fee`
    if (typeCompany === TypeCompanie.Tribe) {
      if (typeInvoice === TypeInvoice.RecuringPlatformFees) return `Tribe Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesPayedAnnuallyPromoCode) return `Tribe Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesTrial) return `Tribe Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesPromoCode) return `Tribe Fee`
    }
    if (typeCompany !== TypeCompanie.Tribe) {
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesPayedAnnuallyPromoCode) return `Membership Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFees) return `Membership Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesTrial) return `Membership Fee`
      if (typeInvoice === TypeInvoice.RecuringPlatformFeesPromoCode) return `Membership Fee`
    }
    if (typeInvoice === TypeInvoice.RecuringPlatformFeesTechSoup) return `Membership Fee`
    if (typeInvoice === TypeInvoice.CardVerificationMicroDeposits) return `Card Verification Fee`
    if (typeInvoice === TypeInvoice.TopUp) return `Topup`
    if (typeInvoice === TypeInvoice.AutoTopUp) return `Auto topup`
    if (typeInvoice === TypeInvoice.Refund) return `Refund`
    if (typeInvoice === TypeInvoice.RefundCashOut) return `Cashout`
    if (typeInvoice === TypeInvoice.PlatformFees) return `Platform Fees`
    if (typeInvoice === TypeInvoice.PhysicalCardFees) return `Physical NachoCard Fees`
    if (typeInvoice === TypeInvoice.SellerRevshare) return `Seller revshare`
    if (typeInvoice === TypeInvoice.CashbacknnCashOut) return `Redeem Cashback`
    if (typeInvoice === TypeInvoice.CashbackTransferToBalance) return `Cashback to Balance Transfer`
    if (typeInvoice === TypeInvoice.PostpaidPayment) return `Postpaid Repayment`
    if (typeInvoice === TypeInvoice.Affiliate) return `Purchase`

    return typeInvoice
  },

  mappingTypeInvoiceAdmin: (typeInvoice: TypeInvoice): string => {
    if (typeInvoice === 'VIRTUAL_CARD') return `Purchase`
    if (typeInvoice === 'RECURING_PLATFORM_FEES') return `Membership Fee`
    if (typeInvoice === 'RECURING_PLATFORM_FEES_TRIAL') return `Membership Fees Trial`
    if (typeInvoice === 'RECURING_PLATFORM_FEES_PROMO_CODE') return `User Fees Promo Code`
    if (typeInvoice === 'RECURING_PLATFORM_FEES_TECH_SOUP') return `User Fees TechSoup`
    if (typeInvoice === 'CARD_VERIFICATION_MICRO_DEPOSITS') return `Card Verification Fee`
    if (typeInvoice === 'TOP_UP') return `Topup`
    if (typeInvoice === 'AUTO_TOP_UP') return `Auto topup`
    if (typeInvoice === 'REFUND') return `Refund`
    if (typeInvoice === 'REFUND_CASH_OUT') return `Cashout`
    if (typeInvoice === 'PLATFORM_FEES') return `Platform Fees`
    if (typeInvoice === 'PHYSICAL_CARD_FEES') return `Physical NachoCard Fees`
    if (typeInvoice === 'SELLER_REVSHARE') return `Seller revshare`

    return typeInvoice
  },
  getFriendlyWorkLocationName: (data: WorkLocation | undefined | null) => {
    if (data === WorkLocation.Both) return 'Both in-person and remote'
    if (data === WorkLocation.Remote) return 'Remote'
    if (data === WorkLocation.InPerson) return 'In-person'
    return data
  },

  mappingCashbackStatus: (cashbackStatus: Invoice['cashbackStatus']): string => {
    if (cashbackStatus === 'AVAILABLE') return 'available'
    if (cashbackStatus === 'PENDING') return 'pending'
    return ''
  },

  mappingSpendCashbackStatus: (invoice: Pick<Invoice, 'status' | 'dateInvoice' | 'cashbackNNAmount'>): string => {
    if (!invoice.cashbackNNAmount) {
      return ''
    }

    if (invoice.status === StatusInvoice.Successful) {
      return new Date().getTime() - 30 * 24 * 60 * 60 * 1000 > new Date(invoice.dateInvoice).getTime() ? 'available' : 'pending'
    }

    return 'pending'
  },

  mappingRevshareStatus: (revshareStatus: Invoice['revshareStatus']): Maybe<string> | undefined => {
    if (revshareStatus === 'AVAILABLE') return 'Available'
    if (revshareStatus === 'PENDING') return 'Pending'
    return revshareStatus
  },

  mappingStatusInvoice: (statusInvoice: Invoice['status'] | StatusInvoice): string => {
    if (statusInvoice === StatusInvoice.Successful) return `Successful`
    if (statusInvoice === StatusInvoice.ErrorPayment) return `Payment error`
    if (statusInvoice === StatusInvoice.Pending) return `Approved`
    if (statusInvoice === StatusInvoice.TechnicalErrorPayment) return `Approved`
    if (statusInvoice === StatusInvoice.Error) return `Error`

    return statusInvoice
  },

  getInvoiceStatus: (invoice: Pick<Invoice, 'status' | 'type'> | Pick<Invoice, 'status' | 'type'>): string => {
    if (invoice.type === TypeInvoice.CashbacknnCashOut) {
      return invoice.status === StatusInvoice.Successful ? 'Successful' : 'Initiated'
    }
    if (invoice.status === 'PENDING') {
      const isTopUp = invoice.type === 'TOP_UP' || invoice.type === 'AUTO_TOP_UP'
      return isTopUp ? `Pending` : `Approved`
    }

    return utils.mappingStatusInvoice(invoice.status)
  },

  mappingStatusInvoiceSellerRevshare: (statusInvoice: Invoice['status'] | StatusInvoice): string => {
    if (statusInvoice === 'SUCCESSFUL') return `Successful`
    if (statusInvoice === 'ERROR_PAYMENT') return `Payment error`
    if (statusInvoice === 'PENDING') return `Pending`

    if (statusInvoice === 'ERROR') return `Error`

    return statusInvoice
  },
  mappingStatusInvoiceSellerFee: (statusInvoice: Invoice['status'] | StatusInvoice): string => {
    if (statusInvoice === StatusInvoice.Due) return `Payment Due`
    if (statusInvoice === 'ERROR_PAYMENT') return `Payment error`
    if (statusInvoice === 'PENDING') return `Pending`
    if (statusInvoice === 'ERROR') return `Error`
    if (statusInvoice === StatusInvoice.Successful) return `Successful`

    return statusInvoice
  },

  mappingStatusSubscriptionManagement: (status: StatusSubscriptionManagement): string => {
    if (status === 'PENDING') return 'Pending'
    if (status === 'APPROVED') return 'Approved'
    if (status === 'REJECTED') return 'Rejected'

    return status
  },

  mappingStatusIssuedCard: (status: IssuedCard['statusBox']): string => {
    if (status === 'canceled') return `Canceled`
    if (status === 'inactive') return `Suspended`
    if (status === 'expired') return `Expired`
    if (status === 'previewCard') return `Pending Activation`
    if (status === 'dateRestriction') return `Date restriction`
    if (status === 'requestPending') return `Request Pending`
    if (status === 'requestDeclined') return `Request Declined`
    if (status === 'pendingActivation') return `Pending activation`
    // if (status === 'active') return `Active`
    return ''
  },
  mappingPriceMonthly: (priceRange: number): string => {
    const projectPriceRangeArray = [
      { value: 1, label: '<$1k' },
      { value: 1000, label: '$1k' },
      { value: 2000, label: '$2k' },
      { value: 3000, label: '$3k' },
      { value: 4000, label: '$4k' },
      { value: 6000, label: '$6k' },
      { value: 8000, label: '$8k' },
      { value: 10000, label: '$10k' },
      { value: 12000, label: '$12k' },
      { value: 15000, label: '$15k' },
      { value: 18000, label: '$18k' },
      { value: 20000, label: '$20k' },
      { value: 25000, label: '$25k' },
      { value: 30000, label: '$30k' },
      { value: 40000, label: '$40k' },
      { value: 50000, label: '$50k' },
      { value: 60000, label: '$60k' },
      { value: 77000, label: '$75k' },
      { value: 100000, label: '$100k' },
      { value: 200000, label: '$200k' },
      { value: 300000, label: '> $200k' },
    ]
    return projectPriceRangeArray.find((el) => el.value === priceRange)?.label ?? ''
  },
  mappingPriceRangeHourly: (priceRange: number): string => {
    const priceRangeArray = [
      { value: 1, label: '< $25' },
      { value: 25, label: '$25 - $50' },
      { value: 50, label: '$50 - $100' },
      { value: 100, label: '$100 - $150' },
      { value: 150, label: '$150 - $200' },
      { value: 200, label: '$200 - $300' },
      { value: 300, label: '$300 - $400' },
      { value: 400, label: '$400 - $500' },
      { value: 500, label: '$500 - $600' },
      { value: 600, label: '> $600' },
    ]
    return priceRangeArray.find((el) => el.value === priceRange)?.label ?? ''
  },

  dateFormated(date: Date, formatString = 'MMM dd, yyyy') {
    return format(new Date(date), formatString)
  },

  priceFormatedUSDNoDecimals(priceToFormat: number) {
    if (priceToFormat === null) return '0'
    return (
      '$' +
      priceToFormat
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    )
  },

  priceFormated(priceToFormat: number | null | undefined, currency: string | null | undefined) {
    if (priceToFormat === null || priceToFormat === undefined) return '0'
    if (priceToFormat >= 0) {
      if (currency === 'usd') {
        return (
          '$' +
          priceToFormat
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        )
      }
      if (currency === 'jpy') {
        return (
          '¥' +
          (priceToFormat * 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        )
      }
      if (currency === 'eur') {
        return (
          priceToFormat
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '‎€'
        )
      }
      return (
        priceToFormat
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + currency
      )
    } else {
      if (currency === 'usd') {
        return (
          '-$' +
          (priceToFormat * -1)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        )
      }
      if (currency === 'jpy') {
        return (
          '-¥' +
          (priceToFormat * 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        )
      }
      if (currency === 'eur') {
        return (
          '-' +
          (priceToFormat * -1)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          '‎€'
        )
      }
      return (
        '-' +
        (priceToFormat * -1)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        currency
      )
    }
  },

  chargeStatusMapping(status: ChargeStatus | undefined): string {
    if (status === ChargeStatus.Successful) return 'succeeded'
    if (status === ChargeStatus.Error) return 'failed'
    if (status === ChargeStatus.Pending) return 'pending'

    return ''
  },

  smallIdFormat(smallId: number) {
    return this.padZerros(smallId, 12)
      .toString()
      .replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3')
  },

  numberCardFormat(numberCard: string) {
    return (
      numberCard.substring(0, 4) +
      ' ' +
      numberCard.substring(4, 8) +
      ' ' +
      numberCard.substring(8, 12) +
      ' ' +
      numberCard.substring(12, 16)
    )
  },

  getUserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : 'America/Los_Angeles'
  },

  makeid: (nbChar: number) => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.-_'
    for (let i = 0; i < nbChar; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
  },

  isValidDateString: (date: string | undefined): date is string => {
    if (!date) return false
    const regexValidate = new RegExp('^((19|2[0-1])[0-9]{2}-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2][0-9]|3[0-1]))$')
    return regexValidate.test(date)
  },

  removeTime(dateTime: Date | string) {
    const date = typeof dateTime === 'string' ? new Date(dateTime) : dateTime
    return setSeconds(setMinutes(setHours(date, 0), 0), 0)
  },

  generateInvitationLink: (
    inviter: { id: string; firstName: string; lastName: string },

    mode: string,
    signupHandle: string,
  ): string => {
    const nnCode = {
      invitedById: inviter.id,
      invitedByFirstName: inviter.firstName,
      invitedByLastName: inviter.lastName,
      mode,
    }
    const nnCodeJson = window.btoa(JSON.stringify(nnCode))

    const link = `${document.location.origin}/${signupHandle}?nnCode=${nnCodeJson}`

    return link
  },

  isValidEmail: (email: string): boolean => {
    return emailValidator.validate(email)
  },

  formatNumber: (numberToFormat: number): number => {
    if (!numberToFormat) {
      return 0
    }
    return Number(numberToFormat.toFixed(2))
  },

  isValidLinkedInUrl: (url: string): boolean => {
    const regex = /^(?:https?:\/\/)?(?:[\w]+\.)?linkedin\.com(?:\/in\/)?/i
    return regex.test(url)
  },
  isValidFacebookUrl: (url: string): boolean => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com/i
    return regex.test(url)
  },
  isValidTwitterUrl: (url: string): boolean => {
    const regex = /^(?:https?:\/\/)?(?:[A-z]+\.)?twitter\.com\/@?/i
    return regex.test(url)
  },
  isValidYoutubeUrl: (url: string): boolean => {
    const regex = /^(?:https?:\/\/)?(?:[A-z]+\.)?youtube.com\//i
    return regex.test(url)
  },
  isValidInstagramUrl: (url: string): boolean => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\//i
    return regex.test(url)
  },

  formatUrl: (url: string): string => {
    url = url.startsWith('http://') || url.startsWith('https://') ? url : 'https://' + url
    return url
  },

  convertStringOrderByExpressionToObject: (orderBy: string): object | undefined => {
    if (!orderBy) return undefined
    const orderByObject: any = {}
    const orderByKey = orderBy.split('_')[0]
    const orderByValue = orderBy.split('_')[1].toLowerCase()
    orderByObject[orderByKey] = orderByValue
    return orderByObject
  },

  formatPaymentFrequency: (paymentFrequency: Maybe<DiscoveryProductPaymentFrequency> | undefined): Maybe<string> | undefined => {
    if (paymentFrequency === DiscoveryProductPaymentFrequency.Annual) return 'Yearly'
    if (paymentFrequency === DiscoveryProductPaymentFrequency.Monthly) return 'Monthly'
    if (paymentFrequency === DiscoveryProductPaymentFrequency.OneTime) return 'One time'
    if (paymentFrequency === DiscoveryProductPaymentFrequency.Semiannual) return 'Semiannual'
    if (paymentFrequency === DiscoveryProductPaymentFrequency.Quarterly) return 'Quarterly'
    return paymentFrequency
  },

  hasPermissionToEditAddresse: (context: Context, type: TypeAddresse): boolean => {
    const isBillingAddress = type === TypeAddresse.Billing
    const canEditBillingAddress = context.userRoleCompanie?.permissions.includes(Permission.CanEditBillingAddress)

    if (isBillingAddress) return !!canEditBillingAddress
    return true
  },

  openActivateSubscriptionsDialog: (subscriptionId: string, navigate: NavigateFunction) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('showDialog', 'activateSubscription')
    searchParams.set('subscriptionId', subscriptionId)
    navigate({ search: searchParams.toString() })
  },
  // openCreateReviewDialog: (productId: string, navigate: NavigateFunction) => {
  //   const searchParams = new URLSearchParams(window.location.search)
  //   searchParams.set('showDialog', 'createReview')
  //   searchParams.set('productId', productId)
  //   navigate({ search: searchParams.toString() })
  // },
  openEditReviewDialog: (productId: string, navigate: NavigateFunction) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('showDialog', 'manageReview')
    searchParams.set('productId', productId)
    navigate({ search: searchParams.toString() })
  },

  openCreateReviewDialog: (navigate: NavigateFunction) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('showDialog', 'createReview')
    navigate({ search: searchParams.toString() })
  },

  sleep: (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),

  getWebsite: (url: string) => {
    if (!url) return ''
    if (url.substring(0, 7) === 'http://' || url.substring(0, 8) === 'https://') {
      return url
    }
    return `https://${url}`
  },

  relativeDate: (date: Date) => {
    const now = new Date()
    const providedDate = new Date(date)
    const diff = Math.round((now.getTime() - providedDate.getTime()) / 1000)

    const minute = 60
    const hour = minute * 60
    const day = hour * 24
    const week = day * 7
    const month = day * 30
    const year = month * 12

    if (diff < 30) return 'just now'
    if (diff < minute) return diff + ' seconds ago'
    if (diff < 2 * minute) return 'a minute ago'
    if (diff < hour) return Math.floor(diff / minute) + ' minutes ago'
    if (diff < 2 * hour) return 'an hour ago'
    if (diff < day) return Math.floor(diff / hour) + ' hours ago'
    if (diff < 2 * day) return 'yesterday'
    if (diff < week) return Math.floor(diff / day) + ' days ago'
    if (diff < 2 * week) return 'a week ago'
    if (diff < month) return Math.floor(diff / week) + ' weeks ago'
    if (diff < year) return Math.floor(diff / month) + ' months ago'
    return Math.floor(diff / year) + ' years ago'
  },

  arraysMatch: (arr1: string[], arr2: string[]): boolean => {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false

    // Sort both arrays
    arr1.sort()
    arr2.sort()

    // Check each element
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }

    // If all elements match
    return true
  },

  getCategorieProductTreeTransformers: () => {
    type CategorieProductTree = Array<
      CategorieProduct & {
        children?: CategorieProductTree
      }
    >

    const getTree = (flatData: CategorieProduct[]): CategorieProductTree => {
      const tree: CategorieProductTree = []

      const sortedItems = [...flatData]
      sortedItems.sort((a, b) => a.level - b.level)

      for (const item of sortedItems) {
        if (item.level === 1) {
          tree.push({ ...item, children: [] })
        } else if (item.level === 2) {
          const parent = tree.find(({ id }) => id === item.parentCategorieProductId)
          parent?.children?.push({ ...item, children: [] })
        } else if (item.level === 3) {
          const parent = tree.find(({ children }) => children?.some(({ id }) => id === item.parentCategorieProductId))
          const child = parent?.children?.find(({ id }) => id === item.parentCategorieProductId)
          child?.children?.push({ ...item })
        }
      }

      return tree
    }

    const getFlat = (treeData: CategorieProductTree): CategorieProduct[] => {
      const flatData: CategorieProduct[] = []

      const recursive = (treeData: CategorieProductTree) => {
        for (const item of treeData) {
          flatData.push(item)
          if (item.children) {
            recursive(item.children)
          }
        }
      }

      recursive(treeData)

      return flatData
    }

    return { getTree, getFlat }
  },
  getFriendlyNamePartnerFontStyle: (fontStyle: PartnerFontStyle) => {
    if (fontStyle === PartnerFontStyle.Arial) return 'Arial'
    if (fontStyle === PartnerFontStyle.Nachonacho) return 'NachoNacho'
    if (fontStyle === PartnerFontStyle.Roobert) return 'Roobert'

    return fontStyle
  },

  getCreateNachoCardCopy: (ctx: Context) => {
    if (!ctx.userRoleCompanie) return 'Create NachoCard'
    if (ctx.userRoleCompanie.companieRole === 'PURCHASER') return 'Request NachoCard'
    return 'Create NachoCard'
  },

  getFriendlyNameApprovalStatusFilter: (approvalStatus: ProductsRelationshipApprovalStatus) => {
    if (approvalStatus === ProductsRelationshipApprovalStatus.Approved) return 'Verified'
    if (approvalStatus === ProductsRelationshipApprovalStatus.Requested) return 'Unverified'
    if (approvalStatus === ProductsRelationshipApprovalStatus.Disregarded) return 'Removed'
    return approvalStatus
  },

  getFriendlyNameApprovalStatus: (approvalStatus: ProductsRelationshipApprovalStatus) => {
    if (approvalStatus === ProductsRelationshipApprovalStatus.Approved) return 'Verified'
    if (approvalStatus === ProductsRelationshipApprovalStatus.Requested) return 'Unverified'
    if (approvalStatus === ProductsRelationshipApprovalStatus.Disregarded) return 'Removed'
    return approvalStatus
  },
}

export default utils
