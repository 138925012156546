import React from 'react'
import ListInvoicesNotPaidQuery from './ListInvoicesNotPaidQuery'
import { useAppContextWithCompany } from '../../AppContext'
import { Permission } from '__generated__/graphql'

const ListInvoicesNotPaid = () => {
  const context = useAppContextWithCompany()

  if (!context.userRoleCompanie) {
    return null
  }

  if (!context.userRoleCompanie.permissions.includes(Permission.CanSeeInvoicesInCompanie)) {
    return null
  }

  return (
    <ListInvoicesNotPaidQuery
      companieId={context.userRoleCompanie.companie.id}
      variables={{
        where: {
          testMode: context.testMode,
          status: { equals: 'ERROR_PAYMENT' },
          type: { equals: 'VIRTUAL_CARD' },
          companie: {
            id: context.userRoleCompanie.companie.id,
          },
        },
      }}
    />
  )
}

export default ListInvoicesNotPaid
