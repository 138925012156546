import React from 'react'
import { Button } from '@mui/material'
import MetaTagsToUserRoleCompanieManagementAutocomplete from './MetaTagsToUserRoleCompanieManagementAutocomplete'
import UpdateCompanyToMetaTag from './UpdateCompanyToMetaTag'
import { MetaTag, TypeMetaTag } from '__generated__/graphql'
import MetaTagsToUserRoleCompanieManagementAutocompleteSingle from './MetaTagsToUserRoleCompanieManagementAutocompleteSingle'

type Props = {
  metaTags: Array<Pick<MetaTag, 'id' | 'name' | 'type'>>
  companieId?: string
  productId?: string
  userRoleCompanieId?: string
  onUpdate: () => void
  onCancel: () => void
  type: TypeMetaTag
  multiple: boolean
  buttonText?: string
  hideCancel?: boolean
  fullWidth?: boolean
  required?: boolean
}

const CompanyToMetaTagsManagementChild = (props: Props) => {
  const [selectedMetaTags, setSelectedMetaTags] = React.useState(props.metaTags)

  return (
    <div>
      {props.multiple ? (
        <MetaTagsToUserRoleCompanieManagementAutocomplete
          type={props.type}
          selectedMetaTags={selectedMetaTags}
          onChange={(selected) => {
            setSelectedMetaTags(selected)
          }}
        />
      ) : (
        <MetaTagsToUserRoleCompanieManagementAutocompleteSingle
          type={props.type}
          selectedMetaTags={selectedMetaTags}
          onChange={(selected) => {
            setSelectedMetaTags(selected)
          }}
        />
      )}
      <div style={{ height: 20 }} />
      {!props.hideCancel && <Button onClick={props.onCancel}>Cancel</Button>}{' '}
      <UpdateCompanyToMetaTag
        fullWidth={props.fullWidth}
        disabled={!!props.required && selectedMetaTags.length === 0}
        type={props.type}
        companieId={props.companieId}
        userRoleCompanieId={props.userRoleCompanieId}
        productId={props.productId}
        metaTagIds={selectedMetaTags.map((x) => x.id)}
        updateTextButton={props.buttonText ? props.buttonText : 'Update'}
        onUpdate={() => props.onUpdate()}
      />
    </div>
  )
}

export default CompanyToMetaTagsManagementChild
