import React, { createContext, useState, ReactNode } from 'react'
import { CategorieProduct as CategorieProductGen } from '__generated__/graphql'
import utils from 'components/utils'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'

export type CategorieProduct = Pick<CategorieProductGen, 'id' | 'name' | 'level' | 'hasChildren' | 'parentCategorieProductId'>
export type DialogMode = 'ADD' | 'EDIT'

const QUERY = gql(/* GraphQL */ `
  query categorieProductsFromProduct_CategorieProductSelectorProvider($productId: String!) {
    categorieProductsFromProduct(productId: $productId) {
      id
      name
      level
      hasChildren
      parentCategorieProductId
    }
  }
`)

const treeTransformers = utils.getCategorieProductTreeTransformers()

interface CategorieProductSelectorContextProps {
  // All the selected categories (will be saved with set in the DB)
  selectedCategorieProducts: CategorieProduct[]
  setSelectedCategorieProducts: (categorieProducts: CategorieProduct[]) => void

  // The current categorie product level 1 (the one that is being edited)
  currentCategorieProductLevel1: CategorieProduct | null
  setCurrentCategorieProductLevel1: (categorieProduct: CategorieProduct | null) => void

  // Add and remove categories
  addCategorieProductsToSelection: (categorieProducts: CategorieProduct[]) => CategorieProduct[]
  removeCategorieProductFromSelection: (categorieProduct: CategorieProduct) => CategorieProduct[]
  removeCategorieProductsFromSelection: (categorieProducts: CategorieProduct[]) => CategorieProduct[]

  // Backend data
  savedCategorieProducts: CategorieProduct[]
  refetchSavedCategorieProducts: () => Promise<unknown>
}

export const CategorieProductSelectorContext = createContext<CategorieProductSelectorContextProps | undefined>(undefined)

export const CategorieProductSelectorProvider: React.FC<{ children: ReactNode; productId: string }> = ({
  children,
  productId,
}) => {
  // States
  const [selectedCategorieProducts, setSelectedCategorieProducts] = useState<CategorieProduct[]>([])
  const [currentCategorieProductLevel1, setCurrentCategorieProductLevel1] = useState<CategorieProduct | null>(null)

  // Receives a list of category products and adds them to the selected ones
  const addCategorieProductsToSelection = React.useCallback(
    (categorieProducts: CategorieProduct[]) => {
      const selected = [...selectedCategorieProducts]
      for (const categorieProduct of categorieProducts) {
        const isAlreadySelected = selectedCategorieProducts.some((cp) => cp.id === categorieProduct.id)
        if (!isAlreadySelected) selected.push(categorieProduct)
      }
      setSelectedCategorieProducts(selected)
      return selected
    },
    [selectedCategorieProducts],
  )

  // Receives a category product and removes it and all its children
  const removeCategorieProductFromSelection = React.useCallback(
    (categorieProduct: CategorieProduct) => {
      // If it doesn't have children, just remove it
      if (!categorieProduct.hasChildren) {
        const newSelected = selectedCategorieProducts.filter((cp) => cp.id !== categorieProduct.id)
        setSelectedCategorieProducts(newSelected)
        return newSelected
      }

      let tree = treeTransformers.getTree(selectedCategorieProducts)

      // Otherwise, remove it and all its children
      if (categorieProduct.level === 1) {
        tree = tree.filter((cp) => cp.id !== categorieProduct.id)
      } else if (categorieProduct.level === 2) {
        tree = tree.map((cp) => {
          if (cp.id === categorieProduct.parentCategorieProductId) {
            cp.children = cp.children?.filter((cp) => cp.id !== categorieProduct.id) || []
          }
          return cp
        })
      }

      const newSelected = treeTransformers.getFlat(tree)
      setSelectedCategorieProducts(newSelected)

      return newSelected
    },
    [selectedCategorieProducts],
  )

  // Removes multiple categorie products from the selection
  const removeCategorieProductsFromSelection = React.useCallback(
    (categorieProducts: CategorieProduct[]) => {
      const newSelected = selectedCategorieProducts.filter((cp) => !categorieProducts.some((cp2) => cp2.id === cp.id))
      setSelectedCategorieProducts(newSelected)
      return newSelected
    },
    [selectedCategorieProducts],
  )

  // Get saved categorie products in the backend
  const { data, refetch } = useQuery(QUERY, {
    variables: { productId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSelectedCategorieProducts(data.categorieProductsFromProduct)
    },
  })

  return (
    <CategorieProductSelectorContext.Provider
      value={{
        selectedCategorieProducts,
        setSelectedCategorieProducts,
        currentCategorieProductLevel1,
        setCurrentCategorieProductLevel1,
        addCategorieProductsToSelection,
        removeCategorieProductFromSelection,
        removeCategorieProductsFromSelection,
        savedCategorieProducts: data?.categorieProductsFromProduct || [],
        refetchSavedCategorieProducts: refetch,
      }}
    >
      {children}
    </CategorieProductSelectorContext.Provider>
  )
}
