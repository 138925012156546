import React from 'react'
import { Paper } from '@mui/material'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import WarningAction from 'components/subscription/single/action/WarningAction'
import NotFound from 'components/nav/error/NotFound'
import NotAuth from 'components/nav/error/NotAuth'
import Loading from 'components/nav/error/Loading'
import { gql } from '__generated__'
import { useAppContext } from 'components/AppContext'

const USER_QUERY = gql(/* GraphQL */ `
  query user_WarningPhoneNotVerifiedQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      isPhoneValidated
      isPhoneValidationRequired
    }
  }
`)

const WarningPhoneNotVerifiedQuery: React.FC = () => {
  const { me } = useAppContext()
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: { where: { id: me.id } },
  })

  if (error) return <NotAuth />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  const user = data.user

  if (user.isPhoneValidated) return null
  if (!user.isPhoneValidationRequired) return null

  return (
    <div className="paperOut">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn" style={{ backgroundColor: '#1445FE' }}>
          <WarningAction
            message="Please verify your phone number"
            actionText="Verify now!"
            shwowActionButton={true}
            onClick={() => {
              navigate(`/settings/user?mode=profile&isEditMode=true`)
            }}
          />
        </Paper>
      </div>
    </div>
  )
}

export default WarningPhoneNotVerifiedQuery
