import React from 'react'
import AddresseForm from './form/AddresseForm'
import { addressInit } from '../addresse/Addresse.type'
import { Addresse } from '__generated__/graphql'

type Props = {
  onCancel: () => void
  onCreate?: () => void
  onUpdate?: () => void
  userId: string
  companieId?: string
  productId?: string
  type: Addresse['type']
}

const AddAddresseContainer = (props: Props) => {
  return (
    <AddresseForm
      textButtonUpdate="Update"
      textButtonCreate="Save"
      textButtonCancel="Cancel"
      onCreate={() => {
        props.onCreate && props.onCreate()
      }}
      onCancel={props.onCancel}
      userId={props.userId}
      onUpdate={() => {
        props.onUpdate && props.onUpdate()
      }}
      companieId={props.companieId}
      productId={props.productId}
      addresse={{
        ...addressInit,
        type: props.type,
        country: 'US',
      }}
    />
  )
}

export default AddAddresseContainer
