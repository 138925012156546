import { Box, Button, Chip, Paper } from '@mui/material'
import themeNN from 'components/themeNN'
import React, { useState } from 'react'
import CategorieProductsSelectorDialog from './CategorieProductsSelectorDialog'
import PencilEdit from 'components/nav/PencilEdit'
import utils from 'components/utils'
import { DialogMode, type CategorieProduct } from '../context/CategorieProductSelectorContext'
import { useCategorieProductSelector } from '../context/useCategorieProductSelector'

type Props = {
  title: string
  productId: string
}

const CategorieProductMultiSelectorWrapper: React.FC<Props> = ({ title, productId }) => {
  const { setCurrentCategorieProductLevel1, savedCategorieProducts } = useCategorieProductSelector()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogMode, setDialogMode] = useState<DialogMode>('ADD')

  const categoriesTree = React.useMemo(() => {
    const { getTree } = utils.getCategorieProductTreeTransformers()
    return getTree(savedCategorieProducts)
  }, [savedCategorieProducts])

  const openDialog = (categorieProduct: CategorieProduct | null) => {
    setCurrentCategorieProductLevel1(categorieProduct)
    setDialogMode(categorieProduct ? 'EDIT' : 'ADD')
    setIsDialogOpen(true)
  }

  return (
    <Box className="paperOut">
      <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
        <h4 className="m-0" style={{ marginBottom: '16px' }}>
          {title}
        </h4>

        <Box bgcolor="white" p="16px" pt="8px" borderRadius="8px">
          <Box display="flex" gap="16px" justifyContent="space-between" alignItems="center">
            <h3 className="text-black fs-14 ff-roobert fw-700">Categories</h3>
            <Button variant="outlined" onClick={() => openDialog(null)} size="small">
              Add category
            </Button>
          </Box>

          {categoriesTree.map((categorieProductLevel1) => (
            <Box
              key={categorieProductLevel1.id}
              sx={{
                bgcolor: themeNN.greyBackground.color,
                padding: '16px',
                borderRadius: '8px',
                my: '8px',
              }}
            >
              <Box display="flex" gap="12px">
                <h3 className="text-black fs-14 ff-roobert fw-700">{categorieProductLevel1.name}</h3>
                <PencilEdit id="categorie-product-multi-selector" onClick={() => openDialog(categorieProductLevel1)} />
              </Box>

              {categorieProductLevel1.children?.map((categorieProductLevel2) => (
                <Box key={categorieProductLevel2.id} mb="16px">
                  <Box className="fs-14 ff-roobert text-light-grey-1" sx={{ mb: '8px' }}>
                    {categorieProductLevel2.name}
                  </Box>

                  <Box display="flex" gap="8px" flexWrap="wrap">
                    {categorieProductLevel2.children?.map((categorieProductLevel3) => (
                      <Chip
                        key={categorieProductLevel3.id}
                        label={categorieProductLevel3.name}
                        variant="outlined"
                        color="default"
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}

          <CategorieProductsSelectorDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            mode={dialogMode}
            productId={productId}
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default CategorieProductMultiSelectorWrapper
