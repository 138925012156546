import React from 'react'
import { issuedCardInit } from '../../IssuedCard.type'
import NotFound from '../../../nav/error/NotFound'
import Error from '../../../nav/error/Error'
import queryString from 'query-string'
import { useQuery } from '@apollo/client'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import IssuedCardDesign from '../design/IssuedCardDesign'
import { useAppContextWithCompany } from 'components/AppContext'
import { Button, FormControl, Icon, InputAdornment, InputLabel, TextField, Theme, useMediaQuery } from '@mui/material'
import UserTeamSelect from 'components/user/list/team/UserTeamSelect'
import AutocompleteProductsIssuedCard from 'components/product/list/autocomplete/AutocompleteProductsIssuedCard'
import {
  AuthorizedAmountUnitType,
  IssuedCardTypeCreation,
  Product,
  StatusBox,
  TypeAddresse,
  TypeIssuedCard,
  IssuedCard as IssuedCardGen,
} from '__generated__/graphql'
import CreateIssuedCard from './CreateIssuedCard'
import ShippingAddressesIssuedCard from 'components/addresse/list/ShippingAddressesIssuedCard'
import CreatePhyisicalIssuedCardSummary from './CreatePhyisicalIssuedCardSummary'
import UserDobForm from 'components/user/single/profile/sectionDetails/UserDobForm'
import UserName from 'components/nav/layout/titlePage/UserName'
import CardTypeSelect from './CardTypeSelect'
import AddAddresseContainer from 'components/addresse/AddAddresseContainer'
import RadioSelectNN from 'components/ui/RadioSelectNN'
import { gql } from '__generated__'
import { Permission } from '__generated__/graphql'
import HeaderRightSideBar from 'components/application/HeaderRightSideBar'
import utils from 'components/utils'

const COMPANIE_QUERY = gql(/* GraphQL */ `
  query companie_CreateIssuedCardLogic($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      isPersonal
      canCreatePhysicalIssuedCard
    }
  }
`)
const STATUS_QUERY = gql(/* GraphQL */ `
  query getStatusToCreateIssueCard_CreateIssuedCardLogicCondition(
    $companieId: String!
    $userId: String!
    $type: TypeIssuedCard!
  ) {
    getStatusToCreateIssueCard(companieId: $companieId, userId: $userId, type: $type)
  }
`)

const PRODUCT_QUERY = gql(/* GraphQL */ `
  query productSimple_AutocompleteProductsIssuedCard($where: ProductWhereUniqueInput!) {
    productSimple(where: $where) {
      id
      name
      nameFile
    }
  }
`)

type IssuedCard = React.ComponentProps<typeof CreatePhyisicalIssuedCardSummary>['issuedCard'] &
  Pick<IssuedCardGen, 'type'> & {
    initProduct: Pick<Product, 'id' | 'name'> | null
  }

const CreateIssuedCardForm = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const location = useLocation()
  const navigate = useNavigate()
  const context = useAppContextWithCompany()
  const parsed = queryString.parse(location.search)
  const issuedCardName = parsed.issuedCardName ? (parsed.issuedCardName as string) : ''
  const [issuedCard, setIssuedCard] = React.useState<IssuedCard>({
    ...issuedCardInit,
    name: issuedCardName,
    initProduct: null,
  })
  const [step, setStep] = React.useState('cardForm')
  const [firstLoad, setFirstLoad] = React.useState(true)
  const productId: string = parsed.dialogProductId as string
  const cardType = parsed.cardType ? parsed.cardType : 'virtual'
  const disableNameChange = Boolean(parsed.disableNameChange)
  const discoveryProductId = parsed.discoveryProductId ? (parsed.discoveryProductId as string) : undefined
  const userId: string = ['ADMIN', 'OWNER'].includes(context.userRoleCompanie.companieRole)
    ? (parsed.selectUserId as string)
    : context.me.id
  const companieQuery = useQuery(COMPANIE_QUERY, {
    variables: {
      where: {
        id: context.userRoleCompanie.companie.id,
      },
    },
  })

  const statusPhysicalQuery = useQuery(STATUS_QUERY, {
    variables: {
      userId: context.me.id,
      companieId: context.userRoleCompanie.companie.id,
      type: TypeIssuedCard.Physical,
    },
    fetchPolicy: 'network-only',
  })
  const statusVirtualQuery = useQuery(STATUS_QUERY, {
    variables: {
      userId: context.me.id,
      companieId: context.userRoleCompanie.companie.id,
      type: TypeIssuedCard.Virtual,
    },
    fetchPolicy: 'network-only',
  })
  const productQuery = useQuery(PRODUCT_QUERY, {
    variables: {
      where: {
        id: productId,
      },
    },
    skip: !productId,
  })

  if (companieQuery.error)
    return <Error message={companieQuery.error.graphQLErrors.length > 0 ? companieQuery.error.graphQLErrors[0].message : ''} />
  if (companieQuery.loading) return <></>
  if (!companieQuery.data?.companie) return <NotFound />
  const companie = companieQuery.data.companie

  if (statusPhysicalQuery.error)
    return (
      <Error
        message={statusPhysicalQuery.error.graphQLErrors.length > 0 ? statusPhysicalQuery.error.graphQLErrors[0].message : ''}
      />
    )
  if (statusPhysicalQuery.loading) return <></>
  if (!statusPhysicalQuery.data?.getStatusToCreateIssueCard) return <NotFound />
  const statusPHysical = statusPhysicalQuery.data?.getStatusToCreateIssueCard

  if (statusVirtualQuery.error)
    return (
      <Error
        message={statusVirtualQuery.error.graphQLErrors.length > 0 ? statusVirtualQuery.error.graphQLErrors[0].message : ''}
      />
    )
  if (statusVirtualQuery.loading) return <></>
  if (!statusVirtualQuery.data?.getStatusToCreateIssueCard) return <NotFound />
  const statusVirtual = statusVirtualQuery.data?.getStatusToCreateIssueCard

  if (productId && productQuery?.data?.productSimple && firstLoad) {
    const product = productQuery?.data?.productSimple
    setFirstLoad(false)
    setIssuedCard({ ...issuedCard, name: product?.name, initProduct: product })
  }

  const onChange = (name: string) => {
    setIssuedCard({ ...issuedCard, name, initProduct: null })
    const parsed = queryString.parse(location.search)
    delete parsed.dialogProductId
    navigate('?' + queryString.stringify(parsed))
  }

  const onSelect = (product: Product | null) => {
    const parsed = queryString.parse(location.search)
    const updatedIssuedCard = { ...issuedCard, initProduct: product }
    if (product) {
      updatedIssuedCard.name = product.name
      parsed.dialogProductId = product.id
      navigate('?' + queryString.stringify(parsed))
    } else {
      if (parsed.dialogProductId) {
        delete parsed.dialogProductId
        navigate('?' + queryString.stringify(parsed))
      }
    }
    setIssuedCard(updatedIssuedCard)
  }

  const closeDialog = (issuedCardId?: string) => {
    console.log(issuedCardId)
    delete parsed.showDialog
    delete parsed.dialogProductId
    // if it is an issue card creation in the context of expense discovery
    // we only close the Dialogue, no need to redirect.
    if (issuedCardId && !discoveryProductId) {
      navigate(`/issuedCard/${issuedCardId}?isNewCard=true`)
    } else {
      navigate('?' + queryString.stringify(parsed))
    }
  }

  const isFormValide = () => {
    const isValid = issuedCard.authorizedAmountUnit === AuthorizedAmountUnitType.None || issuedCard.authorizedAmount
    const isNameValid = issuedCard.name.length >= 3
    if (!isValid || !userId || !isNameValid) return false
    return true
  }

  const getStatus = () => {
    if (cardType === 'virtual') {
      return statusVirtual
    } else {
      return statusPHysical
    }
  }

  return (
    <>
      <HeaderRightSideBar title={utils.getCreateNachoCardCopy(context)} closeDialog={closeDialog} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            height: isMobile ? 200 : 270,
            width: isMobile ? 320 : 440,
            transform: cardType === 'physical' ? 'rotateY(180deg)' : '',
            position: 'relative',
            transition: 'transform 0.8s',
            transformStyle: 'preserve-3d',
          }}
        >
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backfaceVisibility: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IssuedCardDesign
              userId={userId}
              productId={productId}
              showCopyToClipboard={false}
              issuedCard={{
                type: TypeIssuedCard.Virtual,
                statusBox: StatusBox.Inactive,
                name: issuedCard.name,
                last4: '----',
                companie: {
                  name: companie.name,
                },
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backfaceVisibility: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              transform: 'rotateY(180deg)',
            }}
          >
            <IssuedCardDesign
              userId={userId}
              productId={productId}
              showCopyToClipboard={false}
              issuedCard={{
                ...issuedCard,
                type: TypeIssuedCard.Physical,
                last4: '----',
                companie: {
                  name: companie.name,
                },
                issuedCardStripe: undefined,
                statusBox: null,
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ width: '370px', margin: '32px auto 0' }}>
        <CardTypeSelect
          isDisabled={!companie.canCreatePhysicalIssuedCard}
          onChange={(type) => {
            setIssuedCard({ ...issuedCard, type })
          }}
        />
        {getStatus() !== 'ok' && (
          <>
            {getStatus() === 'notEnoughBalance' && (
              <>
                <p>
                  Not enough balance in your NachoNacho account. Please top up your balance by going to the tab 'Payment Source'.
                </p>

                {context.userRoleCompanie.permissions.includes(Permission.CanSeeCards) && (
                  <Link to={'/paymentSource/' + context.userRoleCompanie.companie.id}>
                    <Button variant="outlined">
                      <Icon className=" ">account_balance</Icon>
                      <span className="white">_</span> Payment source
                    </Button>
                  </Link>
                )}
              </>
            )}
            {getStatus() === 'noSource' && (
              <>
                <p>
                  No primary source of payment has been registered in your NachoNacho account. Please add a payment source by
                  going to the tab 'Payment Source'.
                </p>

                {context.userRoleCompanie.permissions.includes(Permission.CanSeeCards) && (
                  <Link to={'/paymentSource/' + context.userRoleCompanie.companie.id}>
                    <Button variant="outlined">
                      <Icon className=" ">account_balance</Icon>
                      <span className="white">_</span> Payment source
                    </Button>
                  </Link>
                )}
              </>
            )}
            {getStatus() === 'nameLengthError' && (
              <>
                <p>To create a card, the name "{userId && <UserName userId={userId} />}" must be at most 24 characters</p>

                <Link to="/settings/company">
                  <Button variant="outlined">
                    <Icon className=" ">settings</Icon>
                    <span className="white">_</span> Go to Settings
                  </Button>
                </Link>
              </>
            )}
            {getStatus() === 'dobError' && (
              <>
                <h3>Add your Date of Birth</h3>
                <p>
                  Add your date of birth. NachoNacho has a regulatory requirement to conduct ongoing monitoring and due diligence
                  of users on our platform.
                </p>
                <UserDobForm
                  showCancel={false}
                  user={context.me}
                  onUpdate={() => {
                    statusVirtualQuery.refetch()
                    statusPhysicalQuery.refetch()
                  }}
                  onCancel={() => {}}
                  buttonSave="Save"
                  buttonCancel="Cancel"
                />
              </>
            )}
            {getStatus() === 'billingAddresseError' && (
              <>
                {context.userRoleCompanie.companieRole === 'PURCHASER' ? (
                  <>
                    <p>
                      <b>Unable to proceed! Add a billing address and try again later.</b>
                    </p>
                    <p>
                      To create a card, a billing address must have been registered in the company account. Please ask a company
                      admin to add a billing address.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      A billing address is required before accessing physical NachoCards. This is the address you will enter if a
                      vendor asks you for a billing address when making an online payment with your Nachocard.
                    </p>
                    <AddAddresseContainer
                      companieId={context.userRoleCompanie.companie.id}
                      type={TypeAddresse.Billing}
                      userId={context.me.id}
                      onCancel={() => {}}
                      onCreate={() => {
                        statusPhysicalQuery.refetch()
                        statusVirtualQuery.refetch()
                      }}
                    />
                  </>
                )}
              </>
            )}
            {getStatus() === 'accountOnHold' && (
              <>
                <h3>NachoCard setup</h3>
                <p>Your account is on hold! You cannot create New Card for now.</p>
              </>
            )}
            {getStatus() === 'noBalance' && (
              <>
                <h3>NachoCard setup</h3>
                <p>Error. You account is not ready (Balance issue). Please contact us</p>
              </>
            )}
            {getStatus() === 'maxIssuedCardCountTotalReachedError' && (
              <>
                <h3>NachoCard setup</h3>
                <p>Maximum number of permitted NachoCards reached. Please contact us.</p>
              </>
            )}
            {getStatus() === 'maxIssuedCardsWithinTimeLimitError' && (
              <>
                <h3>NachoCard setup</h3>
                <p>You created too many cards too quickly! Please contact us.</p>
              </>
            )}
            {getStatus() === 'maxInactiveLiveCardsError' && (
              <>
                <h3>NachoCard setup</h3>
                <p>
                  You already have too many unused cards. Please use the existing cards before activating more of them.
                  Alternatively, please contact us.
                </p>
              </>
            )}
          </>
        )}
        {getStatus() === 'ok' && (
          <div>
            {step === 'cardForm' && (
              <div style={{ marginTop: '20px' }}>
                <AutocompleteProductsIssuedCard
                  label="Nickname of NachoCard"
                  placeholder="E.g.: General expenses, Dropbox, etc."
                  issuedCard={issuedCard}
                  onChange={onChange}
                  onSelect={onSelect}
                  disabled={disableNameChange}
                />
                {['ADMIN', 'OWNER'].includes(context.userRoleCompanie.companieRole) && (
                  <UserTeamSelect hideAnalyst label="Who is this card for?" onChange={() => {}} />
                )}
                <RadioSelectNN
                  options={[
                    { value: AuthorizedAmountUnitType.PerMonth, label: 'Per Month' },
                    { value: AuthorizedAmountUnitType.PerYear, label: 'Per Year' },
                    { value: AuthorizedAmountUnitType.Total, label: 'Cumulative' },
                    { value: AuthorizedAmountUnitType.None, label: 'No Limit' },
                  ]}
                  value={issuedCard.authorizedAmountUnit}
                  htmlFor="authorizedAmountUnit"
                  title="Spending limit"
                  onChange={(unit) => {
                    setIssuedCard({ ...issuedCard, authorizedAmountUnit: unit as AuthorizedAmountUnitType })
                  }}
                />
                {issuedCard.authorizedAmountUnit !== AuthorizedAmountUnitType.None && (
                  <FormControl style={{ marginLeft: '5px' }} className="width100per" variant="standard">
                    <InputLabel shrink htmlFor="authorizedAmountUnit">
                      <b className="black2" />
                    </InputLabel>
                    <TextField
                      id="authorizedAmount"
                      size="small"
                      className="width100per spaceForm"
                      placeholder=""
                      InputProps={{
                        sx: { '& .MuiInputBase-input': { borderLeft: '1px solid #858580', paddingLeft: '5px' } },
                        startAdornment: (
                          <InputAdornment position="start">
                            <div>USD</div>
                          </InputAdornment>
                        ),
                      }}
                      onWheelCapture={(e) => (e.target as HTMLInputElement).blur()}
                      onChange={(e) => {
                        setIssuedCard({
                          ...issuedCard,
                          authorizedAmount:
                            e.target.value === ''
                              ? null
                              : Number(e.target.value) < 0
                                ? Number(e.target.value) * -1
                                : Number(e.target.value),
                        })
                      }}
                      type="number"
                      value={issuedCard.authorizedAmount}
                    />
                  </FormControl>
                )}
                <div style={{ marginTop: '20px' }}>
                  {cardType === 'virtual' && (
                    <CreateIssuedCard
                      userId={userId}
                      typeCreation={IssuedCardTypeCreation.CreateCardForm}
                      disabled={!isFormValide()}
                      sx={{ width: '100%' }}
                      productId={productId}
                      onCreate={(issuedCard) => {
                        closeDialog(issuedCard.id)
                      }}
                      companieId={companie.id}
                      buttonText="Create Card"
                      issuedCard={issuedCard}
                      discoveryProductId={discoveryProductId}
                      type={cardType as TypeIssuedCard}
                    />
                  )}
                  {cardType === 'physical' && (
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!isFormValide()}
                      size="medium"
                      sx={{ width: '100%' }}
                      onClick={() => setStep('shippingAddressForm')}
                    >
                      Continue to Shipping Address
                    </Button>
                  )}
                </div>
                <div className="tac">
                  <Button variant="text" href="https://blog.nachonacho.com/fintech/new-way-to-buy-saas/" target="_blank">
                    How do NachoCards work?
                  </Button>
                </div>
              </div>
            )}
            {step === 'shippingAddressForm' && (
              <>
                <h3>Shipping Address</h3>
                <p>Please make sure you can receive physical mail at this address securely.</p>
                <ShippingAddressesIssuedCard
                  onCancel={() => setStep('cardForm')}
                  onUpdate={() => setStep('physicalCardTerms')}
                  onCreate={() => setStep('physicalCardTerms')}
                  companieId={companie.id}
                  userId={userId}
                />
              </>
            )}
            {step === 'physicalCardTerms' && (
              <>
                <ul style={{ color: 'black', padding: 'left' }}>
                  <li className="fontWeight18">Currently we can ship cards only to the US and Canada.</li>
                  <li className="fontWeight18">Each physical card order costs $15.00.</li>
                  <li className="fontWeight18">This amount will be invoiced to you after you finish your order.</li>
                </ul>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!isFormValide()}
                  size="medium"
                  sx={{ width: '100%' }}
                  onClick={() => setStep('summary')}
                >
                  Continue to Order Review
                </Button>
              </>
            )}
            {step === 'summary' && (
              <CreatePhyisicalIssuedCardSummary
                userId={userId}
                companie={companie}
                issuedCard={issuedCard}
                onCancel={() => setStep('shippingAddressForm')}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CreateIssuedCardForm
