import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { Button } from '@mui/material'
import { useAppContextWithCompany } from '../../../../AppContext'
import ButtonLoadingAfterClick from '../../../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import TextFieldNN from 'components/ui/TextFieldNN'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation totpLoginVerify_TotpLoginVerify($token: String!) {
    totpLoginVerify(token: $token) {
      id
      deviceToken
      isVerified
      email
    }
  }
`)
type Props = {
  onCancel: () => void
  onUpdate: () => void
}

const TotpLoginVerify = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const [verifyLogin] = useMutation(MUTATION)
  const context = useAppContextWithCompany()
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [token, setToken] = React.useState('')

  const verifyLoginF = async () => {
    if (token.length < 2) return
    setLoading(false)
    setMessage('')
    try {
      const dataAuthDevice = await verifyLogin({ variables: { token } })
      setLoading(false)
      if (dataAuthDevice && dataAuthDevice.data && dataAuthDevice.data.totpLoginVerify) {
        console.log(dataAuthDevice.data.totpLoginVerify)

        props.onUpdate()
        context.updateAuthDevice({ ...context.authDevice, ...dataAuthDevice.data.totpLoginVerify })
        await client.resetStore()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      <p>Get a verification code from the Authenticator app</p>
      <div>
        <TextFieldNN
          id="id-token-TotpLoginVerify"
          label="Token"
          onChange={(e) => setToken(e.target.value)}
          type="text"
          value={token}
          onKeyDown={(e) => {
            if (e.key === 'Enter') verifyLoginF()
          }}
        />
      </div>
      <div style={{ height: '10px' }} />
      <div>
        <Button onClick={props.onCancel}>Cancel</Button>{' '}
        <ButtonLoadingAfterClick
          actionName="totpLoginVerify"
          id="idTotpLoginVerify"
          disabled={token.length < 2}
          icon=""
          size="medium"
          color="primary"
          variant="outlined"
          buttonText="Verify"
          buttonLoadingText="Setting up..."
          onClick={verifyLoginF}
          loading={loading}
        />{' '}
        <div className="secondary">{message}</div>
      </div>
    </>
  )
}

export default TotpLoginVerify
