import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import UpdateAddresse from './UpdateAddresse'
import UpdateAddresseAndSpread from './UpdateAddresseAndSpread'
import { Addresse } from '__generated__/graphql'

type Props = {
  disabled: boolean
  onUpdate: () => void
  onCancel: () => void
  textButton: string
  textButtonCancel: string
  hideCancel?: boolean
  addresse: Pick<Addresse, 'id' | 'type' | 'name' | 'address1' | 'address2' | 'city' | 'zip' | 'state' | 'country'>
}

export const UpdateAddressLogic = (props: Props) => {
  const [spread, setSpread] = React.useState(false)

  return (
    <>
      {props.addresse.type === 'BILLING' && (
        <div>
          <div>
            The new NachoCard Billing Address will apply to all new NachoCards you create from now onwards. If you would like the
            new address to apply to all existing NachoCards for all users in your company as well, please select that option
            below.
          </div>

          <FormControlLabel
            control={<Switch checked={spread} onChange={(e) => setSpread(e.target.checked)} value={true} />}
            label="Apply to all existing and new NachoCards"
          />
          <div style={{ height: '10px' }} />
        </div>
      )}

      {spread ? (
        <UpdateAddresseAndSpread
          textButton={props.textButton}
          textButtonCancel={props.textButtonCancel}
          onUpdate={props.onUpdate}
          disabled={props.disabled}
          addresse={props.addresse}
          onCancel={props.onCancel}
          hideCancel={props.hideCancel}
        />
      ) : (
        <UpdateAddresse
          textButton={props.textButton}
          textButtonCancel={props.textButtonCancel}
          onUpdate={props.onUpdate}
          disabled={props.disabled}
          addresse={props.addresse}
          onCancel={props.onCancel}
          hideCancel={props.hideCancel}
        />
      )}
    </>
  )
}

export default UpdateAddressLogic
