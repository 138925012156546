import React from 'react'
import { ReactComponent as FileTypeJpg } from 'assets/icon/fileType/jpg.svg'
import { ReactComponent as FileTypePng } from 'assets/icon/fileType/png.svg'
import { ReactComponent as FileTypePdf } from 'assets/icon/fileType/pdf.svg'

type Props = {
  type: string
}

const IconFilePrivate = (props: Props) => {
  return (
    <>
      {props.type === 'application/pdf' && <FileTypePdf />}
      {props.type === 'image/jpeg' && <FileTypeJpg />}
      {props.type === 'image/jpg' && <FileTypeJpg />}
      {props.type === 'image/png' && <FileTypePng />}
    </>
  )
}

export default IconFilePrivate
