import React from 'react'
import Paper from '@mui/material/Paper'
import NotFound from '../nav/error/NotFound'
import Error from '../nav/error/Error'
import Loading from '../nav/error/Loading'
import OnboardingValidateEmail from './OnboardingValidateEmail'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'

const USER_QUERY = gql(/* GraphQL */ `
  query user_OnboardingValidaterEmailUserQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      email
    }
  }
`)

type Props = {
  userId: string
}

const OnboardingValidaterEmailUserQuery = (props: Props) => {
  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: {
      where: { id: props.userId },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="borderless" className="paperIn">
          <OnboardingValidateEmail user={data.user} />
        </Paper>
      </div>
    </div>
  )
}

export default OnboardingValidaterEmailUserQuery
