import React from 'react'
import UpdateUser from '../../action/UpdateUser'
import OffsiteTemplate from './OffsiteTemplate'
import { UserContext } from 'components/Context.type'
import { Grid, FormControl, InputLabel, Input } from '@mui/material'

type Props = {
  user: Pick<UserContext, 'id' | 'firstName' | 'lastName'>
}

const UserProfileNameForm = (props: Props) => {
  const [user, setUser] = React.useState(props.user)
  const [firstNameValid, setFirstNameValid] = React.useState(true)
  const [lastNameValid, setLastNameValid] = React.useState(true)
  const isFormValid = () => {
    return user.firstName.length && firstNameValid && user.lastName.length && lastNameValid
  }

  return (
    <OffsiteTemplate>
      <div>
        <h3>Your full name</h3>
      </div>

      <>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <FormControl className="width100per" variant="standard">
              <InputLabel htmlFor="firstName">Legal first name</InputLabel>
              <Input
                id="firstName"
                error={!firstNameValid}
                onChange={(e) => {
                  setUser({
                    ...user,
                    firstName: e.target.value,
                  })

                  setFirstNameValid(e.target.value.length > 0 ? true : false)
                }}
                type="text"
                value={user.firstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className="width100per" variant="standard">
              <InputLabel htmlFor="lastName">Legal last name</InputLabel>
              <Input
                id="lastName"
                error={!lastNameValid}
                onChange={(e) => {
                  setUser({
                    ...user,
                    lastName: e.target.value,
                  })

                  setLastNameValid(e.target.value.length > 0 ? true : false)
                }}
                type="text"
                value={user.lastName}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <br />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>
              <UpdateUser disabled={!isFormValid()} user={user} updateTextButton="Save" onUpdate={() => {}} />
            </div>
          </Grid>
        </Grid>
      </>
    </OffsiteTemplate>
  )
}

export default UserProfileNameForm
