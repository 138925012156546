import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { Divider, Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { URL_HOME } from 'config/config'

const DrawerLeftNNAnalyst = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const pathnames = location.pathname.split('/')
  let baseURL = ''
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  if (pathnames.length > 2) {
    if (pathnames[1] === 'seller') {
      baseURL = pathnames[2]
    }
  }

  return (
    <div className="tac">
      <ListItem className={baseURL === '' ? 'bgGrey' : ''} onClick={() => navigate('/')}>
        <ListItemIcon>
          <Icon className={baseURL === '' ? 'secondary' : 'black'}>home</Icon>
        </ListItemIcon>
        <ListItemText classes={{ primary: 'menuDrawerLeft ' }} primary="Home" />
      </ListItem>

      <Divider />
      <Link to="/balanceAdmin">
        <ListItem className={baseURL === 'balanceAdmin' ? 'bgGrey' : ''}>
          <ListItemIcon>
            <Icon className={baseURL === 'balanceAdmin' ? 'secondary' : 'black'}>settings</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: 'menuDrawerLeft ' }} primary="balance NN" />
        </ListItem>
      </Link>

      <Link to="/settings/company">
        <ListItem className={baseURL === 'settings' ? 'bgGrey' : ''}>
          <ListItemIcon>
            <Icon className={baseURL === 'settings' ? 'secondary' : 'black'}>settings</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: 'menuDrawerLeft ' }} primary="Settings" />
        </ListItem>
      </Link>

      <a href={`${URL_HOME}/marketplace`}>
        <ListItem className={baseURL === 'marketplaceBtoB' ? 'bgGrey' : ''}>
          <ListItemIcon>
            <Icon className={baseURL === 'marketplaceBtoB' ? 'secondary' : 'black'}>shopping_cart</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: 'menuDrawerLeft ' }} primary="Marketplace" />
        </ListItem>
      </a>
    </div>
  )
}

export default DrawerLeftNNAnalyst
