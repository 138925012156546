import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import ButtonLoadingAfterClick from '../nav/ButtonLoadingAfterClick'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import TextFieldNN from 'components/ui/TextFieldNN'

const MUTATION = gql(/* GraphQL */ `
  mutation redeemPromoCode_RedeemPromoCode($code: String!, $companieId: String!) {
    redeemPromoCode(code: $code, companieId: $companieId) {
      id
      isRedeem
    }
  }
`)

type Props = {
  companieId: string
}

const RedeemPromoCode = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const client = useApolloClient()
  const [code, setCode] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [redeemPromoCode] = useMutation(MUTATION)

  const createPromoCodeF = async () => {
    setLoading(true)
    setMessage('')
    try {
      const promoCode = await redeemPromoCode({
        variables: {
          companieId: props.companieId,
          code,
        },
      })

      if (promoCode) {
        setMessage('')
        await client.resetStore()
        setLoading(false)
        setShow(false)
        setCode('')
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      {show ? (
        <div>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextFieldNN id="discount" label="Enter Promo Code" value={code} onChange={(e) => setCode(e.target.value)} />
            </Grid>
          </Grid>
          <div style={{ height: '15px' }} />
          <Button onClick={() => setShow(false)}>Cancel</Button>{' '}
          <ButtonLoadingAfterClick
            actionName="redeemPromoCode"
            id="idButtonUpdateAddress"
            disabled={false}
            icon=""
            size="medium"
            color="primary"
            variant="outlined"
            buttonText="Redeem"
            buttonLoadingText="Setting up..."
            onClick={createPromoCodeF}
            loading={loading}
          />
          <div id="idMessage" className="secondary">
            {message}
          </div>
        </div>
      ) : (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShow(true)
            }}
          >
            + Promo Code
          </Button>
        </div>
      )}
    </>
  )
}

export default RedeemPromoCode
