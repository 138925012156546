import React from 'react'
import { Button, Icon } from '@mui/material'
import { useAppContext } from 'components/AppContext'

const LogOutCross = () => {
  const context = useAppContext()

  return (
    <Button onClick={() => context.logout(['app', 'home'])}>
      Log out <span className="white">_</span>
      <Icon className="textSize10">logout</Icon>
    </Button>
  )
}

export default LogOutCross
