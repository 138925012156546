import React from 'react'
import SubmitVerification from 'components/onboarding/SubmitVerification'
import { URL_FRONTEND } from 'config/config'

type Props = {
  onUpdate: () => void
}

const OnboardingConfirmationFormMaven = (props: Props) => {
  return (
    <>
      <div className="titleStepKYC">Confirm Submission</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
          padding: '0 20px',
        }}
      >
        <p>
          By clicking Accept button below, you accept the{' '}
          <a
            style={{ color: '#1445FE' }}
            href={`${URL_FRONTEND}/settings/company?mode=mavenAgreement`}
            target="_blank"
            rel="noreferrer"
          >
            NachoNacho Maven Marketplace Agreement.
          </a>
        </p>
      </div>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <SubmitVerification
          onUpdate={() => {
            props.onUpdate()
          }}
          textButton="Accept"
        />
      </div>
    </>
  )
}

export default OnboardingConfirmationFormMaven
