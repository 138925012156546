import React, { useState } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import useDebounce from 'hooks/useDebounce'
import { MetaTag, MetaTagOrderByInput, TypeMetaTag } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query metaTagsConnection_MetaTagsToUserRoleCompanieManagementAutocompleteSingle(
    $where: MetaTagWhereInput
    $orderBy: MetaTagOrderByInput
    $skip: Int
    $take: Int
  ) {
    metaTagsConnection(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      metaTags {
        id
        name
        type
      }
    }
  }
`)

type Props = {
  type: TypeMetaTag
  selectedMetaTags: Array<Pick<MetaTag, 'id' | 'name' | 'type'>>
  onChange: (selection: Props['selectedMetaTags']) => void
}

const MetaTagsToUserRoleCompanieManagementAutocompleteSingle = (props: Props) => {
  const [input, setInput] = useState('')
  const debouncedInput = useDebounce(input, 100)

  const notCategoryIds = props.selectedMetaTags.map((category) => {
    return {
      id: category.id,
    }
  })

  const { error, data } = useQuery(QUERY, {
    variables: {
      take: 30,
      orderBy: MetaTagOrderByInput.OrderByIntAsc,
      where: {
        type: { equals: props.type },
        name: { contains: debouncedInput },
        NOT: notCategoryIds.length ? notCategoryIds : undefined,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />

  const options = data?.metaTagsConnection.metaTags ? data.metaTagsConnection.metaTags : []

  return (
    <Box alignItems="center" display="flex" flex="1" gap="21px">
      <Autocomplete
        disableClearable={true}
        fullWidth
        size="small"
        id="id-CategoriesPreferencesAutocomplete"
        options={options}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        onChange={(_, value) => {
          props.onChange([value])
          setInput('')
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={props.selectedMetaTags[0]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Start typing..."
            value={debouncedInput}
            onChange={(e) => setInput(e.target.value)}
          />
        )}
      />
    </Box>
  )
}

export default MetaTagsToUserRoleCompanieManagementAutocompleteSingle
