import { TEST_MODE, SPOOFED_USER_ID, URL_SERVER_GRAPHQL, RECAPTCHA_TOKEN } from 'config/config'
import { ApolloClient, HttpLink, ApolloLink, from, InMemoryCache } from '@apollo/client'

const httpLink = new HttpLink({ uri: URL_SERVER_GRAPHQL, credentials: 'include' })

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const testMode = localStorage.getItem(TEST_MODE)
  const recaptchaToken = localStorage.getItem(RECAPTCHA_TOKEN)
  const spoofeduserheader = localStorage.getItem(SPOOFED_USER_ID) ? localStorage.getItem(SPOOFED_USER_ID) : ''
  const gqlOperationName = operation.operationName

  operation.setContext({
    headers: {
      testMode,
      spoofeduserheader,
      recaptchaToken,
      gqlOperationName,
    },
  })

  return forward(operation)
})

const client = new ApolloClient({
  link: from([middlewareAuthLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          events: {
            keyArgs: ['where', 'take', 'companieId'],
            // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
            merge(existing, incoming, { args }) {
              const skip = args?.skip ?? 0
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i]
              }
              return merged
            },
          },
        },
      },
    },
  }),
})

export default client
