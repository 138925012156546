import React from 'react'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import GetStartedStatus from './GetStartedStatus'
import NotFound from 'components/nav/error/NotFound'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'

const USER_ROLE_COMPANIE_QUERY = gql(/* GraphQL */ `
  query userRoleCompanie_GetStartedStatusParent($where: UserRoleCompanieWhereUniqueInput!) {
    userRoleCompanie(where: $where) {
      id
      showSetupGuide
    }
  }
`)

const GetStartedStatusParent = () => {
  const { userRoleCompanie } = useAppContextWithCompany()

  const { loading, error, data } = useQuery(USER_ROLE_COMPANIE_QUERY, {
    variables: { where: { id: userRoleCompanie.id } },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.userRoleCompanie) return <NotFound />
  if (!data.userRoleCompanie.showSetupGuide) return null

  return <GetStartedStatus />
}

export default GetStartedStatusParent
