import React from 'react'
import { ReactComponent as FilledProgressCircle } from '../../assets/icon/filledProgressCircle.svg'
import { ReactComponent as EmptyProgressCircle } from '../../assets/icon/emptyProgressCircle.svg'
import { useKYCContext } from './KYCContext'
import { StripeBusinessType } from '__generated__/graphql'

type Props = {
  stripeBusinessType?: StripeBusinessType | null
}
const KYCStepsProgress = (props: Props) => {
  const kycContext = useKYCContext()
  const getStepStatus = (step) => {
    return kycContext.getStepStatus(step)
  }
  const stepStyle = { display: 'flex', alignItems: 'center', margin: '20px 0 0 -12.5px' }
  return (
    <div style={{ width: '321px', marginLeft: '50px' }}>
      <div style={{ fontWeight: 'bold' }}>Personal</div>
      <div style={{ margin: '10px 12px', paddingLeft: '40px', borderLeft: '1px dashed black' }}>
        <div style={{ borderLeft: '1px dashed black' }}>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('personalPhoneNumber') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('personalPhoneNumber') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'personalPhoneNumber' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('personalPhoneNumber')}
            >
              Phone validation
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('personalAddress') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('personalAddress') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'personalAddress' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('personalAddress')}
            >
              Home address
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('personalDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('personalDetails') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'personalDetails' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('personalDetails')}
            >
              Additional details
            </div>
          </div>
        </div>
      </div>
      <div style={{ fontWeight: 'bold' }}>Company</div>
      <div style={{ margin: '10px 12px', paddingLeft: '40px', borderLeft: '1px dashed black' }}>
        <div style={{ borderLeft: '1px dashed black' }}>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('companyType') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('companyType') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'companyType' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('companyType')}
            >
              Account type
            </div>
          </div>
          {props.stripeBusinessType !== StripeBusinessType.Individual && (
            <>
              <div style={stepStyle}>
                <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
                  {getStepStatus('companyAddress') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
                </div>
                <div
                  style={{
                    cursor: getStepStatus('companyAddress') ? 'pointer' : 'unset',
                    fontWeight: kycContext.currentStep() === 'companyAddress' ? 700 : 400,
                  }}
                  onClick={() => kycContext.setCurrentStep('companyAddress')}
                >
                  Company address
                </div>
              </div>
              <div style={stepStyle}>
                <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
                  {getStepStatus('companyDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
                </div>
                <div
                  style={{
                    cursor: getStepStatus('companyDetails') ? 'pointer' : 'unset',
                    fontWeight: kycContext.currentStep() === 'companyDetails' ? 700 : 400,
                  }}
                  onClick={() => kycContext.setCurrentStep('companyDetails')}
                >
                  Company details
                </div>
              </div>
              <div style={stepStyle}>
                <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
                  {getStepStatus('beneficiaryDetails') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
                </div>
                <div
                  style={{
                    cursor: getStepStatus('beneficiaryDetails') ? 'pointer' : 'unset',
                    fontWeight: kycContext.currentStep() === 'beneficiaryDetails' ? 700 : 400,
                  }}
                  onClick={() => kycContext.setCurrentStep('beneficiaryDetails')}
                >
                  Beneficiary details
                </div>
              </div>
            </>
          )}
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('leadershipDocuments') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('leadershipDocuments') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'leadershipDocuments' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('leadershipDocuments')}
            >
              Document upload
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('leadershipIdNumber') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('leadershipIdNumber') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'leadershipIdNumber' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('leadershipIdNumber')}
            >
              Beneficiary identification
            </div>
          </div>
          <div style={stepStyle}>
            <div style={{ width: 'fit-content', background: '#FAF8F7', marginRight: '16px' }}>
              {getStepStatus('submitForm') ? <FilledProgressCircle /> : <EmptyProgressCircle />}
            </div>
            <div
              style={{
                cursor: getStepStatus('submitForm') ? 'pointer' : 'unset',
                fontWeight: kycContext.currentStep() === 'submitForm' ? 700 : 400,
              }}
              onClick={() => kycContext.setCurrentStep('submitForm')}
            >
              Confirm submission
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYCStepsProgress
