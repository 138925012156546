import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { Button, Icon } from '@mui/material'
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link'
import { PlaidData } from '__generated__/graphql'
import { gql } from '__generated__'

const NEW_LOGIN_PAID_MUTATION = gql(/* GraphQL */ `
  mutation newLoginPlaid_AddPlaidRefreshLogin($plaidDataId: String!, $publicToken: String!) {
    newLoginPlaid(plaidDataId: $plaidDataId, publicToken: $publicToken) {
      id
    }
  }
`)

type Props = {
  plaidData: Pick<PlaidData, 'id' | 'publicTokenFresh'>
}

const AddPlaidRefreshLogin = (props: Props) => {
  console.log(props.plaidData)
  const [newLoginPlaid] = useMutation(NEW_LOGIN_PAID_MUTATION)

  const client = useApolloClient()

  const newLoginPlaidF = async (publicToken: string) => {
    try {
      const plaidDataQuery = await newLoginPlaid({
        variables: {
          plaidDataId: props.plaidData.id,
          publicToken,
        },
      })

      console.log(plaidDataQuery)
      if (plaidDataQuery) {
        await client.resetStore()
      }
    } catch (e) {
      /**/
    }
  }

  const onSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    newLoginPlaidF(publicToken)
  }

  const config = {
    clientName: 'NachoNacho',
    product: ['transactions'],
    token: props.plaidData.publicTokenFresh ? props.plaidData.publicTokenFresh : '',
    onSuccess,
  }

  const { open, ready, error } = usePlaidLink(config)

  if (error) {
    console.log(error)
  }

  return (
    <Button color="secondary" variant="outlined" onClick={() => open()} disabled={!ready} className="btnOpenPlaid">
      Reconnect Bank Account
      <div style={{ width: '10px' }} />
      <Icon>account_balance</Icon>
    </Button>
  )
}

export default AddPlaidRefreshLogin
