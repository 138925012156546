import React from 'react'
import { Grid } from '@mui/material'
import OnboardingAddresses from 'components/addresse/list/OnboardingAddresses'
import { TypeAddresse } from '__generated__/graphql'

type Props = {
  userId: string
  productId: string
  onCreate: () => void
  onUpdate: () => void
  onCancel: () => void
}

const MavenCompanieAddresssForm = (props: Props) => {
  return (
    <>
      <div className="titleStepKYC">Maven address</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Your primary place of business
      </p>
      <Grid container spacing={1} style={{ maxWidth: '350px', margin: '0 auto' }}>
        <Grid item xs={12} sm={12}>
          <OnboardingAddresses
            type={TypeAddresse.Maven}
            onCreate={() => {
              props.onCreate()
            }}
            onUpdate={() => {
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            userId={props.userId}
            productId={props.productId}
            variables={{
              where: {
                product: {
                  id: { equals: props.productId },
                },
                type: 'MAVEN',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default MavenCompanieAddresssForm
