import React from 'react'
import { Button } from '@mui/material'
import { useAppContextWithCompany } from '../../AppContext'
import { useMutation, useApolloClient } from '@apollo/client'
import ReviewForm from './ReviewForm'
import ImageTemplate from '../../nav/ImageTemplate'
import { Link, useNavigate } from 'react-router-dom'
import { nachoNachoProductId, URL_HOME } from 'config/config'
import { Product, Review } from '__generated__/graphql'
import { gql } from '__generated__'
import useShowErrors from 'hooks/useShowErrors'

const MUTATION = gql(/* GraphQL */ `
  mutation createReview_CreateReview($data: ReviewCreateInput!) {
    createReview(data: $data) {
      id
    }
  }
`)

type Props = {
  product: Pick<Product, 'id' | 'name' | 'nameFile' | 'urlName' | 'visibility'>
}

const CreateReview = (props: Props) => {
  const showErrors = useShowErrors()
  const [message, setMessage] = React.useState('')
  const client = useApolloClient()
  const navigate = useNavigate()
  const {
    userRoleCompanie: { companie },
  } = useAppContextWithCompany()
  const [formValid, setFormValid] = React.useState(false)
  const [review, setReview] = React.useState<Pick<Review, 'rating' | 'content' | 'title' | 'headline'>>({
    rating: 0,
    content: '',
    title: '',
    headline: '',
  })
  const [createReview] = useMutation(MUTATION)
  const isNNProduct = nachoNachoProductId === props.product.id
  const link = isNNProduct ? `${URL_HOME}/userFeedback` : `${URL_HOME}/product/${props.product.urlName}`

  const createReviewF = async () => {
    try {
      const { data } = await createReview({
        variables: {
          data: {
            rating: review.rating,
            content: review.content,
            title: review.title,
            headline: review.headline,
            companieId: companie.id,
            productId: props.product.id,
          },
        },
      })
      if (data) {
        await client.resetStore()
        navigate('/reviews')
      }
    } catch (e) {
      showErrors(e, setMessage)
    }
  }
  return (
    <>
      <div>
        <a className="link" href={link}>
          <ImageTemplate format="small" nameFile={props.product.nameFile} />
        </a>
      </div>
      <h3>
        Review for {props.product.name} by {companie.name}
      </h3>
      <div style={{ height: '30px' }} />
      <ReviewForm
        product={props.product}
        review={review}
        onFormValidation={(formValid: boolean) => setFormValid(formValid)}
        setReview={(updatedReview) => setReview((review) => ({ ...review, ...updatedReview }))}
      />
      <div style={{ height: '30px' }} />
      <Link to="/reviews">
        <Button>Cancel</Button>
      </Link>{' '}
      <Button disabled={!formValid} color="primary" variant="contained" onClick={createReviewF}>
        Submit
      </Button>
      {message && (
        <div id="idMessage-UnlinkProductToCompanieAdmin" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default CreateReview
