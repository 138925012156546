import React from 'react'
import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { Companie } from '__generated__/graphql'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation updateLeadershipFromSelf_UpdateLeadershipFromSelf(
    $companieId: String!
    $isIndividualCompany: Boolean!
    $leadershipIsOwner: Boolean!
  ) {
    updateLeadershipFromSelf(
      companieId: $companieId
      isIndividualCompany: $isIndividualCompany
      leadershipIsOwner: $leadershipIsOwner
    ) {
      id
      leadershipTitle
      leadershipFirstName
      leadershipLastName
      leadershipPhone
      leadershipPhoneCode
      leadershipEmail
      leadershipPercentOwnership
      stripeBusinessType
    }
  }
`)

type Props = {
  textButton: string
  textCancelButton: string
  showCancelButton: boolean
  disabled: boolean
  companieId: string
  isIndividualCompany: boolean
  leadershipIsOwner: boolean
  onCancel: () => void
  onUpdate: (companie: Companie) => void
}

const UpdateLeadershipFromSelf = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [UpdateLeadershipFromSelf] = useMutation(MUTATION)

  const UpdateLeadershipFromSelfInternalF = async () => {
    setLoading(true)
    let newCompanie
    try {
      newCompanie = await UpdateLeadershipFromSelf({
        variables: {
          companieId: props.companieId,
          isIndividualCompany: props.isIndividualCompany,
          leadershipIsOwner: props.leadershipIsOwner,
        },
      })
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
    if (newCompanie) {
      setLoading(false)
      props.onUpdate(newCompanie)
    }
  }

  return (
    <>
      {props.showCancelButton && <Button onClick={() => props.onCancel()}>{props.textCancelButton}</Button>}{' '}
      <ButtonLoadingAfterClick
        actionName="updateLeadershipFromSelf"
        id="idButtonUpdateCompanie"
        icon=""
        color="primary"
        disabled={props.disabled}
        sx={{ width: '100%' }}
        variant="contained"
        size="medium"
        buttonText={props.textButton}
        buttonLoadingText="Loading..."
        onClick={() => UpdateLeadershipFromSelfInternalF()}
        loading={loading}
      />
      <p className="secondary">{errorMessage}</p>
    </>
  )
}

export default UpdateLeadershipFromSelf
