import React from 'react'
import { useAppContext } from 'components/AppContext'
import { AxiosError } from 'axios'

export type GraphQLError = {
  graphQLErrors?: [
    {
      message: string
    },
  ]
}

const useShowErrors = () => {
  const context = useAppContext()
  const { openSnackbar } = context

  return React.useCallback(
    (error: unknown, setMessage?: (value: React.SetStateAction<string>) => void) => {
      let message = ''

      const gqlError = (error as GraphQLError).graphQLErrors?.[0]?.message
      const axiosError = (error as AxiosError).cause
      if (gqlError) message = gqlError
      else if (axiosError) message = axiosError.message
      else if (error instanceof Error) message = error.message
      else message = 'An unexpected error has occurred. Please try again or contact us.'

      if (setMessage) setMessage(message)
      else openSnackbar(message, 'error')
    },
    [openSnackbar],
  )
}

export default useShowErrors
