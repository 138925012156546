import React from 'react'
import { useQuery } from '@apollo/client'
import NotFound from 'components/nav/error/NotFound'
import NotAuth from 'components/nav/error/NotAuth'
import Loading from 'components/nav/error/Loading'
import IsEmailValidated from './IsEmailValidated'
import { gql } from '__generated__'

const USER_QUERY = gql(/* GraphQL */ `
  query user_EmailValidatedQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      isEmailValidated
    }
  }
`)

type Props = {
  userId: string
}

const EmailValidatedQuery: React.FC<Props> = (props: Props) => {
  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: {
      where: {
        id: props.userId,
      },
    },
  })

  if (error) return <NotAuth />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  return <IsEmailValidated user={data.user} />
}

export default EmailValidatedQuery
