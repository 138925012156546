import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { gql } from '__generated__'
import CompanyToCateogoriesManagementChild from 'components/metaTag/objectToMetaTagsManagement/CompanyToMetaTagsManagementChild'
import { Box, Chip, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material'
import themeNN from 'components/themeNN'
import EditHeaderSection from 'components/nav/EditHeaderSection'
import { Product, TypeMetaTag } from '__generated__/graphql'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import Error from 'components/nav/error/Error'
import useShowErrors from 'hooks/useShowErrors'
import theme from 'components/themeNN'

const QUERY = gql(/* GraphQL */ `
  query metaTagsOfObjects_ProductICPLocationAtomic(
    $type: TypeMetaTag!
    $companieId: String
    $userRoleCompanieId: String
    $productId: String
  ) {
    metaTagsOfObjects(type: $type, companieId: $companieId, userRoleCompanieId: $userRoleCompanieId, productId: $productId) {
      id
      name
      type
    }
  }
`)

const MUTATION = gql(/* GraphQL */ `
  mutation updateProduct_ProductICPLocationAtomic($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
      isIcpAllLocations
    }
  }
`)

type Props = {
  product: Pick<Product, 'id' | 'isIcpAllLocations'>
  onUpdate?: () => void
}

const ProductICPLocationAtomic = (props: Props) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [updateProductMutation] = useMutation(MUTATION)
  const showErrors = useShowErrors()
  const [isIcpAllLocations, setisIcpAllLocations] = React.useState(props.product.isIcpAllLocations)
  const updateProduct = async () => {
    try {
      await updateProductMutation({
        variables: {
          where: { id: props.product.id },
          data: {
            isIcpAllLocations,
          },
        },
      })
    } catch (e) {
      showErrors(e, setMessage)
    }
    setIsEdit(false)
    props.onUpdate && props.onUpdate()
  }
  const queryMetaTags = useQuery(QUERY, {
    variables: {
      productId: props.product.id,
      type: TypeMetaTag.IcpGeography,
    },
  })

  if (queryMetaTags.error)
    return <Error message={queryMetaTags.error.graphQLErrors.length > 0 ? queryMetaTags.error.graphQLErrors[0].message : ''} />
  if (queryMetaTags.loading) return <Loading />
  if (!queryMetaTags.data?.metaTagsOfObjects) return <NotFound />

  return (
    <>
      <div className="paperOut">
        <Paper variant="borderless" className="paperIn" sx={{ backgroundColor: themeNN.greyBackground.color }}>
          {isEdit ? (
            <>
              <h4>Geography</h4>
              <div style={{ margin: '20px 0 5px', color: theme.lightGrey.color }}>Do you target companies globally?</div>
              <ToggleButtonGroup
                color="primary"
                value={isIcpAllLocations}
                exclusive
                onChange={(e, value) => setisIcpAllLocations(value)}
                aria-label="Pricing Type"
                sx={{ '& .MuiToggleButton-root': { height: '30px', borderRadius: '20px' } }}
              >
                <ToggleButton className="ff-roobert" value={true}>
                  Yes
                </ToggleButton>
                <ToggleButton className="ff-roobert" value={false}>
                  No
                </ToggleButton>
              </ToggleButtonGroup>
              <div style={{ margin: '20px 0 5px', color: theme.lightGrey.color }}>
                Select the main regions you focus on{isIcpAllLocations ? ' (optional)' : ''}:
              </div>
              <CompanyToCateogoriesManagementChild
                multiple={true}
                metaTags={queryMetaTags.data.metaTagsOfObjects}
                productId={props.product.id}
                type={TypeMetaTag.IcpGeography}
                onUpdate={() => {
                  updateProduct()
                  queryMetaTags.refetch()
                }}
                onCancel={() => {
                  setIsEdit(false)
                }}
              />
              {message && (
                <div id="idMessage" className="secondary">
                  {message}
                </div>
              )}
            </>
          ) : (
            <div>
              <EditHeaderSection
                canEdit={true}
                title="Location"
                onClick={() => setIsEdit(true)}
                isEdit={isEdit}
                id="id-CompanyToMetaTagsManagement"
              />
              <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'wrap', gap: '6px', alignItems: 'center' }}>
                <div style={{ color: theme.lightGrey.color }}>Targets all locations?</div>

                <div>{props.product.isIcpAllLocations ? 'Yes' : props.product.isIcpAllLocations === false ? 'No' : '-'}</div>
              </div>
              <Box display="flex" gap={1} flexWrap="wrap">
                {queryMetaTags.data.metaTagsOfObjects.map((title) => (
                  <Chip key={title.id} label={title.name} />
                ))}
              </Box>
            </div>
          )}
        </Paper>
      </div>
    </>
  )
}

export default ProductICPLocationAtomic
