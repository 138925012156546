import React from 'react'
import { Grid } from '@mui/material'
import AdblockDetect from './AdblockDetect'
import IconFilePrivate from './IconFilePrivate'
import DownloadFile from '../nav/file/DownloadFile'
import DeleteFile from './DeleteFile'
import { File } from '__generated__/graphql'

type Props = {
  file: Pick<File, 'id' | 'description' | 'shortNameFile' | 'type'>
  gridView?: boolean
  canDelete: boolean
  onDelete: () => void
  showDownload: boolean
}

const SingleFile = (props: Props) => {
  return (
    <>
      {props.gridView ? (
        <div
          key={props.file.id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              border: '1px solid black',
              borderRadius: '10px',
              width: 150,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '10px',
              textAlign: 'center',
              padding: '10px',
              boxSizing: 'border-box',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <IconFilePrivate type={props.file.type as string} />
            {props.file.shortNameFile &&
              (props.file.shortNameFile.length > 15 ? '...' + props.file.shortNameFile?.slice(-11) : props.file.shortNameFile)}
          </div>
          {props.canDelete && <DeleteFile fullButton fileId={props.file ? props.file.id : ''} onDelete={props.onDelete} />}
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} md={2} className="marginAuto">
            <IconFilePrivate type={props.file.type as string} />
          </Grid>
          <Grid item xs={12} md={6} className="marginAuto">
            <div>
              {props.file.shortNameFile &&
                (props.file.shortNameFile.length > 15 ? '...' + props.file.shortNameFile?.slice(-11) : props.file.shortNameFile)}
            </div>

            <div>{props.file.description && <div>{props.file.description}</div>}</div>
          </Grid>

          <Grid item xs={12} md={4} className="marginAuto tar">
            {props.showDownload && (
              <AdblockDetect>
                <DownloadFile file={props.file} />
              </AdblockDetect>
            )}
            {props.canDelete && <DeleteFile fileId={props.file.id} onDelete={props.onDelete} />}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SingleFile
