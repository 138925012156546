import React from 'react'
import { CategorieProductSelectorProvider } from '../context/CategorieProductSelectorContext'
import CategorieProductMultiSelectorWrapper from './CategorieProductMultiSelectorWrapper'

type Props = typeof CategorieProductMultiSelectorWrapper extends React.FC<infer P> ? P : never

const CategorieProductMultiSelector: React.FC<Props> = (props) => {
  return (
    <CategorieProductSelectorProvider productId={props.productId}>
      <CategorieProductMultiSelectorWrapper {...props} />
    </CategorieProductSelectorProvider>
  )
}

export default CategorieProductMultiSelector
