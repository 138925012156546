import React from 'react'
import IsValidated from '../../user/single/profile/sectionDetails/IsValidated'
import { PromoCode } from '__generated__/graphql'
import utils from 'components/utils'

type Props = {
  promoCode: Pick<
    PromoCode,
    'id' | 'code' | 'isRedeem' | 'description' | 'createdAt' | 'discount' | 'timeInMonth' | 'dateRedeem' | 'isLifeTimeDeal'
  >
}

const SinglePromoCodeBasicPlus = (props: Props) => {
  return (
    <>
      <>
        <h3>Promo Code</h3>
        <div>
          Promo Code: {props.promoCode.code}{' '}
          <IsValidated
            iconNotValidated="clear"
            icon="done"
            isValidated={props.promoCode.isRedeem}
            textValidated="Redeemed"
            textNotValidated="Not Redeemed"
          />
        </div>
        <div>Description: {props.promoCode.description}</div>
        <div>Discount: {props.promoCode.discount}%</div>

        <div>Final amount due: {utils.priceFormated(99 * (1 - props.promoCode.discount / 100), 'usd')}</div>
        {/* {props.promoCode.isLifeTimeDeal ? <div>Term: Forever</div> : <div>Term: {props.promoCode.timeInMonth} Months</div>} */}
      </>

      <div style={{ height: 30 }} />
      {/* <h3>Your Plan</h3>
      <CompanieUsageFee companieId={props.companieId} discount={props.promoCode.discount ?? 0} /> */}
      {/* <div>Fee: $444</div>
      <div>Free trial: 1 month free trial</div> */}
    </>
  )
}

export default SinglePromoCodeBasicPlus
