import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useAppContextWithCompany } from 'components/AppContext'
import { ReactComponent as SettingIcon } from 'assets/icon/settingIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/icon/homeIcon.svg'
import { ReactComponent as MarketplaceIcon } from 'assets/icon/marketplaceIcon.svg'

const DrawerLeftReviewer = () => {
  const context = useAppContextWithCompany()

  const location = useLocation()

  const pathnames = location.pathname.split('/')
  let baseURL = ''
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  if (pathnames.length > 2) {
    if (pathnames[1] === 'seller') {
      baseURL = pathnames[2]
    }
  }

  const columnMenuDrawer = {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 20,
  }

  const listItemStyle = {
    fontWeight: 'bold',
    fontSize: '0.78rem !important',
    height: 42,
    marginBottom: '0px !important',
    borderRadius: 20,
  }

  const listItemStyleSelected = {
    backgroundColor: '#2475ff',
    color: 'white',
    fill: 'white',
  }
  const listItemStyleNotSelected = {
    '&:hover': {
      color: '#1445fe!important',
      backgroundColor: '#faf8f7',
      fill: '#1445fe',
    },
  }
  const listItemIconClass = {
    marginLeft: 8,
    minWidth: 35,
  }

  const fontListItemText = {
    fontSize: '0.75rem',
    fontWeight: 400,
  }

  return (
    <div style={columnMenuDrawer}>
      <Link to="/">
        <ListItem
          id="ldrawer-home"
          sx={{
            ...listItemStyle,
            ...(baseURL === '' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
          onClick={() => context.setDrawerLeftMobile(false)}
        >
          <ListItemIcon style={listItemIconClass}>
            <HomeIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Home</div>
        </ListItem>
      </Link>

      <Link to="/settings/company">
        <ListItem
          id="ldrawer-settings"
          sx={{
            ...listItemStyle,
            ...(baseURL === 'settings' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
          onClick={() => context.setDrawerLeftMobile(false)}
        >
          <ListItemIcon style={listItemIconClass}>
            <SettingIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Settings</div>
        </ListItem>
      </Link>
      <Link to="/marketplace">
        <ListItem
          id="ldrawer-marketplace"
          sx={{
            ...listItemStyle,
            ...(baseURL === 'marketplace' ? listItemStyleSelected : listItemStyleNotSelected),
          }}
          onClick={() => context.setDrawerLeftMobile(false)}
        >
          <ListItemIcon style={listItemIconClass}>
            <MarketplaceIcon />
          </ListItemIcon>
          <div style={fontListItemText}>Marketplace</div>
        </ListItem>
      </Link>
    </div>
  )
}

export default DrawerLeftReviewer
