import { Rating as MuiRating, RatingProps } from '@mui/material'
import React from 'react'

type Props = {
  color?: string // default: black
} & RatingProps

const RatingNN: React.FC<Props> = (props) => {
  const color = props.color || 'black'

  return (
    <MuiRating
      {...props}
      precision={props.precision ?? 0.5}
      sx={{
        ...props.sx,
        '& .MuiRating-icon': { color, opacity: 0.26 },
        '& .MuiRating-iconFilled': { color, opacity: 1 },
      }}
    />
  )
}

export default RatingNN
