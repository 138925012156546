import { useContext } from 'react'
import { CategorieProductSelectorContext } from './CategorieProductSelectorContext'

export const useCategorieProductSelector = () => {
  const context = useContext(CategorieProductSelectorContext)

  if (!context) {
    throw new Error('useCategorieProductSelector must be used within a CategorieProductSelectorProvider')
  }

  return context
}
