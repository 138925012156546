import React from 'react'
import CountUp from 'react-countup'

type Props = {
  number: number
}

const CountUpFormatted: React.FC<Props> = (props: Props) => {
  if (99 < props.number && props.number < 1000) {
    return (
      <>
        0<CountUp duration={1} end={props.number} />
      </>
    )
  }

  if (9 < props.number && props.number < 100) {
    return (
      <>
        00
        <CountUp duration={1} end={props.number} />
      </>
    )
  }

  if (0 < props.number && props.number < 10) {
    return (
      <>
        000
        <CountUp duration={1} end={props.number} />
      </>
    )
  }

  return <CountUp duration={1} end={props.number} />
}

export default CountUpFormatted
