import React from 'react'
import { useQuery } from '@apollo/client'
import NotFound from '../nav/error/NotFound'
import Error from '../nav/error/Error'
import Loading from '../nav/error/Loading'
import PhoneLogic from '../user/single/phone/PhoneLogic'
import { useAppContext } from 'components/AppContext'
import { useKYCContext } from './KYCContext'
import { Button } from '@mui/material'
import { gql } from '__generated__'
import utils from 'components/utils'

const USER_QUERY = gql(/* GraphQL */ `
  query user_OnboardingValidaterPhoneUserQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      email
      enabled2FAPhone
      enabled2FAEmail
      enabled2FATotp
      isEmailValidated
      role
      isPhoneChangeRequestedPending
      phone
      phoneCode
      phoneChangeRequested
      phoneCodeChangeRequested
      isPhoneValidated
      isPhoneValidationRequired
      firstName
      lastName
      nameFile
      signupType
      whereDidyouHearAboutUs
      requestWhereDidyouHearAboutUs
      requestEmailValidatedOffSite
      verificationStatus
      dobMonth
      dobYear
      dobDay
      requestBirthdayOffSite
      showGettingStarted
      showInviteContactsFeature
    }
  }
`)

type Props = {
  userId: string
  onUpdate: () => void
}

const OnboardingValidaterPhoneUserQuery = (props: Props) => {
  const context = useAppContext()
  const kycContext = useKYCContext()
  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: {
      where: {
        id: props.userId,
      },
    },
  })
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />
  return (
    <>
      {data.user.phone && data.user.phoneCode && data.user.isPhoneValidated ? (
        <div className="tac" style={{ maxWidth: '350px', margin: '0 auto' }}>
          <h3>Phone number verification</h3>
          <div style={{ height: '30px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Your registered phone number is:</div>
            <div>
              <b>{utils.getPhoneFormatted(data.user.phoneCode, data.user.phone)}</b>
            </div>
          </div>
          <div style={{ height: '20px' }} />
        </div>
      ) : (
        <div className="tac">
          <PhoneLogic
            showCancelButton={false}
            user={data.user}
            onCancel={() => {}}
            onUpdate={async () => {
              await refetch()
              if (data.user) context.updateMe(data.user)
              kycContext.reloadKYCState()
              props.onUpdate()
            }}
          />
        </div>
      )}
      <div style={{ maxWidth: '350px', margin: '20px auto' }}>
        <Button
          id="idButtonAdditionalDetails"
          disabled={!kycContext.filledSteps['personalPhoneNumber']}
          size="medium"
          sx={{ width: '100%' }}
          color="primary"
          variant="contained"
          onClick={() => kycContext.nextStep()}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export default OnboardingValidaterPhoneUserQuery
