import React from 'react'
import { Tooltip, IconButton, Icon, Dialog, DialogContent } from '@mui/material'
import UpdateNickname from './UpdateNickname'
import { Source } from '__generated__/graphql'

type Props = {
  source: Pick<Source, 'id' | 'nickname'>
}

const UpdateNicknameDialog = (props: Props) => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      <Tooltip arrow title="Nickname">
        <IconButton size="small" onClick={() => setShow(true)}>
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
      <Dialog open={show} maxWidth="sm" fullWidth onClose={() => setShow(false)}>
        <DialogContent>
          <UpdateNickname onCancel={() => setShow(false)} onUpdate={() => setShow(false)} source={props.source} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UpdateNicknameDialog
