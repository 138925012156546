import React from 'react'
import { useAppContext } from 'components/AppContext'
import utils from './utils'

const MyAuthDevice = () => {
  const context = useAppContext()

  React.useEffect(() => {
    if (!context?.me?.id) return
    if (!context?.me?.email) return
    const authDevice = utils.getFullAuthDeviceData()
    if (!authDevice) return
    if (context.authDevice.isVerified === authDevice.isVerified) return
    if (!authDevice.id) return

    context.updateAuthDevice({ ...authDevice })
  }, [context])

  return null
}
export default MyAuthDevice
