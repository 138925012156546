import React, { useMemo } from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import CloseIcon from '@mui/icons-material/Close'
import { Badge, Box, IconButton, Modal, Slide, Theme, useMediaQuery } from '@mui/material'
import { gql } from '__generated__'
import { useMutation, useQuery } from '@apollo/client'
import Notifications from './Notifications'
import { useAppContext } from 'components/AppContext'
import useShowErrors from 'hooks/useShowErrors'

const NOTIFICATIONS_QUERY = gql(/* GraphQL */ `
  query notifications_NotificationsBell($skip: Int!, $take: Int!) {
    notifications(skip: $skip, take: $take) {
      id
      startAt
      pinned
      title
      description
      link
    }
  }
`)

const USER_QUERY = gql(/* GraphQL */ `
  query user_NotificationsBell($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      notificationsLastSeen
    }
  }
`)

const UPDATE_LAST_SEEN_MUTATION = gql(/* GraphQL */ `
  mutation updateNotificationLastSeen_NotificationsBell {
    updateNotificationLastSeen {
      id
      notificationsLastSeen
    }
  }
`)

const NotificationsBell = () => {
  const showErrors = useShowErrors()
  const { me } = useAppContext()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [open, setOpen] = React.useState(false)
  const [lastSeen, setLastSeen] = React.useState<string | null>(null)

  const { data } = useQuery(NOTIFICATIONS_QUERY, {
    variables: { skip: 0, take: 10 },
  })

  const { data: userData } = useQuery(USER_QUERY, {
    variables: { where: { id: me.id } },
    onCompleted: (data) => {
      if (open) return
      if (data?.user) setLastSeen(data.user.notificationsLastSeen ?? null)
    },
  })

  const [updateLastSeen] = useMutation(UPDATE_LAST_SEEN_MUTATION)

  const notifications = useMemo(() => {
    return data?.notifications || []
  }, [data?.notifications])

  const newNotifications = useMemo(() => {
    const user = userData?.user
    if (!user || !notifications) return 0
    const lastSeen = user.notificationsLastSeen
    if (!lastSeen) return notifications.length
    return notifications.filter(({ startAt }) => new Date(startAt) > new Date(lastSeen)).length
  }, [userData?.user, notifications])

  const openNotifications = async () => {
    setOpen(true)
    try {
      await updateLastSeen()
    } catch (error) {
      showErrors(error)
    }
  }

  const closeNotifications = () => {
    setOpen(false)
    setLastSeen(userData?.user?.notificationsLastSeen ?? new Date().toISOString())
  }

  return (
    <>
      <IconButton onClick={openNotifications}>
        <Badge badgeContent={newNotifications} invisible={newNotifications === 0} color="error">
          <NotificationsNoneIcon sx={{ fontSize: '24px' }} />
        </Badge>
      </IconButton>

      <Modal open={open} onClose={closeNotifications}>
        <Slide in={open} direction="left">
          <Box
            sx={{
              width: isMobile ? '100vw' : '500px',
              maxWidth: '100vw',
              height: '100vh',
              background: '#FAF8F7',
              position: 'absolute',
              right: 0,
              top: 0,
              overflowY: 'auto',
            }}
            className="hideScrollBar"
          >
            <Box py="29px" bgcolor="var(--blue)" textAlign="center" position="relative">
              <CloseIcon
                onClick={closeNotifications}
                sx={{ position: 'absolute', top: '10px', left: '10px', color: 'white', cursor: 'pointer' }}
              />
              <h3 className="fs-24 fw-700 text-white">
                Notifications
                {newNotifications > 0 ? ` (${newNotifications})` : ''}
              </h3>
            </Box>

            <Notifications notifications={notifications} lastSeen={lastSeen} onClick={closeNotifications} />
          </Box>
        </Slide>
      </Modal>
    </>
  )
}

export default NotificationsBell
