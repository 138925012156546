import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { useAppContextWithCompany } from 'components/AppContext'
import { gql } from '__generated__'
import { Companie } from '__generated__/graphql'

const MUTATION = gql(/* GraphQL */ `
  mutation submitApplication_SubmitApplication($companieId: ID!) {
    submitApplication(companieId: $companieId) {
      id
      statusVerification
    }
  }
`)

type Props = {
  companie: Pick<Companie, 'id' | 'statusVerification'>
  textButton: string
  onUpdate: (companie: Props['companie']) => void
  disabled?: boolean
}

const SubmitApplication = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [submitApplication] = useMutation(MUTATION)
  const context = useAppContextWithCompany()

  const submitApplicationF = async () => {
    setLoading(true)
    try {
      const { data } = await submitApplication({
        variables: {
          companieId: props.companie.id,
        },
      })
      if (data?.submitApplication) {
        const { statusVerification } = data.submitApplication
        context.updateUserRoleCompanieViaQuery()
        props.onUpdate({ ...props.companie, statusVerification })
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="submitApplication"
        id="SubmitApplicationId"
        icon=""
        color="primary"
        disabled={props.disabled || false}
        sx={{ width: '100%' }}
        variant="contained"
        size="medium"
        buttonText={props.textButton}
        buttonLoadingText="Loading..."
        onClick={() => submitApplicationF()}
        loading={loading}
      />
      <p className="secondary">{errorMessage}</p>
    </>
  )
}

export default SubmitApplication
