import React from 'react'
import TextFieldNN from 'components/ui/TextFieldNN'
import CreateAddresse from './CreateAddresse'
import UpdateAddressLogic from './UpdateAddressLogic'
import SelectCountryForm from './SelectCountryForm'
import SelectStateForm from './SelectStateForm'
import { bankCountriesMailing } from '../bankAddress/BankCountriesMailing'
import { bankCountriesBilling } from '../bankAddress/BankCountriesBilling'
import { bankCountriesShipping } from '../bankAddress/BankCountriesShipping'
import { BankCountriesHome } from '../bankAddress/BankCountriesHome'
import { Addresse, TypeAddresse } from '__generated__/graphql'
import { Box } from '@mui/material'

type Props = {
  onUpdate: () => void
  onCancel: () => void
  onCreate: () => void
  hideCancel?: boolean
  textButtonCreate: string
  textButtonUpdate: string
  textButtonCancel: string
  companieId?: string
  productId?: string
  userId: string
  addresse: Pick<Addresse, 'id' | 'zip' | 'country' | 'state' | 'type' | 'name' | 'city' | 'address1' | 'address2'>
}

export const AddresseFormShort = (props: Props) => {
  const [addresse, setAddresse] = React.useState(props.addresse)
  const [valid, setValid] = React.useState({
    cityValid: true,
    stateValid: true,
    nameValid: true,
  })

  const onChangeCountry = (country: string) => {
    setAddresse({ ...addresse, country, state: '' })
  }

  const onChangeState = (state: string) => {
    setAddresse({ ...addresse, state })
    setValid({ ...valid, stateValid: state.length ? true : false })
  }

  const isFormValid = () => {
    return valid.cityValid && valid.stateValid && addresse.city.length && addresse.state.length
  }

  return (
    <>
      {addresse.type === 'SHIPPING' && (
        <Box>
          <TextFieldNN
            label="Name"
            id="id-addressName"
            type="text"
            error={!valid.nameValid}
            value={addresse.name}
            onChange={(e) => {
              setAddresse({ ...addresse, name: e.target.value })
              setValid({ ...valid, nameValid: e.target.value.length ? true : false })
            }}
          />
        </Box>
      )}

      <Box>
        {addresse.type === 'BILLING' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={bankCountriesBilling}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}

        {addresse.type === 'SHIPPING' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={bankCountriesShipping}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}

        {addresse.type === 'HOME' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={BankCountriesHome}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}

        {addresse.type === 'PAYMENT_SOURCE_BILLING_ADDRESS' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={BankCountriesHome}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}

        {addresse.type === 'MAILING' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={bankCountriesMailing}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}

        {addresse.type === 'MAVEN' && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={bankCountriesMailing}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}
        {addresse.type === TypeAddresse.LeadershipCompanie && (
          <SelectCountryForm
            id="addressCountry"
            label="Country"
            bankCountries={bankCountriesMailing}
            country={addresse.country}
            onUpdate={onChangeCountry}
          />
        )}
      </Box>

      <Box>
        <SelectStateForm
          id="addressState"
          state={addresse.state}
          error={!valid.stateValid}
          country={addresse.country}
          onUpdate={(state: string) => onChangeState(state)}
        />
      </Box>

      <Box>
        <TextFieldNN
          label="City"
          id="addressCity"
          error={!valid.cityValid}
          type="text"
          value={addresse.city}
          onChange={(e) => {
            setAddresse({ ...addresse, city: e.target.value })
            setValid({ ...valid, cityValid: e.target.value.length >= 3 ? true : false })
          }}
        />
      </Box>

      <br />
      <Box>
        <>
          {addresse.id ? (
            <UpdateAddressLogic
              textButton={props.textButtonUpdate}
              textButtonCancel={props.textButtonCancel}
              onUpdate={props.onUpdate}
              disabled={!isFormValid()}
              addresse={addresse}
              onCancel={props.onCancel}
              hideCancel={props.hideCancel}
            />
          ) : (
            <CreateAddresse
              textButton={props.textButtonCreate}
              textButtonCancel={props.textButtonCancel}
              disabled={!isFormValid()}
              userId={props.userId}
              onCreate={() => props.onCreate()}
              companieId={props.companieId}
              productId={props.productId}
              addresse={addresse}
              onCancel={props.onCancel}
              hideCancel={props.hideCancel}
            />
          )}
        </>
      </Box>
    </>
  )
}

export default AddresseFormShort
