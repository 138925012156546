import React from 'react'
import { Autocomplete, Grid, TextField, useMediaQuery, Theme } from '@mui/material'
import { format } from 'date-fns'
import isExists from 'date-fns/isExists'
import { Maybe } from '__generated__/graphql'

type Props = {
  year?: Maybe<number>
  month?: Maybe<number>
  day?: Maybe<number>
  onUpdateYear: (year: number) => void
  onUpdateDay: (dat: number) => void
  onUpdateMonth: (dat: number) => void
  isValid: (isValid: boolean) => void
}

type Selection = { label: string; value: number } | undefined

const DateComponentDropDown = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [isValid, setIsValid] = React.useState(true)

  React.useEffect(() => {
    if (!(props.year && props.month && props.day)) return
    const isValidBoolean = isExists(props.year, props.month - 1, props.day)
    setIsValid(isValidBoolean)
    props.isValid(isValidBoolean)
  }, [props])

  const range = (start: number, end: number) => {
    const foo: Selection[] = []
    for (let i: number = start; i <= end; i++) {
      foo.push({ label: i.toString(), value: i })
    }
    return foo
  }

  const arrayDay = range(1, 31)
  const arrayYear = range(1930, new Date().getFullYear() - 14)

  const arrayMonth: Selection[] = []
  for (let i = 1; i < 13; i++) {
    arrayMonth.push({ label: format(new Date(2022, i - 1, 1), 'MMMM'), value: i })
  }
  const [monthSelected, setMonthSelected] = React.useState(
    props.month ? arrayMonth.find((data) => data?.value === props.month) : { label: '', value: 1 },
  )
  const [daySelected, setDaySelected] = React.useState(
    props.day ? arrayDay.find((data) => data?.value === props.day) : { label: '', value: 1 },
  )
  const [yearSelected, setYearSelected] = React.useState(
    props.year ? arrayYear.find((data) => data?.value === props.year) : { label: '', value: 1930 },
  )

  return (
    <div>
      <div style={{ width: isMobile ? undefined : '100%', display: 'inline-block' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5.5}>
            <Autocomplete
              disableClearable={true}
              disablePortal
              value={monthSelected}
              isOptionEqualToValue={(option, data) => option?.value === data?.value}
              onChange={(_event, newValue) => {
                props.onUpdateMonth(newValue.value)
                setMonthSelected(newValue)
              }}
              id="id-Month"
              options={arrayMonth}
              renderInput={(params) => <TextField {...params} size="small" label="Month" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disableClearable={true}
              disablePortal
              value={daySelected}
              isOptionEqualToValue={(option, data) => option?.value === data?.value}
              onChange={(_event, newValue) => {
                props.onUpdateDay(newValue.value)
                setDaySelected(newValue)
              }}
              id="id-Day"
              options={arrayDay}
              renderInput={(params) => <TextField {...params} size="small" label="Day" />}
            />
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <Autocomplete
              disableClearable={true}
              disablePortal
              onOpen={(event) => {
                props.onUpdateYear(1985)
                setYearSelected({ label: '1985', value: 1985 })
              }}
              value={yearSelected}
              isOptionEqualToValue={(option, data) => option?.value === data?.value}
              onChange={(_event, newValue) => {
                props.onUpdateYear(newValue.value)
                setYearSelected(newValue)
              }}
              id="id-Year"
              options={arrayYear}
              renderInput={(params) => <TextField {...params} size="small" label="Year" />}
            />
          </Grid>
        </Grid>

        {!isValid && (
          <div className="secondary tal" style={{ height: 20, marginTop: 10 }}>
            Invalid date!
          </div>
        )}
      </div>
    </div>
  )
}

export default DateComponentDropDown
