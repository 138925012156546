import React from 'react'
import { Link } from 'react-router-dom'
import { Paper, Grid } from '@mui/material'
import ContactUsOnboardingLink from '../../ContactUsOnboardingLink'
import MenuLeftDesktop from '../../../user/single/drawer/topLeft/MenuLeftDesktop'
import LogOutCross from 'components/nav/LogoutCross'
import LogoNN from 'assets/logo/nn/nachonacho-full-blue-horizontal.svg'

const OnboardingCompanieSeller = () => {
  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <>
            <Grid container>
              <Grid item xs={6}>
                <MenuLeftDesktop />
              </Grid>
              <Grid item xs={6} className="tar">
                <LogOutCross />
              </Grid>
            </Grid>

            <div className="tac margin6">
              <Link to="/">
                <img alt="logo" className="logoNachoNacho" src={LogoNN} />
              </Link>
            </div>
            <div className="tac responsiveMargin2" />
          </>
          <Grid container>
            <Grid item xs={12} sm={12} className="tar">
              <ContactUsOnboardingLink />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  )
}

export default OnboardingCompanieSeller
