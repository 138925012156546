function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        console.log(error)
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          console.log('looping')

          if (process.env.REACT_APP_ENV === 'production') {
            window.location.reload()
          }
        }, interval)
      })
  })
}

export default retry
