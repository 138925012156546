import React from 'react'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import SingleFile from './SingleFile'
import NotFound from 'components/nav/error/NotFound'
import CreateFile from './form/CreateFile'
import { gql } from '__generated__'
import { TypeFile } from '__generated__/graphql'

type Props = {
  textDragNDrop: string
  typeFile: TypeFile
  userId?: string
  perkId?: string
  companieId?: string
  subscriptionId?: string
  contractId?: string
  invoiceId?: string
  disputeIssuingId?: string
  sourceId?: string
  hideDelete?: boolean
  showDownload: boolean
  errorMessageFileNotPermited: string
  acceptedImageTypes: string[]
  fileSizeMax: number
  onCreate: () => void
  onDelete?: () => void
  onGetFilesLength: (length: number) => void
}

const QUERY = gql(/* GraphQL */ `
  query filesConnection_ManageFileGrid($where: FileWhereInput!, $orderBy: FileOrderByInput, $skip: Int, $first: Int) {
    filesConnection(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      edges {
        node {
          id
          description
          typeFile
          shortNameFile
          type
          fileScanStatus
        }
      }
      aggregate {
        count
      }
    }
  }
`)

const ManageFileGrid = (props: Props) => {
  const [loadingData, setLoadingData] = React.useState(true)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      where: {
        typeFile: props.typeFile,
        user: props.userId ? { id: props.userId } : undefined,
        companie: props.companieId ? { id: props.companieId } : undefined,
        subscription: props.subscriptionId ? { id: props.subscriptionId } : undefined,
        contract: props.contractId ? { id: props.contractId } : undefined,
        invoice: props.invoiceId ? { id: props.invoiceId } : undefined,
        disputeIssuing: props.disputeIssuingId ? { id: props.disputeIssuingId } : undefined,
        source: props.sourceId ? { id: props.sourceId } : undefined,
        perk: props.perkId ? { id: props.perkId } : undefined,
      },
    },
  })

  React.useEffect(() => {
    if (data?.filesConnection) {
      props.onGetFilesLength(data.filesConnection.edges.length)
    }
  }, [data, props])

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.filesConnection) return <NotFound />
  if (loadingData) {
    setLoadingData(false)
  }

  const filesCount = data.filesConnection.edges.length
  return (
    <>
      {filesCount === 0 ? (
        <CreateFile
          textDragNDrop={props.textDragNDrop}
          fileSizeMax={props.fileSizeMax}
          errorMessageFileNotPermited={props.errorMessageFileNotPermited}
          acceptedImageTypes={props.acceptedImageTypes}
          typeFile={props.typeFile}
          onCancel={() => {}}
          onCreate={() => {
            props.onCreate()
            refetch()
          }}
          subscriptionId={props.subscriptionId}
          contractId={props.contractId}
          invoiceId={props.invoiceId}
          companieId={props.companieId}
          disputeIssuingId={props.disputeIssuingId}
          sourceId={props.sourceId}
          userId={props.userId}
          perkId={props.perkId}
        />
      ) : (
        <>
          {data.filesConnection.edges.map((fileNode) =>
            fileNode ? (
              <SingleFile
                key={fileNode.node.id}
                file={fileNode.node}
                gridView={true}
                canDelete={!props.hideDelete}
                onDelete={() => {
                  refetch()
                  if (props.onDelete) props.onDelete()
                }}
                showDownload={props.showDownload}
              />
            ) : (
              <></>
            ),
          )}
        </>
      )}
    </>
  )
}

export default ManageFileGrid
