import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { useAppContextWithCompany } from 'components/AppContext'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import client from 'providers/apollo'

const MUTATION = gql(/* GraphQL */ `
  mutation setDefaultSource_SetDefaultSource($where: SourceWhereUniqueInput!) {
    setDefaultSource(where: $where) {
      id
    }
  }
`)

type Props = {
  sourceId: string
  onSucceed: () => void
}

const SetDefaultSource = (props: Props) => {
  const showErrors = useShowErrors()
  const context = useAppContextWithCompany()
  const [message, setMessage] = React.useState('')

  const [setDefaultSource] = useMutation(MUTATION)

  const [loading, setLoading] = React.useState(false)

  const setDefaultSourceF = async () => {
    setLoading(true)
    try {
      const defaultSource = await setDefaultSource({
        variables: {
          where: {
            id: props.sourceId,
          },
        },
      })

      setLoading(false)
      if (defaultSource) {
        context.openSnackbar('Payment Source successfully set as primary', 'success')
        await client.resetStore()
        props.onSucceed()
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      showErrors(e, setMessage)
    }
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="setDefaultSource"
        id="idButtonSetDefaultSource"
        icon=""
        color="primary"
        disabled={false}
        variant="outlined"
        size="medium"
        buttonText="Set as primary"
        buttonLoadingText="Loading..."
        onClick={setDefaultSourceF}
        loading={loading}
      />
      {message && (
        <div id="idMessage" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default SetDefaultSource
