import React from 'react'
import Icon from '@mui/material/Icon'
import { Badge } from '@mui/material'
import { gql } from '__generated__/gql'
import { useQuery } from '@apollo/client'

const QUERY = gql(/* GraphQL */ `
  query leadsConnection_SellerLeadsBadge($where: LeadWhereInput!, $orderBy: LeadOrderByInput, $first: Int, $skip: Int) {
    leadsConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      count
    }
  }
`)

type Props = {
  productId: string
  baseURL: string
}

const SellerLeadsBadge = (props: Props) => {
  const { data } = useQuery(QUERY, {
    variables: { where: { productId: props.productId, isArchived: false } },
  })
  return (
    <Badge badgeContent={data?.leadsConnection.count} color="primary">
      <Icon className={`iconDrawer ${props.baseURL === 'leads' && 'menuSelected'}`}>people_alt</Icon>
    </Badge>
  )
}

export default SellerLeadsBadge
