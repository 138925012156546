import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { Grid, Button } from '@mui/material'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import '../Card.css'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import SingleAddresse from '../../addresse/SingleAddresse'
import useShowErrors from 'hooks/useShowErrors'
import { Addresse, Companie, User } from '__generated__/graphql'
import { gql } from '__generated__'
import TextFieldNN from 'components/ui/TextFieldNN'

const MUTATION = gql(/* GraphQL */ `
  mutation createCard_AddCard($tokenCardId: String!, $companieId: String!) {
    createCard(tokenCardId: $tokenCardId, companieId: $companieId) {
      id
    }
  }
`)

type Props = {
  companie: Pick<Companie, 'id' | 'requestCardVerification'>
  addresse: Pick<Addresse, 'id' | 'zip' | 'country' | 'state' | 'type' | 'name' | 'city' | 'address1' | 'address2'>
  onCancel: () => void
  onSuccess: () => void
  user: Pick<User, 'firstName' | 'lastName'>
}

const AddCard = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const [createCardMutation] = useMutation(MUTATION)
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = React.useState('')
  const [name, setName] = React.useState(`${props.user.firstName} ${props.user.lastName}`)

  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe) return
    if (!elements) return
    setLoading(true)

    const element = elements.getElement(CardElement)
    if (element === null) {
      setMessage('Unfortunately your card did not work. Please try again or use a different card.')
      setLoading(false)
      return
    }

    if (!name.length) {
      setMessage('Cardholder Name of the card is mandatory')
      setLoading(false)
      return
    }

    const { token } = await stripe.createToken(element, {
      name,
      address_line1: props.addresse.address1,
      address_line2: props.addresse.address2,
      address_city: props.addresse.city,
      address_state: props.addresse.state,
      address_zip: props.addresse.zip,
      address_country: props.addresse.country,
    })

    if (!token) {
      setMessage('Invalid data. Please check the details and try again.')
      setLoading(false)
      return
    }

    try {
      const card = await createCardMutation({
        variables: {
          tokenCardId: token.id,
          companieId: props.companie.id,
        },
      })

      setLoading(false)

      if (card) {
        await client.resetStore()
        props.onSuccess()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
      throw e
    }
  }

  return (
    <div>
      {props.companie.requestCardVerification && (
        <p className="secondary">
          We will charge your card $1.00 for verification purposes. You will need to log into your card account, and retrieve a 6
          letter code in the statement descriptor next to the $1.00 charge. It starts with NN and has 4 further characters
          (NN----). You will need to enter this code on the Payment Source page. The charge in your card account may appear
          instantly or could take 1-2 business days.
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <TextFieldNN
            label="Cardholder Name"
            id="name"
            type="text"
            error={name.length === 0}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ height: '40px' }} />

        <SingleAddresse title="Payment Source Billing Address" addresse={props.addresse} />

        <div style={{ height: '40px' }} />
        <div>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <h3>Add your card details</h3>
            </Grid>
            <Grid item xs={12} sm={4} className="tac">
              <img alt="Card" className="cardsImg" src="/cards.png" />
            </Grid>
          </Grid>
          <CardElement />
        </div>
        <div className="secondary">{message}</div>
        <Grid container>
          <Grid item xs={12} sm={12} className="tac">
            <div style={{ height: '20px' }} />
            <Button onClick={props.onCancel}>Cancel</Button>{' '}
            <ButtonLoadingAfterClick
              actionName="addCard"
              id="idButtonAddCard"
              size="medium"
              type="submit"
              disabled={!stripe}
              icon=""
              color="primary"
              variant="contained"
              buttonText="Add"
              buttonLoadingText="Setting up..."
              onClick={() => {}}
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default AddCard
