import React, { useMemo } from 'react'
import ImageTemplate from '../ImageTemplate'
import { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button'
import axios from 'axios'
import { useAppContext } from '../../AppContext'
import { useLazyQuery } from '@apollo/client'
import { Alert, LinearProgress } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import { TypeMedia } from '__generated__/graphql'

const QUERY_SIGNED_URL = gql(/* GraphQL */ `
  query generatePostSignedUrl_UploadFile($data: [GeneratePostPresignedUrl!]!) {
    generatePostSignedUrl(data: $data) {
      presignedurl
    }
  }
`)

type Props = {
  format: 'FREE' | 'SQUARE' | '1200_630'
  nameFile: string
  textDragNDrop: string
  deleteImageText: string
  onSelectFile: (file: string) => void
  recommendedDimensions?: {
    width: number
    height: number
    maxSize: string
  }
}

const UploadFile = (props: Props) => {
  const context = useAppContext()
  const { format, nameFile, deleteImageText, onSelectFile, recommendedDimensions } = props
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [Files, setFiles] = React.useState<File[]>([])
  const removeImage = () => {
    onSelectFile('')
  }

  const isValidImageFormat = React.useCallback(
    (file: File, image: HTMLImageElement): boolean => {
      if (format === 'SQUARE' && image.width !== image.height) {
        setMessage(`The image "${file.name}" is ${image.width}x${image.height}. It must be a square image.`)
        return false
      }
      return true
    },
    [format],
  )

  const loadToServerInput = (presignedUrlData: { generatePostSignedUrl: string }) => {
    const presigned_url = JSON.parse(presignedUrlData.generatePostSignedUrl)
    const post_form = new FormData()
    for (const key in presigned_url.fields) {
      post_form.append(key, presigned_url.fields[key as keyof typeof presigned_url.fields])
    }
    return { url: presigned_url.url, form: post_form }
  }

  const uploadFileToServer = async (file: File, presignedUrlInput: { url: string; form: FormData }) => {
    setLoading(true)
    presignedUrlInput.form.append(
      'tagging',
      `<Tagging><TagSet><Tag><Key>userId</Key><Value>${context.me.id}</Value></Tag></TagSet></Tagging>`,
    )
    presignedUrlInput.form.append('Content-Type', file.type)
    presignedUrlInput.form.append('file', file)
    try {
      await axios(presignedUrlInput.url, {
        method: 'POST',
        data: presignedUrlInput.form,
      })
    } catch (e) {
      showErrors(e, setMessage)
      return
    } finally {
      setLoading(false)
    }
    onSelectFile(presignedUrlInput.form.get('key')?.toString() || '')
  }

  const [getAndConsumePresignedUrl] = useLazyQuery(QUERY_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      await uploadFileToServer(Files[0], loadToServerInput({ generatePostSignedUrl: data.generatePostSignedUrl[0].presignedurl }))
    },
    onError: (err) => {
      showErrors(err, setMessage)
    },
  })

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      setMessage('')
      if (acceptedFiles.length !== 1) return setMessage('Please select only one file')
      const file = acceptedFiles[0]
      const acceptedImageTypes = ['image/jpeg', 'image/png']
      if (!acceptedImageTypes.includes(file.type)) return setMessage('Must be an image. Format JPG or PNG')
      if (file.size > 3000000) return setMessage('File is too big. Max 3MB.')
      const image = new Image()
      image.addEventListener('load', () => {
        if (!isValidImageFormat(file, image)) return
        setFiles([file])
        getAndConsumePresignedUrl({
          variables: {
            data: { fileName: `${Date.now()}_${file.name}`, contentType: file.type, typeMedia: TypeMedia.PublicFile, id: '0' },
          },
        })
      })
      image.src = window.URL.createObjectURL(file)
    },
    [getAndConsumePresignedUrl, isValidImageFormat],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noDragEventsBubbling: true })

  const dimensionsAlert = useMemo(() => {
    if (!recommendedDimensions) return null
    const { width, height, maxSize } = recommendedDimensions
    return (
      <Alert severity="info" sx={{ alignItems: 'center' }}>
        Recommended dimensions: {width} x {height}px
        <br />
        Max. size: {maxSize}
      </Alert>
    )
  }, [recommendedDimensions])

  return (
    <div>
      {nameFile ? (
        <>
          <ImageTemplate format="roundLarge" nameFile={nameFile} />
          {nameFile && <Button onClick={() => removeImage()}>{deleteImageText}</Button>}
        </>
      ) : (
        <div
          {...getRootProps()}
          style={{
            backgroundColor: isDragActive ? '#ccc' : '#eee',
            padding: '2px',
            cursor: loading ? 'default' : 'pointer',
          }}
        >
          <input {...getInputProps()} type="file" accept="image/png, image/jpeg" disabled={loading} />
          <div
            style={{
              border: '2px dashed #333',
              padding: '20px',
              borderRadius: '4px',
            }}
          >
            {loading && <LinearProgress />}
            {!loading && <>{isDragActive ? 'Drop the files here ...' : props.textDragNDrop}</>}
          </div>
        </div>
      )}
      <p className="secondary">{message}</p>

      {dimensionsAlert}
    </div>
  )
}

export default UploadFile
