import { Box, Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import NotificationsBell from './NotificationsBell'
import ProfileMenu from './ProfileMenu'
import { useAppContext } from 'components/AppContext'
import { TypeCompanie } from '__generated__/graphql'
import TutorialButton from './TutorialButton'

const ProfileWrapper = () => {
  const context = useAppContext()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box
      {...(isMobile && { display: 'flex', justifyContent: 'flex-end', mr: '5px', mt: '5px' })}
      {...(!isMobile && { position: 'absolute', top: 15, right: 40, zIndex: 10 })}
    >
      <Box display="flex" gap="16px" alignItems="center">
        <div>
          {context.userRoleCompanie?.companie.typeCompanie === TypeCompanie.Buyer && <TutorialButton />}
          {context.userRoleCompanie?.companie.typeCompanie === TypeCompanie.Tribe && <TutorialButton />}
          {context.userRoleCompanie?.companie.typeCompanie === TypeCompanie.Seller && <TutorialButton />}
          {context.userRoleCompanie?.companie.typeCompanie === TypeCompanie.Maven && <TutorialButton />}
          {context.userRoleCompanie?.companie.typeCompanie === TypeCompanie.Buyer && <NotificationsBell />}
        </div>
        <ProfileMenu />
      </Box>
    </Box>
  )
}

export default ProfileWrapper
