import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/client'
import { Button, Grid } from '@mui/material'
import ReviewForm from './ReviewForm'
import ImageTemplate from '../../nav/ImageTemplate'
import { nachoNachoProductId, URL_HOME } from 'config/config'
import useShowErrors from 'hooks/useShowErrors'
import { Review, Product } from '__generated__/graphql'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation updateReview_EditReview($data: ReviewUpdateInput!, $where: ReviewWhereUniqueInput!) {
    updateReview(data: $data, where: $where) {
      id
      rating
      headline
      title
      userTypeReview
      content
    }
  }
`)

type Props = {
  review: Pick<Review, 'id' | 'content' | 'headline' | 'title' | 'userTypeReview' | 'rating' | 'companieId'> & {
    product: Pick<Product, 'id' | 'name' | 'nameFile' | 'urlName' | 'visibility'>
  }
}

const EditReview = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const navigate = useNavigate()

  const isNNProduct = nachoNachoProductId === props.review.product.id
  const link = isNNProduct ? `${URL_HOME}/userFeedback` : `${URL_HOME}/product/${props.review.product.urlName}`

  const [review, setReview] = React.useState(props.review)
  const [formValid, setFormValid] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const [updateReview] = useMutation(MUTATION)
  const updateReviewF = async () => {
    try {
      const reviewUpdated = await updateReview({
        variables: {
          data: {
            rating: review.rating,
            userTypeReview: review.userTypeReview,
            title: review.title,
            headline: review.headline,
            content: review.content,
          },
          where: {
            id: review.id,
          },
        },
      })
      if (reviewUpdated) {
        client.resetStore()
        if (props.review) {
          navigate(`/reviews`)
        }
      }
    } catch (e) {
      console.log(e)
      showErrors(e, setMessage)
    }
  }
  return (
    <div className="paperOut">
      <div>
        <a className="link" href={link}>
          <ImageTemplate format="small" nameFile={props.review.product.nameFile} />
        </a>
      </div>
      <h3>Review for {props.review.product.name}</h3>
      <div style={{ height: '30px' }} />
      <Grid container>
        <Grid item xs={12} sm={8}>
          <ReviewForm
            product={props.review.product}
            review={review}
            onFormValidation={(formValid: boolean) => setFormValid(formValid)}
            setReview={(updatedReview) => setReview({ ...review, ...updatedReview })}
          />
        </Grid>
      </Grid>
      <div style={{ height: '10px' }} />
      <Link to="/reviews">
        <Button>Cancel</Button>
      </Link>{' '}
      <Button disabled={!formValid} color="primary" variant="contained" onClick={updateReviewF}>
        Save
      </Button>
      {message && (
        <div id="idMessage-EditReview" className="secondary">
          {message}
        </div>
      )}
    </div>
  )
}

export default EditReview
