import React from 'react'
import { useQuery } from '@apollo/client'
import { useAppContext } from 'components/AppContext'
import { gql } from '__generated__'

const ME_QUERY = gql(/* GraphQL */ `
  query me_MyMe {
    me {
      id
      role
      firstName
      lastName
      nameFile
      email
      signupType
      isEmailValidated
      isPhoneValidated
      whereDidyouHearAboutUs
      requestWhereDidyouHearAboutUs
      requestEmailValidatedOffSite
      verificationStatus
      dobYear
      dobMonth
      dobDay
      requestBirthdayOffSite
      isPhoneValidationRequired
      showGettingStarted
      enabled2FAPhone
      enabled2FAEmail
      enabled2FATotp
      showInviteContactsFeature
    }
  }
`)

const MyMe = () => {
  const { data, loading, error } = useQuery(ME_QUERY)
  const context = useAppContext()

  React.useEffect(() => {
    context.updateIsMeLoading(loading)
    if (data?.me?.id) {
      context.updateMe(data.me)
    } else if (error) {
      context.updateIsUserRoleCompanieLoading(false)
    }
  }, [data, loading, error, context])

  return null
}
export default MyMe
