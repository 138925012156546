import React from 'react'
// import { Invoice } from '../../Invoice.type'
import ImageTemplate from '../../../nav/ImageTemplate'
import { URL_FRONTEND } from '../../../../config/config'
import { Invoice, Product } from '__generated__/graphql'
import TopupIcon from '../../../../assets/icon/topupIcon.svg'
import AutoTopupIcon from '../../../../assets/icon/autoTopupIcon.svg'

type Props = {
  invoice: Pick<Invoice, 'type'> & { product?: Pick<Product, 'id' | 'nameFile'> | null }
  format: 'small' | 'roundSmall'
}

const ImageInvoice = (props: Props) => {
  if (props.invoice.type === 'TOP_UP') {
    return <ImageTemplate format={props.format} nameFile={TopupIcon} />
  }
  if (props.invoice.type === 'AUTO_TOP_UP') {
    return <ImageTemplate format={props.format} nameFile={AutoTopupIcon} />
  }
  if (props.invoice.type === 'PLATFORM_FEES') {
    return <ImageTemplate format={props.format} nameFile={`${URL_FRONTEND}/icon/platformFees.png`} />
  }
  if (props.invoice.type === 'PHYSICAL_CARD_FEES') {
    return <ImageTemplate format={props.format} nameFile="icon/physicalCardPlatformFee.png" />
  }
  if (props.invoice.type === 'REFUND_CASH_OUT') {
    return <ImageTemplate format={props.format} nameFile={`${URL_FRONTEND}/icon/refund.png`} />
  }
  if (props.invoice.product) {
    return <ImageTemplate format={props.format} nameFile={props.invoice.product.nameFile} />
  }

  return null
}

export default ImageInvoice
