import React from 'react'
import { Grid } from '@mui/material'
import OnboardingAddresses from 'components/addresse/list/OnboardingAddresses'
import { TypeAddresse } from '__generated__/graphql'
import { useSellerKYCContext } from './kyc/seller/SellerKYCContext'

type Props = {
  userId: string
  companieId: string
  onCreate: () => void
  onUpdate: () => void
  onCancel: () => void
}

const SellerCompanieAddresssForm = (props: Props) => {
  const sellerKYCContext = useSellerKYCContext()
  return (
    <>
      <div className="titleStepKYC">Your company address</div>
      <p
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        Your primary place of business
      </p>
      <Grid container spacing={1} style={{ maxWidth: '350px', margin: '0 auto' }}>
        <Grid item xs={12} sm={12}>
          <OnboardingAddresses
            type={TypeAddresse.Mailing}
            onCreate={() => {
              sellerKYCContext.reloadKYCState()
              props.onCreate()
            }}
            onUpdate={() => {
              sellerKYCContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            userId={props.userId}
            companieId={props.companieId}
            variables={{
              where: {
                companie: {
                  id: props.companieId,
                },
                type: 'MAILING',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SellerCompanieAddresssForm
