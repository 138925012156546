import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { TextField } from '@mui/material'
import { useKYCContext } from 'components/onboarding/KYCContext'
import { Companie } from '__generated__/graphql'
import UpdateCompanie from 'components/companie/form/UpdateCompanie'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  companie: Pick<Companie, 'id' | 'registeredBusinessName' | 'name' | 'website'>
}

const MavenCompanieForm = (props: Props) => {
  const kycContext = useKYCContext()
  const [companie, setCompanie] = React.useState(props.companie)
  const [companieNameValid, setCompanieNameValid] = React.useState(true)
  const [registeredBusinessNameValid, setRegisteredBusinessNameValid] = React.useState(true)
  const isFormValid = () => {
    return companieNameValid && registeredBusinessNameValid && companie.name && companie.registeredBusinessName
  }
  return (
    <>
      <div className="titleStepKYC">About Maven</div>
      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div>
          <FormControl className="width100per" variant="standard">
            <InputLabel shrink htmlFor="name">
              <b className="black2">Company name</b>
            </InputLabel>
            <TextField
              id="companyName"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.name}
              error={!companieNameValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  name: e.target.value,
                })

                setCompanieNameValid(e.target.value.length >= 3)
              }}
            />
            {!companieNameValid && <FormHelperText error>Min. 3 characters</FormHelperText>}
          </FormControl>
        </div>

        <div>
          <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
            <InputLabel shrink htmlFor="registeredBusinessName">
              <b className="black2">Company legal business name</b>
            </InputLabel>
            <TextField
              id="registeredBusinessName"
              type="text"
              size="small"
              className="width100per spaceForm"
              value={companie.registeredBusinessName}
              error={!registeredBusinessNameValid}
              onChange={(e) => {
                setCompanie({
                  ...companie,
                  registeredBusinessName: e.target.value,
                })

                setRegisteredBusinessNameValid(e.target.value.length >= 3 ? true : false)
              }}
            />
            {!registeredBusinessNameValid && <FormHelperText error>Min. 3 characters</FormHelperText>}
          </FormControl>
        </div>

        <div style={{ height: '20px' }} />

        <div>
          <UpdateCompanie
            fullWidth
            actionName="updateCompanyOnboarding"
            disabled={!isFormValid()}
            showCancelButton={false}
            textButton="Next"
            textCancelButton=""
            onUpdate={() => {
              kycContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            companie={{
              id: companie.id,
              name: companie.name,
              registeredBusinessName: companie.registeredBusinessName,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default MavenCompanieForm
