import React from 'react'
import { FormControl, Grid, InputLabel, TextField } from '@mui/material'
import ProductsQueryAutocompleteLight from './ProductsQueryAutocompleteLight'
import { Product, IssuedCard } from '__generated__/graphql'

type Props = {
  issuedCard: Pick<IssuedCard, 'name'>
  label?: string
  placeholder?: string
  hideEnter?: boolean
  disabled?: boolean
  onChange: (name: string) => void
  onSelect: (product: Product | null) => void
  enterName?: (name: string) => void
}

const AutocompleteProductsIssuedCard = (props: Props) => {
  const [showResults, setShowResults] = React.useState(false)
  const { issuedCard } = props

  const onClick = (product: Product) => {
    setShowResults(false)
    props.onSelect(product)
  }

  const onInputChange = (name: string) => {
    if (name.length <= 25) {
      setShowResults(true)
      props.onChange(name)
    }
  }

  return (
    <FormControl className="width100per" variant="standard">
      {issuedCard.name && showResults && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            paddingTop: '30px',
            zIndex: 9999,
            background: 'white',
            marginTop: 30,
            boxShadow: '0px 4px 10px rgb(0 0 0 / 15%)',
            borderRadius: '0 0 8px 8px',
          }}
        >
          {!props.hideEnter && (
            <div
              style={{ margin: '10px', cursor: 'pointer', padding: '15px 0' }}
              className=" bgHover"
              onClick={() => {
                setShowResults(false)
                props.enterName && props.enterName(issuedCard.name)
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>Enter {`"${issuedCard.name}"`}</Grid>
              </Grid>
            </div>
          )}
          <ProductsQueryAutocompleteLight
            onClick={(product) => onClick(product as Product)}
            sortByPromotions={true}
            variables={{
              take: 10,
              skip: 0,
              filter: { showShowcase: true },
              searchString: issuedCard.name,
            }}
            showPromotions={true}
          />
        </div>
      )}
      <InputLabel shrink htmlFor="issuedCardTitle" style={{ width: '133%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <b className="black2">{props.label}</b>
          <b style={{ color: issuedCard.name.length < 3 ? '#FF7A00' : '#161716' }}>{issuedCard.name.length}/25</b>
        </div>
      </InputLabel>
      <TextField
        id="issuedCardTitle"
        size="small"
        className="width100per spaceForm"
        style={{ zIndex: 9999 }}
        autoFocus
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : ''}
        inputProps={{ id: 'input-nachocard-name', input: 'data-hj-allow' }}
        onChange={(e) => onInputChange(e.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' && !props.hideEnter) {
            setShowResults(false)
          }
        }}
        type="text"
        value={issuedCard.name}
      />
    </FormControl>
  )
}

export default AutocompleteProductsIssuedCard
