import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { useAppContextWithCompany } from '../../AppContext'
import { useStripe } from '@stripe/react-stripe-js'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { Grid, Icon, FormControl, InputLabel, Select, MenuItem, Input, Button } from '@mui/material'
import useShowErrors, { GraphQLError } from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation createAchSourceMutation_AddBankMicroDeposit($tokenCardId: String!, $companieId: String!) {
    createAchSourceMutation(tokenCardId: $tokenCardId, companieId: $companieId) {
      id
    }
  }
`)

type Props = {
  onCancel: () => void
  onSuccess: () => void
  companieId: string
}

const AddBankMicroDeposit = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const [createAchSourceMutation] = useMutation(MUTATION)

  const context = useAppContextWithCompany()
  const stripe = useStripe()
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [bank, setBank] = React.useState({
    account_holder_name: '',
    account_number: '',
    routing_number: '',
    account_holder_type: 'company',
    currency: 'usd',
    source: 'bank_account',
    country: 'US',
  })

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    if (!stripe) return
    let data
    setLoading(true)

    try {
      data = await stripe.createToken('bank_account', bank)
    } catch (e) {
      console.log(e)
      setLoading(false)
      throw e
    }
    setLoading(false)
    console.log(data)
    if (!data) {
      setLoading(false)
      setMessage(`Unknown source`)
      return
    }
    if (data.error) {
      setLoading(false)
      setMessage(`Unknown source`)

      return
    }

    let acchSource
    try {
      acchSource = await createAchSourceMutation({
        variables: {
          tokenCardId: data.token.id,
          companieId: props.companieId,
        },
      })
    } catch (e) {
      console.log(e)
      const gqlErrors = (e as GraphQLError).graphQLErrors
      if (
        gqlErrors?.some(
          (error) =>
            error.message === 'A bank account with that routing number and account number already exists for this customer.',
        )
      ) {
        showErrors(new Error('This bank account is already connected to NachoNacho'), setMessage)
      } else showErrors(e, setMessage)
    }
    if (acchSource) {
      await client.resetStore()
      props.onSuccess()
    }
  }

  const testMode = context.testMode
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <h3>Add your Bank details</h3>
          <h4>
            <Icon className="iconAlignText">info</Icon> SUPPORTED BANK ACCOUNT TYPES
          </h4>
          <p>
            A standard bank account with a financial institution must be provided (e.g., checking). Other types of bank accounts
            (e.g., savings) or those with a virtual bank account provider (e.g., e-wallet or cross-border services) are not
            supported.
          </p>
        </Grid>
        <Grid item xs={12} sm={12}>
          {testMode && (
            <div>
              <h3>Admin</h3>
              Test Data
              <br />
              Type: individual
              <br />
              Account number (success): 000123456789
              <br />
              Account number (NSF/insufficient funds): 000222222227
              <br />
              Routing number: 110000000
              <br />
              <br />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FormControl className="width100per" variant="standard">
            <InputLabel htmlFor="account_holder_type">Type</InputLabel>
            <Select
              id="account_holder_type"
              value={bank.account_holder_type}
              onChange={(e) =>
                setBank({
                  ...bank,
                  account_holder_type: e.target.value,
                })
              }
            >
              <MenuItem value="company">Company</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl className="width100per" variant="standard">
            <InputLabel htmlFor="account_holder_name">{`Bank account holder's name`}</InputLabel>
            <Input
              id="account_holder_name"
              onChange={(e) =>
                setBank({
                  ...bank,
                  account_holder_name: e.target.value,
                })
              }
              type="text"
              value={bank.account_holder_name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl className="width100per" variant="standard">
            <InputLabel htmlFor="account_number">Account number</InputLabel>
            <Input
              id="account_number"
              onChange={(e) =>
                setBank({
                  ...bank,
                  account_number: e.target.value,
                })
              }
              type="text"
              value={bank.account_number}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl className="width100per" variant="standard">
            <InputLabel htmlFor="routing_number">Routing number</InputLabel>
            <Input
              id="routing_number"
              onChange={(e) =>
                setBank({
                  ...bank,
                  routing_number: e.target.value,
                })
              }
              type="text"
              value={bank.routing_number}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} className="tac">
          <div style={{ height: '20px' }} />
          <ButtonLoadingAfterClick
            actionName="addBankMicroDeposit"
            id="idButton"
            size="medium"
            type="submit"
            icon=""
            disabled={false}
            color="primary"
            variant="outlined"
            buttonText="Add"
            buttonLoadingText="Setting up..."
            onClick={() => {}}
            loading={loading}
          />{' '}
          <Button onClick={props.onCancel}>Cancel</Button>
        </Grid>
      </Grid>
      <div style={{ height: '10px' }} />
      <div id="idMessage" className="secondary">
        {message}
      </div>
    </form>
  )
}
export default AddBankMicroDeposit
