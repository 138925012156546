import React from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateNewReview from './CreateNewReview'
import HeaderRightSideBar from 'components/application/HeaderRightSideBar'

const CreateReviewDialog = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const parsed = queryString.parse(location.search)

  const closeDialog = () => {
    delete parsed.showDialog
    navigate('?' + queryString.stringify(parsed))
  }

  return (
    <div>
      <HeaderRightSideBar title="Write a review" closeDialog={closeDialog} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CreateNewReview />
      </div>
    </div>
  )
}

export default CreateReviewDialog
