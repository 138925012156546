import React from 'react'
import { FormControl, TextField, MenuItem, FormLabel } from '@mui/material'
import { bankStatesCA, bankStatesUS } from '../bankAddress/BankAddress'
import SelectFieldNN from 'components/ui/SelectFieldNN'

type Props = {
  id: string
  country: string
  state: string
  error: boolean
  onUpdate: (state: string) => void
}

const SelectStateForm = (props: Props) => {
  const { id, country, state, error, onUpdate } = props
  const states =
    {
      US: bankStatesUS,
      CA: bankStatesCA,
    }[country] || []
  if (country === 'US' || country === 'CA') {
    return (
      <SelectFieldNN
        id="addressState"
        label="State/Province"
        error={error}
        value={state}
        onChange={(e) => onUpdate(e.target.value)}
      >
        <MenuItem value="" />
        {states.map((state) => (
          <MenuItem key={state.code} value={state.code}>
            {state.label}
          </MenuItem>
        ))}
      </SelectFieldNN>
    )
  }

  return (
    <FormControl fullWidth variant="standard" sx={{ mb: '16px' }}>
      <FormLabel
        htmlFor={id}
        sx={{
          textAlign: 'left',
          color: 'black',
          fontWeight: 700,
          mb: '5px',
          fontSize: '14px',
        }}
      >
        State/Province
      </FormLabel>
      <TextField size="small" id="select-state" type="text" value={state} onChange={(e) => onUpdate(e.target.value)} />
    </FormControl>
  )
}

export default SelectStateForm
