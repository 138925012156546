import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import PhoneCodeForm from './PhoneCodeForm'
import { TextField } from '@mui/material'

type Props = {
  phone: string
  phoneCode: string
  onKeyPress: () => void
  onChangePhone: (phone: string) => void
  onChangePhoneCode: (phoneCode: string) => void
}

const PhoneForm = (props: Props) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <PhoneCodeForm phoneCode={props.phoneCode} onChange={(phoneCode) => props.onChangePhoneCode(phoneCode)} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
          <InputLabel shrink htmlFor="phone">
            <b className="black2">Phone number</b>
          </InputLabel>
          <TextField
            id="phone"
            type="text"
            size="small"
            className="width100per spaceForm"
            value={props.phone}
            onKeyPress={(data) => {
              if (data.charCode === 13) {
                props.onKeyPress()
              }
            }}
            onChange={(e) => {
              const cleanNumber = e.target.value.replace(/\s/g, '')
              if (Number.isInteger(Number(cleanNumber))) {
                if (props.phoneCode === '+1') {
                  if (Number(cleanNumber) < 9999999999) {
                    props.onChangePhone(cleanNumber)
                  }
                  return
                }
                props.onChangePhone(cleanNumber)
              }
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default PhoneForm
