import React, { useEffect } from 'react'
import { FormControl, FormHelperText, Icon, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material'
import UpdateCompanie from '../companie/form/UpdateCompanie'
import { Companie } from '__generated__/graphql'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_ISSUING, URL_HOME } from 'config/config'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { useKYCContext } from './KYCContext'
import { Link } from 'react-router-dom'

type CompanieP = Pick<Companie, 'id' | 'leadershipIdNumberToken'>

type Props = {
  companie: CompanieP & Partial<Companie>
  onCancel: () => void
  onUpdate: () => void
}

type IdNumberProps = {
  value: any
  onChange: (string) => void
  setToken: (string) => void
  setIdNumberValid: (boolean) => void
}
const IdNumberFieldInternal = (props: IdNumberProps) => {
  const [idNumber, setIdNumber] = React.useState(props.value)
  const [idNumberInit, setIdNumberInit] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false)
  const { setIdNumberValid } = props
  const stripe = useStripe()
  useEffect(() => {
    if (idNumberInit === false) {
      setIdNumberValid(idNumber && idNumber.search('pii_') === 0)
    }
  }, [setIdNumberValid, idNumber, idNumberInit])
  const idNumberValid = () => {
    return idNumber && (idNumber.length >= 9 || idNumber?.search('pii_') === 0)
  }
  const convertPii = async (numberToken: string) => {
    if (numberToken.search('pii_') === 0) return numberToken
    if (stripe) {
      const data = await stripe.createToken('pii', { personal_id_number: numberToken })
      if (data?.token) {
        props.setToken(data.token.id)
        props.setIdNumberValid(true)
      }
    }
  }
  const ssnTooltipText = `We need this information to comply with banking regulations. This data is encrypted and is only made available to authorized parties for KYC purposes. It is NOT used to do credit checks or credit reporting.`
  return (
    <>
      <FormControl style={{ marginTop: '10px' }} className="width100per" variant="standard">
        <InputLabel shrink htmlFor="leadershipIdNumber">
          <b className="black2">Social Security Number</b>
        </InputLabel>
        <OutlinedInput
          id="leadershipIdNumber"
          type={idNumber?.search('pii_') === 0 || !isVisible ? 'password' : 'text'}
          size="small"
          className="width100per spaceForm"
          value={idNumber}
          error={!idNumberValid() && idNumberInit}
          endAdornment={
            <InputAdornment position="end">
              <Icon style={{ cursor: 'pointer' }} onClick={() => setIsVisible((v) => !v)}>
                {isVisible ? 'visibility_off' : 'visibility'}
              </Icon>
            </InputAdornment>
          }
          onBlur={() => {
            setIdNumberInit(true)
          }}
          onFocus={(e) => {
            if (idNumber?.search('pii_') === 0) {
              setIdNumber('')
              props.setIdNumberValid(false)
            }
          }}
          onChange={(e) => {
            const newNumber = e.target.value.replace(/[^0-9]/g, '')
            setIdNumber(newNumber)
            props.onChange(newNumber)
            props.setIdNumberValid(false)
            if (newNumber.length === 9) convertPii(newNumber)
          }}
        />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip arrow title={ssnTooltipText}>
          <FormHelperText style={{ cursor: 'default', width: 'fit-content' }} className="primary">
            Why is this required?
          </FormHelperText>
        </Tooltip>
        <Tooltip
          arrow
          title={
            <>
              Please{' '}
              <Link className="link" to={`${URL_HOME}/contact`} style={{ fontWeight: '700' }}>
                contact us
              </Link>{' '}
              if you don't have a Social Security Number.
            </>
          }
        >
          <FormHelperText style={{ cursor: 'default', width: 'fit-content' }} className="primary">
            Don't have a Social Security Number?
          </FormHelperText>
        </Tooltip>
      </div>
    </>
  )
}

const IdNumberField = (props: IdNumberProps) => {
  const stripePromise = loadStripe(STRIPE_ISSUING.STRIPE_KEY_PK_LIVE)
  return (
    <Elements stripe={stripePromise}>
      <IdNumberFieldInternal
        value={props.value}
        onChange={props.onChange}
        setToken={props.setToken}
        setIdNumberValid={props.setIdNumberValid}
      />
    </Elements>
  )
}

const LeadershipIdNumber: React.FC<Props> = (props: Props) => {
  const [companie, setCompanie] = React.useState(props.companie)
  const kycContext = useKYCContext()
  const [idNumberValid, setIdNumberValid] = React.useState(false)

  const isFormValid = () => {
    return idNumberValid
  }

  return (
    <>
      <div className="titleStepKYC">Beneficiary details</div>

      <div style={{ maxWidth: '350px', margin: '0 auto' }}>
        <div id="last4Social">
          <IdNumberField
            value={props.companie.leadershipIdNumberToken}
            onChange={(newValue) =>
              setCompanie({
                ...companie,
                leadershipLast4: newValue.substring(newValue.length - 4),
              })
            }
            setToken={(token) => {
              setCompanie({
                ...companie,
                leadershipIdNumberToken: token,
              })
            }}
            setIdNumberValid={setIdNumberValid}
          />
        </div>

        <div style={{ height: '20px' }} />

        <div>
          <UpdateCompanie
            actionName="updateLeadership"
            disabled={!isFormValid()}
            showCancelButton={false}
            textButton="Continue"
            textCancelButton="Cancel"
            onUpdate={() => {
              props.onUpdate()
              kycContext.reloadKYCState()
            }}
            onCancel={() => props.onCancel()}
            companie={{
              id: companie.id,
              leadershipIdNumberToken: companie.leadershipIdNumberToken,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default LeadershipIdNumber
