import React from 'react'
import { Chip } from '@mui/material/'
import { useAppContextWithCompany } from '../../AppContext'
import SetDefaultSource from './SetDefaultSource'
import { Source } from '__generated__/graphql'
import DeleteSourceLogicallyDialog from './DeleteSourceLogicallyDialog'
import UpdateNicknameDialog from './UpdateNicknameDialog'
import { Permission } from '__generated__/graphql'

type Props = {
  source: Pick<
    Source,
    | 'id'
    | 'isDefaultSource'
    | 'typeSource'
    | 'object'
    | 'brand'
    | 'funding'
    | 'last4'
    | 'exp_month'
    | 'exp_year'
    | 'bank_name'
    | 'nickname'
    | 'name'
  >
  onSucceed: () => void
}

const ActionSource = (props: Props) => {
  const context = useAppContextWithCompany()
  return (
    <>
      {props.source.isDefaultSource && <Chip color="secondary" label="Primary" variant="filled" />}
      {!props.source.isDefaultSource && context.userRoleCompanie.permissions.includes(Permission.CanSetAsDefaultCard) && (
        <SetDefaultSource sourceId={props.source.id} onSucceed={props.onSucceed} />
      )}

      <UpdateNicknameDialog source={props.source} />

      {context.userRoleCompanie.permissions.includes(Permission.CanDeleteCard) && (
        <DeleteSourceLogicallyDialog source={props.source} onDelete={props.onSucceed} />
      )}
    </>
  )
}

export default ActionSource
