import Icon from '@mui/material/Icon'
import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import { useAppContextWithCompany } from 'components/AppContext'
import MenuListLeft from './MenuListLeft'
import { useQuery } from '@apollo/client'
import '../../Style.css'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompanies_MenuLeftDesktop($where: UserRoleCompanieWhereInput!) {
    userRoleCompanies(where: $where) {
      id
      companie {
        id
        name
        typeCompanie
      }
    }
  }
`)

const MenuLeftDesktop = () => {
  const context = useAppContextWithCompany()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        userId: context.me.id,
        isInvitationApproved: true,
      },
    },
  })

  if (!context.userRoleCompanie) return null
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.userRoleCompanies) return null

  // const userRoleCompanieCheck = data.userRoleCompanies.find(
  //   (userRoleCompanie) =>
  //     userRoleCompanie.companie.typeCompanie === 'SELLER' || userRoleCompanie.companie.typeCompanie === 'TRIBE',
  // )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button
        id="buttonSelectCompanie"
        variant="outlined"
        color="primary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {context.userRoleCompanie && context.userRoleCompanie.companie.name}
        {context.userRoleCompanie.companie.id ? (
          <>
            {context.userRoleCompanie.companie.typeCompanie === 'BUYER' && (
              <>
                <div className="white">_</div>
                <div className="secondary">(Buyer Account)</div>
              </>
            )}
          </>
        ) : (
          <>Select</>
        )}
        {context.userRoleCompanie.companie.typeCompanie === 'SELLER' && (
          <>
            <div className="white">_</div>
            <div className="secondary">(Seller Station)</div>
          </>
        )}
        {anchorEl ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
      </Button>
      <Menu
        className="menuAvatarList"
        classes={{ paper: 'paperMenuLeft' }}
        id="buttonSelectCompanie"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuListLeft onClose={handleClose} userRoleCompanies={data.userRoleCompanies} />
      </Menu>
    </div>
  )
}

export default MenuLeftDesktop
