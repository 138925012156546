import React from 'react'
import ButtonLoadingAfterClick from '../nav/ButtonLoadingAfterClick'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'
import TextFieldNN from 'components/ui/TextFieldNN'
import { useLazyQuery } from '@apollo/client'
import { PromoCodeGlobal } from '__generated__/graphql'
import utils from 'components/utils'

const QUERY = gql(/* GraphQL */ `
  query getPromoCodeGlobalByCode_RedeemPromoCode($code: String!, $companieId: String!) {
    getPromoCodeGlobalByCode(code: $code, companieId: $companieId) {
      id
      code
      description
      discount
    }
  }
`)

type Props = {
  fullPrice: number
  setPromoCodeGlobal: (promoCodeGlobal: Pick<PromoCodeGlobal, 'id' | 'code' | 'description' | 'discount'>) => void
  companieId: string
  promoCodeGlobal: Pick<PromoCodeGlobal, 'id' | 'code' | 'description' | 'discount'> | null
}

const GetStatusRedeemPromoCodeGlobal = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [getPromoCodeGlobalByCode] = useLazyQuery(QUERY)

  const getPromoCodeGlobalByCodeF = async () => {
    setLoading(true)
    setMessage('')

    const promoCode = await getPromoCodeGlobalByCode({
      variables: {
        companieId: props.companieId,
        code,
      },
    })
    console.log(promoCode)
    if (promoCode.data) {
      // setPromoCodeGlobal(promoCode.data.getPromoCodeGlobalByCode)
      if (promoCode.data.getPromoCodeGlobalByCode) {
        props.setPromoCodeGlobal(promoCode.data.getPromoCodeGlobalByCode)
      }
      setMessage('')
      setLoading(false)
      setShow(false)
      setCode('')
    }
    if (promoCode.error) {
      showErrors(promoCode.error, setMessage)
      setLoading(false)
    }
  }

  return (
    <>
      {show ? (
        <div>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextFieldNN id="discount" label="Enter Promo Code" value={code} onChange={(e) => setCode(e.target.value)} />
            </Grid>
          </Grid>
          <Button onClick={() => setShow(false)}>Cancel</Button>{' '}
          <ButtonLoadingAfterClick
            actionName="redeemPromoCode"
            id="id-GetStatusRedeemPromoCodeGlobal"
            disabled={false}
            icon=""
            size="medium"
            color="primary"
            variant="outlined"
            buttonText="Redeem"
            buttonLoadingText="Setting up..."
            onClick={getPromoCodeGlobalByCodeF}
            loading={loading}
          />
          {message && (
            <div id="idMessage-GetStatusRedeemPromoCodeGlobal" className="secondary">
              {message}
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.promoCodeGlobal ? (
            <div>
              <div>Promo Code: {props.promoCodeGlobal.code}</div>
              <div>Description: {props.promoCodeGlobal.description}</div>
              <div>Discount: {props.promoCodeGlobal.discount}%</div>
              <div>
                Final Price: {utils.priceFormatedUSDNoDecimals((1 - props.promoCodeGlobal.discount / 100) * props.fullPrice)}
              </div>
            </div>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setShow(true)
              }}
            >
              + Promo Code
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default GetStatusRedeemPromoCodeGlobal
