import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { useAppContextWithCompany } from '../../AppContext'
import { Grid, FormControl, InputLabel, Input, InputAdornment, Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation verifySource_VerifySource($sourceId: String!, $number1: Float!, $number2: Float!) {
    verifySource(sourceId: $sourceId, number1: $number1, number2: $number2) {
      id
    }
  }
`)

type Props = {
  sourceId: string
  onVerified: () => void
  onCancel: () => void
}

const VerifySource = (props: Props) => {
  const showErrors = useShowErrors()
  const client = useApolloClient()
  const context = useAppContextWithCompany()
  const [verifySource] = useMutation(MUTATION)
  const [number1, setNumber1] = React.useState('')
  const [number2, setNumber2] = React.useState('')
  const [message, setMessage] = React.useState('')

  const verifySourceF = async () => {
    try {
      const card = await verifySource({
        variables: {
          number1: Number(number1),
          number2: Number(number2),
          sourceId: props.sourceId,
        },
      })

      if (card) {
        context.openSnackbar(`Your new payment source is verified & is now the Primary Payment Source`, 'success')
        props.onVerified()
        await client.resetStore()
      }
    } catch (e) {
      showErrors(e, setMessage)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        {context.testMode && (
          <>
            <div>Test data</div>
            <div>0.32 - 0.45</div>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <FormControl variant="standard">
          <InputLabel htmlFor="number1">Deposit 1</InputLabel>
          <Input
            id="number1"
            placeholder="- -"
            startAdornment={<InputAdornment position="start">$ 0.</InputAdornment>}
            onChange={(e) => {
              if (Number(e.target.value) < 100) {
                setNumber1(e.target.value)
              }
            }}
            type="text"
            value={number1}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <FormControl variant="standard">
          <InputLabel htmlFor="number1">Deposit 2</InputLabel>
          <Input
            id="number2"
            placeholder="- -"
            startAdornment={<InputAdornment position="start">$ 0.</InputAdornment>}
            onChange={(e) => {
              if (Number(e.target.value) < 100) {
                setNumber2(e.target.value)
              }
            }}
            type="text"
            value={number2}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ height: '20px' }} />
        <div>
          <Button variant="outlined" color="primary" id="verifyMicroDepositsStripe" onClick={() => verifySourceF()}>
            Verify
          </Button>{' '}
          <Button onClick={props.onCancel}>Cancel</Button>
        </div>
        <div id="idMessage" className="secondary">
          {message}
        </div>
      </Grid>
    </Grid>
  )
}

export default VerifySource
