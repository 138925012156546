import React from 'react'
import { Source } from '__generated__/graphql'

type Props = {
  source: Pick<Source, 'name'>
}

const PaypalSource = (props: Props) => {
  return (
    <div>
      <img alt="paypal" src="/icon/paypal-logo.png" width="18px" /> Paypal: {props.source.name}
    </div>
  )
}

export default PaypalSource
