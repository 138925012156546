import { Tooltip } from '@mui/material'
import { URL_HOME } from 'config/config'
import React from 'react'

const ContactUsOnboardingLink = () => {
  return (
    <>
      <div>
        <Tooltip arrow title="Request a live video call for verification and/or demo">
          <a className="primary" target="_blank" rel="noopener noreferrer" href={`${URL_HOME}/support-call`}>
            Request a live video call
          </a>
        </Tooltip>
      </div>
      <div>
        <Tooltip arrow title="Contact us by email">
          <a className="primary" target="_blank" rel="noopener noreferrer" href={`${URL_HOME}/contact`}>
            Contact us
          </a>
        </Tooltip>
      </div>
    </>
  )
}

export default ContactUsOnboardingLink
