import React from 'react'
import { Autocomplete, Box, Button, TextField } from '@mui/material'
import { Product, ProductOrderByInput, ProductsRelationship, ProductsRelationshipsType } from '__generated__/graphql'
import AddProductsRelationshipWithProductCreationSeller from './AddProductsRelationshipWithProductCreationSeller'
import useDebounce from 'hooks/useDebounce'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import Error from 'components/nav/error/Error'
import ImageTemplate from 'components/nav/ImageTemplate'

const QUERY = gql(/* GraphQL */ `
  query simpleProductsConnection_AddProductsRelationshipManagementSeller(
    $orderBy: ProductOrderByInput
    $where: ProductWhereInput
    $skip: Int
    $first: Int
  ) {
    simpleProductsConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      products {
        id
        name
        nameFile
        urlName
      }
    }
  }
`)

type Props = {
  relationType: ProductsRelationshipsType
  productId: string
  productsRelationshipSellerSelected: Array<
    Pick<ProductsRelationship, 'id'> & {
      productB: Pick<Product, 'id' | 'name' | 'urlName' | 'nameFile'>
    }
  >
  onUpdate: (relations: Props['productsRelationshipSellerSelected']) => void
  onCancel: () => void
  onSave: () => void
}

type ProductOptionAugmented = { id: string; productB: Pick<Product, 'id' | 'name' | 'urlName' | 'nameFile'> }
type ProductOption = Pick<Product, 'id' | 'name' | 'urlName' | 'nameFile'>

const productInit: ProductOption = {
  id: '',
  name: '',
  urlName: '',
  nameFile: '',
}

const AddProductsRelationshipManagementSeller = (props: Props) => {
  const [product, setProduct] = React.useState<ProductOption>(productInit)
  const [productToCreateName, setProductToCreateName] = React.useState('')
  const [showSearch, setShowSearch] = React.useState(true)
  const debouncedInput = useDebounce(product.name, 50)
  const [message, setMessage] = React.useState('')

  const notProductIds = props.productsRelationshipSellerSelected.map((singleRelationship) => {
    return {
      id: {
        equals: singleRelationship.productB.id,
      },
    }
  })

  const { error, data } = useQuery(QUERY, {
    variables: {
      orderBy: ProductOrderByInput.NameAsc,
      first: 10,
      skip: 0,
      where: {
        showShowcase: true,
        name: { contains: debouncedInput },
        NOT: notProductIds.length ? notProductIds : undefined,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />

  const dataAugmented = data?.simpleProductsConnection
    ? data.simpleProductsConnection.products.map((product) => {
        return {
          id: '',
          productB: product,
        }
      })
    : []

  const onChangeProduct = (name: string) => {
    if (name.length <= 20) {
      const newProduct = {
        ...product,
        name,
      }
      setProduct(newProduct)
      setMessage('')
    }
  }

  const onClickAutocomplete = async (productsRelationships: ProductOptionAugmented[]) => {
    setMessage('')
    setShowSearch(true)
    props.onUpdate(productsRelationships)
    setProduct(productInit)
  }

  const onClickAutocompleteSingle = async (product: ProductOption) => {
    setMessage('')
    setProduct(product)
    setShowSearch(true)
    props.onUpdate([
      ...props.productsRelationshipSellerSelected,
      {
        id: '',
        productB: product,
      },
    ])
    setProduct(productInit)
  }

  return (
    <>
      <div>
        {showSearch && (
          <>
            <Autocomplete
              multiple
              id="id-autocomplete"
              disableClearable={true}
              onChange={(_e, values) => {
                console.log('Values', values)
                console.log('Current: ', props.productsRelationshipSellerSelected)
                onClickAutocomplete(values)
              }}
              isOptionEqualToValue={(option, value) => option.productB.id === value.productB.id}
              options={dataAugmented}
              inputValue={debouncedInput}
              onInputChange={(_e, newInputValue) => onChangeProduct(newInputValue)}
              getOptionLabel={(option) => option.productB.name}
              value={props.productsRelationshipSellerSelected}
              renderOption={(props, option) => (
                <Box {...props} component="li" aria-selected={false}>
                  <ImageTemplate nameFile={option.productB.nameFile} format="roundSmall" /> <span style={{ width: 10 }} />
                  {option.productB.name}
                </Box>
              )}
              renderInput={(params) => <TextField placeholder="Start typing..." {...params} />}
              noOptionsText={
                <span>
                  {debouncedInput ? (
                    <div
                      className="cursor"
                      onClick={() => {
                        setProductToCreateName(debouncedInput)
                        setShowSearch(false)
                      }}
                    >
                      Create "{debouncedInput}"
                    </div>
                  ) : (
                    <>Start typing...</>
                  )}
                </span>
              }
            />
            <div style={{ height: 20 }} />
            <Button onClick={props.onCancel}>Cancel</Button>{' '}
            <Button variant="contained" onClick={props.onSave}>
              Save
            </Button>
          </>
        )}
      </div>
      {/* <div style={{ height: 20 }} /> */}
      {productToCreateName !== '' && (
        <div>
          <AddProductsRelationshipWithProductCreationSeller
            productName={productToCreateName}
            onCreate={(product) => {
              onClickAutocompleteSingle(product)
              setProductToCreateName('')
              setProduct(productInit)
              setShowSearch(true)
            }}
            onCancel={() => {
              setProductToCreateName('')
              setShowSearch(true)
            }}
          />
        </div>
      )}
      {message && (
        <div id="idMessage-AddProductsRelationshipManagementSeller" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default AddProductsRelationshipManagementSeller
