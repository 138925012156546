import React from 'react'
import UpdateCompanie from './UpdateCompanie'
import OffsiteTemplate from '../../user/single/profile/sectionDetails/OffsiteTemplate'
import { useAppContextWithCompany } from '../../AppContext'
import { Companie } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'

const CompanieFormName = () => {
  const context = useAppContextWithCompany()
  const [companie, setCompanie] = React.useState<Companie>(context.userRoleCompanie.companie as Companie)
  const [isValidName, setIsValidName] = React.useState(true)

  const onUpdate = () => {
    // const newUserRoleCompanie: UserRoleCompanieContext = {
    //   ...context.userRoleCompanie,
    //   companie: {
    //     ...context.userRoleCompanie.companie,
    //     name: companieName,
    //   },
    // }
    context.updateUserRoleCompanieViaQuery()
  }

  if (!companie.id) return null

  return (
    <OffsiteTemplate>
      <h3>Tell us which company you represent</h3>

      <div>
        <TextFieldNN
          label="Company name"
          id="companyName"
          type="text"
          error={!isValidName}
          helperText="Min. 3 characters"
          value={companie.name}
          onChange={(e) => {
            setCompanie({
              ...companie,
              name: e.target.value,
            })
            setIsValidName(e.target.value.length >= 3 ? true : false)
          }}
        />
      </div>

      <div style={{ height: '20px' }} />

      <div>
        <UpdateCompanie
          actionName="updateCompanyName"
          disabled={companie.name.length >= 3 ? false : true}
          showCancelButton={false}
          textButton="Update"
          textCancelButton="Cancel"
          onUpdate={onUpdate}
          onCancel={() => {}}
          companie={companie}
        />
      </div>
    </OffsiteTemplate>
  )
}

export default CompanieFormName
