import React from 'react'
import { Grid } from '@mui/material'
import OnboardingAddresses from 'components/addresse/list/OnboardingAddresses'
import { TypeAddresse } from '__generated__/graphql'
import { useKYCContext } from './KYCContext'

type Props = {
  userId: string
  onCreate: () => void
  onUpdate: () => void
  onCancel: () => void
}

const PersonalAddresssForm = (props: Props) => {
  const kycContext = useKYCContext()
  return (
    <>
      <div className="titleStepKYC">Home address</div>
      <Grid container spacing={1} style={{ maxWidth: '350px', margin: '0 auto' }}>
        <Grid item xs={12} sm={12}>
          <OnboardingAddresses
            type={TypeAddresse.Home}
            onCreate={() => {
              kycContext.reloadKYCState()
              props.onCreate()
            }}
            onUpdate={() => {
              kycContext.reloadKYCState()
              props.onUpdate()
            }}
            onCancel={props.onCancel}
            userId={props.userId}
            variables={{
              where: {
                user: {
                  id: props.userId,
                },
                type: 'HOME',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PersonalAddresssForm
