import React from 'react'
import App from './App'
import { authDeviceInit } from './authDevice/AuthDevice.type'
import { TEST_MODE, AUTH_FRONTEND_URL } from 'config/config'
import {
  AuthDeviceContext,
  Context,
  LogoutMode,
  Snackbar,
  UserContext,
  userContextInit,
  UserRoleCompanieContext,
} from './Context.type'
import { AppContext } from './AppContext'
import { useLazyQuery } from '@apollo/client'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query userRoleCompanie_AppContextProvider($where: UserRoleCompanieWhereUniqueInput) {
    userRoleCompanie(where: $where) {
      id
      companieRole
      permissions
      companie {
        id
        name
        partnerSignupId
        ownerPartnerId
        typeCompanie
        statusVerification
      }
    }
  }
`)

const AppContextProvider = () => {
  const [userRoleCompanieQuery] = useLazyQuery(QUERY)

  const [userRoleCompanie, setUserRoleCompanie] = React.useState<UserRoleCompanieContext | null>(null)
  const [authDevice, setAuthDevice] = React.useState<AuthDeviceContext>(authDeviceInit)
  const [me, setMe] = React.useState(userContextInit)
  const [isMeLoading, setIsMeLoading] = React.useState(true)
  const [isUserRoleCompanieLoading, setIsUserRoleCompanieLoading] = React.useState(true)
  const [isSideBarOpenLeftMobile, setIsSideBarOpenLeftMobile] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState<Snackbar>({ showCloseIcon: true, message: '', type: 'info', open: false })

  const logout = async (mode: LogoutMode[]) => {
    const queryParams = new URLSearchParams({ redirectUrl: window.location.href })
    mode.forEach((m) => queryParams.append('mode', m))
    window.location.href = `${AUTH_FRONTEND_URL}/logout?${queryParams.toString()}`
  }

  const openSnackbar = (message: string, type: Snackbar['type'], showCloseIcon = true, time = 5000) => {
    setSnackbar({ showCloseIcon, message, type, open: true })
    setTimeout(() => {
      setSnackbar((snackbar) => ({ ...snackbar, open: false }))
    }, time)
  }

  const testMode = localStorage.getItem(TEST_MODE) === 'true'

  const setMeAndUpdateGTM = (me: UserContext) => {
    setMe(me)
  }
  const updateUserRoleCompanieViaQuery = async () => {
    console.log('updateUserRoleCompanieViaQuery')
    if (!userRoleCompanie) return
    try {
      const { data } = await userRoleCompanieQuery({
        variables: { where: { id: userRoleCompanie.id } },
        fetchPolicy: 'network-only',
      })
      if (data?.userRoleCompanie) setUserRoleCompanie(data.userRoleCompanie)
    } catch (error) {
      console.error('updateUserRoleCompanieViaQuery', error)
    }
  }

  const context: Context = {
    authDevice,
    isMeLoading,
    isSideBarOpenLeftMobile,
    isUserRoleCompanieLoading,
    me,
    testMode,
    userRoleCompanie,
    snackbar,
    logout,
    openSnackbar,
    closeSnackbar: () => setSnackbar((snackbar) => ({ ...snackbar, open: false })),
    setDrawerLeftMobile: setIsSideBarOpenLeftMobile,
    updateAuthDevice: setAuthDevice,
    updateIsMeLoading: setIsMeLoading,
    updateIsUserRoleCompanieLoading: setIsUserRoleCompanieLoading,
    updateMe: setMeAndUpdateGTM,
    updateUserRoleCompanie: setUserRoleCompanie,
    updateUserRoleCompanieViaQuery,
  }

  return (
    <AppContext.Provider value={context}>
      <App />
    </AppContext.Provider>
  )
}

export default AppContextProvider
