import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContextWithCompany } from '../../../AppContext'
import { Button, Grid, Paper } from '@mui/material'
import DateComponent from '../../../nav/DateComponent'
import MenuLeftDesktop from '../drawer/topLeft/MenuLeftDesktop'
import ContactUsOnboardingLink from '../../../onboarding/ContactUsOnboardingLink'
import { useQuery } from '@apollo/client'
import NotFound from '../../../nav/error/NotFound'
import Loading from '../../../nav/error/Loading'
import Error from '../../../nav/error/Error'
import LogOutCross from 'components/nav/LogoutCross'
import LogoNN from 'assets/logo/nn/nachonacho-full-blue-horizontal.svg'
import { URL_HOME } from 'config/config'
import { gql } from '__generated__'

const USER_QUERY = gql(/* GraphQL */ `
  query user_UserVerificationSplash($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      verificationStatus
      verificationDateSubmission
    }
  }
`)

const UserVerificationSplash = () => {
  const context = useAppContextWithCompany()

  const { loading, error, data } = useQuery(USER_QUERY, {
    variables: {
      where: {
        id: context.me.id,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.user) return <NotFound />

  console.log(context)
  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <>
            <Grid container>
              <Grid item xs={6}>
                <MenuLeftDesktop />
              </Grid>
              <Grid item xs={6} className="tar">
                <LogOutCross />
              </Grid>
            </Grid>

            <div className="tac margin6">
              <Link to="/">
                <img alt="logo" className="logoNachoNacho" src={LogoNN} />
              </Link>
            </div>
            <div className="tac responsiveMargin2">
              <h3>{data.user.verificationStatus === 'REVOKE_ACCESS' ? 'No account access' : 'User Verification'}</h3>
              <div className="tal">
                {data.user.verificationStatus === 'SUBMITED' && (
                  <>
                    {data.user.firstName}, we received your verification information on{' '}
                    {data.user.verificationDateSubmission && <DateComponent date={data.user.verificationDateSubmission} />}. Thank
                    you for submitting it. We will review it and get back to you asap.
                  </>
                )}
                {data.user.verificationStatus === 'BACKBURNER' && (
                  <>
                    {data.user.firstName}, we received your verification information on{' '}
                    {data.user.verificationDateSubmission && <DateComponent date={data.user.verificationDateSubmission} />}. Thank
                    you for submitting it. We will review it and get back to you asap.
                  </>
                )}

                {data.user.verificationStatus === 'PENDING_ONBOARDING_CALL' && (
                  <>
                    {data.user.firstName}, your account application was received on{' '}
                    {data.user.verificationDateSubmission && <DateComponent date={data.user.verificationDateSubmission} />}. We
                    sent you an email to set up an onboarding call with a NachoNacho representative. Please click the link in the
                    email, or click on the button below to set up a call.
                    <br />
                    <br />
                    <a href={`${URL_HOME}/support-call`} target="_blank" rel="noreferrer">
                      <Button color="primary" variant="outlined">
                        Set up video call with NachoNacho
                      </Button>
                    </a>
                  </>
                )}

                {data.user.verificationStatus === 'APPROVED' && (
                  <>
                    {data.user.firstName}, we approved your verification information on{' '}
                    {data.user.verificationDateSubmission && <DateComponent date={data.user.verificationDateSubmission} />}.
                    Please Contact us.
                  </>
                )}
                {data.user.verificationStatus === 'NOT_APPROVED' && (
                  <>
                    <p>
                      {data.user.firstName}, Your Identity Verification submission was not approved. There can be numerous reasons
                      why we could not verify your identity.
                    </p>
                    <p>Please contact us if you would like to appeal our decision.</p>
                  </>
                )}
                {data.user.verificationStatus === 'REVOKE_ACCESS' && (
                  <>
                    <p>
                      {`You currently don't have access to your NachoNacho account. This could be because of multiple reasons. Some
                      common examples:`}
                    </p>

                    <ol>
                      <li>{`You tried to sign up with an email that doesn't have a company domain.`}</li>
                      <li>We could not verify your identity or the identity of your company.</li>
                      <li>There was suspicious activity in your account.</li>
                    </ol>
                  </>
                )}
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12} className="tar">
                <ContactUsOnboardingLink />
              </Grid>
            </Grid>
          </>
        </Paper>
      </div>
    </div>
  )
}

export default UserVerificationSplash
