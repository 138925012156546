import React from 'react'
import UpdateSource from './UpdateSource'
import { Source } from '__generated__/graphql'
import TextFieldNN from 'components/ui/TextFieldNN'

type Props = {
  onCancel: () => void
  onUpdate: () => void
  source: Pick<Source, 'id' | 'nickname'>
}

const UpdateNickname = (props: Props) => {
  const [nickNameValid, setNickNameValid] = React.useState(true)
  const [source, setSource] = React.useState(props.source)
  const maxLengthNickName = 20

  return (
    <>
      <TextFieldNN
        label={`Nickname (${source.nickname ? source.nickname.length : 0}/${maxLengthNickName})`}
        id="nickname"
        error={!nickNameValid}
        onChange={(e) => {
          if (e.target.value.length <= maxLengthNickName) {
            setSource({
              ...source,
              nickname: e.target.value,
            })
            setNickNameValid(e.target.value.length > 0 && e.target.value.length <= maxLengthNickName)
          }
        }}
        type="text"
        value={source.nickname}
      />

      <UpdateSource
        onCancel={props.onCancel}
        onUpdate={props.onUpdate}
        disabled={!(nickNameValid && source.nickname && source.nickname.length > 0)}
        source={source}
      />
    </>
  )
}

export default UpdateNickname
