import React from 'react'
import Paper from '@mui/material/Paper'

const Loading = () => {
  return (
    <div className="paperOut">
      <Paper variant="borderless" className="paperIn">
        Loading...
      </Paper>
    </div>
  )
}

export default Loading
