import React from 'react'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from '../../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { User } from '__generated__/graphql'
import { gql } from '__generated__'

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUser_UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      lastName
      lastNameTemp
      firstName
      firstNameTemp
      unsubscribe
      whereDidyouHearAboutUs
      nameFile
      last4Social
      enabled2FAPhone
      enabled2FAEmail
      enabled2FATotp
      inviteMembersTooltip
      whereDidYouHearAboutUsSource
      createIssuedCardsTooltip
      switchAccountsTooltip
      createIssuedCardTooltip
      actionIssuedCardTooltip
      spendingLimitIssuedCardTooltip
      expiryDateIssuedCardTooltip
      copyClipboardIssuedCardTooltip
      isPhoneValidationRequired
      linkedInLink
      twitterLink
      facebookLink
      instagramLink
      dobYear
      dobMonth
      dobDay
    }
  }
`)

type Props = {
  updateTextButton: string
  disabled: boolean
  fullWidth?: boolean
  user: Partial<User> & Pick<User, 'id'>
  onUpdate: () => void
}

const UpdateUser = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)

  const updateUserF = async () => {
    setLoading(true)
    try {
      await updateUser({
        variables: {
          where: { id: props.user.id },
          data: {
            firstName: props.user.firstName,
            firstNameTemp: props.user.firstNameTemp,
            unsubscribe: props.user.unsubscribe,
            lastName: props.user.lastName,
            lastNameTemp: props.user.lastNameTemp,
            nameFile: props.user.nameFile,
            enabled2FATotp: props.user.enabled2FATotp,
            enabled2FAPhone: props.user.enabled2FAPhone,
            enabled2FAEmail: props.user.enabled2FAEmail,
            inviteMembersTooltip: props.user.inviteMembersTooltip,
            createIssuedCardsTooltip: props.user.createIssuedCardsTooltip,
            switchAccountsTooltip: props.user.switchAccountsTooltip,
            createIssuedCardTooltip: props.user.createIssuedCardTooltip,
            actionIssuedCardTooltip: props.user.actionIssuedCardTooltip,
            spendingLimitIssuedCardTooltip: props.user.spendingLimitIssuedCardTooltip,
            expiryDateIssuedCardTooltip: props.user.expiryDateIssuedCardTooltip,
            copyClipboardIssuedCardTooltip: props.user.copyClipboardIssuedCardTooltip,
            isPhoneValidationRequired: props.user.isPhoneValidationRequired,
            whereDidyouHearAboutUs: props.user.whereDidyouHearAboutUs,
            whereDidYouHearAboutUsSource: props.user.whereDidYouHearAboutUsSource,
            linkedInLink: props.user.linkedInLink,
            twitterLink: props.user.twitterLink,
            facebookLink: props.user.facebookLink,
            instagramLink: props.user.instagramLink,
            last4Social: props.user.last4Social,
            dobYear: props.user.dobYear,
            dobMonth: props.user.dobMonth,
            dobDay: props.user.dobDay,
          },
        },
      })
      props.onUpdate()
    } catch (error) {
      showErrors(error, setErrorMessage)
    }
    setLoading(false)
  }

  return (
    <>
      <ButtonLoadingAfterClick
        actionName="updateUser"
        id="updateUser"
        icon=""
        color="primary"
        disabled={props.disabled}
        sx={props.fullWidth ? { width: '100%' } : {}}
        variant="contained"
        size="medium"
        buttonText={props.updateTextButton}
        buttonLoadingText="Loading..."
        onClick={updateUserF}
        loading={loading}
      />{' '}
      <span className="secondary">{errorMessage}</span>{' '}
    </>
  )
}

export default UpdateUser
