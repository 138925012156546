import React from 'react'
import { Button } from '@mui/material'
import { Product, TypeMetaTag } from '__generated__/graphql'
import { useMavenKYCContext } from './MavenKYCContext'
import CompanyToMetaTagsManagement from 'components/metaTag/objectToMetaTagsManagement/CompanyToMetaTagsManagement'
import ProductICPLocationAtomic from 'components/product/single/edit/atomicField/ProductICPLocationAtomic'
import ProductICPIndustryAtomic from 'components/product/single/edit/atomicField/ProductICPIndustryAtomic'

type Props = {
  product: Pick<Product, 'id' | 'isIcpAllIndustries' | 'isIcpAllLocations'>
  onUpdate: () => void
}

const MavenTargetCustomersForm = (props: Props) => {
  const mavenKYCContext = useMavenKYCContext()
  return (
    <>
      <div className="titleStepKYC">Target customers</div>
      <div style={{ margin: '20px 30px 0' }}>
        <CompanyToMetaTagsManagement
          multiple
          productId={props.product.id}
          title="Company Size (number of employees)"
          type={TypeMetaTag.IcpCompanySize}
          onUpdate={() => mavenKYCContext.reloadKYCState()}
        />
        <ProductICPLocationAtomic product={props.product} onUpdate={() => mavenKYCContext.reloadKYCState()} />
        <ProductICPIndustryAtomic product={props.product} onUpdate={() => mavenKYCContext.reloadKYCState()} />
      </div>

      <div style={{ maxWidth: '350px', margin: '20px auto 0' }}>
        <div style={{ marginTop: '20px' }}>
          <Button
            id="idButtonUpdateProduct"
            data-action="updateProduct"
            size="medium"
            disabled={!mavenKYCContext.getStepStatus('targetCustomers')}
            color="primary"
            variant="contained"
            onClick={props.onUpdate}
            sx={{ width: '100%' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}
export default MavenTargetCustomersForm
