import { useQuery } from '@apollo/client'
import { useAppContextWithCompany } from 'components/AppContext'
import Error from 'components/nav/error/Error'
import NotFound from 'components/nav/error/NotFound'
import React from 'react'
import { gql } from '__generated__'
import { MavenKYCContext, MavenKYCContextType, MavenKYCStep } from './MavenKYCContext'

const KYC_QUERY = gql(/* GraphQL */ `
  query getMavenKYCStatus_MavenKYCContextProvider($companieId: String!) {
    getMavenKYCStatus(companieId: $companieId) {
      personalDetails
      personalLocation
      mavenType
      companyDetails
      mavenAddress
      basicInformation
      serviceCategories
      socialMedia
      productDescription
      valueProposition
      pricing
      targetCustomers
      payment
      submitForm
    }
  }
`)

type Props = {
  children: React.ReactNode
}

const stepsMap: MavenKYCStep[] = [
  'personalDetails',
  'personalLocation',
  'mavenType',
  'companyDetails',
  'mavenAddress',
  'basicInformation',
  'serviceCategories',
  'socialMedia',
  'productDescription',
  'valueProposition',
  'pricing',
  'targetCustomers',
  'payment',
  'submitForm',
  'requestSent',
]

const MavenKYCContextProvider = (props: Props) => {
  const [step, setStep] = React.useState(0)
  const [firstLoad, setFirstLoad] = React.useState(true)

  const appContext = useAppContextWithCompany()
  const { data, loading, error, refetch } = useQuery(KYC_QUERY, {
    variables: { companieId: appContext.userRoleCompanie.companie.id },
  })
  if (loading) return <></>
  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (!data) return <NotFound />

  const filledSteps = data.getMavenKYCStatus
  if (firstLoad) {
    setFirstLoad(false)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    setStep(firstUnfilled)
  }
  const nextStep = () => {
    if (step < stepsMap.length - 1) {
      setStep(step + 1)
    }
  }
  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }
  const setCurrentStep = (kycStep: MavenKYCStep, force?: boolean) => {
    console.log('setCurrentStep')
    if (step === stepsMap.length - 1) {
      return
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index <= firstUnfilled || force) {
      setStep(index)
    }
  }
  const getStepStatus = (kycStep: MavenKYCStep) => {
    if (!filledSteps[kycStep]) {
      return false
    }
    const index = Object.keys(stepsMap).findIndex((key) => stepsMap[key] === kycStep)
    const firstUnfilled = Object.keys(stepsMap).findIndex(
      (key) => filledSteps[stepsMap[key]] === false || filledSteps[stepsMap[key]] === undefined,
    )
    if (index < firstUnfilled) {
      return true
    }
    return false
  }

  const context: MavenKYCContextType = {
    filledSteps,
    currentStep: () => stepsMap[step],
    setCurrentStep,
    nextStep,
    previousStep,
    getStepStatus,
    reloadKYCState: refetch,
  }

  return <MavenKYCContext.Provider value={context}>{props.children}</MavenKYCContext.Provider>
}

export default MavenKYCContextProvider
