import React from 'react'
import { Button } from '@mui/material'
import UpdateUserDob from '../../action/UpdateUserDob'
import DateComponentDropDown from 'components/nav/DateComponentDropDown'
import { UserContext } from 'components/Context.type'

type Props = {
  buttonSave: string
  buttonCancel: string
  user: Pick<UserContext, 'id' | 'dobYear' | 'dobMonth' | 'dobDay'>
  showCancel: boolean
  onUpdate: () => void
  onCancel: () => void
}

const UserDobForm = (props: Props) => {
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() - 365 * 13)

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 365 * 100)

  const [user, setUser] = React.useState(props.user)
  const [isValid, setIsValid] = React.useState(false)

  const isFormValid = () => {
    return (
      isValid &&
      user.dobYear &&
      user.dobMonth &&
      user.dobDay &&
      new Date(user.dobYear, user.dobMonth - 1, user.dobDay) > minDate &&
      new Date(user.dobYear, user.dobMonth - 1, user.dobDay) < maxDate
    )
  }

  return (
    <>
      <div>
        <DateComponentDropDown
          year={user.dobYear}
          month={user.dobMonth}
          day={user.dobDay}
          onUpdateYear={(dobYear) => setUser({ ...user, dobYear })}
          onUpdateDay={(dobDay) => setUser({ ...user, dobDay })}
          onUpdateMonth={(dobMonth) => setUser({ ...user, dobMonth })}
          isValid={(isValid) => setIsValid(isValid)}
        />
      </div>
      <br />
      <div>
        <div style={{ height: 20 }} />
        <div>
          {props.showCancel && <Button onClick={props.onCancel}>{props.buttonCancel}</Button>}
          <UpdateUserDob
            disabled={!isFormValid()}
            user={{
              ...props.user,
              dobYearTemp: user.dobYear,
              dobMonthTemp: user.dobMonth,
              dobDayTemp: user.dobDay,
            }}
            updateTextButton={props.buttonSave}
            onUpdate={props.onUpdate}
          />
        </div>
      </div>
    </>
  )
}

export default UserDobForm
