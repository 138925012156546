import React from 'react'
import { Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { useMutation } from '@apollo/client'
import ButtonLoadingAfterClick from 'components/nav/ButtonLoadingAfterClick'
import PhoneForm from './PhoneForm'
import { gql } from '__generated__'
import { User } from '__generated__/graphql'

const MUTATION = gql(/* GraphQL */ `
  mutation updatePhone_UpdatePhone($phoneChangeRequested: String!, $phoneCodeChangeRequested: String!) {
    updatePhone(phoneChangeRequested: $phoneChangeRequested, phoneCodeChangeRequested: $phoneCodeChangeRequested) {
      id
      phoneChangeRequested
      phoneCodeChangeRequested
      isPhoneValidated
      isPhoneChangeRequestedPending
      phone
      phoneCode
    }
  }
`)

type Props = {
  showCancelButton: boolean
  user: Pick<User, 'id' | 'phoneChangeRequested' | 'phoneCodeChangeRequested'>
  onUpdate: () => void
  onCancel: () => void
}

const UpdatePhone: React.FC<Props> = (props: Props) => {
  const showErrors = useShowErrors()

  const [loading, setLoading] = React.useState(false)
  const [user, setUser] = React.useState(props.user)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [updatePhone] = useMutation(MUTATION)

  const isFormValid = () => {
    return user.phoneChangeRequested && user.phoneCodeChangeRequested
  }

  const updatePhoneF = async () => {
    setLoading(true)

    try {
      const data = await updatePhone({
        variables: {
          phoneChangeRequested: user.phoneChangeRequested,
          phoneCodeChangeRequested: user.phoneCodeChangeRequested,
        },
      })

      if (data) {
        setLoading(false)
        props.onUpdate()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setErrorMessage)
    }
  }

  return (
    <>
      <PhoneForm
        phone={user.phoneChangeRequested}
        phoneCode={user.phoneCodeChangeRequested}
        onChangePhone={(phone) => setUser({ ...user, phoneChangeRequested: phone })}
        onChangePhoneCode={(phoneCode) => setUser({ ...user, phoneCodeChangeRequested: phoneCode })}
        onKeyPress={updatePhoneF}
      />
      <div style={{ height: '10px' }} />
      {errorMessage && <div className="secondary">{errorMessage}</div>}
      <div>
        {props.showCancelButton && <Button onClick={() => props.onCancel()}>Cancel</Button>}{' '}
        <ButtonLoadingAfterClick
          actionName="updatePhone"
          id="idButton"
          icon=""
          color="primary"
          disabled={!isFormValid()}
          variant="outlined"
          size="medium"
          buttonText="Send verification code"
          buttonLoadingText="Loading..."
          onClick={updatePhoneF}
          loading={loading}
        />
      </div>
    </>
  )
}

export default UpdatePhone
