import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context, contextInit } from './Context.type'

export const AppContext = React.createContext({ ...contextInit })

export const useAppContext = (): Context => React.useContext(AppContext)

type ContextWithCompany = Context & { userRoleCompanie: NonNullable<Context['userRoleCompanie']> }

export const useAppContextWithCompany = (): ContextWithCompany => {
  const context = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()

  if (!context.userRoleCompanie) {
    console.error("User reached with-company component with null 'userRoleCompanie'")
    console.trace()
    if (location.pathname !== '/') navigate('/')
  }

  return context as ContextWithCompany
}
