import React from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { ProductOrderByInput } from '__generated__/graphql'
import useDebounce from 'hooks/useDebounce'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import Error from 'components/nav/error/Error'
import ImageTemplate from 'components/nav/ImageTemplate'

const QUERY = gql(/* GraphQL */ `
  query simpleProductsConnection_AddProductsRelationshipManagementSeller(
    $orderBy: ProductOrderByInput
    $where: ProductWhereInput
    $skip: Int
    $first: Int
  ) {
    simpleProductsConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      products {
        id
        name
        nameFile
        urlName
      }
    }
  }
`)

type Props = {
  onUpdate: (productId: string) => void
}

const AddProductsRelationshipManagementSeller = (props: Props) => {
  const [productName, setProductName] = React.useState('')

  const debouncedInput = useDebounce(productName, 50)

  const { error, data } = useQuery(QUERY, {
    variables: {
      orderBy: ProductOrderByInput.NameAsc,
      first: 10,
      skip: 0,
      where: {
        showShowcase: true,
        name: { contains: debouncedInput },
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  const products = data ? data.simpleProductsConnection.products : []

  return (
    <Autocomplete
      id="id-AddProductsRelationshipManagementSeller"
      onChange={(_e, value) => {
        if (value) props.onUpdate(value.id)
      }}
      options={products}
      inputValue={productName}
      onInputChange={(_e, name) => setProductName(name)}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box {...props} component="li" aria-selected={false}>
          <ImageTemplate nameFile={option.nameFile} format="roundSmall" /> <span style={{ width: 10 }} />
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField placeholder="Start typing..." {...params} />}
      noOptionsText={<span>{debouncedInput ? <div>No Result</div> : <>Start typing...</>}</span>}
    />
  )
}

export default AddProductsRelationshipManagementSeller
