import React from 'react'
import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import useShowErrors from 'hooks/useShowErrors'
import { Companie } from '__generated__/graphql'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation updateCompanie_UpdateCompanie($data: CompanieUpdateInput!, $where: CompanieWhereUniqueInput!) {
    updateCompanie(data: $data, where: $where) {
      id
      name
      registeredBusinessName
      website
      linkedin
      nameFile
      valueSpent

      leadershipTitle
      leadershipFirstName
      leadershipLastName
      typeCompanie
      leadershipPhone
      leadershipPhoneCode
      leadershipEmail
      leadershipIdNumberToken
      isOnboardingMembersDone
      hideDebitCredit
      hideDebitCard
      typeBusinessStructure
      registrationNumber
      hideAddBank

      questionPricingPage
      questionPayingCustomers
      questionHowManyUsersPerAccount
      questionHowGeographiesInterest
      questionSizeCustomersTargeting
      questionUserAcquisitionChannelsDoYouUseToday

      canManageSellerSubscriptionManagement
      sellerARR
      recommendationSellerRevshare
      onboardProcessDone
      stripe_cus_id
      stripe_cus_test_id
      emailCompany
    }
  }
`)

type Props = {
  actionName: string
  textButton: string
  textCancelButton: string
  showCancelButton: boolean
  disabled: boolean
  fullWidth?: boolean
  companie: Pick<Companie, 'id'> & Partial<Companie>
  onCancel: () => void
  onUpdate: (companieName: string) => void
}

const UpdateCompanie = (props: Props) => {
  const showErrors = useShowErrors()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [updateCompanie] = useMutation(MUTATION)

  const updateCompanieF = async () => {
    setLoading(true)
    try {
      const { data } = await updateCompanie({
        variables: {
          where: {
            id: props.companie.id,
          },
          data: {
            name: props.companie.name,
            website: props.companie.website,
            linkedin: props.companie.linkedin,
            nameFile: props.companie.nameFile,
            typeUploadNameFile: props.companie.typeUploadNameFile,
            typeBusinessStructure: props.companie.typeBusinessStructure,
            hideDebitCredit: props.companie.hideDebitCredit,
            hideDebitCard: props.companie.hideDebitCard,
            hideAddBank: props.companie.hideAddBank,
            registrationNumber: props.companie.registrationNumber,
            registeredBusinessName: props.companie.registeredBusinessName,
            canManageSellerSubscriptionManagement: props.companie.canManageSellerSubscriptionManagement,
            onboardProcessDone: props.companie.onboardProcessDone,
            mcc: props.companie.mcc,
            sellerARR: props.companie.sellerARR,
            recommendationSellerRevshare: props.companie.recommendationSellerRevshare,
            companyPhone: props.companie.companyPhone,
            companyPhoneCode: props.companie.companyPhoneCode,
            stripeCompanyStructure: props.companie.stripeCompanyStructure,
            stripeBusinessType: props.companie.stripeBusinessType,
            leadershipPhone: props.companie.leadershipPhone,
            leadershipPhoneCode: props.companie.leadershipPhoneCode,
            leadershipEmail: props.companie.leadershipEmail,
            leadershipTitle: props.companie.leadershipTitle,
            leadershipFirstName: props.companie.leadershipFirstName,
            leadershipLastName: props.companie.leadershipLastName,
            leadershipLast4: props.companie.leadershipLast4,
            leadershipDobYear: props.companie.leadershipDobYear,
            leadershipDobMonth: props.companie.leadershipDobMonth,
            leadershipDobDay: props.companie.leadershipDobDay,
            leadershipPercentOwnership: props.companie.leadershipPercentOwnership,
            leadershipIsOwner: props.companie.leadershipIsOwner,
            leadershipIdNumberToken: props.companie.leadershipIdNumberToken,
            emailCompany: props.companie.emailCompany,
            questionPricingPage: props.companie.questionPricingPage,
            questionPayingCustomers: props.companie.questionPayingCustomers,
            questionHowManyUsersPerAccount: props.companie.questionHowManyUsersPerAccount,
            questionHowGeographiesInterest: props.companie.questionHowGeographiesInterest,
            questionSizeCustomersTargeting: props.companie.questionSizeCustomersTargeting,
            questionUserAcquisitionChannelsDoYouUseToday: props.companie.questionUserAcquisitionChannelsDoYouUseToday,
          },
        },
      })
      if (data?.updateCompanie) props.onUpdate(data.updateCompanie.name)
    } catch (e) {
      showErrors(e, setErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {props.showCancelButton && <Button onClick={() => props.onCancel()}>{props.textCancelButton}</Button>}{' '}
      <ButtonLoadingAfterClick
        actionName={props.actionName}
        id="id-UpdateCompanie"
        icon=""
        color="primary"
        disabled={props.disabled}
        sx={props.fullWidth ? { width: '100%' } : {}}
        variant="contained"
        size="medium"
        buttonText={props.textButton}
        buttonLoadingText="Loading..."
        onClick={() => updateCompanieF()}
        loading={loading}
      />
      <p className="secondary">{errorMessage}</p>
    </>
  )
}

export default UpdateCompanie
