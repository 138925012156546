import React from 'react'
import { useAppContext } from 'components/AppContext'
import { useApolloClient } from '@apollo/client'
import { GraphQLError } from 'hooks/useShowErrors'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query meIdle_MyIdle {
    meIdle
  }
`)

const MyIdle = () => {
  const client = useApolloClient()
  const context = useAppContext()

  React.useEffect(() => {
    const fetchMyIdle = async () => {
      try {
        const data = await client.query({
          query: QUERY,
          fetchPolicy: 'no-cache',
          variables: {},
        })
        if (data && !context.me.id) {
          await client.resetStore()
        }
      } catch (e) {
        const isLoggedIn = !!context.me.id
        const isNotAuthError = (e as GraphQLError).graphQLErrors?.some((error) => error.message === 'NOT_AUTH')
        if (isLoggedIn && isNotAuthError) context.logout(['app'])
      }
    }

    const intervalId = setInterval(
      () => {
        fetchMyIdle()
      },
      1000 * 60 * 2,
    )

    return () => clearInterval(intervalId) //This is important
  }, [context, client])

  return null
}

export default MyIdle
