import React from 'react'
import utils from '../utils'
import { Addresse } from '__generated__/graphql'

type Props = {
  title: string
  addresse: Pick<Addresse, 'name' | 'address1' | 'address2' | 'city' | 'country' | 'state' | 'zip'>
}

const SingleAddresseView = (props: Props) => {
  return (
    <>
      <div>
        <h4>{props.title}</h4>
      </div>
      <div>{props.addresse.name}</div>
      <div>{props.addresse.address1}</div>
      <div>{props.addresse.address2}</div>
      <div>
        {props.addresse.city}, {utils.getLabelState(props.addresse.country, props.addresse.state)} {props.addresse.zip}
      </div>

      <div>{utils.getLabelCountry(props.addresse.country)}</div>
    </>
  )
}

export default SingleAddresseView
