import { Maybe } from '__generated__/graphql'

export type FrontEndForm<T extends {}> = {
  [P in keyof T]: T[P] extends number ? string : T[P] extends Maybe<number> ? Maybe<string> : T[P]
}

export const transformToFrontEndForm = <T extends {}>(form: T): FrontEndForm<T> => {
  const newForm = Object.assign({}, form) as any

  for (const key in form) {
    const value = form[key]
    if (typeof value === 'number') {
      newForm[key] = value.toString()
    }
  }

  return newForm
}
