import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import './Style.css'

type Props = {
  text: string
  options: string[]
  onChange: (data: any) => void
}

const EditField: React.FC<Props> = (props: Props) => {
  const [editorState, setEditorState] = React.useState(() => {
    const html = props.text
    const { contentBlocks, entityMap } = htmlToDraft(html)
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState)
    return editorState
  })

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Editor
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        stripPastedStyles={true}
        editorState={editorState}
        editorStyle={{ height: '220px' }}
        toolbar={{
          options: props.options,
          blockType: { options: ['Normal', 'H2', 'H3'] },
          inline: { options: ['bold'] },
          list: { options: ['unordered', 'ordered'] },
        }}
      />
    </div>
  )
}

export default EditField
