import React from 'react'
import { useAddToHomescreenPrompt } from './useAddToHomescreenPrompt'
import Button from '@mui/material/Button'
import { Paper } from '@mui/material'
import { ReactComponent as LogoNN } from 'assets/logo/nn/nachonacho-logotype-blue.svg'

const Pwa = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()
  const [status, setStatus] = React.useState('pending')

  // const hide = () => setVisibleState(false)

  React.useEffect(() => {
    if (prompt) {
      setStatus('ok')
    }
    if (prompt === null) {
      setStatus('error')
    }
  }, [prompt])

  console.log(prompt)
  return (
    <div className="tac">
      <div className="responsiveMargin2">
        <div className="paperOut">
          <div className="tac margin6" />
          <div style={{ height: 80 }} />
          <Paper variant="borderless" className="paperIn">
            <LogoNN width="300" />

            <div style={{ marginTop: 40 }}>
              <p>Install the nachonacho app if you device is compatible</p>
            </div>
            <div style={{ minHeight: 100 }}>
              {status === 'pending' && <div>Pending</div>}
              {status === 'ok' && (
                <div>
                  <Button variant="contained" onClick={promptToInstall}>
                    Install
                  </Button>
                </div>
              )}
              {status === 'error' && <div>...</div>}
              <div style={{ marginTop: 40 }}>
                <a href="/">
                  <Button>Home</Button>
                </a>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  )
}
export default Pwa
