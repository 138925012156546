import { Theme, useMediaQuery } from '@mui/material'
import { useAppContext } from 'components/AppContext'
import { ENV, FRESHCHAT_HOST, FRESHCHAT_SRC, FRESHCHAT_TOKEN, FRESHCHAT_WIDGET_ID } from 'config/config'
import React from 'react'

interface HTMLFreshchatScriptElement extends HTMLScriptElement {
  widgetId: string
  chat: boolean
}

interface FCUser {
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setEmail: (value: string) => void
}

interface FCWidget {
  init: (value: {
    token: string
    widgetUuid: string
    host: string
    siteId: string
    config: {
      eagerLoad: boolean

      headerProperty?: { direction?: string; appLogo?: string; appName?: string }
      cssNames?: { widget: string }
    }
  }) => void
  setExternalId: (value: string) => void
  isInitialized: () => boolean
  isOpen: () => boolean
  open: () => void
  user: FCUser
}

export interface WindowFreshChat extends Window {
  fcWidget?: FCWidget
}

const FreshChatWidget = () => {
  const [show, setShow] = React.useState(false)
  const context = useAppContext()
  const user = context.me
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  React.useEffect(() => {
    const interval = setInterval(() => {
      const fcWindow = window as WindowFreshChat
      const fcWidget = fcWindow.fcWidget

      if (fcWidget && !fcWidget.isInitialized()) {
        clearInterval(interval)
        fcWidget.init({
          token: FRESHCHAT_TOKEN,
          widgetUuid: FRESHCHAT_WIDGET_ID,
          host: FRESHCHAT_HOST,
          siteId: 'app.nachonacho.com',
          config: {
            eagerLoad: true,
            // headerProperty: {
            //   // direction: 'ltr',
            //   appName: 'Gadget God',
            //   appLogo: 'https://d1qb2nb5cznatu.cloudfront.net/startups/i/2473-2c38490d8e4c91660d86ff54ba5391ea-medium_jpg.jpg',
            // },
            // cssNames: {
            //   widget: 'custom_fc_frame',
            // },
          },
        })
        if (user) {
          fcWidget.setExternalId(user.id)
          fcWidget.user.setFirstName(user.firstName)
          fcWidget.user.setLastName(user.lastName)
          fcWidget.user.setEmail(user.email)
        }
      }
    }, 500)
    setShow(true)
    return () => clearInterval(interval)
  }, [show, user])

  // if (process.env.NODE_ENV !== 'production') return null

  React.useEffect(() => {
    const isProd = ENV === 'production'
    if (!show || isMobile || !isProd) return
    const id = 'freshchat'
    if (document.getElementById(id)) return
    const script = document.createElement('script') as HTMLFreshchatScriptElement
    script.async = true
    script.type = 'text/javascript'
    script.src = FRESHCHAT_SRC
    script.id = id
    // script.widgetId = FRESHCHAT_WIDGET_ID
    script.chat = true
    document.head.appendChild(script)
  }, [isMobile, show])

  return null
}

export default FreshChatWidget
