import { TypeCreationApp } from '__generated__/graphql'
import { URL_FRONTEND, GOOGLE } from 'config/config'

export type GoogleStateObject = {
  redirectPath: string
  typeCreationApp: TypeCreationApp
}

export const GOOGLE_SCOPES = {
  'userinfo.profile': 'https://www.googleapis.com/auth/userinfo.profile',
  'userinfo.email': 'https://www.googleapis.com/auth/userinfo.email',
  contacts: 'https://www.googleapis.com/auth/contacts.readonly',
  'admin.reports.usage': 'https://www.googleapis.com/auth/admin.reports.usage.readonly',
  'admin.directory.user': 'https://www.googleapis.com/auth/admin.directory.user.readonly',
} as const

type ScopeName = keyof typeof GOOGLE_SCOPES

const manageGoogle = {
  connect: async (options: { typeCreationApp: TypeCreationApp; onlyBusinessDomain?: boolean; scopes: ScopeName[] }) => {
    const { typeCreationApp, onlyBusinessDomain } = options

    // basic scopes
    const scopes = new Set<ScopeName>(['userinfo.profile', 'userinfo.email'])

    for (const scope of options.scopes) scopes.add(scope)
    const scope = Array.from(scopes)
      .map((scope) => GOOGLE_SCOPES[scope])
      .join(' ')

    const stateObject: GoogleStateObject = {
      redirectPath: window.location.pathname + window.location.search,
      typeCreationApp: typeCreationApp,
    }

    const state = JSON.stringify(stateObject)
    const searchParams = new URLSearchParams({
      access_type: 'offline',
      scope,
      response_type: 'code',
      client_id: GOOGLE.clientId,
      redirect_uri: `${URL_FRONTEND}/google`,
      state,
    })
    if (onlyBusinessDomain) {
      searchParams.set('hd', '*')
    }

    const url = `https://accounts.google.com/o/oauth2/v2/auth?${searchParams.toString()}`

    window.location.href = url
  },
}

export default manageGoogle
