import React from 'react'
import ButtonLoadingAfterClick from '../../nav/ButtonLoadingAfterClick'
import { useMutation } from '@apollo/client'
import { useAppContextWithCompany } from '../../AppContext'
import { Button } from '@mui/material'
import useShowErrors from 'hooks/useShowErrors'
import { gql } from '__generated__'

const MUTATION = gql(/* GraphQL */ `
  mutation deleteSourceLogically_DeleteSourceLogically($where: SourceWhereUniqueInput!) {
    deleteSourceLogically(where: $where) {
      id
    }
  }
`)

type Props = {
  buttonText: string
  sourceId: string
  onDelete: () => void
  onCancel: () => void
}

const DeleteSourceLogically = (props: Props) => {
  const showErrors = useShowErrors()
  const context = useAppContextWithCompany()
  // const client = useApolloClient()
  const [deleteSourceLogically] = useMutation(MUTATION)

  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const deleteSourceLogicallyF = async () => {
    setLoading(true)

    try {
      const balance = await deleteSourceLogically({
        variables: {
          where: {
            id: props.sourceId,
          },
        },
      })
      if (balance) {
        // await client.resetStore()
        context.openSnackbar(`Payment Source successfully deleted`, 'success')
        props.onDelete()
      }
    } catch (e) {
      setLoading(false)
      showErrors(e, setMessage)
      throw e
    }
    setLoading(false)
  }

  return (
    <>
      <Button onClick={props.onCancel}>Cancel</Button>{' '}
      <ButtonLoadingAfterClick
        actionName="deleteSource"
        id="idButton"
        icon=""
        disabled={false}
        color="primary"
        variant="outlined"
        size="medium"
        buttonText={props.buttonText}
        buttonLoadingText="Loading..."
        onClick={deleteSourceLogicallyF}
        loading={loading}
      />
      {message && (
        <div id="idMessage-DeleteSourceLogically" className="secondary">
          {message}
        </div>
      )}
    </>
  )
}

export default DeleteSourceLogically
