import React from 'react'
import { hotjar } from 'react-hotjar'
import { useAppContext } from '../AppContext'
import { HOTJAR, SPOOFED_USER_ID } from '../../config/config'

const HotJarComponent = () => {
  const spoofedUserId = localStorage.getItem(SPOOFED_USER_ID)

  const context = useAppContext()

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === 'local') return
    if (process.env.REACT_APP_ENV === 'dev') return
    if (process.env.REACT_APP_ENV === 'localProd') return
    if (context?.me?.role === 'ADMIN') return
    if (spoofedUserId) return
    if (!context?.me?.id) return
    if (!context?.me?.email) return

    hotjar.initialize(HOTJAR.hjid, HOTJAR.hjsv)

    hotjar.identify(context.me.id, { name: `${context.me.firstName} ${context.me.lastName}`, email: context.me.email })
  }, [context, spoofedUserId])

  return null
}

export default HotJarComponent
