import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import NotFound from '../../nav/error/NotFound'
import Loading from '../../nav/error/Loading'
import RedeemPromoCode from '../RedeemPromoCode'
import { gql } from '__generated__'
import SinglePromoCodeBasicPlus from './SinglePromoCodeBasicPlus'

const QUERY = gql(/* GraphQL */ `
  query promoCodesConnection_SettingsCompaniePromoCodes(
    $where: PromoCodeWhereInput!
    $orderBy: PromoCodeOrderByInput
    $skip: Int
    $first: Int
  ) {
    promoCodesConnection(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      edges {
        node {
          id
          code
          dateRedeem
          isRedeem
          isLifeTimeDeal
          description
          createdAt
          discount
          timeInMonth
          type
          partner {
            id
            name
            nameFile
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`)

type Props = {
  companieId: string
}

const SettingsCompaniePromoCodesBasicPlus = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        companie: {
          id: props.companieId,
        },
        isRedeem: true,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.promoCodesConnection) return <NotFound />

  return (
    <div className="">
      {/* <Paper variant="outlined" className="paperIn"> */}
      <div>
        {data.promoCodesConnection.edges.map((promoCodeNode) => (
          <div key={promoCodeNode.node.id}>
            <SinglePromoCodeBasicPlus promoCode={promoCodeNode.node} />
          </div>
        ))}
      </div>
      {data.promoCodesConnection.edges.length === 0 && <RedeemPromoCode companieId={props.companieId} />}
      {/* </Paper> */}
    </div>
  )
}

export default SettingsCompaniePromoCodesBasicPlus
