import React from 'react'
import { Grid, Divider } from '@mui/material'
import utils from '../../../utils'
import { Companie, Invoice, Maybe, Product, Subscription } from '__generated__/graphql'
import ImageInvoice from 'components/invoice/single/listSingle/ImageInvoice'
import PayInvoiceButton from 'components/invoice/single/action/PayInvoiceButton'
import { Link } from 'react-router-dom'

type Props = {
  invoice: Pick<Invoice, 'id' | 'dateInvoice' | 'status' | 'productCostLocal' | 'currency' | 'type' | 'buyerFinalPrice'> & {
    subscription?: Maybe<Pick<Subscription, 'id'>>
    product?: Maybe<Pick<Product, 'id' | 'nameFile' | 'name'>>
    companie: Pick<Companie, 'id'>
  }
}

const SingleInvoiceListSellerPaymentFee = (props: Props) => {
  return (
    <Link to={'/invoice/' + props.invoice.id} className="black">
      <div className="singleListMargin bgHover">
        <div className="paperOut">
          <Grid container>
            <Grid item xs={12} sm={3} className="marginAuto">
              <ImageInvoice invoice={props.invoice} format="roundSmall" />
            </Grid>

            <Grid item xs={12} sm={3} className="marginAuto">
              <div>{utils.priceFormated(-props.invoice.productCostLocal, props.invoice.currency)}</div>
            </Grid>
            <Grid item xs={12} sm={3} className="marginAuto">
              <div>{utils.mappingStatusInvoiceSellerFee(props.invoice.status)}</div>
            </Grid>
            <Grid item xs={12} sm={3} className="marginAuto">
              {props.invoice.status === 'DUE' && <PayInvoiceButton invoice={props.invoice} />}
            </Grid>
          </Grid>
        </div>
      </div>
      <Divider />
    </Link>
  )
}

export default SingleInvoiceListSellerPaymentFee
