import React from 'react'
import SingleProductLightAutocomplete from './SingleProductLightAutocomplete'
import { Product, Promotion } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import { gql } from '__generated__'

const QUERY = gql(/* GraphQL */ `
  query searchProduct_ProductsQueryAutocompleteLight(
    $searchString: String!
    $skip: Int
    $take: Int
    $filter: ProductSearchFilter
    $sortByPromotions: Boolean
  ) {
    searchProduct(searchString: $searchString, take: $take, skip: $skip, filter: $filter, sortByPromotions: $sortByPromotions) {
      nodes {
        id
        name
        nameFile
        urlName
        promotions {
          id
          text1
        }
      }
      count
    }
  }
`)

type AutocompleteSearchProductsVariables = {
  take: number
  skip: number
  filter: object
  searchString: string
}

type PartialProduct = Pick<Product, 'id' | 'urlName' | 'name' | 'nameFile'> & {
  promotions: Array<Pick<Promotion, 'id' | 'text1'>>
}

type Props = {
  variables: AutocompleteSearchProductsVariables
  onClick: (product: PartialProduct) => void
  showPromotions?: boolean
  sortByPromotions: boolean
}

const ProductsQueryAutocompleteLight = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: { ...props.variables, sortByPromotions: props.sortByPromotions },
  })

  if (error) return null
  if (loading) return null
  if (!data?.searchProduct) return null

  return (
    <>
      {data.searchProduct.nodes.length !== 0 &&
        data.searchProduct.nodes.map((product) => (
          <div key={product.id}>
            <SingleProductLightAutocomplete onClick={props.onClick} product={product} showPromotions={props.showPromotions} />
          </div>
        ))}
    </>
  )
}

export default ProductsQueryAutocompleteLight
