import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import theme from 'providers/theme'
import client from 'providers/apollo'
import AppContextProvider from './AppContextProvider'

const AppProviders = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AppContextProvider />
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default AppProviders
